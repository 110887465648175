import $ from 'jquery'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import TypingView from './typing'
import Velocity from 'velocity-animate'
import { extend, debounce, find } from 'lodash-es'
import { lesson_typing_block } from '@templates/student'

export default TypingView.extend({

  template: lesson_typing_block,

  // the state of the page: key | key_confirm | lesson
  mode: 'key',

  contentClassName: 'Basic',

  keysPerView: 8,

  initialize: function() {
    TypingView.prototype.initialize.apply(this, arguments);

    // if there is no new_key, go straight to the block style screens.
    // this whole screen type is screaming for a refactor
    if(!this.screen.get('new_key')) {
      this.mode = 'lesson';
    }

    var events = this.events || {};

    this.delegateEvents(
      extend(events, {
        'click .js-continue-button': 'showNextPart'
      })
    );
  },

  /**
   * Override this
   * @returns {*}
   */
  render: function() {
    this.listenTo(this.screen, 'change:typed', this.animate);

    var user = Registry.get('student'),
      content = this.screen.getContentByLine(),
      userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens'),
      fingerDetails = this.dictation.getFingerDetails(this.screen.get('new_key'), false),
      total = 0

    content = content.map((line) =>line.join('')).join('') // Turn the array of arrays into a string
      .match(/([^\n]+?){1,8}/g) // Separate the string by newline AND make sure each match has only 8 characters
      .map((line) => line.split('')) // turn array of strings into array of arrays

    this.contentMap = content.map(function (row) { total += row.length; return total; });

    this.$el.append(this.template({
      skin: find(FTWGLOBALS('skins'), {id: this.user.get('skin_id') || 1}),
      ads: AdView.canShowAds() && totalScreens > 0,
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens }),
      fingerDetails: fingerDetails,
      lesson: this.lesson.toJSON(),
      screen: this.screen.toJSON(),
      typingContent: content,
      productGrade: user.getCurriculumGrade(),
      mode: this.mode,
      user: user.toJSON()
    }));

    TypingView.prototype.render.apply(this, arguments);

    this.typingContent = this.$('.screenIntro-lines');

    if(this.mode === 'key') {
      this.toggleAnimateCorrectKey();
    }

    window.setTimeout(() => {  // let it render
      this.typingContent = this.$('.screenIntro-lines');
      this.cacheAnimations();
      if(this.mode === 'lesson') {
        this.setLessonMode(); // so hacky. 'block' screens are hacked into this intro thing
      }
    }, 0);

    Velocity(this.$('.frame-avatar'),
      {scaleX: ['1','0.2'], scaleY: ['1','0.5'], opacity: 1},
      {duration: 300, easing: [0.68, -0.55, 0.46, 1.59],}
    )
    Velocity(this.$('.frame-bubble'),
      {scaleX: ['1','0'], scaleY: ['1','0.2'], opacity: 1},
      {delay: 100, duration: 500, easing: [0.68, -0.55, 0.46, 1.39]}
    )

    window.setTimeout(() => {  // let it render
      this.typingContent = this.$('.screenIntro-lines');
      this.cacheAnimations();
    }, 10);

    window.setTimeout(() => {  // let it render
      this.typingContent = this.$('.screenIntro-lines');
      this.cacheAnimations();
    }, 1000);

    $(window).resize(debounce(this.cacheAnimations.bind(this), 1000, false));

    return this;
  },

  /**
   * Animate the keys sideways
   */
  animate: function(model, typed) {
    var newAnimationRow = this.contentMap.filter((row) => row <= typed).length;

    if(this.animationRow !== newAnimationRow) {
      this.animationRow = newAnimationRow;
      var duration = (this.lowPerformance) ? 100: 250,
        lineWidth = this.$('.screenIntro-line').outerWidth(true);
      Velocity(this.typingContent, {translateX: '-' + this.animationRow * lineWidth + 'px'}, {duration: duration, easing: 'ease-in-out'});
    }
  },

  keyBlinkTimeout: 0,
  toggleAnimateCorrectKey: function() {
    if(this.keyBlinkTimeout) {
      window.clearInterval(this.keyBlinkTimeout);
      this.keyBlinkTimeout = 0;
    } else {
      var key = this.$('.key.is-active');
      this.keyBlinkTimeout = window.setInterval(function(){
        key.toggleClass('is-active');
      }.bind(this), 500);
    }
  },

  handleInput: function(input) {
    if(input.dead) {
      // Handle dead keys in order to display the "Keyboard discrepancy" modal
      this.handleDeadKeyTyped(null, true);
      return;
    }

    if(this.mode === 'key') {
      if(input.special) {
        return false;
      }

      var singleLetterBubble = this.$('.js-single-letter js-bubble'),
        singleLetter = this.$('.js-single-letter .js-typing-content'),
        singleLetterConfirm = this.$('.js-single-letter .js-intro-confirm'),
        keyPressed = input.key.toLowerCase(), // don't care if it's wrong case
        correctKey = (this.screen.get('new_key') === '⏎') ? "\n" : this.screen.get('new_key');

      if(keyPressed === correctKey) {
        Velocity(singleLetterBubble, {opacity: 0.5});
        singleLetter.addClass('is-right').removeClass('is-wrong');
        Velocity(singleLetter,
          {scaleX: ['1','0.2'], scaleY: ['1','0.5']},
          { duration: 300,
            easing: [0.68, -0.55, 0.45, 3.59],
          }
        );
        Velocity(singleLetterConfirm, 'slideDown');

        this.showNextPart();
      } else {
        this.dictation.speakScreen({  // force dictation because these block screens have multiple steps
          isError: true,
          nextKey: correctKey,
          letterTyped: keyPressed
        });
        this.playSounds('error');
        singleLetter.addClass('is-wrong');
        if(this.keyboardView) {
          this.keyboardView.highlightErrorKey(keyPressed);
        }
      }
    } else if(this.mode === 'key_confirm') {
      if(input.key === '\n') {
        this.showNextPart();
      }
    }else {
      return TypingView.prototype.handleInput.apply(this, arguments);
    }
  },

  handleErrorTyped: function(model) {
    if(this.mode === 'lesson') {
      this.lettersTyped.trackError(model.get('correctLetter'));
      this.setPosition(model.get('typed'), false, true);
    }

    TypingView.prototype.handleErrorTyped.call(this, model);
  },

  showNextPart: function() {
    var key = this.screen.getContentByLine()[0][0].toLowerCase();

    if(this.mode === 'key') {
      this.mode = 'key_confirm';
      this.toggleAnimateCorrectKey();

    } else if(this.mode === 'key_confirm') {
      this.mode = 'lesson';
      this.setLessonMode();
    }

    this.dictation.speakScreen({
      begin: true,
      mode: this.mode,
      nextKey: key
    });
    return false;
  },

  setLessonMode: function() {
    var key = this.screen.getContentByLine()[0][0].toLowerCase(),
      singleLetterContent = this.$('.js-single-letter'),
      keyboard = this.$('.js-keyboard-holder'),
      typingContent = this.$('.js-screen-content'),
      nextKeyboard = this.$('.js-keyboard-holder-next');

    singleLetterContent.addClass('hide');
    typingContent.removeClass('hide');
    nextKeyboard.replaceWith(keyboard);
    if(this.keyboardView) {
      this.keyboardView.highlightKey(key);
    }

    window.setTimeout(() => {
      this.setPosition(0);
      this.startCursorAnimation();
    }, 0);
  }
})
