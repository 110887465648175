import Backbone from 'backbone'
import Registry from '@registry'
import LayoutView from '../global/views/layout'
import ChangeCurriculumModal from './views/change_curriculum_modal';
import Alert from '../global/views/alert';
import MainView from './views/main'

export default Backbone.View.extend({

  layout: LayoutView,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.userLessons = Registry.get('userLessons'); // this is the localStorage stored lesson progress collection
    this.userLessons.set(Registry.get('userTests').getCompiled(), {remove:false});

    // set progress and stats on lessons and courses collections so they can be rendered with data
    Registry.get('lessons').setProgress(this.userLessons);

    Registry.get('units').setProgress(Registry.get('lessons'));

    // After setting up the user's lessons, update the assignments with this lesson data
    Registry.get('assignments').setLessons()

    this.views.main = new MainView();
    this.addChild(this.el, this.views.main, true);
    this.render();

    if(this.newProduct) {
      // Only allow the user to switch curriculum if they aren't in a class
      if(this.newProduct !== 'notFound' && !Registry.get('student').get('in_class')) {
        new ChangeCurriculumModal({
          product: this.newProduct
        }).open()
      } else {
        new Alert({
          title: 'lesson.lesson_not_found_title'.t(),
          text: 'lesson.lesson_not_found_text'.t(),
          closeButton: false
        }).on('ok', () => {
          const googleClassroomAddon = window.opener && window.localStorage.getItem('googleClassroomAddonData')
          if (googleClassroomAddon) {
            return window.location.href = __url('/student/google-classroom-addon-login')
          }
          window.location.href = __url('/student/lessons')
        })
      }
    }
  }
})
