import Modal from './modal'
import Model from '@shared/language_form'
import Registry from '@registry'
import { global_curriculum_modal } from '@templates/student'

export default Modal.extend({

  template: global_curriculum_modal,

  width: 'lg',

  formSelector: 'form',

  modelClass: Model,

  firstSet: false,

  events: {
    'click .js-curriculum': 'switchCurriculum',
    'keydown .js-curriculum': 'handleKeydown'
  },

  initialize: function(options) {

    this.titleText = options.firstSet ?  'curriculum.welcome_title_text'.t() : 'shared.select_a_curriculum_text'.t();
    this.okText = options.firstSet ?  'curriculum.get_started_text'.t() : 'curriculum.update_text'.t();

    Modal.prototype.initialize.apply(this, arguments);
  },

  serialize: function() {
    return {
      classicProducts: FTWGLOBALS('products').filter((row) => ['typing', 'typing_es', 'typing_br'].includes(row.product_id)),
      newProducts: FTWGLOBALS('products').filter((row) => !['typing', 'typing_es', 'typing_br'].includes(row.product_id)),
      product_id: this.productId,
      firstSet: this.firstSet
    }
  },

  switchCurriculum: function(e) {
    const id = this.$(e.currentTarget).data('id')

    this.$('.js-curriculum').removeClass("is-selected")
    this.$(`.js-curriculum[data-id=${id}]`).addClass("is-selected")

    this.$('input[name=product_id]').val(id)
  },

  handleKeydown: function(e) {
    if (e.which === 13) {
      this.switchCurriculum(e);
    }
  },

  /**
   * Override submitting so we don't close the modal.
   * @param data
   * @returns {boolean}
   */
  submitCallback: function(data) {
    if(data.product_id === this.productId) {
      this.close()
      return false
    } else {
      const product = FTWGLOBALS('products').find((row) => row.product_id === data.product_id),
        user = Registry.get('student')

      if(data.product_id !== this.productId) {
        // Sometimes the user's skin can be updated when setting curriculum
        if(data.skin_id) {
          user.changeSkin(data.skin_id)
          delete data.skin_id
        }
        user.setSettings(data)
        window.location.reload()
      }
    }

    return false
  },
})
