import $ from 'jquery'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import TypingView from './typing'
import Velocity from 'velocity-animate'
import { debounce } from 'lodash-es'
import { lesson_typing_standard } from '@templates/student'
import { getIntroAIHtml } from '../../utils/problem_keys_lesson';

export default TypingView.extend({

  template: lesson_typing_standard,

  contentClassName: 'Basic',

  preAnimationLines: 0, // this is really only a coding curriculum thing
  postAnimationLines: 1,

  /**
   * Override this
   * @returns {*}
   */
  render: function() {
    Registry.set('preventKeyboardInput', true);

    this.listenTo(this.screen, 'change:letterCacheIndex', this.animate);

    const userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens'),
      unitType = this.unit ? this.unit.get('type') : '';

    // Advanced screen types need to be treated like coding screens so they scroll properly
    if(this.lesson.get('content_type') === 'adventure' && (this.lesson.get('settings')?.display === 'email' || this.lesson.get('settings')?.display === 'resume' || this.lesson.get('settings')?.display === 'vocab' || this.lesson.get('settings')?.slug === 'dictionary')) {
      this.postAnimationLines = 2
    }

    this.$el.append(this.template({
      ads: AdView.canShowAds() && (unitType === 'test' || (unitType !== 'test' && totalScreens > 0)), // Always show ads on the test page
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens, forceAd: unitType === 'test' }),
      lesson: this.lesson.toJSON(),
      screen: this.screen.toJSON(),
      typingContent: this.screen.getContentByWord(),
      testType: this.lesson.getTestType(),
      wordsPerPage: this.wordsPerPage,
      user: this.user.toJSON(),
      displayType: this.lesson.get('content_type') === 'adventure' && (this.lesson.get('settings')?.display === 'email' || this.lesson.get('settings')?.display === 'resume' || this.lesson.get('settings')?.display === 'vocab' || this.lesson.get('settings')?.slug === 'dictionary') ? 'advanced' : this.screen.get('settings')?.display,
      image: this.screen.get('settings')?.image,
      hasKeyboard: this.keyboardView && this.user.getSetting('show_keyboard')
    }));

    TypingView.prototype.render.apply(this, arguments);

    this.typingContent = this.$('.screen' + this.contentClassName + '-lines');

    if (this.problemKeysLesson && this.user.hasOption('problemkeysai') && this.user.getSetting('AIContent')) {
      this.triggerLetterFadeInAnimation();
    } else {
      this.startCursorAnimation();
    }

    // this is such a hassle because dom changes..
    window.setTimeout(() => {  // let it render
      this.triggerCacheAnimations();
      this.setPosition(0);
    }, 0);
    window.setTimeout(() => this.triggerCacheAnimations(), 10);
    window.setTimeout(() => this.triggerCacheAnimations(), 1000);
    $(window).resize(debounce(() => this.cacheAnimations(), 1000, false));

    return this;
  },

  triggerCacheAnimations: function() {
    this.typingContent = this.$('.js-screen-lines');
    this.cacheAnimations();
  },

  /**
   * Animate the vertically
   */
  topOffset: 0,
  lastOffset: 0,
  rowHeight: 0,
  lastAnimationLine: 0,
  animate: function(model, index) {
    var nextEle = this.lettersCache[index+1],
      currentEle = this.lettersCache[index];

    if(!nextEle) return;
    if(currentEle.animationOffset !== this.lastOffset) {
      var offset = currentEle.animationOffset;
      this.lastOffset = offset;
      // Only animate if there are visible lines below the viewing area.
      if((this.screen.get('lines') - this.lastAnimationLine > this.screen.get('visibleLines') - this.postAnimationLines) &&
        currentEle.animationLine !== this.lastAnimationLine
      ) {
        this.lastAnimationLine = currentEle.animationLine;
        var duration = (this.lowPerformance) ? 100 : 250;
        var translateY = parseInt('-'+ (offset - (this.topOffset))) + (this.preAnimationLines * this.rowHeight);
        if(this.preAnimationLines && this.screen.get('typed') === 0) {
          Velocity(this.typingContent, 'stop')
          Velocity(this.typingContent, {translateY: translateY + 'px'}, {easing: 'ease-in-out', duration: 1000});
        } else {
          Velocity(this.typingContent, 'stop')
          Velocity(this.typingContent, {translateY: translateY + 'px'}, {easing: 'ease-in-out', duration: duration});
        }
      }
    }
  },

  triggerLetterFadeInAnimation: function() {
    // check to see if the user has the keyboard hide setting or not
    const showKeyboardSetting = this.user.hasOption('showkeyboard');
    const delay = showKeyboardSetting ? 25 : 15;
    const numberOfCharacters = showKeyboardSetting ? 250 : 500;

    const icon = this.$('.js-icon-element').show();
    const letters = this.$('.screenBasic-letter');
    letters.css("opacity", 0);

    setTimeout(() => {
      this.animateLetterFadeIn(icon, letters, numberOfCharacters, delay);
    }, 1500); // delay letter appearance and icon moving for 1.5 seconds
  },

  animateLetterFadeIn: function(icon, letters, numberOfCharacters, delay) {
    icon.removeClass('blink-svg'); // remove blink animation
    this.startCursorAnimation();

    letters.slice(0, numberOfCharacters).each((i, el) => {
      const letterElement = $(el);
      setTimeout(() => {
        this.showLetterAndMoveIcon(icon, letterElement, letters, i, numberOfCharacters, delay);
      }, i * delay);
    });
  },

  showLetterAndMoveIcon: function(icon, letterElement, letters, i, numberOfCharacters, delay) {
    letterElement.css("opacity", 1);

    const nextLetter = letters.eq(i + 1);
    if (nextLetter.length > 0) {
      const iconLeftPosition = nextLetter.position().left;
      const iconTopPosition = nextLetter.position().top;
      const extraTopPosition = 15;
      const extraLeftPosition = 30;

      if (nextLetter.position().top > letterElement.position().top) {
        // If the next letter is on a new line, fade out the icon
        icon.animate({opacity: 0}, {
          duration: delay,
          complete: () => {
            // After the icon has faded out, wait for a bit before moving the icon and fading it in
            setTimeout(() => {
              // Move the icon
              icon.css({
                top: iconTopPosition + extraTopPosition,
                left: iconLeftPosition + extraLeftPosition,
              });
              // Fade in the icon
              icon.animate({opacity: 1}, delay);
            }, delay);
          }
        });
      } else {
        icon.animate(
          {
            top: iconTopPosition + extraTopPosition,
            left: iconLeftPosition + extraLeftPosition,
          },
          {
            duration: delay,
            queue: false
          }
        );
      }
    }

    if (i === numberOfCharacters - 1) {
      setTimeout(() => {
        letters.slice(numberOfCharacters).css("opacity", 1);
        // Then fade out and remove the icon
        this.fadeOutIcon(icon, delay);
      }, delay);
    }
  },

  fadeOutIcon: function(icon, delay) {
    icon.animate(
      { opacity: 0 },
      {
        duration: 1000,
        complete: function() {
          icon.remove(); // remove the icon from the DOM after fading out
        }
      }
    );
  }
})
