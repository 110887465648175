
// generates a ID using the current timestamp
export function nextID() {
  return Date.now().toString(36)
}

// shortcut for a SVG G element
export const newContainer = () => document.createElementNS('http://www.w3.org/2000/svg', 'g')

// just a quick pause
export const nextFrame = async () => new Promise(requestAnimationFrame)

// gets a bounding box using the position and size of a shape
// NOTE: previously supported scaling for shapes, but at the moment
// that is not enabled
export function getBounds(shape) {
  const size = 0.5
  return {
    left: shape.x - size,
    right: shape.x + size,
    top: shape.y - size,
    bottom: shape.y + size,
  }
}

// checks if two boxes overlap with each other
export function overlaps(a, b, extend = 0.01) {
  return !(
    (a.bottom + extend) < (b.top - extend)
    || (a.top - extend) > (b.bottom + extend)
    || (a.right + extend) < (b.left - extend)
    || (a.left - extend) > (b.right + extend)
  )
}

// merges two bounding boxes
export function merge(a, b) {
  return {
    left: Math.min(a.left, b.left),
    right: Math.max(a.right, b.right),
    top: Math.min(a.top, b.top),
    bottom: Math.max(a.bottom, b.bottom),
  }
}

// attempts to create groups from nearby groups
// TODO: this can be better as it tends to assign old group configurations to the wrong parent. Look at this next
export function createVisualGroups(content) {
  const scene = content.shapes.reduce((options, shape) => Object.assign(options, {
    [shape.id]: {
      id: shape.id,
      bounds: getBounds(shape)
    }
  }), { })

  // tracking the generated groups
  const groups = [ ]

  // with each group, start trying to combine into a single group
  let bounds
  let group

  // start scanning for groups
  let safety = 1000
  while (--safety > 0) {
    let ids = Object.keys(scene)

    // nothing left to find
    if (!ids.length) {
      break
    }

    // check if there's an active shape
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i]
      const shape = scene[id]
      let restart

      // we've run out of shapes
      if (!shape) {
        continue
      }

      // no shapes have been started
      if (!bounds) {
        group = [id]
        bounds = { ...shape.bounds }
        restart = true
      }

      // if there's a shape and it overlaps, merge it
      // into the current group
      if (!restart && overlaps(bounds, shape.bounds)) {
        group.push(id)
        bounds = merge(bounds, shape.bounds)
        restart = true
      }

      // remove this entry and return to the start
      if (restart) {
        delete scene[id]
        ids[i] = undefined
        i = 0
      }
    }

    // appears to be nothing left to merge
    groups.push({
      id: groups.length + 1,
      ids: group,
      bounds
    })

    // reset the group
    bounds = null
  }

  return groups
}

// handles reordering layers based on the requested operation
export function reorderShapes(id, shapes, operation) {
  const index = shapes.findIndex(item => item.id === id)

  // not a shape? shouldn't happen
  if (index === -1) {
    return
  }

  // check a few things
  const { length: total } = shapes
  const atTop = index === total - 1
  const atBottom = index === 0
  const shape = shapes[index]

  // go ahead and remove the shape
  let copy = [...shapes]
  copy.splice(index, 1)

  // perform the update
  switch (operation) {
  case 'up':
    if (atTop) {
      return shapes
    }

    // adjust order
    return [
      ...copy.slice(0, index + 1),
      shape,
      ...copy.slice(index + 1)
    ]

  case 'down':
    if (atBottom) {
      return shapes
    }

    // adjust order
    return [
      ...copy.slice(0, index - 1),
      shape,
      ...copy.slice(index - 1)
    ]

  case 'top':
  case 'front':
    return [...copy, shape]

  case 'bottom':
  case 'back':
    return [shape, ...copy]

  default:
    console.error('unsupported ordering')
    return shapes
  }

}

// rotate a point around a common center
function rotate(x, y, rotation) {
  const center = 0.5
  const precision = 100
  const cos = Math.cos(rotation)
  const sin = Math.sin(rotation)
  const nx = (cos * (x - center)) + (sin * (y - center)) + center;
  const ny = (cos * (y - center)) - (sin * (x - center)) + center;

  // all points on a generated path
  // are offset from the center for the
  // physics library. Points are rotated from
  // the center and then shifted over
  return [
    ((0 | (nx * precision)) / precision) - center,
    ((0 | (ny * precision)) / precision) - center
  ]
}

// creates a path generator used for special shapes
// to create more accurate hit boxes
export function createPathGenerator(...path) {
  return (rotation) => {
    // match to rotation property
    const radians = [
      Math.PI,
      Math.PI * 0.5,
      0,
      Math.PI * 1.5,
    ][rotation]

    // generate the new points
    const generated = [ ]
    for (const [x, y] of path) {
      generated.push(rotate(x, y, radians))
    }

    return generated
  }
}
