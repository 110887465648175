import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import SkinsCollection from '../../global/collections/skins'
import AdButtonsView from '../../global/views/ad_buttons'
import AdView from '../../global/views/ad'
import { each, times } from 'lodash-es'
import { skins_main } from '@templates/student'
import user from '../../global/models/user'

export default Backbone.View.extend({

  template: skins_main,

  events: {
    'click .js-skin': 'selectSkin',
    'keydown .js-skin': function(e) {
      if(e.which === 13 && this.$(document.activeElement).hasClass("js-skin")) {
        this.selectSkin(e);
      }
    }
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.skins = new SkinsCollection(FTWGLOBALS('skins'));
    this.user = Registry.get('student');
    this.userActivity = Registry.get('userActivity');

    if(AdView.canShowAds()) {
      AdView.initAds('other');
      // grab all the ads and assign them to the right divs
      var ads = AdView.getAds();
      each(ads, (ad, size) => {
        if(!ad) { return; }
        this.addChild('.js-'+size+'-ad', ad);
      });

      var adButtonsView = new AdButtonsView();
      this.addChild('.js-ad-buttons', adButtonsView, false);
    }
  },

  serialize: function() {
    var skin = this.skins.get(this.user.get('skin_id') || 1),
      activity = this.userActivity.getOrAdd(0).toJSON(),
      rank = skin.getRank(activity.typed - activity.errors);
      userSkinId = this.user.get('skin_id');
      userVariantId = this.user.get('variant_id');
    
    return {
      lodash: { times },
      ads: AdView.canShowAds(),
      adsClass: AdView.getLeftMarginClass(),
      rank: rank,
      skins: this.skins.toJSON()
    };
  },


  createAlertElement: function(message) {
    // Create a new p element
    var p = document.createElement('p');
  
    // Set the p's class, role, aria-live, and innerHTML
    p.className = 'sr-only js-skin-aria-live-text';
    p.setAttribute('role', 'alert');
    p.setAttribute('aria-live', 'polite');
    p.textContent = message;
  
    return p;
  },

  clearAlertElement: function() {
    var alertElement = document.querySelector('.js-skin-aria-live-text');
    if(alertElement) {
      alertElement.remove();
    }
  },

  selectSkin: function(e) {
    var skinId = $(e.currentTarget).data('skin_id'),
      variantId = $(e.currentTarget).data('variant_id');
    $(e.currentTarget).attr('aria-selected', 'true');
    $('.theme-avatar').not(e.currentTarget).attr('aria-selected', 'false');
    this.user.changeSkin(skinId, variantId);

    var alertElement = this.createAlertElement('skin.change_theme_success'.t());
    document.body.appendChild(alertElement);
    setTimeout(this.clearAlertElement, 5000);

    return false;
  }
})
