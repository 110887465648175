import TypingStandardView from './typing_standard'
import SyntaxHighlighter from '@shared/syntax_highlighter'
import { lesson_typing_coding } from '@templates/student'

export default TypingStandardView.extend({

  template: lesson_typing_coding,

  contentClassName: 'Code',

  preAnimationLines: 1, // this is really only a coding curriculum thing
  postAnimationLines: 2,

  initialize: function() {
    TypingStandardView.prototype.initialize.apply(this, arguments);

    this.syntaxColors = SyntaxHighlighter.getColorArray(this.screen.get('content'), this.screen.get('content_setting'));
  }
})
