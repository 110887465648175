import Backbone from 'backbone'
import Registry from '@registry'
import { account_profile } from '@templates/student'

export default Backbone.View.extend({

  template: account_profile,

  formSelector: 'form',

  initialize: function() {
    this.successNotice = 'account.profile_updated'.t();
  },

  serialize: function() {
    return {
      user: Registry.get('student').toJSON(),
    };
  },

  submitCallback: function(data) {
    Registry.get('student').set(data);
    Backbone.View.prototype.submitCallback.apply(this, arguments);
  }
})
