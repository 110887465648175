import Backbone from 'backbone'
import Registry from '@registry'
import LayoutView from '../global/views/layout'
import MainView from './views/main'
import ScoreboardCollection from './collections/scoreboard'

export default Backbone.View.extend({

  layout: LayoutView,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    if(!Registry.get('student').hasOption('scoreboard')) {
      window.location.href = __url('/student/lessons');
      return;
    }

    var scoreboard = new ScoreboardCollection();
    scoreboard.fetch({reset: true});
    this.listenTo(scoreboard, 'reset', function(){this.render();}.bind(this));

    this.views.mainView = new MainView({
      scoreboard: scoreboard
    });

    this.addChild(this.el, this.views.mainView, true);
  }
})
