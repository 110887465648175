import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import ReportAdModal from './report_content_modal'
import { global_ad_buttons } from '@templates/student'

export default Backbone.View.extend({

  template: global_ad_buttons,

  events: {
    'click .js-report-ad': 'handleReportButton',
    'click .js-remove-ad': 'removeAd'
  },

  showReportAd: function() {
    Registry.set('preventKeyboardInput', true);
    var modal = new ReportAdModal({ user: Registry.get('student') })
      .always(function(){
        Registry.set('preventKeyboardInput', false);  // allow typing in modal's input fields while on the lessons pages
        $('.structure').removeClass('is-dimmed');     // remove background overlay
        $('.js-input-box').focus();                   // if on the lessons page, this will refocus into the necessary input
      });
    modal.open();
    $('.structure').addClass('is-dimmed');
  },

  removeAd: function() {
    window.location.href = __url('/student/upgrade');
    return false;
  },

  serialize: function () {
    return {
      adNetwork: window.__adNetwork
    };
  },

  handleReportButton: function() {
    const freestarReportButton = $(".__fs-ancillary .report-ad-button, #tc-report-btn");
    const playwireReportButton = $(".pw_report_ad_container button, #tc-report-btn");

    if(freestarReportButton.length > 0) {
      freestarReportButton.click();
    } else if(playwireReportButton.length > 0) {
      playwireReportButton.click();
    } else {
      this.showReportAd();
    }
  }

})
