import $ from 'jquery'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import TypingView from './typing'
import Velocity from 'velocity-animate'
import { debounce, find } from 'lodash-es'
import { lesson_typing_vocab } from '@templates/student'

export default TypingView.extend({

  template: lesson_typing_vocab,

  contentClassName: 'Basic',

  /**
   * Override this
   * @returns {*}
   */
  render: function() {
    this.listenTo(this.screen, 'change:typed', this.animate);

    var user = Registry.get('student'),
      content = this.screen.get('content'),
      userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens'),
      fingerDetails = this.dictation.getFingerDetails(this.screen.get('new_key'), false);

    content = content
      .replace(/\n/g, '')
      .replace(/\s+/g, ' ')   // take newlines off the end
      .split('') // turn into array of letters

    this.$el.append(this.template({
      skin: find(FTWGLOBALS('skins'), {id: this.user.get('skin_id') || 1}),
      ads: AdView.canShowAds() && totalScreens > 0,
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens }),
      fingerDetails: fingerDetails,
      lesson: this.lesson.toJSON(),
      screen: this.screen.toJSON(),
      typingContent: content,
      image: this.screen.get('settings')?.image,
      user: user.toJSON()
    }));

    TypingView.prototype.render.apply(this, arguments);

    this.typingContent = this.$('.screenIntro-lines');

    window.setTimeout(() => {  // let it render
      this.typingContent = this.$('.screenIntro-lines');
      this.cacheAnimations();
      this.setLessonMode(); // so hacky. 'block' screens are hacked into this intro thing
    }, 0);

    window.setTimeout(() => {  // let it render
      this.typingContent = this.$('.screenIntro-lines');
      this.cacheAnimations();
    }, 10);

    window.setTimeout(() => {  // let it render
      this.typingContent = this.$('.screenIntro-lines');
      this.cacheAnimations();
    }, 1000);

    $(window).resize(debounce(this.cacheAnimations.bind(this), 1000, false));

    return this;
  },

  keyBlinkTimeout: 0,
  toggleAnimateCorrectKey: function() {
    if(this.keyBlinkTimeout) {
      window.clearInterval(this.keyBlinkTimeout);
      this.keyBlinkTimeout = 0;
    } else {
      var key = this.$('.key.is-active');
      this.keyBlinkTimeout = window.setInterval(function(){
        key.toggleClass('is-active');
      }.bind(this), 500);
    }
  },

  handleInput: function(input) {
    if(input.dead) {
      // Handle dead keys in order to display the "Keyboard discrepancy" modal
      this.handleDeadKeyTyped(null, true);
      return;
    }

    return TypingView.prototype.handleInput.apply(this, arguments);
  },

  setLessonMode: function() {
    var key = this.screen.getContentByLine()[0][0].toLowerCase();

    if(this.keyboardView) {
      this.keyboardView.highlightKey(key);
    }

    window.setTimeout(() => {
      this.setPosition(0);
      this.startCursorAnimation();
    }, 0);
  }
})
