import Alert from '@shared/alert'

// TODO: Remove this file from the student portal - it has already been removed from the teacher portal
export default Alert.extend({
  initialize: function(options) {
    options = options || {};
    if(!options.cancel) {
      options.cancel = 'shared.cancel_text'.t();
    }
    return Alert.prototype.initialize.call(this, options);
  }

})
