import Backbone from 'backbone'
import LayoutView from '../global/views/layout'
import { debug_keyboard } from '@templates/student'

export default Backbone.View.extend({

  layout: LayoutView,

  template: debug_keyboard,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.render();
  }
})
