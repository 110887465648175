import FormModel from '@shared/form_model'

export default FormModel.extend({

  url: '/apiv1/student/auth/signup',

  defaults: {
    username: '',
    password: '',
    password2: '',
    email: '',
    language: ''
  },

  validationRules: {
    username: {
      minlength: 4,
      maxlength: 30,
      username: true,
      required: true,
      remote: {
        url: '/apiv1/student/auth/username',
        type: 'post',
        data: {}
      }
    },
    email: {
      email: true,
      // emailExtended: true
    },
    password: {
      required: true,
      minlength: 4,
      notMatchUsername: true
    },
    password2: {
      required: true,
      minlength: 4,
      equalTo: '#reg-form input[name=password]'
    },
    tos: {
      required: !FTWGLOBALS('whitelabel')
    },
    coppa: {
      required: !FTWGLOBALS('whitelabel')
    }
  },

  validationMessages: {
    username: {
      remote: 'shared.username_exists'.t(),
      minlength: 'shared.username_invalid_length'.t(),
      maxlength: 'shared.username_invalid_length'.t()
    },
    email: {
      email: 'shared.invalid_email_optional'.t(),
      remote: 'auth.email_exists'.t()
    },
    password: {
      notMatchUsername: 'shared.password_can_not_match_username'.t(),
      minlength: 'shared.password_invalid_length'.t()
    },
    password2: {
      equalTo: 'shared.passwords_do_not_match'.t()
    }
  }
})
