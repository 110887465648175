import Backbone from 'backbone'
import Registry from '@registry'
import { account_password } from '@templates/student'

export default Backbone.View.extend({

  template: account_password,

  formSelector: 'form',

  initialize: function(options) {
    this.successNotice = 'account.password_updated'.t();
  },

  submitCallback: function() {
    Backbone.View.prototype.submitCallback.apply(this, arguments);
    Registry.get('student').unset('must_change_password');
  }
})
