import $ from 'jquery'
import { sample, random, each } from 'lodash-es'

let CONFIG = {
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    multiColor: false,
    launchCount: 0,
    burstsOnClick: 0,
    gravity: 9.81,
    fade: 0,
    colors: [],
    assets: ['/dist/shared/images/particles/star-1.png', '/dist/shared/images/particles/star-2.png', '/dist/shared/images/particles/star-3.png']
  },
  EXPLOSIONS = {
    'firework': Firework,
    'confetti': Confetti,
    'custom': Custom
  },
  assets = {},
  canvas = null,
  context = null,
  particles = [],
  rockets = [],
  totalBurstsOnClick = 0,
  totalLaunches = 0,
  lastFrameTime = Date.now(),
  deltaTime = Date.now(),
  lastRocketLaunch = Date.now();

function launch(x) {
  if (rockets.length < 5) {
    var rocket = new Rocket(x);
    rockets.push(rocket);
    lastRocketLaunch = Date.now();
    totalLaunches++;
  }
}

function createExplosion(options) {
  options = Object.assign({
    count: 40,
    type: 'firework',
    x: 0, y: 0,
    color: CONFIG.colors.length > 1 ? sample(CONFIG.colors) : '#ffffff',
    spread: 20
  }, options);

  for (var i = 0; i < options.count; i++) {
    var particle = new EXPLOSIONS[options.type]({ x: options.x, y: options.y });
    var angle = options.angle ? options.angle + random(-options.spread, options.spread) : random(0, 359);

    // emulate 3D effect by using cosine and put more particles in the middle
    var speed = options.speed ? options.speed + random(-(options.speed / 10), (options.speed / 10)) : Math.cos(Math.random() * Math.PI / 2) * 15;

    particle.vel.x = Math.cos(angle * Math.PI / 180) * speed;
    particle.vel.y = Math.sin(angle * Math.PI / 180) * speed;

    let color = (options.multiColor && CONFIG.colors.length > 1 ? sample(CONFIG.colors) : options.color) || '#ffffff'

    // Make the color +/- 10% lighter or darker
    particle.color = colorLuminance(color, random(-10, 10) / 100);

    particles.push(particle);
  }
}

function gameLoop() {
  deltaTime = (Date.now() - lastFrameTime) / 1000;
  lastFrameTime = Date.now();

  // // update screen size
  // if (CONFIG.screenWidth !== window.innerWidth) {
  //   canvas.width = CONFIG.screenWidth = window.innerWidth;
  // }
  // if (CONFIG.screenHeight !== window.innerHeight) {
  //   canvas.height = CONFIG.screenHeight = window.innerHeight;
  // }

  if(CONFIG.launchCount > 0 && totalLaunches <= CONFIG.launchCount && Date.now() - lastRocketLaunch > 1000) {
    launch();
  }

  // Clear the screen
  context.clearRect(0, 0, CONFIG.screenWidth, CONFIG.screenHeight);

  // Update and Render rockets and particles (concat creates a copy of both arrays)
  each(rockets.concat(particles), (particle) => {
    particle.update();
    particle.render();
  });
  // Remove any inactive rockets
  rockets = rockets.filter(function(row) { return row.active === true; });
  // Remove any inactive particles
  particles = particles.filter(function(row) { return row.active === true; });

  window.requestAnimationFrame(gameLoop);
}

function Particle(pos) {
  this.pos = {
    x: pos ? pos.x : 0,
    y: pos ? pos.y : 0
  };
  this.vel = {
    x: 0,
    y: 0
  };
  this.accel = {
    x: 0,
    y: 0
  };
  this.resistance = 1.0;
  this.mass = 1.0;
  this.active = true;
  this.type = 'particle';

  this.size = 2;
  this.alpha = 1;
  this.shrink = 0;
  this.color = 0;
}

Particle.prototype.update = function() {
  // Apply Acceleration each frame (gravity)
  this.accel.y = CONFIG.gravity * this.mass;

  // Apply air resistance (makes particles more floaty)
  this.vel.x *= this.resistance;
  this.vel.y *= this.resistance;

  // update the current velocity
  this.vel.y += this.accel.y * deltaTime;

  // update position based on velocity
  this.pos.x += this.vel.x;
  this.pos.y += this.vel.y;

  // Zero-out acceleration each frame
  this.accel.x = this.accel.y = 0;

  this.size = Math.max(0, this.size - (this.shrink * deltaTime)); // Shrink
  this.alpha = Math.max(0, this.alpha - (CONFIG.fade * deltaTime)); // Fade out

  if(!this.exists()) {
    this.active = false;
  }
};

Particle.prototype.render = function() {
  context.fillStyle = this.color;

  context.beginPath();
  context.arc(this.pos.x, this.pos.y, this.size, 0, Math.PI * 2, true);
  context.fill();
};

Particle.prototype.exists = function() {
  return this.alpha >= 0.01 && this.size >= 1 && this.pos.y <= CONFIG.screenHeight;
};

function Firework(pos) {
  Particle.apply(this, [pos]);

  this.type = 'firework';
  this.resistance = .94;
  this.size = random(12, 14);
  this.mass = this.size / 3;
  this.shrink = 8 + (random(-10, 10) / 10);
}

Firework.prototype = new Particle();
Firework.prototype.constructor = Firework;

Firework.prototype.update = function() {
  Particle.prototype.update.apply(this, arguments);

  this.mass = this.size / 3; // Mass changes when it shrinks
};

function Confetti(pos) {
  Particle.apply(this, [pos]);

  this.type = 'confetti';
  this.size = random(8, 12);
  this.resistance = 0.9 + (0.2 * (1 - (this.size / 12))); // Air Resistance (bigger confetti = more floaty)
  this.tiltAngleIncrement = Math.random() * 0.07 + 0.05;
  this.tilt = random(-7, 7);
  this.tiltAngle = 2 * Math.PI * this.size;
}

Confetti.prototype = new Particle();
Confetti.prototype.constructor = Confetti;

Confetti.prototype.update = function() {
  Particle.prototype.update.apply(this, arguments);

  this.pos.x += (Math.random() * 1) -  0.5; // Apply random horizonal shifting to the confetti
  this.tiltAngle += this.tiltAngleIncrement; // Used for rendering the particle
  this.tilt = Math.sin(this.tiltAngle) * this.size;
};

Confetti.prototype.render = function() {
  var x = this.pos.x,
    y = this.pos.y,
    x2 = this.pos.x + this.tilt,
    y2 = y + this.tilt + this.size / 2;

  x = x2 + this.size / 2;

  context.strokeStyle = this.color;
  context.lineWidth = this.size;

  context.beginPath();
  context.moveTo(x, y);
  context.lineTo(x2, y2);
  context.stroke();
};

function Custom(pos) {
  Particle.apply(this, [pos]);

  this.type = 'custom';
  this.asset = assets[sample(Object.keys(assets))]; // particle is a random image from the list of assets
  this.size = (this.asset.width || 20) + (random(-10, 70) / 10);
  this.angle = 0;
  this.resistance = 0.95;
  this.shrink = 8 + (random(-20, 20) / 10);
  this.rotationSpeed = random(-70, 70) / 10;
}

Custom.prototype = new Particle();
Custom.prototype.constructor = Custom;

Custom.prototype.update = function() {
  Particle.prototype.update.apply(this, arguments);

  this.mass = this.size / 20;
  this.angle = this.angle + this.rotationSpeed * deltaTime;
};

Custom.prototype.render = function() {
  context.save();
  context.translate(this.pos.x + (this.size / 2), this.pos.y + (this.size / 2));
  context.rotate(this.angle);
  try {
    context.globalAlpha = this.alpha
    context.drawImage(this.asset, -(this.size / 2), -(this.size / 2), this.size, this.size);
  } catch(e) {}
  context.restore(); // // Restore the co-ordinate system to its default top left origin with no rotation
};

function Rocket(x) {
  Particle.apply(this, [{ // Either place the rocket at a specific X, or randomly place it on either side of the screen
    x: x || sample([random(CONFIG.screenWidth * 0.1, CONFIG.screenWidth * 0.2), random(CONFIG.screenWidth * 0.8, CONFIG.screenWidth * 0.9)]),
    y: CONFIG.screenHeight}
  ]);

  this.color = 'black';
  this.explosionType = 'confetti'; // Rockets always explode confetti
  this.explosionColor = CONFIG.colors.length > 1 ? sample(CONFIG.colors) : ('#' + Math.floor(Math.random()*16777215).toString(16));
  // Always have the rocket shoot relatively straight up
  this.vel.y = random(-16 * (CONFIG.screenHeight / 800), -12 * (CONFIG.screenHeight / 800));
  this.vel.x = random(-2, 2);
  this.size = 4;
}

Rocket.prototype = new Particle();
Rocket.prototype.constructor = Rocket;

Rocket.prototype.explode = function() {
  createExplosion({ type: this.explosionType, color: this.explosionColor, count: 30, x: this.pos.x, y: this.pos.y });

  this.active = false; // Kills the rocket
};

Rocket.prototype.update = function() {
  Particle.prototype.update.apply(this, arguments);

  // Random chance of explosion as rockets gets higher
  var randomChance = this.pos.y < (CONFIG.screenHeight * .4) ? (Math.random() * 100 <= (this.pos.y / CONFIG.screenHeight) * 100 * 0.01) : false;

  /* Explosion rules
      - 80% of screen
      - Random chance of explosion as rockets gets higher
      - Rocket is falling down
  */
  if (this.pos.y < CONFIG.screenHeight * 0.2 || this.vel.y > 3 || randomChance) {
    this.explode();
  }
};

Rocket.prototype.render = function() {
  context.fillStyle = this.color;

  var gradient = context.createRadialGradient(this.pos.x, this.pos.y, 0.1, this.pos.x, this.pos.y, this.size);
  gradient.addColorStop(0.1, 'rgba(206, 62, 68, 1)');
  gradient.addColorStop(0.9, 'rgba(123, 37, 40, 0.6)');
  context.fillStyle = gradient;
  context.beginPath();
  context.arc(this.pos.x, this.pos.y, this.size, 0, Math.PI * 2, true);
  context.fill();
};

/**
 * Adds or Subtracts a lum amount from a hex color. Returns the color in hex form
 * @param {string} hex color value in hex form
 * @param {string} lum color luminance to add/subtract from the hex
 */
function colorLuminance(hex, lum) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = '#', c, i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i*2,2), 16);
    c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
    rgb += ('00'+c).substr(c.length);
  }

  return rgb;
}

export default {
  init: function(myCanvas, options) {
    canvas = myCanvas;
    context = canvas.getContext('2d');
    CONFIG = Object.assign(CONFIG, options);

    canvas.width = CONFIG.screenWidth;
    canvas.height = CONFIG.screenHeight;

    // Import assets
    each(CONFIG.assets, (asset) => {
      assets[asset] = new Image();
      assets[asset].src = asset;
    });

    // launch more confetti!!!
    $(document).mousedown(function(e) {
      if (!$(e.target).is('a, .btn, .link')) {
        if(CONFIG.launchCount > 0) {
          launch(e.pageX);
        }

        if(CONFIG.burstsOnClick > 0 && totalBurstsOnClick <= CONFIG.burstsOnClick) {
          if(particles.length < 100) {
            if(Object.keys(assets).length > 0) { // Explode custom images if imported
              createExplosion({ type: 'custom',  x: e.pageX, y: e.pageY, count: 5, angle: 270, spread: 45, speed: 15 });
            }
            createExplosion({ type: 'confetti',  x: e.pageX, y: e.pageY, count: (Object.keys(assets).length > 0 ? 5 : 10), angle: 270, spread: 45, speed: 15 });
            totalBurstsOnClick++;
          }
        }
      }
    });

    gameLoop();
  },

  explodeSingle: function(options) {
    createExplosion(options);
  }
}
