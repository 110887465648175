import Backbone from 'backbone'
import $ from 'jquery'
import Model from '../models/user_game'
import { uniq, map } from 'lodash-es'

export default Backbone.Collection.extend({

  model: Model,

  url: '/apiv1/student/games',

  comparator: function(row) {
    return -row.get('created_at');  // backwards date stamp
  },

  getScores: function(gameId) {
    return $.get(this.url + '/' + gameId);
  },

  getLevels: function(gameId) {
    return uniq(map(this.toJSON().filter((row) => row.game_id === gameId), 'level'));
  },

  getDifficulties: function(gameId) {
    return uniq(map(this.toJSON().filter((row) => row.game_id === gameId), 'difficulty'));
  },

  getCompiled: function(afterDate = null) {
    return this.toJSON().reduce((memo, row) => {
      const id = row.game_id + '_' + row.difficulty + '_' + row.level
      // Only get games created after a certain date (used for assignments)
      if(afterDate && afterDate > row.created_at) return memo

      if(memo[id]) {
        memo[id] = memo[id] + row.seconds
      } else {
        memo[id] = row.seconds;
      }
      return memo;
    }, {})
  }
})
