import Backbone from 'backbone'
import $ from 'jquery'
import Velocity from 'velocity-animate'
import { oauth_main } from '@templates/student'

export default Backbone.View.extend({
  template: oauth_main,

  showError: function(title, message) {
    Velocity($('#title'), { opacity: [0, 1] }, function() {
      Velocity($(this).html(title), { opacity: [1, 0] });
      $('#message').html(message);
    });
  }
})
