import Backbone from 'backbone'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import { getIntroAIHtml, formatKeys } from '../../utils/problem_keys_lesson';
import { find } from 'lodash-es'
import { lesson_intro_problem_keys } from '@templates/student'
import ProblemKeysModel from '../models/problem_keys';

export default Backbone.View.extend({

  events: {
    'keydown #optionsForm': 'handleKeyDown',
    'click .options-card': 'selectOption',
    'click .js-continue-button': 'continue',
    'submit #optionsForm': 'handleFormSubmit',
    'change input[type="radio"][name="option"]': 'handleRadioChange',
  },

  template: lesson_intro_problem_keys,

  modelClass: ProblemKeysModel,

  serialize: function() {
    const userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens'),
      productGrade =  this.user.getCurriculumGrade();

    return {
      isPremium: this.isPremium,
      ads: AdView.canShowAds() && totalScreens > 0,
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens }),
      test: this.test,
      userLesson: this.userLesson.toJSON(),
      screen: this.screen.toJSON(),
      lesson: this.lesson.toJSON(),
      product: Registry.get('product'),
      showGuestBanner: !this.showSidebarNav && !Registry.get('loggedIn') && this.screen.screen_type !== 'keyboard-jump',
      noAvatar: find(FTWGLOBALS('skins'), {id: this.user.get('skin_id') || 1}).noAvatar,
      showMascot: this.lesson.get('content_type') !== 'adventure' || productGrade < 7,
      practiceKeys: this.practiceKeys,
      ai: getIntroAIHtml(),
      keys: formatKeys(this.practiceKeys),
      options: this.model.topics.map((topic, index) => ({
        value: topic,
        label: topic.charAt(0).toUpperCase() + topic.slice(1), // capitalize the first letter
        icon: topic,
        disabled: index !== 0 && !this.isPremium // enable the first option unconditionally
      }))
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    let screen = this.screen.toJSON();
    // We no longer set a title on screens. Use the lesson name
    screen.title = this.lesson.get('name').stripHTML();
    screen.intro = 'lesson.problem_keys.intro'.t({
      keys: formatKeys(this.practiceKeys),
      ai: getIntroAIHtml()
    })

    this.dictation.setProperties(this.screen, null);
    this.dictation.speakIntro(screen, false);

    return this;
  },

  continue: function() {
    this.validateAndSubmit();
  },

  handleFormSubmit: function(event) {
    event.preventDefault();
    this.validateAndSubmit();
  },

  validateAndSubmit: async function() {
    if (this.model.get('selectedOption')) {
      await this.generateContentAndUpdateSettings(this.model.get('selectedOption'));
      this.trigger('continue', 'intro', this.$('.js-continue-button'));
      return false;
    }
  },

  selectOption: function(event) {
    const previouslySelected = this.$('.options-card.selected');
    if (previouslySelected.length) {
      previouslySelected.removeClass('selected');
    }

    const clickedOption = this.$(event.currentTarget);
    clickedOption.addClass('selected');
  },

  handleRadioChange: function(event) {
    this.model.set('selectedOption', this.$(event.currentTarget).val());

    if (this.isPremium) {
      this.validateAndSubmit();
    }

    if (this.model.get('selectedOption')) {
      this.$('.js-continue-button').attr('data-tooltip', 'lesson.enter_key_tooltip'.t());
    } else {
      this.$('.js-continue-button').removeAttr('data-tooltip');
    }
  },

  generateContentAndUpdateSettings: async function() {
    try {
      // Show the spinner and hide the form
      this.$('.js-saving-sparkle-spinner').fastShow();
      this.$('.js-content-ai-form').fastHide();
      this.dictation.speakLoadingAIContent();

      await this.model.fetch();
      this.user.setSettings({ AIContent: this.model.get('AIContent') });
    } catch(err) {
      console.error(err);
    } finally {
      // Hide the spinner whether the operation was successful or not
      this.$('.js-saving-sparkle-spinner').fastHide();
    }
  },

  handleKeyDown: function(event) {
    if (event.key === 'Enter') {
      // Get input value after tab focus or clicked radio button
      const inputValue = this.$(event.target).val() || this.$(event.target).find('input[type=radio]').val();

      if (inputValue) {
        this.model.set('selectedOption', inputValue);
        this.continue();
      }
    }
  },
})
