import Modal from './modal'
import LoginView from '../../login/views/login_basic'
import SignupView from '../../signup/views/main'
import { global_auth_modal, global_auth_content, signup_signup_basic } from '@templates/student'

export default Modal.extend({
  titleText: '',

  okText: '',

  cancelText: '',

  successNotice: undefined,

  modalBaseTemplate: global_auth_modal,
  template: global_auth_content,

  authType: 'login',

  initialize: function(options) {
    this.showGuestContent = options.showGuestContent === undefined;
    this.baseModalOptions = {
      showGuestContent: this.showGuestContent
    }

    Modal.prototype.initialize.apply(this, arguments)

    this.views.login = new LoginView({ isAssessment: this.isAssessment })
    this.listenTo(this.views.login, 'switch', this.showSignup)

    // Allows for EduTec assessments page to inject its own custom signupView
    if(this.signupView) {
      this.views.signup = this.signupView
    } else {
      this.views.signup = new SignupView({ template: signup_signup_basic })
    }
    this.listenTo(this.views.signup, 'switch', this.showLogin)
  },

  render: function() {
    Modal.prototype.render.apply(this, arguments);

    this.$('.js-auth-login').append(this.views.login.render().el)
    this.$('.js-auth-signup').append(this.views.signup.render().el)

    this.showView()

    return this;
  },

  showLogin: function() {
    this.authType = 'login'
    this.showView()
  },

  showSignup: function() {
    this.authType = 'signup'
    this.showView()
  },

  showView: function() {
    this.$('[class*=js-auth-]').fastHide()

    if(this.authType === 'login') {
      this.$('.js-auth-login').fastShow()
    } else {
      this.$('.js-auth-signup').fastShow()
    }
    this.$('.js-description').html(`auth_modal.${this.authType}_description`.t())
  },

  captureKeys: function(e) {
    if(e.which === 27) {
      this.cancel();
      return false;
    }

    if(e.which === 13 && e.target.tagName !== 'TEXTAREA') {
      this.views[this.authType].buttonClick()
      return false;
    }
  }
})
