import Backbone from 'backbone'
import Alert from '../../global/views/alert'
import ForgotPasswordModal from './forgot_password'
import Notice from '@shared/notice'
import MultipleUsersModalView from '../../global/views/multiple_users_modal'
import SectionModalView from '../../global/views/section_modal'
import FieldUsername from './field_username'
import FieldPassword from './field_password'
import Velocity from 'velocity-animate'
import moment from 'moment'
import { isIE11, isEdge, googleLogin, microsoftLogin, cleverLogin, classlinkLogin, Cookies } from '@shared/helpers';
import { map, join } from 'lodash-es'
import { login_main } from '@templates/student'
import Registry from '@shared/registry';

export default Backbone.View.extend({

  template: login_main,

  events: {
    'click .js-clever-login': 'cleverAuth',
    'click .js-google-login': 'googleAuth',
    'click .js-microsoft-login': 'microsoftAuth',
    'click .js-classlink-login': 'classlinkAuth',
    'click .js-forgot-password': 'forgotPassword',
    'click .js-start-over': 'startOver',
    'click .js-submit': 'handleSubmitButton',
    'keydown input': 'handleTab'
  },

  steps: (FTWGLOBALS('site') === 'typing') ? 2: 3,

  step: 'username',

  loginModel:null,

  stepViews: [],

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.stepViews.push('username');
    this.views.username = new FieldUsername();
    this.usernameModel = this.views.username.model;
    this.listenTo(this.views.username, 'submitting', this.handleSubmitting);
    this.listenTo(this.views.username, 'error', this.handleError);
    this.listenTo(this.views.username, 'done', this.handleUsername);
    this.addChild('.js-fields', this.views.username, true);

    this.stepViews.push('password');
    this.views.password = new FieldPassword();
    this.loginModel = this.views.password.model;
    this.listenTo(this.views.password, 'submitting', this.handleSubmitting);
    this.listenTo(this.views.password, 'error', this.handleError);
    this.listenTo(this.views.password, 'done', this.handlePassword);
    this.addChild('.js-fields', this.views.password, true);
  },

  toggleButton: function(loading, text) {
    var btn = this.$('.js-submit');
    if(loading) {
      btn.addClass('btn--loading');
    } else {
      btn.removeClass('btn--loading');
    }
    if(text) {
      btn.text(text);
    }
  },

  switchForm: function(step) {
    var index = this.stepViews.indexOf(step),
      view = this.views[step];
    if(index !== -1 && view && view.value()) {
      this.$('.dotProgress').attr('data-active', this.stepViews.indexOf(step)+1);
      if(isIE11() || isEdge()) {
        this.$('.sessionForm-body').css({marginLeft: '-'+(this.stepViews.indexOf(step))*100+'%'});
      } else {
        Velocity(this.$('.sessionForm-body'), { marginLeft: '-'+(this.stepViews.indexOf(step))*100+'%'}, (step === 'username') ? 0 : 500);
      }
      this.step = step;
      view.$('input').focus();
    }
  },

  handleSubmitButton: function() {
    this.views[this.step].buttonClick();
    return false;
  },

  handleTab: function(e) {
    if(e.which === 9) {
      if(e.shiftKey) {
        history.back();
      } else {
        this.handleSubmitButton();
      }

      return false;
    }
  },

  startOver: function() {
    this.step = 'username';

    this.$('.js-username').hide();
    // If there is a join code only reset the login back to the username step
    if(this.joinCode) {
      this.$('.js-district').show();
      this.$('.js-start-over').hide();
    } else {
      this.$('.js-cap').removeClass('is-active').attr('aria-hidden', 'true');
      this.$('.js-district').hide();
      this.loginModel.clear();
      this.usernameModel.clear();
    }

    this.switchForm(this.step);
    this.$('input').val('');
    this.toggleButton(false, 'shared.next'.t());

    return false;
  },

  handleUsername: function(data) {
    this.$('input[name=password]').prop('disabled', false);
    if(data.login_type === 'google') {
      this.googleAuth(data);
      return false;
    } else if (data.login_type === 'microsoft') {
      this.microsoftAuth();
      return false;
    } else if (data.login_type === 'clever') {
      this.cleverAuth();
      return false;
    } else if (data.login_type === 'classlink') {
      this.classlinkAuth();
      return false;
    }

    this.$('.js-district').hide();
    this.$('.js-start-over').show();
    if(FTWGLOBALS('site') !== 'edutyping') {
      this.$('.js-cap').addClass('is-active').attr('aria-hidden', 'false');
    }
    this.$('.js-username').show();
    this.$('.js-usernameValue').text(data.username);

    this.loginModel.set(data);

    this.$('.dotProgress').attr('data-active', this.steps);
    if(isIE11() || isEdge()) {
      this.$('.sessionForm-body').css({marginLeft: '-'+(this.steps-1)*100+'%'});
    } else {
      Velocity(this.$('.sessionForm-body'), {marginLeft: '-'+(this.steps-1)*100+'%'});
    }

    if (this.joinCode) {
      this.$('input[name=join_code]').val(this.joinCode);
    }
    this.$('input[name=password]').select();
    this.toggleButton(false);
    this.step = 'password';
  },

  handlePassword: function(data) {
    this.login(data)
  },

  handleSubmitting: function() {
    this.toggleButton(true);
  },

  handleError: function(data) {
    Velocity(this.$('input'), 'ftw.miniShake');
    this.toggleButton(false);

    if(data.users) {
      this.$('.sessionInput').blur();
      this.showMultipleUsers(data.users);
    } else if(data.sections) {
      this.$('.sessionInput').blur();
      this.showSections(data.sections);
    } else if (data.expired_licenses) {
      this.$('.sessionInput').blur();
      new Alert({
        title: 'index.expired_licences_confirm.title'.t(),
        text: 'index.expired_licences_confirm.text'.t()
      });
    } else if (data.no_licenses) {
      this.$('.sessionInput').blur();
      new Alert({
        title: 'index.no_licences_alert.title'.t(),
        text: 'index.no_licences_alert.text'.t()
      });
    } else if(data.status_data) {
      let statusData = {}
      try {
        statusData = JSON.parse(data.status_data)
      } catch(e) { }
      this.$('.sessionInput').blur();

      new Alert({
        title: 'auth.class_active_temp_title'.t(),
        text: 'auth.class_active_temp_text'.t({
          activeStart: moment(statusData.active_start, 'LT').format('h:mm A'),
          activeEnd: moment(statusData.active_end, 'LT').format('h:mm A'),
          activeDays: join(map(statusData.active_days, (dayIndex) => new moment().day(dayIndex).format('ddd')), ', ')
        })
      });
    } else if(data.error) {
      new Alert({
        title: 'shared.oops_text'.t(),
        text: data.error
      })
    }
  },

  serialize: function() {
    return {
      joinCode: this.joinCode,
      section: this.section,
      stepViews: this.stepViews,
      steps: this.steps,
      ads: false
    };
  },

  showMultipleUsers: function(data) {
    const modal = new MultipleUsersModalView({
      accountKey: 'user_id',
      data: data
    }).done((data) => {
      this.$('input[name=user_id]').val(data.accountId)
      this.$('input[name=login_type]').val(data.loginType)
      this.handleSubmitButton();
      modal.close();
    })
    modal.open()
  },

  showSections: function(data) {
    const sections = data.map((row) => ([row.section_id, `(${(row.teacher_names?.length > 35 ? row.teacher_names.substr(0, 34) + '...' : row.teacher_names)}) ${row.section_name}`]))

    const modal = new SectionModalView({
      sections: sections
    }).done((section_id) => {
      this.$('input[name=section_id]').val(section_id);
      this.handleSubmitButton();
      modal.close();
    })
    modal.open();
  },

  googleAuth: function() {
    this.setupRedirect()

    googleLogin('student', { joinCode: this.joinCode })

    return false
  },

  microsoftAuth: function() {
    this.setupRedirect()

    microsoftLogin('student', { joinCode: this.joinCode })

    return false
  },

  cleverAuth: function() {
    this.setupRedirect()

    cleverLogin('student')

    return false
  },

  classlinkAuth: function() {
    this.setupRedirect()

    classlinkLogin('student')

    return false
  },

  forgotPassword: function () {
    this.$('.sessionInput').blur();
    if(FTWGLOBALS('whitelabel')) {
      new Alert({
        title: 'index.forgot_login_info_title'.t(),
        text: 'index.forgot_login_info_ask_instructor_text'.t()
      });
    } else {
      var view = new ForgotPasswordModal();
      view.done(function(data){
        if(data && data.bounce) {
          (new Notice({error: true, text: 'shared.password_reset_notice'.t() + '<br /><br />'+ 'shared.password_reset_notice_email_problem'.t({error: data.bounce})}).show());
        } else {
          (new Notice({error: false, text: 'shared.password_reset_notice'.t()}).show());
        }
      }.bind(this));
      view.open();
    }
    return false;
  },

  setupRedirect: function() {
    if(!window.location.pathname.match(/\/signup$/) && !window.location.pathname.match(/\/login$/) && !window.location.pathname.match(/\/student/)) {
      window.localStorage.setItem('_oneTimeRedirect', window.location.href.replace(/\/typing-test\/.*/, '/tests'))
      if(window.location.pathname.match(/student\/upgrade/)) {
        window.localStorage.setItem('_forceUpgrade', 1)
      }
    }
  },

  login: function(data) {
    data.joinCode = this.joinCode;
    Registry.get('student').loginWithData(data);

    this.setupRedirect()

    if(window.location.hash.substr(1, 2) === 'r:') {
      window.window.location.href = window.location.hash.substr(3);
    } else if(FTWGLOBALS('whitelabel') && data.licensed_at === 0) {
      window.location.href = __url('/student/certificacion')
    } else {
      const url = '/student/lessons',
        settings = data.settings

      // if their language is explicitly set, and is different from the page then choose where they go
      if(settings.language && settings.language !== FTWGLOBALS('language')) {
        if(settings.language === FTWGLOBALS('defaultLanguage')) {
          window.location.href = url;  // english has no prefix
        } else {
          window.location.href = '/' + settings.language + url;
        }
      } else {
        window.location.href = __url(url);
      }
    }

  }
})
