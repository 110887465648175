import Backbone from 'backbone'
import Model from '../models/username'
import { login_field_username } from '@templates/student'

export default Backbone.View.extend({

  template: login_field_username,

  modelClass: Model,

  formSelector: 'form',

  className: 'sessionForm-section',

  serialize: function() {
    return {
    };
  },

  value: function() {
    return this.$('input[name=username]').val();
  },

  submittingCallback: function(data) {
    this.trigger('submitting', data);
  },

  submitCallback: function(data) {
    this.trigger('done', data);
  },

  errorCallback: function(data) {
    this.trigger('error', data);
  }

})
