import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import MainView from './views/main'
import LoadingView from '../join/views/loading'
import FailedView from '../join/views/failed'
import LayoutView from '../global/views/layout_basic'
import { Cookies } from '@shared/helpers'
import { random, find } from 'lodash-es'

export default Backbone.View.extend({

  layout: LayoutView,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.views.main = new MainView();

    // If a Class Join Code is provided (from router.js) then display a loading
    // view and validate the Join Code
    if (this.joinCode) {
      this.layout = new LayoutView({ joinCode: this.joinCode });

      this.views.loading = new LoadingView();

      this.views.failed = new FailedView();
      this.views.failed.$el.fastHide();

      this.views.main.$el.fastHide();
      this.views.main.joinCode = this.joinCode;

      // Validate the join code
      $.post('/apiv1/student/auth/join-validate', { join_code: this.joinCode })
        .done(this.showJoinCodeForm.bind(this))
        .fail(this.showJoinCodeFailed.bind(this));

      this.addChild(this.el, this.views.loading, true);
      this.addChild(this.el, this.views.failed, true);
    }

    this.addChild(this.el, this.views.main, true);
    this.render();
  },

  showJoinCodeForm: function (data) {
    const settings = data.settings
    if(settings.language && settings.language !== FTWGLOBALS('defaultLanguage') && settings.language !== FTWGLOBALS('language')) {
      window.location.href = '/' + settings.language + window.location.pathname + window.location.hash;
      return;
    }
    this.views.main.section = data;
    this.views.loading.$el.fastHide();

    // Main form needs to render again in order to get the Join Code's Section data
    this.views.main.render().$el.fastShow();
    this.$('#reg-form input[name=username]').focus();
  },

  showJoinCodeFailed: function (request) {
    if(request.responseJSON && request.responseJSON.error) {
      this.views.failed.error = request.responseJSON.error;
      this.views.failed.render();
    }
    this.views.loading.$el.fastHide();
    this.views.failed.$el.fastShow();
  }
})
