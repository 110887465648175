import $ from 'jquery'
import Backbone from 'backbone'
import { each } from 'lodash-es'
import ConceptMapOverview from './views/screen_concept_map_overview'
import { lesson_screen_concept_map, lesson_transcript_modal } from '@templates/student'
import LayoutView from './views/layout';
import Registry from '@registry';
import KeyboardInput from '@shared/keyboard_input';
import ScreensCollection from '../global/collections/lesson_screens'
import Dictation from './classes/dictation';
import LessonModel from '../global/models/lesson';
import ScreenTypeQA from './views/screen_qa';
import ScreenWrittenPrompt from './views/typing_written_prompt';
import ScreenBlueprints from './views/screen_blueprints';
import TimerModel from '../global/models/timer';
import UserLessonScreens from '../global/collections/user_lesson_screens';
import AdView from '../global/views/ad';
import ModalView from '../global/views/modal';
import IntroView from './views/intro_qa';
import { bindSelectors } from '@shared/utils';
import CongratsView from './views/congrats';
import { starAnimation } from './common';

// concept map base
export default Backbone.View.extend({
  template: lesson_screen_concept_map,

  selectors: {
    $overlay: '.screenConceptMap-overlay',
    $title: '.concept-map--activity--title',
    $display: '.concept-map--display',
    $currentActivity: '.concept-map--activity--current',
    $content: '.js-content',
  },

  events: {
    'click [return-to-overview]': 'onReturnToOverview',
    'click [selectable-screen]': 'onSelectScreen',
    'click [submit-concept-map]': 'onSubmitConceptMap',
    'click .js-return-to-overview': 'onReturnToOverview'
  },

  starAnimation,

  getProgress() {
    const total = this.screens.filter(screen => screen.get('screen_type') !== 'text-only')
    const completed = Registry.get('userLessonScreens').where({ lesson_id: this.lesson.id, completed: true })
    const intro = !this.userLesson.get('_state')
    return {
      total: total.length,
      completed: completed.length,
      done: total.length === completed.length,
      intro
    }
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);
    bindSelectors(this)

    this.user = Registry.get('student');
    this.timer = new TimerModel();      // Timer can fire events by second and track timing
    this.input = new KeyboardInput();   // Handles and normalizes browser input
    this.dictation = new Dictation(null, null, this.user); // Handles dictation for all screens

    // find the lesson
    let lesson = FTWGLOBALS('lessons').find(item => item.lesson_id === this.lessonId)
    if(!lesson) {
      lesson = FTWGLOBALS('custom_lesson');
    }
    this.lesson = new LessonModel(lesson);
    this.screens = new ScreensCollection(FTWGLOBALS('lesson_screens'));

    // get units info
    this.units = Registry.get('units');
    this.units.set(FTWGLOBALS('units'));
    this.unit = this.units.get(this.lesson.get('unit_id'));


    // gather user lesson info
    const userLessons = Registry.get('userLessons');
    const userLessonScreens = Registry.get('userLessonScreens').where({ lesson_id: this.lessonId })
    this.userLessonScreens = new UserLessonScreens(userLessonScreens);
    this.userLesson = userLessons.get(this.lesson.id);
    if (!this.userLesson) {
      // If they've never done this lesson, create a new record for it
      this.userLesson = userLessons.add({lesson_id: this.lesson.id});
    }

    // TODO: REVIEW: Concept Map lessons need to remain completed even when restarting after a login
    // so check for a few flags to mark each screen as completed already
    for (let i = 0; i < this.userLessonScreens.length; i++) {
      const userLessonScreen = this.userLessonScreens.at(i)
      if (userLessonScreen) {
        const completed = !!userLessonScreen.get('completed') || userLessonScreen.get('state') === 'completed' || userLessonScreen.get('stars') === 3
        userLessonScreen.set('completed', completed)
        userLessonScreen.completed = completed
      }
    }

    // create the main layout
    this.layout = (new LayoutView({
      user: this.user,
      timer: this.timer,
      child: this,
      input: this.input,
      dictation: this.dictation,
      unit: this.unit,
      lesson: this.lesson,
      screen: this.screen,
      userLesson: this.userLesson,
      progressComplete: this.userLesson.get('_state') === 'complete'
    }));

    // needs to jump to a screen directly
    const { screenIndex } = this
    if (screenIndex instanceof Number || typeof screenIndex === 'number') {
      const jumpTo = this.screens.at(screenIndex - 1)
      if (jumpTo) {
        const id = jumpTo.get('lesson_screen_id')
        this.render();
        this.setActiveScreen(id)
        return
      }
    }

    // with a new concept map, attempt to determine a few things about
    // the kind of concept map
    this.rootNodes = this.screens.filter(screen => !screen.get('settings')?.cm_parent)

    // get the root nodes - these should not have parents
    const first = this.screens.first()
    const last = this.screens.last()
    const firstIndex = this.screens.findIndex(find => find.id === first.id)

    // if this is a concept map with no connections, then
    // we can assume it's linear
    let layoutType
    if (this.rootNodes.length === this.screens.length) {
      layoutType = 'linear'
    }
      // if this only has one connection that goes back to
    // the first node, then we can assume it's cyclical
    else if (this.rootNodes.length === this.screens.length - 1 && (0 | last.get('settings')?.cm_parent) === firstIndex) {
      layoutType = 'cycle'

      // when doing a cyclical layout, just flatten everything out again
      // since we want everything to be on a flat layout before being
      // positioned into place
      this.rootNodes = this.screens
    }
      // otherwise, we can assume this is a tree based
    // concept map since it's the typical style
    else {
      layoutType = 'tree'
    }

    // save the layout type
    this.layoutType = layoutType

    // create the concept map node view
    const { done } = this.getProgress()
    this.views.overview = new ConceptMapOverview({
      lessonCompleted: done,
      lesson: this.lesson,
      screens: this.screens,
      userLessonScreens: this.userLessonScreens,
      layoutType: this.layoutType,
      rootNodes: this.rootNodes,
      dictation: this.dictation,
    })

    // check for an intro screen to use unless the
    // lesson has already started (which will be in
    // the overview mode or complete view
    let intro
    if (!['overview', 'complete'].includes(this.userLesson.get('_state'))) {
      const content = this.lesson.get('settings')?.intro

      // if there's content, create a model to act as the "screen"
      // for the IntroView
      if (content) {
        intro = new Backbone.Model({
          intro: content
        })
      }
    }

    // if the first intro screen has an intro
    if (intro) {
      this.views.intro = new IntroView({
        isSubScreen: true,
        user: this.user,
        screen: intro,
        lesson: this.lesson,
        userLesson: this.userLesson,
        input: this.input,
        dictation: this.dictation
      });

      // when finished
      this.views.intro.once('continue', function() {
        this.userLesson.set({ _state: 'overview' })
        window.setTimeout(() => window.location.safeReload(this.removeHash), 0)
      }.bind(this));
    }
    // no intro to show
    else {
      this.userLesson.set({ _state: 'overview' });
    }

    // create the congrats
    this.views.congrats = new CongratsView({
      isSubScreen: true,
      user: this.user,
      lesson: this.lesson,
      lettersTyped: this.lettersTyped,
      dictation: this.dictation,
      userLesson: this.userLesson,
      userLessonScreens: this.userLessonScreens,
      hideTypingStats: true
    })

    // Lessons always retain content
    // NOTE: leaving for now until we're sure that's the behavior

    // // check if restarting and, if so, reset all values
    // if (this.userLesson.get('restart')) {
    //   this.userLesson.unset('restart');
    //   this.userLesson.set({
    //     _state: 'overview',
    //     completed: false,
    //     max_progress: Math.max(this.screens.length, this.userLesson.get('max_progress')),
    //     progress: 0
    //   })
    //
    //   // clear the local cache for each screen since
    //   // it's possible to that this might be restored
    //   // if the lesson is repeated
    //   this.screens.forEach(child => {
    //     this.user.set(`_writtenResponse${child.get('lesson_screen_id')}`, '')
    //   })
    //
    //   // clear prior content
    //   this.userLessonScreens.forEach(child => {
    //     child.set('content', '')
    //     child.set('std', 0)
    //     child.set('completed', false)
    //     child.set('response', '')
    //   })
    // }

    // check if done
    // const progress = this.userLesson.get('progress')
    // const completed = progress === this.screens.length

    // // After lesson complete screen
    // if (completed) {
    //   this.views.completed = new ConceptMapCompleteView({
    //     user: this.user,
    //     dictation: this.dictation,
    //     lesson: this.lesson,
    //     screens: this.screens,
    //     userLesson: this.userLesson,
    //     userLessonScreens: this.userLessonScreens,
    //     layoutType: this.layoutType,
    //     rootNodes: this.rootNodes
    //   });
    //
    //   // since this is done, mark this as needing
    //   // to be restarted next time opening this view
    //   this.userLesson.set('restart', true)
    // }

    // update the title to match the lesson
    // TODO: should this change depending on selected screen?
    window.document.title = lesson.name;

    // render the view
    this.render();

    let ads = {};
    const adOptions = { }
    AdView.initAds('lesson_screen');
    ads = AdView.getAds(adOptions);

    // append each ad
    each(ads, (ad, name) => {
      if(!ad) { return; }
      var ele = $('.js-' + name + '-ad');
      if(ele.length) {
        ele.append(ad.render().el);
      }
    });
  },

  serialize() {
    const { done, intro, completed } = this.getProgress()

    return {
      ads: AdView.canShowAds(),
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: completed }),
      done,
      intro
    };
  },

  onReturnToOverview() {
    this.reloadScreen()
  },

  // reload to return to the main screen
  reloadScreen() {
    window.setTimeout(() => window.location.safeReload(true), 0)
  },

  showModal(text) {
    new ModalView({
      template: lesson_transcript_modal,
      titleText: this.lesson.get('name'),
      okText: '',
      cancelText: 'shared.close'.t(),
      width: 'm',
      model: new Backbone.Model({ text })
    }).open();
  },

  onSubmitConceptMap() {
    const { done } = this.getProgress()

    // can submit - is there anything to do here?
    if (done) {

      // mark as done
      this.userLesson.set({
        completed: done,
        max_progress: this.screens.length,
        stars: this.screens.length * 3
      });

      // also show congrats screen
      const { $content } = this.selectors
      this.views.congrats.render()
      $content
        .empty()
        .append(this.views.congrats.$el)
    }
  },

  tryCompleteLesson() {
    const { $overlay } = this.selectors
    const { done, completed } = this.getProgress()
    const state = done ? 'complete' : 'overview'
    const maxProgress = Math.max(completed, this.userLesson.get('max_progress'))

    // mark this as finished
    this.userLesson.set({
      _state: state,
      max_progress: maxProgress,
      progress: completed,
      completed,
      stars: completed * 3
    });

    // play the animation
    this.starAnimation({ showOverlay: $overlay })
  },

  onSelectScreen(event) {
    const $el = this.$(event.target)
    const $level = $el.closest('[data-screen-id]')

    // cannot access locked sections
    if ($level.is('.locked')) {
      return
    }

    // determine the screen to show
    const id = $level.data('screen-id')
    this.setActiveScreen(id)
  },

  setActiveScreen(id) {

    // stop dictation so a subscreen can take over
    this.dictation.stop()

    const { $title, $currentActivity } = this.selectors
    const screen = this.screens.find(screen => screen.get('lesson_screen_id') === id)
    const screenType = screen.get('screen_type')

    // when doing screens out of order, it seems like you need to
    // create a screen collection with only the screen that's being
    // worked on (otherwise it uses the first unfinished screen
    // for some values)
    const screens = new ScreensCollection([screen])

    // things needed to create a new screen
    let Type
    let getResult
    let config
    let title

    // make a QA compatible screen option
    if (screenType === 'qa') {
      Type = ScreenTypeQA

      config = {
        isStandAloneQuestion: true,
        restoreAnswer: true
      }

      getResult = () => {
        return screen.changed
      }
    }
    // written prompts
    else if (screenType === 'written-prompt') {
      Type = ScreenWrittenPrompt
      config = {
        question: screen.get('title')
      }

      getResult = () => {
        const response = view.editor.getText()
        return { response }
      }
    }
    // building a blueprint
    else if (screenType === 'blueprints') {
      Type = ScreenBlueprints
      title = screen.get('title')
      const buildMode = 'advanced'

      config = {
        buildMode
      }

      getResult = () => {
        const blocks = view.getContent()
        return { blocks }
      }
    }

    // create the new screen to show
    const view = new Type({
      isSubScreen: true,
      user: this.user,
      input: this.input,
      dictation: this.dictation,
      lesson: this.lesson,
      timer: this.timer,
      screens,
      screen,
      userLesson: this.userLesson,
      userLessonScreens: this.userLessonScreens,
      isConceptMapActivity: true,
      ...config
    })

    // update tht title
    $title.text(title)

    // wait for the lesson to be completed
    const finish = () => this.onFinishScreen({ id, getResult })
    view.on('complete', finish)

    // render the subscreen
    view.render({ })

    // attach to the view
    $currentActivity.append(view.$el)
    this.$el.addClass('show-screen')

    // save the current view so it
    // can be cleaned up later
    this.views.current = view.$el
  },

  async onFinishScreen({ id, getResult }) {
    // get the ending result
    const result = getResult?.()

    // save to the screens collection
    const data = {
      lesson_id: this.lesson.id,
      lesson_screen_id: id,
      completed: true,
      last_update: Date.now(),
      stars: 3,
      state: 'completed',
      ...result
    }

    // save the changes
    this.userLesson.saveStats(data)
      .done(() => {

        // update local storage
        this.userLessonScreens.add(data, { merge: true });
        Registry.get('userLessonScreens').add(data);

        // finalize and return to main
        this.tryCompleteLesson()
      })
      .catch(() => {
        // TODO: handle errors
      })
  },

  async renderChildren() {
    Backbone.View.prototype.renderChildren.apply(this, arguments);

    // get the container
    await this.$()
    const { $display } = this.selectors

    // choose and render the correct view
    const { intro, completed, overview } = this.views
    const child = intro ?? completed ?? overview

    // if there's something to render, do it now
    if (child) {
      child.render()
      $display.append(child.$el)
    }

    this.input.initialize({ shortcuts: ['dictation'] });
  }
})

