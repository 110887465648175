import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import AdButtonsView from '../../global/views/ad_buttons'
import AuthModal from '../../global/views/auth_modal'
import ScoreboardView from './scoreboard'
import { each, filter, shuffle, take } from 'lodash-es'
import { games_play } from '@templates/student'

export default Backbone.View.extend({

  template: games_play,

  events: {
    'click .js-siderail-close': 'closeSiderail',
    'click .js-game-version': 'changeGameVersion'
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    if(AdView.canShowAds()) {
      AdView.initAds('game_play');
      // grab all the ads and assign them to the right divs
      each(AdView.getAds(), (ad, size) => {
        if(!ad) { return; }
        this.addChild('.js-'+size+'-ad', ad);
      });

      var adButtonsView = new AdButtonsView();
      this.addChild('.js-ad-buttons', adButtonsView, false);
    }

    // Only show the scoreboard if the game has a scoreboard
    if(FTWGLOBALS('game').scoreboard) {
      this.views.scoreboardView = new ScoreboardView();
      this.addChild('.js-scoreboard', this.views.scoreboardView);
    }

    // Tell Google Analytics which game the user is playing
    if(window.gtag && !localStorage.getItem('student_license') && localStorage.getItem('student_membership') !== 'premium') {
      window.gtag("event", "game_played", {'game_name': FTWGLOBALS('game').folder + (this.hasNewVersion() && this.showingNewVersion() ? '-v2' : '')});
    }

    this.listenTo(Registry.get('userGames'), 'add', () => {
      // Display the signup modal to guests on the first and every 3rd game played
      if(!Registry.get('loggedIn') && (Registry.get('userGames').length === 1 || Registry.get('userGames').length % 3 === 1)) {
        window.setTimeout(() => { new AuthModal({ authType: 'signup' }).open() }, 500)
      }
    })

    window.submitGameScoreboard = this.gameOver.bind(this);
  },

  serialize: function() {
    const game = FTWGLOBALS('game'),
      user = Registry.get('student'),
      availableGames = user.getGames(FTWGLOBALS('games'))

    // this support beta.  after beta games *should* be moved to proper directory
    if(game.type === 'flash' && game.html.match(/"\/game_files/)) {
      game.html = game.html.replace(/"\/game_files/g, '"/dist/student/extras/game_files');
      game.html = game.html.replace(/=\/game_files/g, '=/dist/student/extras/game_files');
    }

    return {
      ads: AdView.canShowAds(),
      squareAd: AdView.isSquareAd(),
      adsClass: AdView.getLeftMarginClass(),
      game: game,
      sideRailGames: take(shuffle(filter(availableGames, (row) => row.folder !== 'nitrotype' && row.folder !== game.folder)), 2),
      otherGames: take(shuffle(filter(availableGames, (row) => row.folder !== 'nitrotype' && row.folder !== game.folder)), 3),
      hasNewVersion: this.hasNewVersion(),
      showingNewVersion: this.showingNewVersion()
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    window.setTimeout(async () => {
      if(FTWGLOBALS('game').type === 'javascript') {
        var gameData = FTWGLOBALS('game'),
          game = FTWGLOBALS(gameData.folder),
          user = Registry.get('student'),
          language = user.getCurriculumLanguage(),
          showingNewVersion = this.showingNewVersion();

        // Phaser 2 exports a Game Object that needs to be instantiated
        const defaultGameStart = () => {
          new game(gameData.width, gameData.height, '/dist/student/extras/game_files/', $('.js-game')[0],
            { language: language });
        }

        if(game && !game.inited) {
          if(gameData.folder === 'tommyq' || gameData.folder === 'keyboard-jump') {
            // Phaser 3 exports a function that takes a Phaser Config Object (https://photonstorm.github.io/phaser3-docs/global.html#GameConfig)
            // and a data object (passed into the first scene of the game)

            var avatar = user.getSkinName(user.get('skin_id'), user.get('variant_id'));
            const nextVersion = this.showingNewVersion()

            // next version
            if (gameData.folder === 'keyboard-jump' && nextVersion) {
              this._startKeyboardJump({ avatar, language })
            }
            // normal game instance
            else {
              game({
                  loader: { baseURL: '/dist/student/extras/game_files/' },
                  parent: 'js-game'
                },
                {
                  gameType: 'game',
                  avatar: avatar.substring(0, avatar.length - 2),
                  language: language
                });
            }

          } else if (gameData.folder === 'keyboard-ninja') {
            this._startKeyboardNinja()
          } else if(gameData.folder === 'ztype') {
            new game(gameData.width, gameData.height, '/dist/student/extras/game_files/ztype/', $('.js-game')[0],
              { language: language });
          } else {
            defaultGameStart()
          }
          game.inited = true;
          $('.js-game-downloading').hide();
        }
      }
    }, 0);

    document.addEventListener('keypress', function(e){
      if(e.which === 32) { // prevent scrolling
        e.preventDefault();
      }
    }.bind(this), false);

    // Check if there's an alert message in localStorage
    let alertMessage = localStorage.getItem('student_alertMessage');

    if (alertMessage) {
      // If there is, display the alert and remove the message from localStorage
      var alertElement = this.createAlertElement(alertMessage);
      document.body.appendChild(alertElement);

      localStorage.removeItem('student_alertMessage');
    }

    return this;
  },

  createAlertElement: function(message) {
    // Create a new p element
    var p = document.createElement('p');

    // Set the p's class, role, aria-live, and innerHTML
    p.className = 'sr-only js-game-version-aria-live-text';
    p.setAttribute('role', 'alert');
    p.setAttribute('aria-live', 'polite');
    p.textContent = message;

    return p;
  },

  // add key names to include extra game versions by id
  hasNewVersion: function() {
    // must also maintain this list in student.blade.php with variable 'hasNewVersion'
    return [
      'keyboard-jump'
    ].includes(FTWGLOBALS('game').folder)
  },

  showingNewVersion: function() {
    return this.hasNewVersion() && /next/i.test(localStorage.getItem(`student_${FTWGLOBALS('game').folder}_version`))
  },

  changeGameVersion: function(e) {
    const game = FTWGLOBALS('game'),
    showingNewVersion = /next/i.test(localStorage.getItem(`student_${game.folder}_version`))

    localStorage.setItem(`student_${game.folder}_version`, showingNewVersion ? '' : 'next')

    // Store the alert message in localStorage
    const alertMessage = showingNewVersion ? 'Switched to old version' : 'Switched to new version';
    localStorage.setItem('student_alertMessage', alertMessage);

    window.location.reload()
  },

  toggleWideScreen: function(active, instance, { width, height, preferredHeight = 500, maxHeight = 500 }) {
    const container = $('.js-game')
    const canvas = $('.js-game > canvas')

    if (active) {
      const syncWideScreen = this._syncWideScreenToResize = () => {
        this._matchWideScreen(instance, width, height)
      }

      // when full screen
      container.css('max-height', 'unset')
      canvas.css('height', 'unset')

      // start syncing the view
      window.addEventListener('resize', syncWideScreen)
      syncWideScreen()
    }
    // can
    else {
      container.css('max-height', `${maxHeight}px`).css('height', `${preferredHeight}px`)
      canvas.css('height', `${preferredHeight}px`)
      this._revertWideScreen(instance)

      window.removeEventListener('resize', this._syncWideScreenToResize)
    }
  },

  // remove wide screen handling
  _revertWideScreen: function (instance) {
    // remove styling
    const card = $('.js-game-card')
    const container = $('.js-game')

    // disable widescreen
    card.data({ widescreen: false })

    // revert sizing before syncing the container
    card.css({ height: 'unset' })
    container.css({
      position: '',
      transform: '',
      left: '',
      width: 'unset'
    })

    // sync the game container - this should
    // be present in tc-game instances
    instance.syncToContainer?.()
  },

  // sync games to wide screen mode
  _matchWideScreen: function (instance, width, height) {
    const card = $('.js-game-card')
    const container = $('.js-game')
    const allowed = $("#app").width()
    const scaled = allowed / width
    const ratio = height / width
    const target = Math.max(height, Math.min(window.innerHeight, (height * scaled)))
    const fitTo = target * ratio

    // cache original sizing
    if (!card.data('widescreen')) {
      card.data({
        widescreen: true,
        height: card.height(),
        width: card.width()
      })
    }

    // define the container height
    card.height(fitTo)

    // absolutely position the game centered as wide
    // as allowed for the container
    container.css({
      position: 'absolute',
      height: fitTo,
      left: '50%',
      transform: 'translate(-50%, 0)',
      width: allowed
    })

    // sync the game container - this should
    // be present in tc-game instances
    instance.syncToContainer?.()
  },

  _startKeyboardJump: function({ avatar, language }) {
    const gameHeight = 450
    const game = FTWGLOBALS('game')
    const activate = FTWGLOBALS('keyboard-jump')
    const { game_id } = game
    const user = Registry.get('student')
    const showAds = AdView.canShowAds()
    const container = $('.js-game')

    // default sizing
    container.css({ 'max-height': `${gameHeight}px` })

    // holds the actual game instance
    let instance

    // start loading the wordlist and return the wordlist
    // (hopefully done loading when requested
    const wordlist = $.getJSON(`/dist/student/extras/game_files/word_list/${language}.json`)
    const getWordList = async () => await wordlist

    // determine game restart/menu behavior
    const onRestartGame = () => {
      window.location.reload()
      return false
    }

    // handle when the game has finished
    const onGameOver = ({ level, difficulty, seconds, score }) => {

      // get the correct name
      const levelName = {
        hard: 'hard_words',
        easy: 'easy_words',
        letters: 'all_letters',
        home: 'home_row',
        top: 'top_row',
        bottom: 'bottom_row',
      }[level] || level

      // log the game ending
      window.gameOver?.({
        score,
        seconds,
        difficulty,
        level: levelName
      })
    }

    // debugging help
    let scene
    let wordCount
    let autoPlay

    // optional params for local testing
    if (/(feature|local)/.test(window.location.host)) {
      wordCount =['word_count', 'wordCount', 'wordcount']
        .map(key => parseInt(localStorage.getItem(`kj:${key}`)))
        .find(value => typeof value === 'number' || value instanceof Number)

      autoPlay = localStorage.getItem('kj:autoplay')

      // get the scene
      scene = localStorage.getItem('kj:scene')
      if (!['forest','desert','underground','arctic','space','sweet'].includes(scene)) {
        scene = undefined
      }
    }

    // allowing wide screen mode, if possible
    const onToggleWideScreen = showAds ? null : ((active, { width, height }) => {
      console.log('size is', height)
      this.toggleWideScreen(active, instance, { width, height, maxHeight: gameHeight, preferredHeight: gameHeight })
    })

    // get an avatar ID
    const avatarVersion = /v3$/i.test(avatar) ? 'c'
        : /v2$/i.test(avatar) ? 'b'
        : 'a'

    const avatarType = /^app/.test(avatar) ? 'bot'
      : /^pixels/i.test(avatar) ? 'pixels'
      : /^fox/i.test(avatar) ? 'fox'
      : /^princess/i.test(avatar) ? 'princess'
      : /^forest/i.test(avatar) ? 'forest'
      : /^sea/i.test(avatar) ? 'sea'
      : /^space/i.test(avatar) ? 'space'
      : /^superhero/i.test(avatar) ? 'superhero'
      : /^nitrotype/i.test(avatar) ? 'nitrotype'
      : 'bot'

    // create the game instance
    instance = activate({
      avatar: `${avatarType}_${avatarVersion}`,
      container: container[0],
      gameId: game_id,
      userId: user.get('user_id'),
      assetDirectory: '/dist/student/extras/game_files/keyboard-jump-next/assets/',
      scene,
      wordCount,
      autoPlay,
      onPlayAgain: onRestartGame,
      getWordList,
      onGameOver,
      onToggleWideScreen
    })

    // start the game
    instance.start()
  },

  // creates a new instance of Keyboard Ninja
  _startKeyboardNinja: function() {
    const gameHeight = 450
    const game = FTWGLOBALS('game')
    const activate = FTWGLOBALS('keyboard-ninja')
    const { game_id } = game
    const user = Registry.get('student')
    const showAds = AdView.canShowAds()
    const container = $('.js-game')

    // holds the actual game instance
    let instance

    // find current points for the player
    const points = this.getPointsFromUserGames(game_id)

    // determine game restart/menu behavior
    const onRestartGame = !showAds
      // no ads? just close the siderail
      ? () => this.closeSiderail()
      // showing ads? refresh the page
      : (() => {
          window.location.reload()
          return false
        })

    // handle when the game has finished
    const onGameOver = ({ mode, difficulty, sliced, seconds, score }) => {

      // map the correct mode key
      const saveModeAs = {
        all: 'all_letters',
        top: 'top_row',
        bottom: 'bottom_row',
        home: 'home_row'
      }[mode] || mode

      const saveDifficultyAs = {
        // 'medium': 'normal'
      }[difficulty] || difficulty

      // log the game ending
      window.gameOver?.({
        score,
        seconds,
        fruitSliced: sliced,
        difficulty: saveDifficultyAs,
        level: saveModeAs
      })
    }

    // allowing wide screen mode, if possible
    const onToggleWideScreen = showAds ? null : ((active, {  width, height }) => {
      this.toggleWideScreen(active, instance, { width, height, maxHeight: gameHeight, preferredHeight: gameHeight })
    })

    // default sizing
    container.css({ 'max-height': `${gameHeight}px` })

    // create the game instance
    instance = activate({
      container: container[0],
      userId: user.get('user_id'),
      assetDirectory: '/dist/student/extras/game_files/keyboard-ninja/assets/',
      points,
      onPlayAgain: onRestartGame,
      onReturnToMain: onRestartGame,
      onGameOver,
      onToggleWideScreen,
      enableSlashEffects: true
    })

    // start the game
    instance.start()
  },

  // tries to figure out how many points a
  // player has for a particular game ID
  getPointsFromUserGames: function (gameId) {
    try {
      return Registry.get('userGames')
        .toJSON()
        .filter(game => game.game_id === gameId)
        .reduce((total, entry) => total + entry.score, 0)
    }
    catch (ex) {
      return 0
    }
  },

  gameOver: function(data) {
    if(this.views.scoreboardView) {
      this.views.scoreboardView.gameOver(data);
    }

    this.openSiderail();
  },

  openSiderail: function() {
    $('.js-siderail').addClass('is-open').attr('aria-hidden', 'false');
  },

  closeSiderail: function() {
    $('.js-siderail').removeClass('is-open');
  }
})
