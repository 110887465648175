import Backbone from 'backbone'

export default Backbone.Model.extend({

  idAttribute: 'unit_id',

  defaults: {
    name: '',
    subject: '',
    header: '',
    product_id: '',
    type: '',
    display_order: 0
  }
})
