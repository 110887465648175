import Backbone from 'backbone'
import FormModel from './models/form'
import MainView from './views/main'
import FailedView from './views/failed'
import LayoutView from '../global/views/layout_basic'

export default Backbone.View.extend({

  layout: LayoutView,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.model = new FormModel();

    this.views.main = new MainView({
      model: this.model
    });
    this.views.failed = new FailedView();

    var parts = window.location.hash.replace('#', '').split('!'),
      hash = parts[0],
      id = parts[1];

    this.model.validateHash(hash, id)
      .done(function(data) {
        this.model.set(data);
        this.addChild(this.el, this.views.main, true);
        this.render();
      }.bind(this))
      .fail(function() {
        this.addChild(this.el, this.views.failed, true);
        this.render();
      }.bind(this));

  }

})
