import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import Scoring from '@shared/scoring'
import UnableToPrintModal from './unable_to_print_modal'
import Alert from '../../global/views/alert'
import { sortBy } from 'lodash-es'
import moment from 'moment'
import { tests_content } from '@templates/student'

export default Backbone.View.extend({

  template: tests_content,

  events: {
    'click .js-print': 'print'
  },

  hasLoaded: false,

  serialize: function(){

    var user = Registry.get('student'),
      products = Registry.get('products'),
      product = products.get(user.getSetting('product_id')),
      productLanguage = (product) ? product.get('language') : FTWGLOBALS('defaultLanguage'),
      suffix = (productLanguage !== FTWGLOBALS('defaultLanguage') && productLanguage) ? ('/' + productLanguage) : '';

    this.data = this.tests.map(function(row){
      row.speed = Scoring.speed(row.typed, row.seconds, row.errors);
      row.accuracy = Scoring.accuracy(row.typed, row.errors);
      return row;
    });

    this.data = sortBy(this.data, function(row){ return row[this.sortBy]; }.bind(this)).reverse();

    return {
      moment: moment,
      seconds: this.seconds,
      hasLoaded: this.hasLoaded,
      sortBy: this.sortBy,
      loggedIn: Registry.get('loggedIn'),
      tests: this.data,
      urlSuffix: suffix
    };
  },

  print: function(e){
    var id = $(e.currentTarget).data('id'),
      user = Registry.get('student');

    if(!(user.get('first_name') && user.get('last_name'))) {
      if(!Registry.get('loggedIn')) {
        new UnableToPrintModal().open();
      } else if(user.get('in_class') && user.hasOption('lockaccount')) {
        (new Alert({
          title: 'lesson.unable_to_print'.t(),
          text: 'lesson.contact_teacher_to_print'.t()
        }).show());
      } else {
        (new Alert({
          cancel: 'shared.cancel_text'.t(),
          title: 'lesson.unable_to_print'.t(),
          text: 'lesson.enter_name_to_print'.t(),
          ok: 'lesson.go_to_account_settings'.t()
        }).show().on('ok', function(){
          window.location.href = __url('/student/account');
        }));
      }

      return;
    }

    window.open('/apiv1/student/tests/' + id + '/' + user.id + '/certificate?language=' + FTWGLOBALS('language'));
  }

})
