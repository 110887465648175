import TypingStandardView from './typing_standard'

export default TypingStandardView.extend({

  render: function() {
    if(this.lesson.getTestType() === 'timed') {
      this.listenTo(this.timer, 'change:seconds', this.updateTimer);
    }
    return TypingStandardView.prototype.render.apply(this);
  },

  updateTimer: function(model, seconds) {
    if (Math.floor(seconds) >= this.lesson.get('time_limit')) {
      this.handleComplete();
    }
  },

  /**
   * Override this to force the seconds to the proper speed so it's always an exact minute multiple
   * @returns {*}
   */
  handleComplete: function() {
    this.timer.stop();
    if(this.unit && this.unit.get('type') === 'test' && this.lesson.getTestType() === 'timed') {
      this.timer.set({seconds: this.lesson.get('time_limit')});
    } else {
      this.timer.set({seconds: this.timer.get('seconds')});
    }
    return TypingStandardView.prototype.handleComplete.call(this);
  }
})
