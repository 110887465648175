import FormModel from '@shared/form_model'

export default FormModel.extend({
  url: '/apiv1/student/login-username',

  defaults: {
    district_id: null,
    username: null
  },

  validationRules: {
    username: {
      required: true
    }
  },

  validationMessages: {}
})
