import Backbone from 'backbone'
import Scoring from '@shared/scoring'
import { isArray } from 'lodash-es'

export default Backbone.Collection.extend({

  model: Backbone.Model.extend({
    idAttribute: 'user_id'
  }),

  url: '/apiv1/student/scoreboard',

  parse: function(response) {
    if(isArray(response)) {
      response.forEach(function(row){
        row.speed = Scoring.speed(row.typed, row.seconds, row.errors);
        row.accuracy = Scoring.accuracy(row.typed, row.errors);
        row.active = row.seconds;
      });
    }

    return Backbone.Collection.prototype.parse.call(this, response);
  },

  comparatorKey: 'speed',
  period: 'this_week',

  comparator: function(model) {
    return - model.get(this.comparatorKey);
  }
})


