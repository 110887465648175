import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import GamesCollection from '../collections/games'
import AdButtonsView from '../../global/views/ad_buttons'
import AdView from '../../global/views/ad'
import { isIE11 } from '@shared/helpers'
import { filter, each } from 'lodash-es'
import { games_list } from '@templates/student'

export default Backbone.View.extend({

  template: games_list,

  events: {
    'click .js-game': 'goToGame'
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    var user = Registry.get('student'),
      availableGames = user.getGames(FTWGLOBALS('games'))

    this.games = new GamesCollection(availableGames);

    if(AdView.canShowAds()) {
      AdView.initAds('games_list');
      // grab all the ads and assign them to the right divs
      var ads = AdView.getAds();
      each(ads, (ad, size) => {
        if(!ad) { return; }
        this.addChild('.js-'+size+'-ad', ad);
      });

      var adButtonsView = new AdButtonsView();
      this.addChild('.js-ad-buttons', adButtonsView, false);
    }
  },

  serialize: function() {
    var user = Registry.get('student');

    return {
      showNitroType: user.hasGame('nitrotype'),
      ads: AdView.canShowAds(),
      adsClass: AdView.getLeftMarginClass(),
      games: this.games.toJSON(),
      loggedIn: Registry.get('loggedIn'),
    };
  },

  goToGame: function(e) {
    var id = parseInt($(e.currentTarget).data('id')),
      game = this.games.get(id);

    if(game.get('failBrowser')) {
      window.alert('games.browser_out_of_date'.t({game: game.get('name')}));
      return false;
    }

    return true;
  }
})
