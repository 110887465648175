import $ from 'jquery'
import moment from 'moment'
import { extend } from 'lodash'

const JWT = function(prefix, user) {
  this.prefix = prefix;
  this.user = user;

  $.ajaxSetup({
    beforeSend: function(xhr) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.token());
      xhr.setRequestHeader('x-language', FTWGLOBALS('language'));
    }.bind(this),
    complete: function(xhr, status) {
      if ((status === 'success' && xhr.status == 200) || xhr.status == 403) {
        if(xhr.getResponseHeader('Authorization') || (xhr.responseJSON && xhr.responseJSON.token)) {
          this.token(xhr.responseJSON.token || xhr.getResponseHeader('Authorization'));
        }
      }


      if(xhr.responseJSON && xhr.responseJSON.checkTimestamp) {
        var serverTime = xhr.responseJSON.checkTimestamp;

        // if local time is off by more than 10 minutes then don't let them proceed
        if(Math.abs(serverTime - Date.getUnixTime()) > 60*10) {
          window.alert('shared.computer_time_problem_header'.t() + '\n\n' + 'shared.computer_time_problem_text'.t({
            computer_time: moment().format('dddd, MMMM Do YYYY, h:mm:ss a'),
            correct_time: moment(serverTime * 1000).format('dddd, MMMM Do YYYY, h:mm:ss a'),
            site: FTWGLOBALS('app_name')
          }));
          return false;
        }
      }
    }.bind(this)
  });

  $(document).ajaxError(function(ajax, response) {
    if (response.status == 401) {
      console.error('Invalid token or session', response); // eslint-disable-line
      window.localStorage.removeItem(this.prefix + '_jwt_token');
    }
  }.bind(this));
};

extend(JWT.prototype, {

  valid: function() {
    return this.token() && !this.expired();
  },

  expired: function() {
    var exp = this.expiration();

    return exp ? Date.now() > exp * 1000 : true;
  },

  expiration: function() {
    var jwtP = this.payload();

    return jwtP ? jwtP.exp : 0;
  },

  token: function(token) {
    if(token) {
      window.localStorage.setItem(this.prefix + '_jwt_token', token.replace('Bearer ', ''));
      return token;
    } else {
      return window.localStorage.getItem(this.prefix + '_jwt_token');
    }
  },

  payload: function() {
    var jwtToken = this.token();
    if (jwtToken) {
      var payload = jwtToken.split('.')[1];
      if (payload) {
        return JSON.parse(atob(payload));
      }
    }
    return undefined;
  },

  /**
   * Get the auth ID for the given prefix
   * @param prefix (student or teacher)
   * @returns {(() => string)|undefined}
   */
  getAuthPayloadId: function(prefix) {
    var token = window.localStorage.getItem(prefix + '_jwt_token');
    if(token) {
      var payload = token.split('.')[1];
      if (payload) {
        payload = JSON.parse(atob(payload));
        if(Date.now() <= payload.exp * 1000) {
          return payload.sub;
        }
      }
    }

    return undefined;
  }
});

export default JWT
