import Registry from '@registry'
import $ from 'jquery'
import moment from 'moment'
import Velocity from 'velocity-animate'
import 'imports-loader?jQuery=jquery,$=jquery,define=>false,module.exports=>false!jquery-countdown'
import LayoutBasic from './layout_basic'
import DashboardView from './dashboard'
import StudentSurveyView from './student_survey'
import Dictation from '../../lesson/classes/dictation'
import LessonsCollection from '../collections/lessons'
import Scoring from '@shared/scoring'
import SkinsCollection from '../collections/skins'
import Tooltip from './tooltip'
import Notice from '@shared/notice'
import AdView from './ad'
import CurriculumModal from './curriculum_modal'
import LanguageModal from './language_modal'
import UnitsCollection from '../collections/units'
import { debounce, isArray, isEmpty } from 'lodash-es'
import { isMac } from '@shared/helpers'
import { global_layout } from '@templates/student'

export default LayoutBasic.extend({

  template: global_layout,

  events: {
    'click .js-logout': 'logOut',
    'click .js-skin': 'changeSkin',
    'click .js-hide-announcement': 'hideAnnouncement',
    'click .js-language': 'showLanguageModal',
    'click .js-curriculum-modal-trigger': 'showCurriculumModal',
    'click .js-dictation': 'toggleDictation',
  },

  initialize: function() {
    LayoutBasic.prototype.initialize.apply(this, arguments);

    this.user = Registry.get('student');
    this.listenTo(this.user, 'change:skin_id', this.animateSkin);
    this.listenTo(this.user, 'change:variant_id', this.animateSkin);
    this.userLettersTyped = Registry.get('userLettersTyped');
    this.userActivity = Registry.get('userActivity');

    this.skins = new SkinsCollection(FTWGLOBALS('skins'));

    this.lessons = new LessonsCollection(FTWGLOBALS('lessons'));
    this.units = new UnitsCollection(FTWGLOBALS('units'));

    this.ads = AdView.canShowAds();

    // Hide the "Get Plus" after user upgrades their account
    this.listenTo(this.user, 'change:licensed_at', () => {
      this.$('.header-callout').fastHide()
    })

    if(window.location.pathname.match(/\/student\/upgrade/) || window.location.pathname.match(/\/student\/start/)  || window.location.pathname.match(/\/student\/digital-literacy$/) || window.location.pathname.match(/\/student\/coding-curriculum$/)) {
      this.ads = 0;
    }

    this.miniNav = (window.location.pathname.match(/\/student\/tests/) !== null && !Registry.get('loggedIn')) || window.location.pathname.match(/\/student\/game/) !== null || window.location.pathname.match(/\/student\/upgrade/) !== null;

    this.views.dashboard = new DashboardView({ads: this.ads});
    this.listenTo(this.views.dashboard, 'change-time', this.changeTime);
    this.views.miniDashboard = new DashboardView({mini: true});

    $(document).keydown(function(e) { // Used to turn dictation on and off from any screen
      if((e.metaKey || e.ctrlKey) && e.shiftKey && e.key === 'd') {
        e.preventDefault();
        this.toggleDictation();

        return false;
      }
    }.bind(this));

    if(!this.miniNav) {
      this.addChild('.js-dashboard', this.views.dashboard);
    }

    this.addChild('.js-dashboard-mini', this.views.miniDashboard);

    // Only show the survey under strict conditions
    if(Registry.get('loggedIn') && !FTWGLOBALS('whitelabel') && !window.location.pathname.match(/\/student\/game\//)  && this.user.get('_hideSurvey') !== true && isEmpty(this.user.getSetting('individual_org_type')) && !this.user.get('in_class') && (Registry.get('userLessonScreens').length > 0 || Registry.get('userTests').length > 0 || Registry.get('userGames').length > 0)) {
      new StudentSurveyView();
    }

    this.setupFixedNav();
  },

  setupFixedNav: function() {
    var mainNav,
      fixedNav;
    if(!this.miniNav) {
      $(window).scroll(debounce((e) => {
        if(!mainNav) {
          mainNav = this.$('#app');
        }
        if(!fixedNav) {
          fixedNav = this.$('.js-fixed-nav');
        }
        if(window.scrollY >= mainNav.offset().top) {
          fixedNav.show();
          fixedNav.addClass('is-active');
        } else {
          fixedNav.removeClass('is-active');
          window.setTimeout(() => fixedNav.hide(), 250);
        }
      }, 100));
    }
  },

  serialize: function() {
    var skin = this.skins.get(this.user.get('skin_id') || 1),
      activity = this.userActivity.getOrAdd(0).toJSON(),
      rank = skin.getRank(activity.typed - activity.errors),
      user = Registry.get('student');

    return {
      moment: moment,
      ads: this.ads,
      adsClass: AdView.getLeftMarginClass(),
      dashboard: this.dashboard,
      loggedIn: Registry.get('loggedIn'),
      user: this.user.toJSON(),
      skin: skin.toJSON(),
      skins: this.skins.toJSON(),
      scoreboard: user.get('in_class') ? user.hasOption('scoreboard') : false,
      lockAccount: user.get('in_class') ? user.hasOption('lockaccount') : false,
      changePassword: user.get('in_class') ? user.hasOption('changepassword') : false,
      typingTests: user.hasOption('typingtests'),
      noGames: user.getGames(FTWGLOBALS('games')).length === 0,
      osType: isMac() ? 'mac' : 'win',
      premium: window.location.pathname.match(/\/student\/upgrade/) !== null,
      product_id: user.getSetting('product_id'),
      rank: rank,
      language: Registry.get('languages').get(FTWGLOBALS('language')).toJSON(),
      miniNav: this.miniNav,
      changeTheme: !user.get('in_class') || user.hasOption('changetheme'),
      logoPath: this.user.get('district')?.logo
    };

  },

  render: function() {
    LayoutBasic.prototype.render.apply(this, arguments);

    // Wait until the entire page is rendered before animating
    window.setTimeout(() => this.animateSkin(), 0);
  },

  logOut: function () {
    Registry.get('student').logOut();
    window.location.href = __url('/student/login');
    return false;
  },

  changeTime: function(e) {
    var target = $(e.currentTarget),
      studentStatsRange = target.data('id'),
      text = target.text();

    this.user.set({ studentStatsRange });

    var activity = this.userActivity.getCompiledData(studentStatsRange);

    var speed = this.$('.js-speed');
    if(speed.length) {
      Velocity(speed.countdown(
        Scoring.speed(activity.typed, activity.seconds, activity.errors),
        null,
        400
      ).parent(), 'fadeIn');

      Velocity(this.$('.js-accuracy').countdown(
        (activity.typed > 0) ? Scoring.accuracy(activity.typed, activity.errors) : 0,
        null,
        400
      ).parent(), 'fadeIn');

      var time = this.$('.js-time-spent'),
        startTime = time.data('data');
      time.data('data', activity.seconds);

      Velocity(time.countdown(
        [activity.seconds, startTime],
        {formatter: function(num){  return num.countdownSeconds(true, true);}},
        400,
        function(){
          if(activity.seconds > 0 && activity.seconds < 60) {
            time.text('dashboard.less_than_1_min'.t());
          }
        }
      ).parent(), 'fadeIn');
    }

    this.$('.js-time-button').html(text);

    Tooltip.hideAll();

    return false;
  },

  hideAnnouncement: function() {
    this.$('.js-announcement').remove();
    // Remove the announcement from local storage so the user never sees it again
    var student = Registry.get('student');
    student.unset('announcement');
    return false;
  },

  showLanguageModal: function() {
    new LanguageModal({
      user: this.user
    }).open().done((data) => {
      data.keyboard_id = parseInt(data.keyboard_id);
      // Will set language and product_id
      this.user.setSettings(data);

      var url = (window.location.pathname + window.location.hash).replace(new RegExp('^/'+FTWGLOBALS('language')), '');
      window.location.href = (data.language === FTWGLOBALS('defaultLanguage')) ? url : ('/'+data.language+url);
    })

    return false;
  },

  showCurriculumModal: function() {
    const productId = this.user.getSetting('product_id')
    new CurriculumModal({
      user: this.user,
      productId: productId
    }).open()

    return false;
  },

  toggleDictation: function() {
    this.user.setSettings({ dictation: !this.user.getSetting('dictation') });
    new Notice({ text: 'global.dictation_confirmation'.t({dictationStatus: this.user.getSetting('dictation') ? 'shared.switch_label_on'.t() : 'shared.switch_label_off'.t()}) }).show(this.button);

    var dictation = new Dictation();
    if(this.user.getSetting('dictation')) {
      dictation.resume();
    } else {
      dictation.turnOff();
    }
  },

  animateSkin: function() {
    var skin = this.user.get('skin_id'),
      variant = this.user.get('variant_id'),
      activeElement = this.$('.velocity-animating'),
      heroAssets = this.$('.js-hero-assets'),
      screenWidth = Math.ceil($(window).width()),
      i = 0,
      animationSequence = null;

    // Reset Dom Elements
    if(activeElement) { Velocity(activeElement, 'stop'); }
    if(heroAssets.length > 0) { heroAssets.remove(); }
    this.$('.hero-asset').replaceWith('<div class="hero-asset"></div>');

    switch (skin) {
    case 1: // App
      if(variant === 1) {
        this.$('.hero-asset').append('<div class="hero-cloud"></div><div class="hero-cloud"></div><div class="hero-cloud"></div>');
        animationSequence = function(elem, index) {
          var elemWidth = $(elem).outerWidth(),
            translateTo = Math.ceil(screenWidth / elemWidth * 120),
            animationDur = 180000;
          Velocity($(elem),
            { translateX: [translateTo + '%','-120%'] },
            {
              delay: index * animationDur / this.$('.hero-cloud').length, // The first delay is different from subsequent delays
              duration: animationDur,
              easing: 'linear',
              complete: function() {
                animationSequence(elem, index);
              }
            });
        }.bind(this);
        this.$('.hero-cloud').each((index, elem) => {
          window.setTimeout(() => animationSequence(elem, index), 0); // We just appended HTML, therefore we must wait to render first
        });
      } else { // App : tumbleweed
        this.$('.hero-asset').append('<div class="hero-hasTumbleweed"><div class="hero-tumbleweed"></div></div>');
        animationSequence = function(direction) {
          var elemWidth = this.$('.hero-tumbleweed').outerWidth(),
            translateTo = Math.ceil(screenWidth/elemWidth*120);
          this.$('.hero-hasTumbleweed').removeClass('to-left').addClass('is-animating to-right').css('transform', 'translateX(-100%)');
          if(i < 3) {
            Velocity(this.$('.hero-hasTumbleweed'),
              {translateX: [translateTo + '%', '-100%']},
              {
                duration: 25000,
                easing: 'linear',
                complete: function() {
                  this.$('.hero-hasTumbleweed').removeClass('to-right').addClass('to-left');
                  Velocity(this.$('.hero-hasTumbleweed'),
                    {translateX: '-100%'},
                    {
                      duration: 25000,
                      delay: 5000,
                      easing: 'linear',
                      complete: function() {
                        window.setTimeout(() => animationSequence(), 3500 );
                        i++;
                      }
                    });
                }.bind(this)
              });
          } else { Velocity(activeElement, 'stop'); }
        }.bind(this);
        window.setTimeout(() => animationSequence(), 3500);
      }
      break;
    case 3: // Princess
      this.$('.hero-asset').append('<img src="/dist/student/images/skins/princess/hero-spider.svg" class="hero-spider">').addClass('is-animating');
      break;
    case 4: // Forest
      this.$('.hero-asset').after('<div class="js-hero-assets"><div class="hero-balloon"></div><div class="hero-bird"><img src="/dist/student/images/skins/forest/hero-bird.png"></div></div>');
      animationSequence = function() {
        if(i < 3) {
          Velocity(this.$('.hero-balloon'),
            {left: '105%', bottom: '50px'},
            {
              duration: 20000,
              easing: 'linear',
              begin: function() {
                this.$('.hero-balloon').addClass('is-animating')
              }.bind(this),
              complete: function() {
                this.$('.hero-balloon').removeClass('is-animating').addClass('is-exploded');
                Velocity(this.$('.hero-balloon'), {bottom: '-100px', opacity: '0'},{duration: 1000, easing: 'easeIn'})
              }.bind(this)
            }
          );
          Velocity(this.$('.hero-bird'),
            { bottom: '-100px', left: '-40%' },
            {
              duration: 6000,
              delay: 18700,
              easing: 'linear',
              complete: function() {
                i++;
                this.$('.hero-balloon').removeClass('is-exploded').css({'opacity': '1', 'left': '-100px'});
                this.$('.hero-bird').css({'left': '150%', 'bottom': '80px'});
                window.setTimeout(() => animationSequence(), 25000 );
              }.bind(this)
            });
        } else { Velocity(activeElement, 'stop'); }
      }.bind(this);
      window.setTimeout(() => animationSequence(), 3500);
      break;
    case 9: // Nitro Type
      this.$('.hero-asset').append('<div class="hero-hasTire"><div class="hero-tire"></div></div>');
      animationSequence = function() {
        var elemWidth = $('.hero-hasTire').outerWidth(),
          animateFromRight = Math.random() > 0.5,
          translateFrom = animateFromRight ? screenWidth : -elemWidth,
          translateTo = animateFromRight ? -elemWidth*5 : screenWidth + elemWidth*5;
        if(i < 6) {
          this.$('.hero-hasTire').addClass('is-animating').css({'left': translateFrom + 'px'});
          if(Math.random() <= 0.5) { this.$('.hero-hasTire').addClass('animation--big'); }
          if(animateFromRight) { this.$('.hero-hasTire').addClass('to-left'); }
          Velocity(this.$('.hero-hasTire'),
            {left: translateTo + 'px'},
            {
              duration: 25000,
              delay: i === 0 ? 2500 : 5000,
              easing: 'easeOutSine',
              complete: function() {
                this.$('.hero-hasTire').removeClass('is-animating animation--big to-left');
                i++;
                animationSequence();
              }.bind(this)
            });
        } else { Velocity(activeElement, 'stop'); }
      }.bind(this);
      window.setTimeout(() => animationSequence(), 2500);
      break;
    }
  }
})
