import Backbone from 'backbone'
import Registry from '@registry'
import Scoring from '@shared/scoring'
import UnableToPrintModal from '../../tests/views/unable_to_print_modal'
import Alert from '../../global/views/alert'
import AdView from '../../global/views/ad'
import AuthModal from '../../global/views/auth_modal'
import ScreenCompleteView from './screen_complete'
import SkinsCollection from '../../global/collections/skins'
import ProgressBarView from '../../global/views/progress_bar'
import { find, extend, difference } from 'lodash-es'
import { lesson_test_complete } from '@templates/student'
import { formatKeys, getIntroAIHtml } from '../../utils/problem_keys_lesson';

export default ScreenCompleteView.extend({

  template: lesson_test_complete,

  saved: true, // Tests are saved before hitting the complete screen

  handleKeyPress: function(e) {
    return false;
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    var events = this.events || {};

    this.delegateEvents(
      extend(events, {
        'click .js-print-certificate': 'printCertificate'
      })
    );

    this.user = Registry.get('student');
    this.userActivity = Registry.get('userActivity');
    this.skins = new SkinsCollection(FTWGLOBALS('skins'));
    this.skin = this.skins.get(this.user.get('skin_id') || 1);

    this.listenTo(Registry.get('userTests'), 'add', () => {
      if(!Registry.get('loggedIn') && (Registry.get('userTests').length === 1 || Registry.get('userTests').length % 3 === 1)) {
        window.setTimeout(() => { new AuthModal({ authType: 'signup' }).open() }, 500);
      }
    })

    if(this.progressView) {
      this.addChild('.js-progress', this.progressView, true);
    }

    this.initializeGoal()
  },

  serialize: function() {
    var screen = (this.problemKeysLesson) ? this.screen : this.userTests.first(),
      skin = this.skins.get(this.user.get('skin_id') || 1),
      activity = this.userActivity.getOrAdd(0).toJSON(),
      testType = this.lesson.getTestType(),
      accuracy = Scoring.accuracy(screen.get('typed'), screen.get('errors')),
      speed = Scoring.speed(screen.get('typed'), screen.get('seconds'), screen.get('errors')),
      time = (this.unit && this.unit.get('type') === 'test' && testType !== 'page') ? this.lesson.get('time_limit') : this.screen.get('seconds'),
      assessmentData = null;

    this.rank = skin.getRank(activity.typed - activity.errors);
    this.nextRank = skin.getNextRank(activity.typed - activity.errors);
    this.rankXPOld = (activity.typed - activity.errors) - (screen.get('typed') - screen.get('errors'));
    this.rankXPTotal = activity.typed - activity.errors;
    this.rankProgressOld = (this.nextRank) ? (this.rankXPOld - this.rank.experience)/(this.nextRank.experience - this.rank.experience)*100 : 100;
    this.rankProgressTotal = (this.nextRank) ? (this.rankXPTotal - this.rank.experience)/(this.nextRank.experience - this.rank.experience)*100 : 100;

    this.updateGoal(screen.get('seconds'))

    // The following code is used for EduTec Assessments! Gather the necessary deets
    if(this.user.isAssessment()) {
      const tests = ['1-minute', '3-minute', '5-minute', '1-page', '2-page', '3-page'],
        userTests = Registry.get('userTests').toJSON(),
        userTestNames = userTests.map(test => test.testType === 'timed' ? `${test.seconds / 60}-minute` : test.testType),
        missingTestNames = difference(tests, userTestNames),
        slug = missingTestNames.length > 0 ? missingTestNames[0] : null

      assessmentData = {
        slug: slug,
        urlSuffix: this.getUrlSuffix(),
        currentAssessmentIndex: userTestNames.length
      }

      // If the user has finished all the tests, get their score
      if(missingTestNames.length === 0) {
        const userTestResults = userTests.reduce((acc, cur) => {
          acc['typed'] += cur.typed;
          acc['errors'] += cur.errors;
          acc['seconds'] += cur.seconds;
          return acc;
        }, { typed: 0, errors: 0, seconds: 0 })

        accuracy = Scoring.accuracy(userTestResults.typed, userTestResults.errors)
        speed = Scoring.speed(userTestResults.typed, userTestResults.seconds, userTestResults.errors)
        time = userTestResults.seconds

        // When the user finishes ALL assessments but has not passed the minimum requirements we put a simple timestamp in their local storage which forces them to wait 8 hours before retrying
        if((assessmentData.accuracy < 95 || assessmentData.speed < 45) && !localStorage.getItem(`${this.user.get('user_id')}_assessment_unlock`)) {
          localStorage.setItem(`${this.user.get('user_id')}_assessment_unlock`, Date.now() + (1000 * 60 * 60 * 8)); // 8 hours from now
        }
      }
    }

    return {
      ads: AdView.canShowAds(),
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: activity.screens }),
      unitType: this.unit ? this.unit.get('type') : '',
      lesson: this.lesson.toJSON(),
      screen: screen.toJSON(),
      title: this.screen.get('title'),
      isCustomTest: this.unit && this.unit.get('type') === 'custom',
      testType: testType,
      accuracy: accuracy,
      speed: speed,
      time: time,
      screenType: this.screenType,
      showGoal: !!this.goalView,
      lessonTest: this.unit && this.unit.get('type') !== 'test',
      problemKeysScreen: this.screen.get('settings')?.problem_keys,
      problemKeysLesson: this.problemKeysLesson,
      problemKeysHasAIEnabled: this.user.hasOption('problemkeysai'),
      ai: getIntroAIHtml(),
      keys: formatKeys(this.practiceKeys),
      skin: skin.toJSON(),
      variantId: this.user.get('variant_id'),
      productGrade: this.user.getCurriculumGrade(),
      skins: this.skins.toJSON(),
      rank: this.rank,
      nextRank: this.nextRank,
      loggedIn: Registry.get('loggedIn'),
      totalRanks: skin.getTotalRanks(),
      rankProgress: this.rankProgressTotal,
      activity: activity,
      firstLesson: this.getFirstLesson(),
      renderInline: this.renderInline,
      unlockedScreens: this.user.hasOption('unlockedscreens'),
      isAssessment: this.user.isAssessment(),
      assessmentData: assessmentData,
      showButtons: !this.hasGoogleClassroomAddon,
    }
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments); // skip the screen_complete render
    this.listenTo(this.input, 'character', (character) => {
      if (this.user.isAssessment() && character.keyCode === 13) {
        window.location.href = this.$('.js-next-assessment').attr('href')
      }
    });

    var data = this.serialize();

    data.accuracy = Scoring.accuracy(data.screen.typed, data.screen.errors);
    data.speed = Scoring.speed(data.screen.typed, data.screen.seconds, data.screen.errors);
    data.seconds = data.screen.seconds;

    const animationsFn = () => {
      this.dictation.setProperties(this.screen, null);
      this.dictation.speakTestComplete(data);
    }

    // Delay playing screen complete animations if the goal achieved is displayed
    if(this.goalAchieved) {
      this.goalAchieved.done(() => {
        animationsFn()
      })
    } else {
      animationsFn()
    }

    // The progress bar needs to be rendered after the student finishes typing, which is on render of this screen
    if (this.$('.js-progress-bar').length) {
      var progressBarView = new ProgressBarView({
        user: this.user,
        rank: this.rank,
        totalRanks: this.skin.getTotalRanks(),
        nextRank: this.nextRank,
        rankXPOld: this.rankXPOld,
        rankXPTotal: this.rankXPTotal,
        rankProgressOld: this.rankProgressOld,
        rankProgressTotal: this.rankProgressTotal
      });
      this.$('.js-progress-bar').append(progressBarView.render().el);
    }

    return this;
  },

  printCertificate: function() {
    var user = Registry.get('student');

    if(!(user.get('first_name') && user.get('last_name'))) {
      if(!Registry.get('loggedIn')) {
        new UnableToPrintModal().open();
      } else if(user.get('in_class') && user.hasOption('lockaccount')) {
        (new Alert({
          error: false,
          title: 'lesson.unable_to_print'.t(),
          text: 'lesson.contact_teacher_to_print'.t()
        }).show());
      } else {
        (new Alert({
          error: false,
          title: 'lesson.unable_to_print'.t(),
          cancel: 'shared.cancel_text'.t(),
          text: 'lesson.enter_name_to_print'.t(),
          ok: 'lesson.go_to_account_settings'.t()
        }).show().on('ok', function(){
          window.location.href = __url('/student/account');
        }));
      }
      return false;
    }

    if(user.isAssessment()) {
      window.open('/apiv1/student/certificacion/' + user.id + '/certificate?language=' + FTWGLOBALS('language'));
    } else {
      let id = this.userLesson.get('last_save_response').id,
        productString = ''

      // Adds product_id to URL so we can put it on the certificate
      if(this.unit && this.unit.get('type') !== 'test') {
        productString = `&product_id=${user.getSetting('product_id')}`
      }
      window.open('/apiv1/student/tests/' + id + '/' + this.user.id + '/certificate?language=' + FTWGLOBALS('language') + productString);
    }

    return false;
  },

  getFirstLesson: function() {
    return find(FTWGLOBALS('lessons'), { unit_id: FTWGLOBALS('units')[0].unit_id, display_index: 1 });
  },

  /**
   * Used only by EduTec for the assessments feature
   * @returns {string|string}
   */
  getUrlSuffix: function() {
    const curriculumLanguage = this.user.getCurriculumLanguage();

    return curriculumLanguage !== FTWGLOBALS('defaultLanguage') ? ('/' + curriculumLanguage) : '';
  }

})
