import $ from 'jquery'
import Registry from '@registry'
import WrittenPromptModel from '../models/written_prompt'
import Alert from '../../global/views/alert';
import AdView from '../../global/views/ad'
import TypingView from './typing'
import Quill from 'quill'
import { lesson_typing_written_prompt } from '@templates/student'

export default TypingView.extend({

  template: lesson_typing_written_prompt,

  contentClassName: 'Basic',

  modelClass: WrittenPromptModel,

  formSelector: 'form',

  timeSpentTyping: 0,

  events: {
    'keyup .js-text-area': 'handleInput',
    'keydown .js-text-area': 'checkForSkip',
    'paste .js-text-area': 'handlePaste',
    'click .js-submit': 'submitResponse'
  },

  initialize: function({ ignoreCache = false } = { }) {
    TypingView.prototype.initialize.apply(this, arguments)

    const userLessonScreen = this.userLessonScreens.toJSON().find((row) => row.lesson_screen_id === this.screen.get('lesson_screen_id'))

    // Only save progress when this written response is not timed
    if(!this.screen.get('settings')?.time_limit) {
      const cachedResponse = ignoreCache ? '' : Registry.get('student').get('_writtenResponse' + this.screen.id)
      this.response = userLessonScreen?.response || cachedResponse || ''
      if(!this.response.startsWith('<p>')) {
        this.response = '<p>' + this.response + '</p>'
      }
      this.response = $(this.response).html()?.trim()
      const cachedTime = ignoreCache ? 0 : Registry.get('student').get('_writtenResponseSeconds' + this.screen.id)
      this.timeSpentTyping = cachedTime || 0

      $(window).on('beforeunload', this.saveProgress.bind(this))
    }

    this.listenTo(this.timer, 'change:seconds', this.updateTimer);
  },

  /**
   * Override this
   * @returns {*}
   */
  render: function() {
    Registry.set('preventKeyboardInput', true);

    // this.listenTo(this.screen, 'change:letterCacheIndex', this.animate);

    const userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens'),
      unitType = this.unit ? this.unit.get('type') : '',
      screenSettings = this.screen.get('settings') || { }

    this.$el.append(this.template({
      ads: AdView.canShowAds() && (unitType === 'test' || (unitType !== 'test' && totalScreens > 0)), // Always show ads on the test page
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens, forceAd: unitType === 'test', isSubScreen: this.isSubScreen }),
      subScreenWithAds: !!(AdView.canShowAds() && this.isConceptMapActivity),
      screen: this.screen.toJSON(),
      question: this.question,
      user: this.user.toJSON(),
      timeSpentTyping: this.timeSpentTyping,
      screenSettings,
      response: this.response
    }));

    const timeLimit = parseInt(this.screen.get('settings')?.time_limit || 0)
    const container = this.$('.js-text-area').get(0),
      options = {
        modules: {
          toolbar: (screenSettings?.allow_formatting ? { container: [
              [{header: [1, 2, 3, 4, 5, 6, false]}],
              ['bold', 'italic', 'underline'],
              [{list: 'ordered'}, {list: 'bullet'}],
              ['clean']
            ]
          } : false)
        },
        placeholder: timeLimit > 0 ? 'lesson.timed_written_prompt_placeholder'.t({time_limit: timeLimit.countdownSeconds(), word_count: this.screen.get("settings")?.word_count}) : 'lesson.written_prompt_placeholder'.t({word_count: this.screen.get("settings")?.word_count}),
        theme: 'snow'
      };
    this.editor = new Quill(container, options);

    window.setTimeout(() => {
      const ele = this.$('.ql-editor'),
        words = Math.floor(ele.text().length / 5),
        totalWords = screenSettings?.word_count || 0,
        text = words >= totalWords ? 'assignments.prompt_word_count_text'.t({ currentWords: `<span class="tc-t">${words}</span>`, totalWords: totalWords }) :
          'assignments.prompt_word_count_reached'.t()

      ele.focus();

      this.$('.js-current-words').html(text);

      this.processInput();
    }, 100);

    TypingView.prototype.render.apply(this, arguments);

    return this;
  },

  checkForSkip: function(e) {
    // Allows admins to skip this lesson
    if(FTWGLOBALS('env') === 'local' || window.location.hostname.match(/feature-(.*)\.typing\.com/) || window.location.hostname.match(/release-(.*)\.typing\.com/) || window.location.hostname.match(/staging-(.*)\.typing\.com/) || (this.user.get('email') && this.user.get('email').indexOf('@teaching.com') !== -1)) {
      if (e.which === 192 && e.shiftKey && e.ctrlKey) {
        $('.ql-editor').html('<p>lesson skipped by admin!</p>')
        this.submitResponse(true)
      }
    }
  },

  handleInput: function() {
    if(!this.timer.hasStarted()) {
      const now = new Date()
      now.setSeconds(now.getSeconds() - this.timeSpentTyping)
      this.timer.start(now)
    } else if(this.timer.isPaused()) {
      this.timer.unpause();
    }
    this.$('.js-countdown').addClass("is-typing")

    window.clearTimeout(this.idleTimeout);
    this.idleTimeout = window.setTimeout(() => {
      if(!this.timer.isPaused()) {
        this.$('.js-countdown').removeClass("is-typing")
        this.timer.pause()
      }
    }, 10*1000); // if they are idle for 10 seconds, pause the timer

    this.processInput()
  },

  /**
   * Written prompt does not use the same input handler as a standard lesson, therefore
   * we need to capture input here
   */
  processInput: function() {
    const ele = $('.ql-editor'),
      plainText = ele.text().trim(),
      response = ele.html(),
      words = Math.floor(plainText.length / 5),
      totalWords = this.screen.get('settings')?.word_count || 0,
      text = words < totalWords ? 'assignments.prompt_word_count_text'.t({ currentWords: `<span class="tc-t">${words}</span>`, totalWords: totalWords }) :
        'assignments.prompt_word_count_reached'.t({ currentWords: words })

    // Update word count
    this.$('.js-current-words').html(text);

    this.$('input[name=response]').val(response);

    if(words >= totalWords) {
      this.$('.js-submit').removeClass('is-disabled').prop('disabled', false);
    } else {
      this.$('.js-submit').addClass('is-disabled').prop('disabled', true);
    }
  },

  handlePaste: function(e) {
    e.preventDefault();
    return false;
  },

  saveProgress: function() {
    const response = $('.ql-editor').html();

    if (this.editor && this.editor.getLength() > 1) {
      Registry.get('student').set('_writtenResponse' + this.screen.id, response);
      Registry.get('student').set('_writtenResponseSeconds' + this.screen.id, Math.round(this.timer.get('seconds')))

      // Save progress to DB
      this.userLesson.saveResponseProgress({
        response: response,
        lesson_id: this.screen.get('lesson_id'),
        lesson_screen_id: this.screen.get('lesson_screen_id')
      })
    }
  },

  updateTimer: function(model, seconds) {
    seconds = Math.floor(seconds);

    // If this is a timed written prompt, check for time running out
    if(this.screen.get('settings')?.time_limit && Math.floor(seconds) >= this.screen.get('settings').time_limit) {
      this.timer.stop();
      this.stopListening(this.timer)

      if(this.screen.get('settings').time_limit) {
        this.timer.set({ seconds: this.screen.get('settings').time_limit });
      }

      // Display notice telling user they ran out of time
      new Alert({
        title: 'lesson.out_of_time_title'.t(),
        text: 'lesson.out_of_time_text'.t(),
        ok: 'lesson.continue_text'.t(),
        closeButton: false
      }).on('ok', () => {
        this.submitResponse()
      })
    }

    if(!this.timerEl) {
      this.timerEl = this.$('.js-countdown');
    }

    var prevSeconds = this.timerLast;
    this.timerLast = seconds;
    if(prevSeconds === seconds) {
      return;
    }

    this.timerEl.html(seconds.countdownSeconds());
  },

  submitResponse: function(fastForward = false) {
    const response = $('.ql-editor').html();

    this.screen.set({
      fastForward: fastForward,
      typed: 1,
      errors: 0,
      seconds: this.timer.get('seconds'),
      response: response
    }, { silent: true })

    Registry.get('student').unset('_writtenResponseSeconds' + this.screen.id)

    $(window).off('beforeunload')

    this.trigger('complete')

    return false
  },
})
