import Registry from '@registry'
import Backbone from 'backbone'
import $ from 'jquery'
import Scoring from '@shared/scoring'
import moment from 'moment'
import { lessons_assignments } from '@templates/student'
import { find, map, filter } from 'lodash-es'

export default Backbone.View.extend({

  template: lessons_assignments,

  className: 'dashboard-listInner',

  events: {
    'click .js-assignment-start': 'handleAssignmentStartClick'
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments)

    // Once the user's lessons are loaded we update the assignments. Therefore, we need to listen for that event
    this.listenTo(this.assignments, 'change', this.render)
  },

  serialize: function() {
    // Sometimes assignments contain lessons not available to the student. When this
    // happens we mark it as 'invalid' and hide the assignment
    var assignments = filter(this.assignments.toJSON(), (row) => !row.invalid),
      userLessons = Registry.get('userLessons') ? Registry.get('userLessons').toJSON() : []

    // Add the user's lesson progress into the assignment scope lessons
    assignments.forEach((assignment) => {
      if(assignment.lessons) {
        map(assignment.lessons, (row) => {
          // We don't need to check userTests because userTests are embedded into userLessons on the lessons page
          var userLesson = find(userLessons, { lesson_id: row.lesson_id });

          if (userLesson) {
            userLesson.speed = Scoring.speed(userLesson.typed, userLesson.seconds, userLesson.errors);
            userLesson.accuracy = Scoring.accuracy(userLesson.typed, userLesson.errors);
            row.userLesson = userLesson;
            row.complete = ((row.time_limit > 0 && userLesson.created_at >= assignment.created_at) ||
              (row.time_limit === 0 && userLesson.max_progress === row.screens));
            assignment.in_progress = assignment.in_progress || row.complete || userLesson.max_progress > 0;
          }

          if (!assignment.nextLessonSlug && !row.complete) {
            if (row.time_limit && row.screens > 1) {
              var user = Registry.get('student'),
                curriculumLanguage = user.getCurriculumLanguage(),
                urlSuffix = curriculumLanguage !== FTWGLOBALS('defaultLanguage') ? ('/' + curriculumLanguage) : '';

              if (row.time_limit >= 30) {
                assignment.nextLessonSlug = __url('/student/typing-test/' + row.time_limit / 60 + '-minute' + urlSuffix);
              } else {
                assignment.nextLessonSlug = __url('/student/typing-test/' + row.time_limit + '-page' + urlSuffix);
              }
            } else {
              assignment.nextLessonSlug = __url('/student/lesson/' + row.lesson_id + '/' + row.name.slug());
            }
          }
          return row
        })
      } else if(assignment.games) {
        map(assignment.games, row => {
          const game = find(FTWGLOBALS('games'), { game_id: row.game_id })
          const userGames = Registry.get('userGames') ? Registry.get('userGames').getCompiled(assignment.created_at) : []
          const userGame = userGames[row.game_id + '_' + row.difficulty + '_' + row.level]

          row.name = game.name
          if(userGame) {
            row.userGame = userGame;
            row.complete = userGame >= row.seconds
            assignment.in_progress = userGame < row.seconds
          }

          if(!assignment.nextLessonSlug && !row.complete) {
            assignment.nextLessonSlug = __url('/student/game/' + game.folder)
          }

          return row
        })
      } else { // Written Prompt
        assignment.nextLessonSlug = __url('/student/lesson/prompt/' + assignment.assignment_id);
        assignment.hasResponse = !!Registry.get('student').get('_writtenResponse' + assignment.assignment_id);
      }
    });

    return {
      moment: moment,
      assignments: assignments
    };
  },

  handleAssignmentStartClick: function(event) {
    event.preventDefault();

    // Get the event id
    const assignmentId = $(event.currentTarget).data('id');

    // Find the assignment
    const assignment = this.assignments.findWhere({ assignment_id: assignmentId });

    // Check if should store in localStorage
    const games = assignment?.get('games') || {};
    const nextGameToComplete = games && games.find(item => !item.complete);

    if (assignment && games && nextGameToComplete) {
      // Prepare data to store in localStorage
      const reloadData = {
        difficulty: nextGameToComplete.difficulty,
        lesson: nextGameToComplete.level,
        loadTo: 'game'
      };

      const reloadDataString = JSON.stringify(reloadData);

      // Store the JSON string in localStorage
      localStorage.setItem("reloadData", reloadDataString);
    }

    // Navigate to URL
    const href = $(event.currentTarget).attr('href');
    window.location.href = href;
  }
})
