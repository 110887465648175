import Backbone from 'backbone'

export default Backbone.Model.extend({

  idAttribute: 'game_id',

  defaults: {
    name: '',
    display_order: 0,
    folder: ''
  }
})
