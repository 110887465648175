import $ from 'jquery'
import Backbone from 'backbone'
import Registry from '@registry'
import 'jquery-ui/ui/widgets/draggable'
import 'jquery-ui/ui/widgets/droppable'
import 'imports-loader?jQuery=jquery,$=jquery,define=>false,module.exports=>false!jquery-ui-touch-punch'
import Velocity from 'velocity-animate'
import CelebrationEffect from '@shared/celebration_effect'
import AdView from '../../global/views/ad'
import { starAnimation } from '../common';
import { lesson_screen_click_drag, } from '@templates/student'

export default Backbone.View.extend({

  template: lesson_screen_click_drag,

  mode: 'lesson',

  events: {
    'click .js-continue': 'completeScreen',
    'click .js-canvas-overlay': 'clickOverlay'
  },

  // include some extra helpers
  starAnimation,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.dictation.setProperties(this.screen, null);

    // if there is no new_key, go straight to the block style screens.
    // this whole screen type is screaming for a refactor
    if(this.screen.get('settings')?.show_inline_intro && this.screen.get('settings')?.show_inline_intro !== "0") {
      this.mode = this.screen.get('settings')?.show_inline_intro;
    }

    const introArray = this.screen.get('intro').match(/(<div class="embed">[^]*?<\/div>)?([^]*)?/)

    this.videoHTML = (introArray[1] ? introArray[1].trim() : '');
    this.transcriptHTML = (introArray[2] ? introArray[2].trim() : '');

  },

  serialize: function() {
    const userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens'),
      screenSettings = this.screen.get('settings'),
      lessonSettings = this.lesson.get('settings')

    return {
      ads: AdView.canShowAds() && totalScreens > 0,
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens }),
      mode: this.mode,
      slug: screenSettings?.display,
      variant: screenSettings?.cd_variant,
      productGrade: this.user.getCurriculumGrade(),
      screenCount: this.screens.length,
      congratsText: lessonSettings?.congrats
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    if(this.mode === 'how_to' || this.mode === 'intro') {
      // animate in Avatar and frame bubble

      Velocity(this.$('.js-avatar'),
        {scaleX: ['1','0.2'], scaleY: ['1','0.5'], opacity: 1},
        {duration: 300, easing: [0.68, -0.55, 0.46, 1.59],}
      )
      Velocity(this.$('.js-bubble'),
        {scaleX: ['1','0'], scaleY: ['1','0.2'], opacity: 1},
        {delay: 100, duration: 500, easing: [0.68, -0.55, 0.46, 1.39]}
      )
    }

    window.setTimeout(() => {
        // Reposition effect so that it is over the entire screen
        const celebrationEle = this.$('.js-celebration-effect');
        celebrationEle.css({ 'top': -celebrationEle.offset().top, 'left': -celebrationEle.offset().left });

        // Instantiate confetti
        CelebrationEffect.init(celebrationEle[0], { assets: ["/dist/student/images/click_drag/explosion.svg"], screenHeight: $(document).height(), screenWidth: $(document).width(), gravity: 0.1, fade: 1.25 });

        // lesson content is templated in as part of lessonContent
        const settings = this.screen.get('settings')
        this.$('.js-lesson-content').append(
          this.lessonTemplate({
            ...this.lessonContent,
            slug: this.lessonContent.slug ?? settings?.display,
            // variant: this.lessonContent.variant ?? settings?.variant ?? settings?.cd_variant
          })
        )

        // after being appending, call the setup call
        // for a view (if any)
        this.build?.call(this)
    }, 0)

    window.setTimeout(() => {
      this.$('.js-cursor-animation').addClass('is-preview')
    }, 100)

    this.listenTo(this.input, 'character', this.handleKeydown);

    // disable right click on these screen types
    window.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    }, false);

    this.speakScreen();


    return this;
  },

  handleKeydown(character) {
    if (character.keyCode === 13) {
      this.$('.js-continue:visible').click()
    }
  },

  completeScreen: function() {
    if(this.mode === 'complete') {
      this.$('.js-complete:visible').addClass('btn--loading')
      this.trigger('continue')
    } else if(this.mode === 'lesson') {
      this.trigger('complete')
      this.mode = 'complete'
      Velocity(this.$('.js-canvas-overlay'), {opacity: 1}, {display: 'flex', duration: 1000})
      this.$('.js-droppable').attr('aria-hidden', true)
      window.setTimeout(() => {
        this.starAnimation()
      }, 500)
    } else if(this.mode === 'how_to') {
      this.mode = 'how_to_confirm';
      this.$('.js-how-to-confirm').fastShow()
    } else if(this.mode === 'how_to_confirm' || this.mode === 'intro') {
      this.mode = 'lesson';
      this.render()
    }

    return false;
  },

  speakScreen: function(options) {
    this.dictation.speakScreen({
      clickDrag: true,
      mode: this.mode,
      ...options
    });
  }
})
