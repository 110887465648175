import Backbone from 'backbone'
import { find, isObject } from 'lodash-es'
import { shared_keyboard_helper_identified } from '@templates/shared'

export default Backbone.View.extend({

  template: shared_keyboard_helper_identified,

  serialize: function() {
    return {
      lodash: { isObject },
      keyboard: find(FTWGLOBALS('keyboards'), { keyboard_id: this.selectedKeyboardId }),
      inClass: this.user && this.user.get('in_class')
    };
  }
})
