
// binds a key/value set as selectors for a backbone instance
export function bindSelectors(instance, map) {

  // check if this was just mapping to a backbone instance
  let bindToSelectorsProp = !map
  if (bindToSelectorsProp) {
    map = instance.selectors
  }

  // create all selector bindings
  const bindings = { }
  Object.keys(map).forEach(key => {
    const selector = map[key]
    bindings[key] = {
      get() {
        return instance.$el.find(selector)
      }
    }
  })

  // bind to the backbone object, if needed
  if (bindToSelectorsProp) {
    Object.defineProperties(instance.selectors, bindings)
  }

  return bindings
}
