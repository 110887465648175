import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import Screentip from '../../global/views/screentip'
import Alert from '../../global/views/alert'
import SyntaxHighlighter from '@shared/syntax_highlighter'
import Velocity from 'velocity-animate'
import { lesson_screen_complete_coding_browser } from '@templates/student'

export default Backbone.View.extend({

  display: 'render',

  template: lesson_screen_complete_coding_browser,

  events: {
    'click .js-toggle-display': 'toggleDisplay',
    'click .js-view-intro': 'viewIntro',
    'click .js-download-code': 'downloadCode',
    'mouseenter svg[data-tooltip-index]': 'decrementTooltipCount',
    'click .js-back': 'codeGoBack',
    'click .js-forward': 'codeGoForward'
  },

  viewingCurrentScreen: 0,
  totalComments: 0,
  codeComments: {},
  screentipConfigs: [],
  screentips: [],
  renderCode: '',
  syntaxColor: [],
  displayCode: [],

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.viewingCurrentScreen = this.screens.indexOf(this.screen);
  },

  serialize: function() {
    this.display = 'render';

    this.setUpCode();
    return {
      loggedIn: Registry.get('loggedIn'),
      disableBackButton: this.viewingCurrentScreen === 0,
      disableForwardButton: this.viewingCurrentScreen === this.lesson.get('screens') - 1 || this.viewingCurrentScreen >= this.userLesson.get('max_progress')-1,
      screen: this.screens.at(this.viewingCurrentScreen).toJSON(),
      displayCode: this.displayCode,
      syntaxColors: this.syntaxColors,
      codeComments: this.codeComments,
      totalComments: this.totalComments,
      showButtons: !this.hasGoogleClassroomAddon,
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    // After the screen has rendered, inject HTML into the iframe
    window.setTimeout(() => {
      var doc = document.getElementById('js-render-iframe');

      if(doc) {
        doc = doc.contentWindow.document;
        doc.open();
        doc.write(this.renderCode);
        doc.close();
      }

      this.browserTooltipTimeout = window.setTimeout(() => { // Wait before showing the browser tooltips
        // After HTML renders, display any browser tooltips
        this.screentipConfigs.forEach((row, index) => {
          var iframeEle = $('#js-render-iframe'),
            ele = $(doc).find(row.selector);
          if (ele.length) {
            var tip = new Screentip({
              target: ele,
              id: index,
              text: row.tooltip,
              closeTrigger: row.closeTrigger,
              offset: {
                left: iframeEle.offset().left + ele.offset().left + (ele.outerWidth() / 2),
                top: iframeEle.offset().top + ele.offset().top
              }
            });
            this.screentips.push(tip);
            $('body').append(tip.render().el);
          }
        });
      }, 500);
    }, 0);

    return this;
  },

  setUpCode: function() {
    var screen = this.screens.at(this.viewingCurrentScreen);

    this.tooltipsSeen = [];
    this.seenCode = false;
    this.codeComments = {};
    this.screentipConfigs = [];
    this.screentips = [];
    this.totalComments = 0;

    this.renderCode = screen.get('original_content') // using original content, which is the whole mess of code
      .replace(/^~/gm, '')  // multi-line mode: replace starting tildes
      .replace(/⏎/g, '')  // replace hardcoded enter keys
      .replace(/^!/gm, ''); // multi-line mode: replace starting exclamations

    var originalContent = screen.get('original_content').replace(/^!.*\n*/gm, '');

    // Identify browser tooltips first because coding comments (below) use index locations within the content string
    var browserTooltip;
    while(1) {
      browserTooltip = originalContent.match(/^~*\s*(\/\*\*([^*].+)\*\*\/|<!--([^-].*)-->).*\n/m);
      if(browserTooltip) {
        try {
          this.screentipConfigs.push(JSON.parse(browserTooltip[2] || browserTooltip[3]));
        } catch(e) {}
        originalContent = originalContent.replace(/^~*\s*(\/\*\*([^*].+)\*\*\/|<!--([^-].*)-->).*\n/m, '');
        continue;
      }
      break;
    }

    var comment;
    while(1) {
      comment = originalContent.match(/^~*\s*(\/\*\*\*(.+)\*\*\*\/|<!---(.*)--->).*\n/m);
      if(comment) {
        this.totalComments++;
        originalContent = originalContent.replace(/^~*\s*(\/\*\*\*.+\*\*\*\/|<!---.*--->).*\n/m, '');
        var eol = originalContent.substr(comment.index).match(/\n/);
        if(eol) {
          this.codeComments[comment.index + eol.index - 1] = comment[2] || comment[3];
        }
        continue;
      }
      break;
    }

    this.displayCode = screen.getContentByWord(originalContent);
    this.syntaxColors = SyntaxHighlighter.getColorArray(screen.get('content'), screen.get('content_setting'));
  },

  seenCode: false,
  toggleDisplay: function(e) {
    if(this.display !== this.$(e.currentTarget).data('id')) {
      this.$('.js-toggle-display').removeClass('is-active');
      if(this.display === 'render') {
        this.hideAllScreentips();
        this.display = 'code';
        this.$('.js-toggle-display[data-id=code]').addClass('is-active');
        this.$('.js-render').fastHide();
        this.$('.js-src').fastShow();
        if(!this.seenCode) {  // scroll to the first typed code, but only once.
          Velocity(this.$('.js-first-typed-code'), 'scroll', {container: this.$('.js-src')});
          this.seenCode = true;
        }
      } else {
        this.display = 'render';
        this.$('.js-toggle-display[data-id=render]').addClass('is-active');
        this.$('.js-render').fastShow();
        this.$('.js-src').fastHide();
      }
    }
  },

  downloadCode: function() {
    window.location.href = '/apiv1/student/lessons/download-code/' + this.screens.at(this.viewingCurrentScreen).get('lesson_screen_id') + '?token=' + Registry.get('token');
  },

  viewIntro: function() {
    new Alert({
      text: this.screens.at(this.viewingCurrentScreen).get('intro'),
      title: this.screens.at(this.viewingCurrentScreen).get('title') + ' ' + 'shared.intro_text'.t(),
      width: 'm'
    }).show();
  },

  tooltipsSeen: [],
  decrementTooltipCount: function(e) {
    var tipId = parseInt($(e.currentTarget).data('tooltip-index'));
    if(this.tooltipsSeen.indexOf(tipId) === -1) {
      this.tooltipsSeen.push(tipId);

      var tip = this.$('.js-tip-count'),
        tipsRemaining = parseInt(tip.text().trim());
      if(tipsRemaining > 1) {
        tip.text(--tipsRemaining);
      } else {
        tip.remove();
      }

    }
  },

  codeGoBack: function(e) {
    if($(e.currentTarget).is('.is-disabled')) {
      return false;
    }
    window.clearTimeout(this.browserTooltipTimeout);
    this.hideAllScreentips();
    this.viewingCurrentScreen--;
    this.render();

    return false;
  },

  codeGoForward: function(e) {
    // can not view future screens if you're at the end of a lesson, or at the end of the screens you've typed
    if($(e.currentTarget).is('.is-disabled')) {
      return false;
    }
    window.clearTimeout(this.browserTooltipTimeout);
    this.hideAllScreentips();
    this.viewingCurrentScreen++;
    this.render();

    return false;
  },

  hideAllScreentips() {
    this.screentips.forEach(function(row) {
      row.remove();
    });
  }

})
