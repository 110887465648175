import Backbone from 'backbone'

export default Backbone.Model.extend({

  urlRoot: '/apiv1/student/user/survey-response',

  defaults: {
    response: 'individual'
  }
})
