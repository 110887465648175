import $ from 'jquery'
import ScreenCompleteView from './screen_complete'
import Velocity from 'velocity-animate'
import { extend, isEmpty, map } from 'lodash-es';
import { lesson_screen_complete_email } from '@templates/student'

export default ScreenCompleteView.extend({

  template: lesson_screen_complete_email,

  state: 'showingStats',

  serialize: function() {
    const paths = this.screen.get('paths') || []

    paths.forEach((row) => {
      const screen = this.screens.get(row.to_id)
      row.slug = screen ? screen.get('title') : ''
    })
    return extend(ScreenCompleteView.prototype.serialize.apply(this, arguments), {
      paths: paths,
      isPortraitScreen: this.screen.get('display_order') === 0
    })
  },

  showNewMessage: function() {
    this.$('.js-screen-complete-content').fastHide();

    if(this.screen.get('display_order') === 0) {
      // the screen complete should present figure options
      this.$('.js-email-response').fastHide();
      this.$('.js-email-content-1, .js-email-content-2').fastShow();
      this.speakEmailComplete()
    } else {
      // the screen complete should just be choices for next response
      this.$('.js-email-content-1').fastShow();

      window.setTimeout(() => { // DOM element doesn't exist, so need a 5ms delay
        this.$('.js-message-illo').addClass('is-active')
      }, 5)

      window.setTimeout(() => {
        this.speakEmailComplete()
        this.$('.js-message-illo').css("transform", "scale(1) translateX(-50%)")
        Velocity(
          this.$('.js-message-illo'),
          { translateX: 0, left: 0,},
          {
            delay: 250,
            duration: 500,
            begin: () => {
              this.$('.js-message-jewel').remove()
              this.$('.has-screenEmail-illo, .screenEmail-illo-wrapper').css("width", "150px")
            },
            complete: () => {
              this.$('.screenEmail-illoMail').addClass('is-active');
              this.$('.js-email-content-2').slideDown();
            }
          }
        )
      }, 2000)
    }

    this.state = 'showingEmail'
  },

  continue: function(e) {
    const id = $(e.currentTarget).data('id'),
      screen = this.screens.get(id),
      progress = this.screens.indexOf(screen),
      lessonComplete = isEmpty(this.screen.get('paths'))

    if(this.state === 'showingStats' && !lessonComplete) {
      if(this.goalView) {
        this.goalView.closeTooltip()
      }
      this.showNewMessage()
    } else {
      if(progress !== -1) { // -1 means there is no more progress
        this.userLesson.set({ progress: progress })
      }

      this.saved = true;

      this.trigger('continue', 'tyoa_pick', $(e.currentTarget));
      return false;
    }
  },

  handleKeyPress: function(character) {
    const options = map(this.screen.get('paths'), 'title')

    if(character.keyCode === 13) {
      // Choosing an option
      if(this.state === 'showingStats' || (this.state === 'showingEmail' && options.length === 1)) {
        this.$('.js-continue-button:visible').click();
      } else if(this.selectedOptionIndex !== undefined) {
        this.$(`.js-continue-button[data-index=${this.selectedOptionIndex}]`).click();
      }
    } else if(character.key <= options.length && this.state === 'showingEmail') {
      if(parseInt(character.key) === 0) {
        // Repeat the intro dictation for this screen
        this.speakEmailComplete();
      } else {
        // Read individual option to user
        const option = this.screen.get('paths')[character.key - 1]
        if(option) {
          this.dictation.speakOption({ text: option.title + '. ' });
          this.selectedOptionIndex = character.key - 1
        }
      }
    }

    return false;
  },

  speakScreenComplete(data) {
    this.dictation.speakScreenComplete(data)
  },

  speakEmailComplete() {
    this.dictation.speakEmailPaths({
      pickFigure: this.screen.get('display_order') === 0, // Will say something slightly different when picking a historical figure
      options: map(this.screen.get('paths'), 'title')
    })
  }
})

