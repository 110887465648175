import { createPathGenerator } from './utils';

export const BASE_SIZE = 1000
export const BASIC_GRID = 0.2
export const ADVANCED_GRID = 0.1

export const RENDER_SCENE_VERTICAL = 2
export const RENDER_SCENE_HORIZONTAL = 5.5
export const RENDER_SCENE_GRAVITY = 3.2

// default palette colors
export const COLORS = [
  '#E84C3E',
  '#E77F23',
  '#F1C410',
  '#2DCC70',
  '#3598DC',
  '#9B58B5',
  '#E84CB3',
  '#875100',
  '#ffffff',
  '#E6E9EB',
  '#95A5A5',
  '#34495E'
]

// default shape options
export const SHAPES = [
  'square',
  'circle',
  'triangle',
  'rounded-square',
  'right-triangle',
  'hexagon',
  'pentagon',
  'arrow',
  'star',
  'heart',
  'donut',
  'wheel'
]

export const CIRCLES = [
  'circle',
  'wheel',
  'donut',
  'hexagon',
  'cookie'
]

// create path generators for special shapes
// so we can create better bounding boxes
export const SPECIAL_SHAPES = {
  'right-triangle': createPathGenerator([0,0], [1, 1], [0, 1]),
  'triangle': createPathGenerator([0.5, 0], [1, 1], [0, 1]),
  'heart': createPathGenerator([0.5, 0], [0.8, 0], [1, 0.33], [0.5, 1], [0, 0.33], [0.2, 0]),
  'arrow': createPathGenerator([0.33, 0.25], [0.5, 0], [0.66, 0.25], [0.66, 1], [0.33, 1]),
  'star': createPathGenerator([0.5, 0], [1, 0.4], [0.66, 1], [0.33, 1], [0, 0.4]),
  'pentagon': createPathGenerator([0.5, 0], [1, 0.4], [0.66, 1], [0.33, 1], [0, 0.4]),
  'dog': createPathGenerator([0, 0], [0.66, 0], [0.66, 0.5], [1, 0.5], [1, 1], [0, 1])
}
