import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import Analytics from '@shared/analytics'
import radarTracker from '@shared/radar_tracker'
import LessonModel from '../global/models/lesson'
import LayoutView from './views/layout'
import {
  getPracticeKeys,
  getProblemKeysLessonIntro,
  getProblemKeysLessonName,
  PROBLEM_KEYS_LESSON_ID,
} from '../utils/problem_keys_lesson';
import { generateWords, getProblemKeysScreenContentAndIntro, getProblemKeysScreenPracticeKeys } from '../utils/problem_keys_screen';
import ScreensCollection from '../global/collections/lesson_screens'
import ProblemKeyScreensCollection from './collections/problemkey_screens';
import IntroView from './views/intro'
import IntroProblemKeysView from './views/intro_problem_keys'
import IntroTestView from './views/intro_test'
import CongratsView from './views/congrats'
import DemoCompleteView from './views/demo_complete'
import ScreenCompleteView from './views/screen_complete'
import ScreenCompleteAdventureView from './views/screen_complete_adventure'
import ScreenCompleteEmailView from './views/screen_complete_email'
import ScreenCompleteResumeView from './views/screen_complete_resume'
import ScreenCompleteCodingView from './views/screen_complete_coding'
import ScreenFailedView from './views/screen_failed'
import TestCompleteView from './views/test_complete'
import ProgressView from './views/progress'
import TypingCodingView from './views/typing_coding'
import TypingBlockView from './views/typing_block'
import TypingStandardView from './views/typing_standard'
import TypingFallingView from './views/typing_falling'
import TypingVocabView from './views/typing_vocab'
import TypingWrittenPrompt from './views/typing_written_prompt'
import TypingTestView from './views/typing_test'
import InstructionView from './views/instruction'
import TypingInlineGameView from './views/typing_inline_game'
import TypingSingleKeyView from './views/typing_single_key'
import TypingFillInBlankView from './views/typing_fill_in_blank'
import TypingCodeReviewView from './views/typing_code_review'
import KeyboardInput from '@shared/keyboard_input'
import TimerModel from '../global/models/timer'
import KeyboardsCollection from '../global/collections/keyboards'
import UserLessonScreens from '../global/collections/user_lesson_screens'
import LettersTyped from '../global/collections/letters_typed'
import AchievementsCollection from '../global/collections/achievements'
import AdView from '../global/views/ad'
import AdButtonsView from '../global/views/ad_buttons'
import SidebarNavView from '../global/views/sidebar_nav'
import Scoring from '@shared/scoring'
import Dictation from './classes/dictation'
import { find, clone, shuffle, each, mapValues, sortBy, slice, reduce, filter, unionBy, map } from 'lodash-es'
import Bugsnag from '@bugsnag/js';

const WORDS_PER_PAGE = 240;

  /**
   * This is the main overall controller for the typing lessons
   */
export default Backbone.View.extend({
  initialize: function(options) {
    Backbone.View.prototype.initialize.apply(this, arguments);
    this.options = options;

    // Different types of typing screens
    this.screenTypes = {
      coding: TypingCodingView,
      block: TypingBlockView,
      standard: TypingStandardView,
      falling: TypingFallingView,
      vocab: TypingVocabView,
      test: TypingTestView,
      instruction: InstructionView,
      inline_game: TypingInlineGameView,
      written_prompt: TypingWrittenPrompt,
      single_key: TypingSingleKeyView,
      fill_in_blank: TypingFillInBlankView,
      code_review: TypingCodeReviewView
    };

    this.user = Registry.get('student');
    this.userAchievements = Registry.get('userAchievements');
    this.userLettersTyped = Registry.get('userLettersTyped');
    this.userActivity = Registry.get('userActivity');
    this.userTests = Registry.get('userTests');
    this.units = Registry.get('units');
    this.units.set(FTWGLOBALS('units'));

    this.input = new KeyboardInput();   // Handles and normalizes browser input
    this.timer = new TimerModel();      // Timer can fire events by second and track timing
    this.dictation = new Dictation(null, null, this.user); // Handles dictation for all screens

    this.removeHash = false // Used by Proc2024 when going to a specific screen

    if(this.problemkeys) {
      this.lesson = new LessonModel({
        lesson_id: PROBLEM_KEYS_LESSON_ID,
        name: getProblemKeysLessonName(this.user),
        keyboard: 'qwerty',
        time_limit: 60
      });
    } else {
      var lesson = find(FTWGLOBALS('lessons'), {lesson_id: this.lessonId});
      if(!lesson) {
        lesson = FTWGLOBALS('custom_lesson');
      }
      this.lesson = new LessonModel(lesson);
    }

    this.unit = this.units.get(this.lesson.get('unit_id'));

    if(this.unit && this.unit.get('type') === 'test') {
      this.options.test = true;
    }
    if(this.options.problemkeys) {
      this.options.test = true;
    }
    if(this.lesson.getTestType() === 'timed') {
      this.options.test = true;
      this.options.minutes = this.lesson.get('time_limit') / 60;
    } else if(this.lesson.getTestType() === 'page') {
      this.options.test = true;
      this.options.pages = this.lesson.get('time_limit');
    }

    // Kick the user back to the assessments page if this is an EduTec assessment and the user has already taken the test
    if(this.user.isAssessment() && this.user.hasTakenTest(this.lesson.getTestType(), this.lesson.get('time_limit'))) {
      window.location.href = __url('/student/certificacion')
      return false
    }

    var keyboardId = (this.lesson.get('keyboard') === '10key') ? find(FTWGLOBALS('keyboards'), {type: 'keypad'}).keyboard_id : (parseInt(this.user.getSetting('keyboard_id')));
    if(!find(FTWGLOBALS('keyboards'), {keyboard_id: parseInt(keyboardId)})) {  // filter out invalid keyboards which maybe went inactive
      keyboardId = 1;
    }
    // Always create a keyboard because dictation needs to know finger placement
    this.keyboard = (new KeyboardsCollection(FTWGLOBALS('keyboards'))).get(keyboardId);

    if(this.options.problemkeys) {
      const words = (FTWGLOBALS('words')[this.user.getCurriculumLanguage()]) || FTWGLOBALS('words'),
        practiceKeys = getPracticeKeys(this.userLettersTyped);

      this.screens = new ProblemKeyScreensCollection([{
        lesson_id: this.lesson.id,
        display_order: 1,
        screen_type: 'standard',
        title: 'lesson.problem_keys.title'.t(),
        intro: getProblemKeysLessonIntro(this.user, practiceKeys),
      }], {
        words: words,
        letters: practiceKeys,
        lettersTyped: this.userLettersTyped.typedLetters(),
        user: this.user,
      });

    } else if(this.unit && this.unit.get('type') === 'test') {
      // typing.com tests are special
      this.lesson.set({screens: 1});  // test are one giant screen
      var firstScreen = FTWGLOBALS('lesson_screens')[0]; // we will base things on the first screen's title etc
      // EduTec Assessments only have 2 screens to choose from for each test. The first test is used for the first time through the assessment, the second is used for the second time through
      var mixedScreens = this.user.isAssessment() ? sortBy(FTWGLOBALS('lesson_screens').filter(row => row.display_order >= 100), (row) => row.display_order) : shuffle(FTWGLOBALS('lesson_screens')) // EduTec assessments keep the same content order
      var contentLength = 1500 * this.options.minutes; // 1500 characters because 300 wpm is 1500 chars in 1 minute
      var startIndex = localStorage.getItem(`${this.user.get('user_id')}_assessment_retried`) ? 1 : 0 // EduTec Assessments will start on either the first or second screen

      firstScreen.lesson_screen_id = mixedScreens[startIndex].lesson_screen_id;
      firstScreen.title = '';
      if(this.lesson.getTestType() === 'page') {
        contentLength = this.options.pages * (WORDS_PER_PAGE * 5); // Avgerage words have 5 characters
      }

      if(this.user.isAssessment()) {
        // EduTec assessments only use one screen because the content is long enough
        firstScreen.content = mixedScreens[startIndex].content.replace(/\n+/g, '\n').trim()
      } else {
        // make sure the content ends at the end of a sentence
        firstScreen.content = mixedScreens  // set the content to a random mix of all content
          .map(function(row){ return row.content.replace(/\n+/g, '\n').trim(); })
          .join('\n')
        firstScreen.content = firstScreen.content.substr(0, firstScreen.content.indexOf('.', contentLength))
      }

      this.screens = new ScreensCollection([firstScreen]);    // and set that as the screen
    } else {
      this.screens = new ScreensCollection(FTWGLOBALS('lesson_screens'));
    }

    this.lettersTyped = new LettersTyped(); // Tracks everything typed for problem key data

    // Get localStorage stored status
    var userLessons = Registry.get('userLessons');
    this.userLesson = userLessons.get(this.lesson.id);
    if (!this.userLesson) {
      // If they've never done this lesson, create a new record for it
      this.userLesson = userLessons.add({lesson_id: this.lesson.id});
    }
    // teacher option is set to not repeat after complete
    // if(this.user.hasOption('locklessons') && !this.options.test && this.userLesson.get('progress') >= this.lesson.get('screens')) {
    // window.location.href = __url('/student/lessons');
    // return;
    // }

    if(this.options.problemkeys || this.lesson.get('time_limit') > 0) {
      this.userLesson.set({progress: 0});
    }
    let progress = this.userLesson.get('progress')
    if(this.lesson.get('content_type') !== 'adventure') {
      progress = Math.max(Math.min(progress, this.lesson.get('screens') || 0), 0)

      if(this.screenIndex) {
        // Use the URL hash to specify which screen to show (when applicable)
        progress = Math.min(Math.max(this.screenIndex - 1, 0), this.lesson.get('screens') - 1)

        this.userLesson.set({progress: progress, restart: false, _state: 'intro'})
        this.removeHash = true
      }
    }
    if(this.userLesson.get('_state') === 'complete' && this.lesson.get('content_type') !== 'adventure' && !this.userLesson.get('_screenStats')) {
      // they are refreshing the screen.  they've progressed past but are view Screen Complete again
      progress--;
    }
    // Lessons with one screen ALWAYS start over because there is no screen_complete (except custom lessons)
    if(this.lesson.get('screens') === 1 && this.unit?.get('type') !== 'custom') {
      progress = 0;
    }
    if(this.userLesson.get('restart')) {
      progress = 0
      // TYOA stories with an ending need their max_progress reset in order to know overall progress
      if(this.lesson.get('settings')?.display === 'story_with_ending') {
        this.userLesson.set({ max_progress: 0 })
      }
    }

    this.userLessonScreens = new UserLessonScreens(Registry.get('userLessonScreens').where({lesson_id: this.lesson.id}));

    // Special tracking on this page with more info
    if(Registry.get('loggedIn') && window.location.pathname.match(/lesson\/[0-9]+/) && this.user.get('in_class') && this.user.isPremium() && this.user.hasOption('realtime')) {
      radarTracker.track(Registry.get('student'), {
        seconds: this.userLesson.get('seconds'),
        errors: this.userLesson.get('errors'),
        typed: this.userLesson.get('typed'),
        progress: progress
      });
    }
    if(this.user.get('demo') && progress > 2) {
      this.views.demoComplete = new DemoCompleteView({
        lesson: this.lesson,
        lettersTyped: this.lettersTyped,
        userLesson: this.userLesson,
        userLessonScreens: this.userLessonScreens
      });
    } else {
      // Get the current screen to type
      this.screen = this.screens.at(progress);

      // It can only be an inline game if the users browser is compatible
      // This must happen BEFORE formatting the content or else the content will not be formatted correctly for inline games converted to standard screens
      if(this.screen && this.screen.get('screen_type') === 'keyboard-jump' && !this.browserCompatibleWithInline()) {
        this.screen.set({ screen_type:  'standard' });
      }

      this.screens.forEach((screen) => {
        if(screen.get('settings')?.problem_keys) {
          const words = generateWords(this.userLettersTyped, this.user);
          const { content, intro } = getProblemKeysScreenContentAndIntro(this.user, this.userLettersTyped, words, screen);

          screen.set({
            content,
            intro: intro || screen.get('intro'),
          });
        }

        screen.formatContent(this.user);
      });
    }

    this.showSidebarNav = !Registry.get('loggedIn') && this.userActivity.getOrAdd(0).get('screens') === 0 && this.screen && this.screen.get('screen_type') !== 'coding'  && this.unit && this.unit.get('type') !== 'test' && this.unit.get('type') !== 'practice' && this.lesson.get('content_type') !== 'adventure';

    var progressView;
    if(!this.options.test && this.lesson.get('content_type') !== 'instruction' && this.lesson.get('content_type') !== 'adventure') {    // test doesn't have the bottom thing
      // Progress bar at the bottom
      progressView = new ProgressView({
        lesson: this.lesson,
        screens: this.screens,
        screen: this.screen,
        userLesson: this.userLesson,
        userLessonScreens: this.userLessonScreens,
        progress: progress
      });
    }

    // Normal lessons will not have a screen when the lesson is complete
    // TYOA lessons will always have a screen, therefore we need to check if the lesson is complete to know if a congrats screen should be shown
    if (!this.screen || this.userLesson.get('_state') === 'congrats') {
      // After lesson congrats screen
      this.views.congrats = new CongratsView({
        user: this.user,
        lesson: this.lesson,
        lettersTyped: this.lettersTyped,
        dictation: this.dictation,
        userLesson: this.userLesson,
        userLessonScreens: this.userLessonScreens,
        progressView: progressView,
        hasGoogleClassroomAddon: !!window.localStorage.getItem('googleClassroomAddonData'),
      })
    } else {
      var screenType = (this.options.test) ? 'test' : this.screen.get('screen_type');
      screenType = (screenType === 'speed') ? 'standard' : screenType
      screenType = (screenType === 'keyboard-jump') ? 'inline_game' : screenType
      screenType = (screenType === 'written-prompt') ? 'written_prompt' : screenType
      screenType = (screenType === 'single-key') ? 'single_key' : screenType
      screenType = (screenType === 'fill-in-blank') ? 'fill_in_blank' : screenType
      screenType = (screenType === 'code-review') ? 'code_review' : screenType

      if(screenType === 'falling' || screenType === 'block') {
        this.screen.set({dictation_type: 'letters'});
      }
      if(this.lesson.get('content_type') === 'adventure' || this.lesson.get('lesson_id') === PROBLEM_KEYS_LESSON_ID) {
        this.screen.set({dictation_type: 'words'})
      }
      if(screenType === 'test' && this.options.minutes) {
        this.lesson.set({time_limit: this.options.minutes * 60});
      }

      // Use the user's grade to set the minimum accuracy threshold
      this.screen.set({ min_acc: this.user.getCurriculumGrade() <= 2 ? 60 : 80 });

      const grading = this.user.getSetting('grading');
      if(grading) {
        var gradingValues = {};
        Object.keys(grading).forEach(function(key){ if(grading[key] !== '') { gradingValues[key] = grading[key]; }}); // clear our empty values
        this.screen.set(gradingValues);
      }

      var testType = this.lesson.getTestType();
      const showProblemKeysIntro = this.options.problemkeys && this.user.hasOption('problemkeysai');

      // Some screens have intros
      if (this.screen.get('intro') ||
        (this.userLesson.get('_state') !== 'typing' &&
          (testType === 'page' || // Intro always shows when taking a page-length test
            showProblemKeysIntro || // Intro always shows when taking a Problem Keys lesson
            (testType && this.user.isAssessment()) || // Intro always shows for EduTec Assessments
            (testType === 'timed' && !Registry.get('loggedIn') && this.userTests.length === 0)))) { // Intro only shows for first time taking a timed test
        if (!this.options.problemkeys && this.unit.get('type') === 'test' && testType) {
          var compiled = this.userActivity.getCompiled(0),
            avgSpeed = compiled.typed ? Scoring.speed(compiled.typed, compiled.seconds, compiled.errors) : 30,
            minutes = Math.round((this.screen.get('content').length / 5) / avgSpeed);

          this.views.intro = new IntroTestView({
            user: this.user,
            userLesson: this.userLesson,
            input: this.input,
            screen: this.screen,
            lesson: this.lesson,
            dictation: this.dictation,
            minutesToComplete: minutes,
            userHasTests: !!compiled.typed,
            testType: testType,
            testLength: (testType === 'timed') ? this.minutes : this.pages
          });
          this.userLesson.set({ _state: 'intro' });
        } else if (showProblemKeysIntro) {
          const practiceKeys = getPracticeKeys(this.userLettersTyped)

          this.views.intro = new IntroProblemKeysView({
            data: { practiceKeys },
            user: this.user,
            test: this.options.test && !this.options.problemkeys,
            keyboard: this.keyboard,
            screen: this.screen,
            lesson: this.lesson,
            userLesson: this.userLesson,
            dictation: this.dictation,
            progressView: progressView,
            showSidebarNav: this.showSidebarNav,
            isPremium: this.user.isPremium(),
            practiceKeys: practiceKeys
          });
          this.userLesson.set({ _state: 'intro' });
        } else {
          const practiceKeys = getProblemKeysScreenPracticeKeys(this.userLettersTyped)
          this.views.intro = new IntroView({
            data: { practiceKeys },
            user: this.user,
            test: this.options.test && !this.options.problemkeys,
            keyboard: this.keyboard,
            screen: this.screen,
            lesson: this.lesson,
            userLesson: this.userLesson,
            input: this.input,
            dictation: this.dictation,
            progressView: progressView,
            showSidebarNav: this.showSidebarNav,
            practiceKeys: practiceKeys,
            hasGoogleClassroomAddon: !!window.localStorage.getItem('googleClassroomAddonData'),
          });
        }
        this.views.intro.once('continue', this.continue.bind(this));
      } else if(!this.userLesson.get('_state') || this.userLesson.get('_state') === 'intro') {
        this.userLesson.set({ _state: 'typing' }); // Setting the state to 'typing' ensures our state accurately reflects the current state of this lesson
      }
      // The actual typing screen.
      this.views.typing = new this.screenTypes[screenType]({
        user: this.user,
        keyboard: this.keyboard,
        lesson: this.lesson,
        screens: this.screens,
        screen: this.screen,
        input: this.input,
        dictation: this.dictation,
        timer: this.timer,
        unit: this.unit,
        screenType: screenType,
        userLesson: this.userLesson,
        userLessonScreens: this.userLessonScreens,
        lettersTyped: this.lettersTyped,
        problemKeysLesson: this.options.problemkeys || Boolean(this.screen.get('settings')?.problem_keys),
        hasGoogleClassroomAddon: !!window.localStorage.getItem('googleClassroomAddonData'),
        wordsPerPage: WORDS_PER_PAGE
      });
      this.views.typing.once('complete', this.handleScreenComplete.bind(this));   // when they're done, move past this screen

      if (this.options.problemkeys || this.lesson.get('time_limit') > 0) {
        // tests have their own completion screen
        this.views.screenComplete = new TestCompleteView({
          user: this.user,
          lesson: this.lesson,
          screens: this.screens,
          screen: this.screen,
          input: this.input,
          dictation: this.dictation,
          timer: this.timer,
          unit: this.unit,
          typingView: this.views.typing,
          userTests: this.userTests,
          userLesson: this.userLesson,
          userLessonScreens: this.userLessonScreens,
          lettersTyped: this.lettersTyped,
          problemKeysLesson: this.options.problemkeys,
          practiceKeys: this.screen.get('settings')?.problem_keys ? getProblemKeysScreenPracticeKeys(this.userLettersTyped) : getPracticeKeys(this.userLettersTyped),
          screenType: screenType,
          progressView: progressView,
          showSidebarNav: this.showSidebarNav,
          hasGoogleClassroomAddon: !!window.localStorage.getItem('googleClassroomAddonData'),
        });
      } else if (this.lesson.get('content_type') === 'adventure') {
        let lessonSettings = this.lesson.get('settings'),
          screenCompleteView = ScreenCompleteAdventureView

        if(lessonSettings?.display === 'email') {
          screenCompleteView = ScreenCompleteEmailView
        } else if(lessonSettings?.display === 'resume') {
          screenCompleteView = ScreenCompleteResumeView
        }

        this.views.screenComplete = new screenCompleteView({
          user: this.user,
          lesson: this.lesson,
          screens: this.screens,
          screen: this.screen,
          input: this.input,
          dictation: this.dictation,
          timer: this.timer,
          typingView: this.views.typing,
          userLesson: this.userLesson,
          userLessonScreens: this.userLessonScreens,
          lettersTyped: this.lettersTyped,
          screenType: screenType,
          progressView: progressView,
          hasGoogleClassroomAddon: !!window.localStorage.getItem('googleClassroomAddonData'),
        });
      } else if(screenType === 'coding' && this.unit?.get('type') !== 'custom') { // Do not show the code complete screen for coding screens in a custom lesson
        this.views.screenComplete = new ScreenCompleteCodingView({
          user: this.user,
          lesson: this.lesson,
          screens: this.screens,
          screen: this.screen,
          input: this.input,
          dictation: this.dictation,
          timer: this.timer,
          typingView: this.views.typing,
          userLesson: this.userLesson,
          userLessonScreens: this.userLessonScreens,
          lettersTyped: this.lettersTyped,
          screenType: screenType,
          progressView: progressView,
          hasGoogleClassroomAddon: !!window.localStorage.getItem('googleClassroomAddonData'),
        });
      } else {
        // The in-between progress screen
        this.views.screenComplete = new ScreenCompleteView({
          user: this.user,
          lesson: this.lesson,
          screens: this.screens,
          screen: this.screen,
          input: this.input,
          dictation: this.dictation,
          timer: this.timer,
          typingView: this.views.typing,
          userLesson: this.userLesson,
          userLessonScreens: this.userLessonScreens,
          lettersTyped: this.lettersTyped,
          screenType: screenType,
          progressView: progressView,
          showSidebarNav: this.showSidebarNav,
          hasGoogleClassroomAddon: !!window.localStorage.getItem('googleClassroomAddonData'),
        });
      }
      this.views.screenComplete.once('continue', this.continue.bind(this));

      // The in-between progress screen
      this.views.screenFailed = new ScreenFailedView({
        user: this.user,
        lesson: this.lesson,
        screens: this.screens,
        screen: this.screen,
        input: this.input,
        dictation: this.dictation,
        timer: this.timer,
        typingView: this.views.typing,
        userLesson: this.userLesson,
        userLessonScreens: this.userLessonScreens,
        lettersTyped: this.lettersTyped,
        screenType: screenType,
        progressView: progressView,
        showSidebarNav: this.showSidebarNav
      });
      this.views.screenFailed.once('continue', this.continue.bind(this));
    }

    if(this.showSidebarNav) {
      this.sidebarNavView = new SidebarNavView({
        lesson: this.lesson
      });
    }

    // layout is specific to typing page
    this.layout = (new LayoutView({
      user: this.user,
      timer: this.timer,
      input: this.input,
      dictation: this.dictation,
      child: this,
      unit: this.unit,
      lesson: this.lesson,
      screen: this.screen,
      userLesson: this.userLesson,
      testType: this.lesson.getTestType(),
      problemkeys: this.options.problemkeys,
      hasGoogleClassroomAddon: !!window.localStorage.getItem('googleClassroomAddonData'),
      progressComplete: this.userLesson.get('_state') === 'complete'
    }));
    this.listenTo(this.layout, 'restart', this.restartScreen);

    if(this.views.demoComplete) {
      this.addChild(this.el, this.views.demoComplete, true, false);
    } else if (this.views.congrats) { // if they're done, show congrats instead of last screen
      this.addChild(this.el, this.views.congrats, true, false);
    } else {
      if (this.views.intro) {
        this.addChild(this.el, this.views.intro, true, false);
        this.addChild(this.el, this.views.typing, true, false);
      } else {
        this.addChild(this.el, this.views.typing, true, false);
      }
    }
    if(this.views.screenComplete) {
      this.addChild(this.el, this.views.screenComplete, true, false);
    }
    if(this.views.screenFailed) {
      this.addChild(this.el, this.views.screenFailed, true, false);
    }

    if(progress === 0) {  // reset the keyboard type at the beginning of any lesson
      this.user.unset('_mobileKeyboard');
    }

    this.render();
  },

  renderChildren: function(parent) {

    var state = this.userLesson.get('_state'),
      ads = {};

    Backbone.View.prototype.renderChildren.apply(this, arguments);

    // for TYOA if they are in the story, logged out and came back, this puts them on the complete screen so they don't have to type the whole mess over again
    // but if they came back in by pressing restart, then they have userlesson.restart set
    if((this.lesson.get('content_type') === 'adventure' && !this.userLesson.get('restart')) && this.userLesson.get('progress') > 0 && !state && this.screen && this.userLessonScreens.get(this.screen.id)) {
      state = 'complete';
    }

    var adOptions = {
      totalScreens: this.userActivity.getOrAdd(0).get('screens'),
      forceAd: this.unit && this.unit.get('type') === 'test' // Test pages will ALWAYS show ads
    };
    var inputElement = 'body';
    if(state === 'complete') {
      // this.screen.set(this.userLesson.get('_screenStats'));
      // this.lettersTyped.reset(this.userLesson.get('_lettersTyped'));
      if(this.views.screenComplete) {
        this.updateLocalStats();
        this.views.screenComplete.render();
      } else if (this.views.congrats) {
        this.views.congrats.render();
      }
      AdView.initAds('lesson_screen');
      ads = AdView.getAds(adOptions);
      this.saveStats();

    } else  if(state === 'failed') {
      // this.screen.set(this.userLesson.get('_screenStats'));
      // this.lettersTyped.reset(this.userLesson.get('_lettersTyped'));
      AdView.initAds('lesson_screen');
      ads = AdView.getAds(adOptions);
      this.views.screenFailed.render();

    } else if(state === 'congrats') {
      AdView.initAds('lesson_screen');
      ads = AdView.getAds(adOptions);
      this.views.congrats.render();

    } else if(state === 'typing') {
      this.userLesson.unset('_screenStats');  // if they're doing anything other than a complete screen, clear the old stats
      this.userLesson.unset('_lettersTyped');
      inputElement = '.js-input-box';
      AdView.initAds('lesson_screen');
      ads = AdView.getAds(adOptions);
      this.views.typing.render();

    } else {
      this.userLesson.unset('_screenStats');
      this.userLesson.unset('_lettersTyped');

      if (!this.userLesson.get('restart') && this.userLesson.get('progress') === this.lesson.get('screens')) {
        // if they're done, show congrats instead of last screen
        AdView.initAds('lesson_screen');
        ads = AdView.getAds(adOptions);
        this.views.congrats.render();

      } else {
        if(this.views.intro) {
          AdView.initAds('lesson_intro');
          ads = AdView.getAds(adOptions);
          this.views.intro.render();
        } else {
          inputElement = '.js-input-box';
          AdView.initAds('lesson_screen');
          ads = AdView.getAds(adOptions);
          this.views.typing.render();
        }
      }
    }

    window.setTimeout(() => {
      each(ads, (ad, name) => {
        if(!ad) { return; }
        var ele = $('.js-' + name + '-ad');
        if(ele.length) {
          ele.append(ad.render().el);
        }
      });

      var adButtonsView = new AdButtonsView();
      $('.js-ad-buttons').append(adButtonsView.render().el);

      if(this.sidebarNavView && this.userLesson.get('_state') !== 'typing') {
        $('.js-sidebar-nav').append(this.sidebarNavView.render().el);
      }

      const hasProblemKeysIntro = $('.structure-content[data-intro-type="problem_keys"]').length > 0;
      const hasMediaIntro = $('.structure-content[data-intro-type="media"][data-screen-count="0"]').length > 0;
      if(!hasMediaIntro && !hasProblemKeysIntro) {
        this.input.initialize({ boundElement: $(inputElement) });
      }

    }, 0);

    // if($(window).height() < 840) {
    //   $('body').velocity('scroll', {offset: 83});
    // }
  },

  continue: function(from, button) {
    const progress = this.userLesson.get('restart') ? 0 : this.userLesson.get('progress'),
      notTracked = this.screen.get('settings')?.not_tracked === '1' || this.userLesson.get('not_tracked'),
      // Stores the current max_progress. Some TYOA screens are not tracked because they are incorrect quiz answers
      maxProgress = (notTracked ? this.userLesson.get('max_progress') : Math.min(this.userLesson.get('max_progress') + 1, this.lesson.get('screens'))),
      lessonSettings = this.lesson.get('settings')

    if(button) {
      $(button).addClass('btn--loading');
    }

    switch(from) {
    case 'intro':
      this.userLesson.set({_state: 'typing'});
      window.setTimeout(() => window.location.safeReload(this.removeHash), 0);
      break;
    case 'typing_success':
      // Single key lessons will never be marked as complete because they have no complete screen
      if(this.screen.get('screen_type') !== 'single-key') {
        this.userLesson.set({ _state: 'complete' });
      }
      this.renderInlineComplete(from);
      break;
    case 'typing_fail':
      this.userLesson.set({_state: 'failed'});
      this.renderInlineComplete(from);
      break;
    case 'complete':
      // Check for lesson complete (TYOA lessons will NEVER call this - they always use "tyoa_pick")
      if(progress === parseInt(this.lesson.get('screens'))) {
        this.userLesson.set({ _state: 'congrats' })
      } else {
        this.userLesson.set({ _state: 'intro' })
      }
      window.setTimeout(() => window.location.safeReload(this.removeHash), 0);
      break;
    case 'tyoa_pick':
      // Save TYOA stats
      this.userLesson.saveTYOAProgress({
        progress: Number.isInteger(this.userLesson.get('new_progress')) ? this.userLesson.get('new_progress') : this.userLesson.get('progress'),
        max_progress: maxProgress
      }).done(() => {
        // TYOA is complete when there are no more paths OR if this is a story with an ending and they've typed the appropriate number of screens
        if(this.screen.get('paths').length === 0 || (lessonSettings?.display === 'story_with_ending' && maxProgress === parseInt(this.lesson.get('screens')))) {
          this.userLesson.set({ _state: 'congrats' })
        } else {
          this.userLesson.set({ _state: 'intro' })
        }
        this.userLesson.set({ max_progress: maxProgress })
        window.setTimeout(window.location.safeReload, 0)
      })
      break;
    case 'complete_restart':
      this.userLesson.set({_state: 'typing'});
      window.setTimeout(window.location.safeReload, 0);
      break;
    case 'failed':
      this.userLesson.set({_state: 'typing'});
      window.setTimeout(window.location.safeReload, 0);
      break;
    default:
      this.userLesson.set({_state: 'intro'});
      window.setTimeout(window.location.safeReload, 0);
    }

    return false;
  },

  /**
   * Render the typing complete page (or fail) inline, instead of refreshing the page.
   * The page
   *
   * @param from
   */
  renderInlineComplete: function(from) {
    // The block screen has TWO 'structure-content' divs. This will remove the one we don't need
    $('.js-single-letter').removeClass('structure-content');

    var parent = this.views.typing.$('.structure-content');

    this.views.typing.hide();

    this.screen.set(this.userLesson.get('_screenStats'));
    this.lettersTyped.reset(this.userLesson.get('_lettersTyped'));

    if(from === 'typing_success') {
      this.updateLocalStats();
      if(this.screen.get('screen_type') !== 'single-key' && this.screen.get('screen_type') !== 'instruction') {
        this.views.screenComplete.renderInline = true;
        this.views.screenComplete.setElement(parent);
        this.views.screenComplete.render();
      }
      this.saveStats();

    } else if (from === 'typing_fail') {
      this.updateLocalStats();
      this.views.screenFailed.renderInline = true;
      this.views.screenFailed.setElement(parent);
      this.views.screenFailed.render();
      this.saveStats();
    }

    const googleClassroomAddon = window.opener && window.localStorage.getItem('googleClassroomAddonData')

    if(this.sidebarNavView && this.userLesson.get('_state') !== 'typing' && !googleClassroomAddon) {
      $('.js-sidebar-nav').append(this.sidebarNavView.render().el);
      $('.js-has-structure').addClass('has-structure--fixedLeftMargin');
    }
  },

  restartScreen: function() {
    if(this.userLesson.get('_state') === 'complete') {
      this.views.screenComplete.restart();
    } else {

      window.setTimeout(window.location.safeReload, 0);
    }
    return false;
  },

  handleScreenComplete: function() {
    let state = '';
    const acc = Scoring.accuracy(this.screen.get('typed'), this.screen.get('errors')),
      speed = Scoring.speed(this.screen.get('typed'), this.screen.get('seconds'), this.screen.get('errors'))

    if(!this.screen.get('fastForward') &&
      this.screen.get('screen_type') !== 'written-prompt' && this.screen.get('screen_type') !== 'code-review' && this.screen.get('screen_type') !== 'instruction' && this.screen.get('screen_type') !== 'single-key' &&
      this.unit &&
      (
        (this.lesson.get('time_limit') === 0 &&
          (acc < this.screen.get('min_acc') || speed < this.screen.get('min_speed') || this.screen.get('typed') < this.screen.get('content').length)
        )
      )
    ) {
      state = 'typing_fail';

      // save the stats in case they need to try to save again
      this.screenStats = {
        failed: 1,
        lesson_id: this.lesson.id,
        lesson_screen_id: parseInt(this.screen.id),
        seconds: this.screen.get('seconds'),
        errors: this.screen.get('errors'),
        typed: this.screen.get('typed'),
        std: this.screen.get('std'),
        stars: 0,
        completed: 0,
        created_at: Date.getUnixTime(),
        now: Math.floor(Date.now()/1000)
      };

    } else {
      state = 'typing_success';
      const progress = (this.userLesson.get('restart')) ? 0 : this.userLesson.get('progress'),
        pastScreen = this.userLessonScreens.get(parseInt(this.screen.id)),
        lesson = this.lesson,
        displayType = lesson.get('settings')?.display,
        tyoaStory = lesson.get('content_type') === 'adventure' && (displayType === 'story'),
        tyoaLesson = lesson.get('content_type') === 'adventure' && (displayType === 'email' || displayType === 'resume' || displayType === 'story_with_ending'),
        tracked = this.screen.get('settings')?.not_tracked !== '1'

      let stars = Scoring.stars(Scoring.accuracy(this.screen.get('typed'), this.screen.get('errors')), this.screen.get('two_stars'), this.screen.get('three_stars')),
        newStars = stars,
        ulsTable = 'hot2';

      if(pastScreen) {
        newStars = Math.max(0, stars - pastScreen.get('stars'))
        stars = Math.max(stars, pastScreen.get('stars'))
        ulsTable = pastScreen.get('uls_table')
      }

      // inline tests get stars but not unit-tests
      if(!tracked || tyoaStory || this.options.problemkeys || this.unit.get('type') === 'test') {
        newStars = 0;
        stars = 0;
      }

      var completed = (progress + 1 === parseInt(lesson.get('screens'))) ? 1 : 0;
      // if there are no more paths, then the TYOA is complete
      if(lesson.get('content_type') === 'adventure' && this.screen.get('paths').length === 0) {
        completed = 1;
      }

      // save the stats in case they need to try to save again
      this.screenStats = {
        lesson_id: lesson.id,
        lesson_screen_id: parseInt(this.screen.id),
        seconds: Math.floor(Math.max(1, this.screen.get('seconds'))),
        errors: this.screen.get('errors'),
        typed: this.screen.get('typed'),
        uls_table: ulsTable,
        stars: stars,
        new_stars: newStars,
        is_redo: pastScreen ? 1 : 0,
        skin_id: this.user.get('skin_id') || 1,
        completed: completed,
        restart: (this.userLesson.get('restart')) ? 1 : 0,
        progress: (lesson.get('content_type') === 'adventure') ? progress : Math.min(progress + 1, lesson.get('screens') || 0),  // TYOA only progress when they pick a path
        created_at: Date.getUnixTime(),
        response: this.screen.get('response'),
        test: (this.options.test) ? 1 : 0,
        testType: (lesson.getTestType() === 'page' ? lesson.get('time_limit') + '-page' : 'timed'),
        unit_type: (this.unit) ? this.unit.get('type') : '',
        problem_keys: (this.options.problemkeys) ? 1 : 0,
        now: Math.floor(Date.now()/1000)
      }

      if(tyoaLesson) {
        // Manually set max progress because we don't want it automatically set using progress
        this.screenStats.max_progress = Math.min(this.userLesson.get('max_progress'), this.lesson.get('screens'))
        if(tracked) {
          // The number of stars on the most recently typed screen
          const starObj = (pastScreen ? { lesson_screen_id: pastScreen.get('lesson_screen_id'), stars: stars } : { lesson_screen_id: -1, stars: stars })
          // Sum of stars for the top X screens (this allows students to redo TYOA screens and get credit for the top screens they've typed)
          const totalStars = reduce(
            slice(
              sortBy(
                unionBy(
                  [starObj],
                  filter(
                    this.userLessonScreens.toJSON(), { lesson_id: this.lesson.id }
                    ), 'lesson_screen_id' // unionBy
                ), (row) => -row.stars // sortBy
              ), 0, this.lesson.get('screens') // slice
            ), (cur, acc) => acc.stars + cur, 0) // reduce

          this.screenStats.total_stars = Math.min(totalStars, this.lesson.get('screens') * 3)
        }
      }
    }

    // store their typing stats and the flag that it hasn't been saved, then we refresh the page
    this.userLesson.set({_localSavePending: true, _screenStats: this.screenStats, _lettersTyped: this.lettersTyped.toJSON()});
    this.continue(state);
  },

  saveStats: function() {
    //if(this.options.problemkeys) { return; }

    this.screenStats = this.userLesson.get('_screenStats'); //grab the stats from before the refresh

    this.views.screenComplete.once('try-again', this.saveStats, this);

    if(this.userLesson.get('_screenStats')) { // if it hasn't been saved, go through the save motions
      this.userLesson.saveStats(this.userLesson.get('_screenStats'), this.userLesson.get('_lettersTyped'))    // save to the server
        .done(() => {
          this.userLesson.unset('_screenStats');
          this.userLesson.unset('_lettersTyped');

          if (this.screenStats && this.screenStats.failed) {

          } else {
            Analytics.customMetric(2, Math.floor((this.userLesson.get('max_progress') / this.lesson.get('screens')) * 100)); // REMOVE ON 7/1/2023

            this.userLesson.set({
              // Adventure screens just increase the max_progress by 1. This allows us to check max_progress vs screens for completion
              max_progress: (this.screenStats.max_progress !== undefined ? this.screenStats.max_progress : Math.max(this.screenStats.progress, this.userLesson.get('max_progress'))),
              progress: this.screenStats.progress,
              lesson_completed: this.userLesson.get('lesson_completed') || this.screenStats.completed || 0  // this flag says if it EVER has been completed in the past or now
            });

            if(Registry.get('loggedIn') && this.options.test && !this.options.problemkeys) {
              this.userTests.first().set({user_test_id: this.userLesson.get('last_save_response').id});
            }

            this.userLesson.unset('restart');

            this.views.screenComplete.showSaved();
            if(this.options.test) {
              // don't keep showing this page if things have saved.  They might refresh to take the test again.
              this.userLesson.unset('_state');
            }

            // Single key screens skip the screen complete and just go to the next lesson
            if(this.screen.get('screen_type') === 'single-key' || this.screen.get('screen_type') === 'instruction') {
              this.handleLessonComplete()
            }
          }
        })
        .fail((response) => {
          this.views.screenComplete.showSaveError();

          window.responseError = response;
          try {
            if(response?.status && response?.responseJSON?.message !== 'INVALID_TOKEN') {
              Bugsnag.notify('Failed to save user lesson', (event) => {
                event.addMetadata('response', { response: response.responseText });
                event.addMetadata('status', { status: response.status });
              })
            }
          } catch (e) {
            console.error('Failed to send to bugsnag', { error: e });
          }
        })
        .always(() =>{
          AchievementsCollection.pause(false);
        })
    } else {
      // Single key screens skip the screen complete and just go to the next lesson
      if(this.screen.get('screen_type') === 'single-key') {
        this.handleLessonComplete()
      } else {
        this.views.screenComplete.showSaved();  // this calls if it's already saved
      }
    }
  },

  /**
   * Called when we do not have a "congrats" screen but we want to go to the next lesson
   */
  handleLessonComplete: function() {
    const userLessons = Registry.get('userLessons'),
      lessons = Registry.get('lessons'),
      units = Registry.get('units');

    lessons.setProgress(userLessons);
    units.setProgress(Registry.get('lessons'));

    const activeLessonId = units.get(this.lesson.get('unit_id')).get('active_lesson'),
      activeLesson = lessons.get(activeLessonId);

    if(activeLesson) {
      window.location.href = __url('/student/lesson/'+ activeLesson.get('lesson_id') + '/' + activeLesson.get('name').slug())
    } else {
      window.location.href = __url('/student/lessons')
    }
  },

  updateLocalStats: function(){
    var screenStats = this.userLesson.get('_screenStats');

    if(!screenStats || !this.userLesson.get('_localSavePending')) {
      return;
    }

    this.userLesson.unset('_localSavePending');

    AchievementsCollection.pause(true);

    if (!this.options.problemkeys && !screenStats.failed){
      if(this.unit.get('type') === 'test') {  // unit-tests ONLY save the userTest
        this.userTests.add(screenStats);
      } else {
        if(this.options.test){  // inline tests save the userTest and also act like a regular screen
          this.userTests.add(screenStats);
        }
        var pastScreen = this.userLessonScreens.get(screenStats.lesson_screen_id);
        if(pastScreen) {
          screenStats.stars = Math.max(screenStats.stars, pastScreen.get('stars'));
        }
        var lastTry = this.userLessonScreens.get(screenStats.lesson_screen_id);
        screenStats.lastTry = (lastTry) ? lastTry.toJSON() : null;
        this.userLessonScreens.add(screenStats, {merge: true});
        Registry.get('userLessonScreens').add(screenStats, {merge: true});  // this is the actual local storage one

        this.userLesson.set({
          updated_at: screenStats.created_at,
          typed: this.userLessonScreens.reduce((memo, row) => memo + row.get('typed'), 0),
          errors: this.userLessonScreens.reduce((memo, row) => memo + row.get('errors'), 0),
          seconds: this.userLessonScreens.reduce((memo, row) => memo + row.get('seconds'), 0),
          stars: Math.min(this.userLessonScreens.reduce((memo, row) => memo + row.get('stars'), 0), (this.lesson.get('screens') || 1) * 3)
        })
      }
    }


    var toSave = mapValues(clone(screenStats), parseInt);
    toSave.stars = (typeof toSave.new_stars !== 'undefined') ? toSave.new_stars : toSave.stars;
    this.userActivity.merge(toSave);
    this.userLettersTyped.merge(this.lettersTyped.toJSON());
  },

  browserCompatibleWithInline: function() {
    var browser = window.navigator.sayswho();
    browser.version = parseFloat(browser.version);

    // Sometimes the inline game simply doesn't load. In this case, we don't want the whole app to become unusable,
    // so we just flag this user as unable to see inline games and show them standard screens instead
    if(Registry.get('student').get('_inlineIncompatible')) {
      return false
    }

    switch (browser.browser.toLowerCase()) {
    case 'edge':
      return browser.version >= 15;
    case 'ie':
      return false;
    case 'chrome':
      return browser.version >= 65;
    case 'firefox':
      return browser.version >= 47;
    case 'safari':
      return browser.version >= 11;
    default:
      return false;
    }
  },
})
