import FormModel from '@shared/form_model'

export default FormModel.extend({

  defaults: {

  },

  validationRules: {
    oldPassword: {
      required: true
    },
    password: {
      required: true
    },
    password2: {
      required: true,
      equalTo: 'input[name=password]'
    }
  },

  url: '/apiv1/student/user/password'
})
