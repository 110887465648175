import Backbone from 'backbone'
import $ from 'jquery'
import moment from 'moment'
import Registry from '@registry'
import Velocity from 'velocity-animate'
import AuthModal from '../../global/views/auth_modal'
import SignupView from './signup'
import Alert from '../../global/views/alert';
import Scoring from '@shared/scoring';
import { groupBy, times, difference } from 'lodash-es'
import { assessments_main } from '@templates/student'
import UnableToPrintModal from '../../tests/views/unable_to_print_modal';

export default Backbone.View.extend({

  template: assessments_main,

  events: {
    'click .js-test': 'goToTest',
    'click .js-signup': 'signup',
    'click .js-try-again': 'tryAgain',
    'click .js-print-certificate': 'printCertificate',
    'click .js-accordion-trigger': 'accordionToggle'
  },

  testTypes: [
    { name: 'timed', slug: '1-minute', testLength: 1 },
    { name: 'timed', slug: '3-minute', testLength: 3 },
    { name: 'timed', slug: '5-minute', testLength: 5 },
    { name: 'page', slug: '1-page', testLength: 1 },
    { name: 'page', slug: '2-page', testLength: 2 },
    { name: 'page', slug: '3-page', testLength: 3 }],
  sortBy: 'created_at',
  activeTab: '1-minute',

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.listenTo(this.tests, 'reset', this.render);
  },

  serialize: function() {
    const assessmentData = {},
      testNames = ['1-minute', '3-minute', '5-minute', '1-page', '2-page', '3-page'],
      userTests = this.tests.toJSON(),
      userTestNames = userTests.map(test => test.testType === 'timed' ? `${test.seconds / 60}-minute` : test.testType),
      missingTestNames = difference(testNames, userTestNames),
      userTestsByType = userTests.reduce((acc, cur) => {
        const slug = cur.testType === 'timed' ? `${cur.seconds / 60}-minute` : cur.testType
        if(!acc[slug] || acc[slug].created_at < cur.created_at) {
          acc[slug] = cur;
        }
        return acc
      }, {})

    // If the user has finished all the tests, get their score
    if(missingTestNames.length === 0) {
      const userTestResults = userTests.reduce((acc, cur) => {
        acc['typed'] += cur.typed;
        acc['errors'] += cur.errors;
        acc['seconds'] += cur.seconds;
        return acc;
      }, { typed: 0, errors: 0, seconds: 0 })

      assessmentData.accuracy = Scoring.accuracy(userTestResults.typed, userTestResults.errors)
      assessmentData.speed = Scoring.speed(userTestResults.typed, userTestResults.seconds, userTestResults.errors)
      assessmentData.time = userTestResults.seconds

      // When the user finishes ALL assessments but has not passed the minimum requirements we put a simple timestamp in their local storage which forces them to wait 8 hours before retrying
      if((assessmentData.accuracy < 95 || assessmentData.speed < 45) && !localStorage.getItem(`${this.user.get('user_id')}_assessment_unlock`)) {
        localStorage.setItem(`${this.user.get('user_id')}_assessment_unlock`, Date.now() + (1000 * 60 * 60 * 8)); // 8 hours from now
      }
    }

    return {
      lodash: { times },
      moment: moment,
      sortBy: this.sortBy,
      activeTab: this.activeTab,
      testTypes: groupBy(this.testTypes, function(row) { return row.name; }),
      showGuestBanner: !Registry.get('loggedIn'),
      loggedIn: Registry.get('loggedIn'),
      testsRest: this.tests.hasReset(),
      userTestsByType: userTestsByType,
      assessmentLocked: localStorage.getItem(`${this.user.get('user_id')}_assessment_retried`) && localStorage.getItem(`${this.user.get('user_id')}_assessment_unlock`),
      assessmentUnlockTime: localStorage.getItem(`${this.user.get('user_id')}_assessment_unlock`),
      assessmentComplete: Object.values(userTests).length >= 6,
      assessmentData: assessmentData
    };
  },

  signup: function(e) {
    new AuthModal({
      authType: 'signup',
      signupView: new SignupView(),
      isAssessment: true
    }).open()
  },

  goToTest: function(e) {
    var target = $(e.currentTarget),
      id = target.data('id');

    window.location.href = __url('/student/typing-test/' + id + '/es');

    return false;
  },

  tryAgain: function() {
    this.user.resetTests().then(() => {
      // Force a rerender with no tests
      this.tests.reset([])
      localStorage.removeItem(`${this.user.get('user_id')}_assessment_unlock`)
      localStorage.setItem(`${this.user.get('user_id')}_assessment_retried`, 1)
    })
  },

  printCertificate: function() {
    var user = Registry.get('student');

    if(!(user.get('first_name') && user.get('last_name'))) {
      if(!Registry.get('loggedIn')) {
        new UnableToPrintModal().open();
      } else if(user.get('in_class') && user.hasOption('lockaccount')) {
        (new Alert({
          error: false,
          title: 'lesson.unable_to_print'.t(),
          text: 'lesson.contact_teacher_to_print'.t()
        }).show());
      } else {
        (new Alert({
          error: false,
          title: 'lesson.unable_to_print'.t(),
          cancel: 'shared.cancel_text'.t(),
          text: 'lesson.enter_name_to_print'.t(),
          ok: 'lesson.go_to_account_settings'.t()
        }).show().on('ok', function(){
          window.location.href = __url('/student/account');
        }));
      }
      return false;
    }

    window.open('/apiv1/student/certificacion/' + user.id + '/certificate?language=' + FTWGLOBALS('language'));

    return false;
  },

  accordionToggle: function(e) {
    var id = $(e.currentTarget).data("id"),
      accordion = this.$('.js-accordion[data-id=' + id + ']'),
      closeOnly = accordion.hasClass("is-open");

    Velocity($(".js-accordion .accordion-content"), "slideUp",
      {
        duration: 250,
        complete: function() {
          this.$(".js-accordion").removeClass("is-open");
        }.bind(this)
      }
    );
    if(!closeOnly) {
      Velocity($('.js-accordion[data-id=' + id + '] .accordion-content'), "slideDown",
        {
          duration: 250,
          complete: function() {
            accordion.addClass("is-open");
          }.bind(this)
        })
    }
  },
})
