import Backbone from 'backbone'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import { find } from 'lodash-es'
import { lesson_intro_test } from '@templates/student'

export default Backbone.View.extend({

  events: {
    'click .js-continue-button': 'continue',
  },

  template: lesson_intro_test,

  serialize: function() {
    var userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens'),
      currentAssessmentIndex = 0

    // The following code is used for EduTec Assessments! Gather the necessary deets
    if(this.user.isAssessment()) {
      currentAssessmentIndex = Registry.get('userTests').toJSON().length + 1
    }

    return {
      ads: AdView.canShowAds(),
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens, forceAd: true }),
      noAvatar: find(FTWGLOBALS('skins'), {id: this.user.get('skin_id') || 1}).noAvatar,
      testType: this.testType,
      testLength: this.testLength,
      userHasTests: this.userHasTests,
      productGrade: this.user.getCurriculumGrade(),
      minutesToComplete: this.minutesToComplete,
      isAssessment: this.user.isAssessment(),
      currentAssessmentIndex: currentAssessmentIndex
    };
  },

  render: function() {
    this.dictation.speakTestIntro({ title: this.lesson.get('name').replace(':00', ' minute') });

    Backbone.View.prototype.render.apply(this, arguments);

    this.listenTo(this.input, 'character', this.handleKeypress);

    return this;
  },

  continue: function() {
    this.trigger('continue', 'intro', this.$('.js-continue-button'));
    return false;
  },

  handleKeypress: function(character){
    if(character.key == '\n') {
      this.continue();
    }
  }
})
