import Backbone from 'backbone'
import Registry from '@registry'
import Scoring from '@shared/scoring'
import LessonModel from '../models/lesson'
import { isArray, find, flatten, values, indexOf, groupBy, concat } from 'lodash-es'

export default Backbone.Collection.extend({
  model: LessonModel,

  comparator: 'display_order',

  setup: function() {
    this.user = Registry.get('student');

    Registry.get('units').reset(FTWGLOBALS('units'));
    var unitIds = Registry.get('units').pluck('unit_id'),
      lessons = concat(FTWGLOBALS('lessons'), Registry.get('customLessons').toJSON());

    // teacher controlled lesson list
    var allowedLessons = this.user.getSetting('lessons') ? this.user.getSetting('lessons') : null;
    if(isArray(allowedLessons)) {
      const validUnitIds = FTWGLOBALS('units').filter(row => row.type !== 'test').map(row => row.unit_id)
      const validLessonIds = lessons.filter(row => validUnitIds.includes(row.unit_id)).map(row => row.lesson_id)
      allowedLessons = allowedLessons.filter((lessonId) => validLessonIds.includes(lessonId))
      if(allowedLessons.length === 0) {
        allowedLessons = null;
      }
    }

    lessons = lessons
      // take the set lessons, and be sure they're valid for this products units
      .filter(function(lesson){ return indexOf(unitIds, lesson.unit_id) != -1; })
      // then filter out any that aren't a default lesson and aren't in the teacher specified allowedLessons, if that list is populated
      .filter(function(lesson){ return lesson.type === 'grouping' || (!allowedLessons && lesson.default_lesson) || (allowedLessons && allowedLessons.indexOf(lesson.lesson_id) != -1); });

    // this complex mess removes any lesson.type == 'grouping'.. here's the explanation...
    // first, group by unit, then pull out the values, and run a map on those so each unitLesson block is run
    lessons = flatten(values(groupBy(lessons, function(lesson){return lesson.unit_id;})).map(function(unitLessons){
      // now check if the
      var lastWasGrouping = unitLessons[unitLessons.length - 1].type == 'grouping';
      return unitLessons.reverse().filter(function(lesson){
        if(lastWasGrouping && lesson.type == 'grouping') return false;
        lastWasGrouping = lesson.type == 'grouping';
        return true;
      });
    }));

    this.reset(lessons);
  },

  setProgress: function(userLessons) {
    this.setup();

    // Set all the user progress on the various collections
    userLessons.forEach((lesson) => {
      var realLesson = this.get(lesson.id);
      if(!realLesson) return;

      var lessonModel = this.get(lesson.id),
        screens = lessonModel.get('screens');

      if(lessonModel.get('time_limit') > 0) {
        var userTest = find(Registry.get('userTests').getCompiled(), {lesson_id: lessonModel.id});
        if(userTest) {
          lesson.set({fastest: userTest.fastest});
        }
      }

      // Lessons need stats for the display
      lessonModel.set({
        progress: Math.min(screens, lesson.get('progress')),    // doing a Math.min because if they change lessons and delete a screen, then possibly their progress is beyond the lesson size, and will explode
        max_progress: realLesson.get('content_type') === 'adventure' ? lesson.get('max_progress') : Math.min(screens, Math.max(lesson.get('progress'), lesson.get('max_progress'))),
        speed: Scoring.speed(lesson.get('typed'), lesson.get('seconds'), lesson.get('errors')),
        avg_speed: Math.ceil(lesson.get('speed_sum') / lesson.get('completed')),
        accuracy: Scoring.accuracy(lesson.get('typed'), lesson.get('errors')),
        stars: lesson.get('stars'),
        seconds: lesson.get('seconds'),
        typed: lesson.get('typed'),
        errors: lesson.get('errors'),
        updated_at: lesson.get('updated_at'),

        // used to mark if a lesson has ever been completed
        completed: lesson.get('completed'),
        fastest: lesson.get('fastest')
      })
    });

    return this;
  }
})
