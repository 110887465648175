import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import Scoring from '@shared/scoring'
import Analytics from '@shared/analytics'
import radarTracker from '@shared/radar_tracker'
import LessonModel from '../global/models/lesson'
import LayoutView from './views/layout'
import ScreensCollection from '../global/collections/lesson_screens'
import CongratsView from './views/congrats';
import IntroView from './views/intro_qa'
import DefaultClickDragScreenView from './views/screen_drag_default'
import MatchPairsScreenView from './views/screen_drag_match_pairs'
import DragCategoriesScreenView from './views/screen_drag_categories'
import TBarScreenView from './views/screen_drag_tbar'
import UserLessonScreens from '../global/collections/user_lesson_screens'
import AchievementsCollection from '../global/collections/achievements'
import AdView from '../global/views/ad'
import KeyboardInput from '@shared/keyboard_input'
import Dictation from './classes/dictation'
import { find, each } from 'lodash-es'

// in development
// import BucketsScreenView from './views/screen_drag_buckets'

/**
 * This is the main overall controller for the typing lessons
 */
export default Backbone.View.extend({

  initialize: function(options) {
    Backbone.View.prototype.initialize.apply(this, arguments);
    this.options = options;

    this.user = Registry.get('student');
    this.userAchievements = Registry.get('userAchievements');
    this.userActivity = Registry.get('userActivity');
    this.userTests = Registry.get('userTests');
    this.units = Registry.get('units');
    this.units.set(FTWGLOBALS('units'));

    this.input = new KeyboardInput();   // Handles and normalizes browser input
    this.dictation = new Dictation(null, null, this.user); // Handles dictation for all screens

    this.removeHash = false // Used by Proc2024 when going to a specific screen

    var lesson = find(FTWGLOBALS('lessons'), { lesson_id: this.lessonId });
    if(!lesson) {
      lesson = FTWGLOBALS('custom_lesson');
    }
    this.lesson = new LessonModel(lesson);

    this.unit = this.units.get(this.lesson.get('unit_id'));
    this.screens = new ScreensCollection(FTWGLOBALS('lesson_screens'));

    // Get localStorage stored status
    var userLessons = Registry.get('userLessons');
    this.userLesson = userLessons.get(this.lesson.id);
    if (!this.userLesson) {
      // If they've never done this lesson, create a new record for it
      this.userLesson = userLessons.add({lesson_id: this.lesson.id});
    }

    var progress = this.userLesson.get('progress');
    progress = Math.min(progress, this.lesson.get('screens') - 1 || 0)
    if(this.screenIndex) {
      // Use the URL hash to specify which screen to show (when applicable)
      progress = Math.min(Math.max(this.screenIndex - 1, 0), this.lesson.get('screens') - 1)

      this.userLesson.set({progress: progress, restart: false, _state: 'intro'})
      this.removeHash = true
    }
    if(this.userLesson.get('restart')) {
      progress = 0;
    }

    this.userLessonScreens = new UserLessonScreens(Registry.get('userLessonScreens').where({lesson_id: this.lesson.id}));

    // Special tracking on this page with more info
    if(Registry.get('loggedIn') && window.location.pathname.match(/lesson\/[0-9]+/) && this.user.get('in_class') && this.user.isPremium() && this.user.hasOption('realtime')) {
      radarTracker.track(Registry.get('student'), {
        seconds: this.userLesson.get('seconds'),
        errors: this.userLesson.get('errors'),
        typed: this.userLesson.get('typed'),
        progress: progress
      });
    }
    // Get the current screen
    this.screen = this.screens.at(progress);

    const grading = this.user.getSetting('grading');
    if(grading) {
      this.screen.set(grading);
    }

    if (!this.screen || this.userLesson.get('_state') === 'congrats') {
      this.views.congrats = new CongratsView({
        user: this.user,
        lesson: this.lesson,
        lettersTyped: this.lettersTyped,
        dictation: this.dictation,
        userLesson: this.userLesson,
        userLessonScreens: this.userLessonScreens
      })
    } else {
      // Some screens have intros
      if (this.screen.get('intro')) {
        this.views.intro = new IntroView({
          user: this.user,
          screen: this.screen,
          lesson: this.lesson,
          userLesson: this.userLesson,
          input: this.input,
          dictation: this.dictation
        });
        this.views.intro.once('continue', function() {
          this.userLesson.set({_state: 'click_drag'});
          window.setTimeout(() => window.location.safeReload(this.removeHash), 0);
        }.bind(this));
      }

      // determine the click drag type to create
      const { display } = this.screen.get('settings') ||  { }
      let Type = display === 'matching' ? MatchPairsScreenView
        : display === 'tbar' ? TBarScreenView
        : display === 'categories' ? DragCategoriesScreenView
        : DefaultClickDragScreenView

      // The actual typing screen.
      this.views.screen = new Type({
        user: this.user,
        input: this.input,
        dictation: this.dictation,
        lesson: this.lesson,
        screens: this.screens,
        screen: this.screen,
        userLesson: this.userLesson,
        userLessonScreens: this.userLessonScreens,
        problemKeysLesson: this.options.problemkeys,
      });

      // prepare, if needed
      this.views.screen?.setup()

      this.listenTo(this.views.screen, 'continue', () => {
        const progress = this.userLesson.get('restart') ? 0 : this.userLesson.get('progress')

        // Check for lesson complete
        if(progress === parseInt(this.lesson.get('screens'))) {
          // If there is just one screen, simply go to the next lesson, otherwise send them to the congrats screen
          if(parseInt(this.lesson.get('screens')) === 1) {
            this.handleLessonComplete()
          } else {
            this.userLesson.set({ _state: 'congrats' })
            window.setTimeout(() => window.location.safeReload(this.removeHash), 0);
          }
        } else {
          this.userLesson.set({ _state: 'intro' })
          window.setTimeout(() => window.location.safeReload(this.removeHash), 0);
        }
      })
      this.listenTo(this.views.screen, 'complete', this.handleScreenComplete.bind(this));
    }
    // layout is specific to typing page
    this.layout = (new LayoutView({
      user: this.user,
      timer: this.timer,
      child: this,
      input: this.input,
      dictation: this.dictation,
      unit: this.unit,
      lesson: this.lesson,
      screen: this.screen,
      userLesson: this.userLesson,
      test: this.options.test,
      problemkeys: this.options.problemkeys,
      progressComplete: this.userLesson.get('_state') === 'complete'
    }));

    if (this.views.congrats) { // if they're done, show congrats instead of last screen
      this.addChild(this.el, this.views.congrats, true, false);
    } else {
      if (this.views.intro) {
        this.addChild(this.el, this.views.intro, true, false);
        this.addChild(this.el, this.views.screen, true, false);
      } else {
        this.addChild(this.el, this.views.screen, true, false);
      }
    }

    this.render();
  },

  renderChildren: function() {
    var state = this.userLesson.get('_state'),
      ads = {};

    Backbone.View.prototype.renderChildren.apply(this, arguments);

    var adOptions = {
      totalScreens: this.userActivity.getOrAdd(0).get('screens')
    }

    if(state === 'congrats') {
      AdView.initAds('lesson_screen');
      ads = AdView.getAds(adOptions);
      this.views.congrats.render();
    } else if(state === 'click_drag') {
      AdView.initAds('lesson_screen');
      ads = AdView.getAds(adOptions);
      this.views.screen.render();
    } else {
      if(this.screen.get('intro')) {
        AdView.initAds('lesson_intro');
        ads = AdView.getAds(adOptions);
        this.views.intro.render();
      } else {
        AdView.initAds('lesson_screen');
        ads = AdView.getAds(adOptions);
        this.views.screen.render();
      }
    }

    window.setTimeout(() => {
      each(ads, (ad, name) => {
        if(!ad) { return; }
        var ele = $('.js-' + name + '-ad');
        if(ele.length) {
          ele.append(ad.render().el);
        }
      });

      this.input.initialize({ shortcuts: ['dictation'] });
    }, 0);
  },

  handleScreenComplete: function() {
    var progress = (this.userLesson.get('restart')) ? 0 : this.userLesson.get('progress');
    var completed = (progress + 1 >= parseInt(this.lesson.get('screens'))) ? 1 : 0;
    var pastScreen = this.userLessonScreens.get(parseInt(this.screen.id));
    var ulsTable = 'hot2'

    let stars = Scoring.stars(Scoring.accuracy(this.screen.get('typed'), this.screen.get('errors')), this.screen.get('two_stars'), this.screen.get('three_stars')),
      newStars = stars

    if(pastScreen) {  // every stat needs to know the right table
      newStars = Math.max(0, stars - pastScreen.get('stars'))
      stars = Math.max(stars, pastScreen.get('stars'))
      ulsTable = pastScreen.get('uls_table')
    }

    this.screenStats = {
      lesson_id: this.lesson.id,
      lesson_screen_id: parseInt(this.screen.id),
      seconds: 0,
      errors: this.screen.get('errors'),
      typed: this.screen.get('typed'),
      std: this.screen.get('std'),
      uls_table: ulsTable,
      stars: stars,
      new_stars: newStars,
      skin_id: this.user.get('skin_id') || 1,
      completed: completed,
      restart: (this.userLesson.get('restart')) ? 1 : 0,
      progress: Math.min(progress + 1, this.lesson.get('screens')), // Progress can go no higher than the number of screens
      created_at: Date.getUnixTime(),
      test:  0,
      problem_keys: 0,
      tech_lit: 1,
      now: Math.floor(Date.now()/1000)
    }

    // check for custom values
    ;[
      'counts' // used by t-bars that allow counting multiple objects
    ].forEach(key => {
      const data = this.screen.get(key)
      if (data) {
        this.screenStats[key] = data
      }
    })

    this.saveStats();
  },

  handleLessonComplete: function() {
    this.userLesson.set({_state: 'complete'})

    const userLessons = Registry.get('userLessons'),
      lessons = Registry.get('lessons'),
      units = Registry.get('units');

    lessons.setProgress(userLessons);
    units.setProgress(Registry.get('lessons'));

    const activeLessonId = units.get(this.lesson.get('unit_id')).get('active_lesson'),
      activeLesson = lessons.get(activeLessonId);

    if(activeLesson) {
      window.location.href = __url('/student/lesson/'+ activeLesson.get('lesson_id') + '/' + activeLesson.get('name').slug())
    } else {
      window.location.href = __url('/student/lessons')
    }
  },

  saveStats: function() {
    AchievementsCollection.pause(true);
    this.userLesson.saveStats(this.screenStats)    // save to the server
      .done(() => {
        this.userLesson.unset('_screenStats');

        if (this.screenStats && this.screenStats.failed) {
          // TODO: appropriate handing for failed save attempts
        } else {
          Analytics.customMetric(2, Math.floor((this.userLesson.get('max_progress') / this.lesson.get('screens')) * 100)); // REMOVE ON 7/1/2023

          var lastTry = this.userLessonScreens.get(this.screenStats.lesson_screen_id);
          this.screenStats.lastTry = (lastTry) ? lastTry.toJSON() : null;
          this.userLessonScreens.add(this.screenStats, {merge: true});
          Registry.get('userLessonScreens').add(this.screenStats, {merge: true});  // this is the actual local storage one

          this.userLesson.set({
            max_progress: Math.max(this.screenStats.progress, this.userLesson.get('max_progress')),
            progress: this.screenStats.progress,
            lesson_completed: this.userLesson.get('lesson_completed') || this.screenStats.completed || 0,  // this flag says if it EVER has been completed in the past or now
            updated_at: this.screenStats.created_at,
            typed: this.screenStats.progress,
            errors: 0,
            seconds: 0,
            stars: Math.min(this.userLessonScreens.reduce((memo, row) => memo + row.get('stars'), 0), (this.lesson.get('screens') || 1) * 3)
          });

          this.userLesson.unset('restart');
        }
      })
      .fail(() => {
        // TODO: appropriate handing for failed save attempts
      })
      .always(() => {
        AchievementsCollection.pause(false);
      })
  }
})
