import Backbone from 'backbone'
import { lesson_screen_progress } from '@templates/student'

export default Backbone.View.extend({

  template: lesson_screen_progress,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    if(this.lesson.getTestType() === 'timed' || (this.lesson.get('content_type') === 'written-prompt' && this.screen?.get('settings')?.time_limit)) {
      this.listenTo(this.timer, 'change:seconds', this.updateProgress);
    } else {
      this.listenTo(this.screen, 'change:typed', this.updateProgress);
    }
  },

  serialize: function() {
    return {
      progressComplete: this.progressComplete
    };
  },

  fill:null,
  bar:null,
  fillWrapper:null,
  lastProgress: 0,
  updateProgress: function(model, progress) {
    progress = Math.floor(progress);
    this.fill = (this.fill) ? this.fill : this.$('.js-fill');
    this.bar = (this.bar) ? this.bar : this.$('.js-bar');
    this.fillWrapper = (this.fillWrapper) ? this.fillWrapper : this.$('.js-fill-wrapper');
    var lastProgress = this.lastProgress;
    this.lastProgress = progress;
    if(lastProgress === progress) {
      return;
    }

    var progressPercent = 0;
    if(this.lesson.getTestType() === 'timed' || (this.lesson.get('content_type') === 'written-prompt' && this.screen?.get('settings')?.time_limit)) {
      progressPercent = (Math.floor(progress) / this.timeLimit) * 100;
    } else {
      progressPercent = (progress / model.get('content').length) * 100;
    }

    this.fill.css({width: Math.round(progressPercent) + '%'});
    this.bar.css({width: Math.round(1000000 / (progressPercent * 100)) + '%'});
    this.fillWrapper.attr('aria-hidden', progressPercent > 0 ? 'false' : 'true');

  }

})
