import Backbone from 'backbone'
import { findIndex, find } from 'lodash-es'

export default Backbone.Model.extend({

  idAttribute: 'id',

  defaults: {
    name: '',
    file: '',
    ranks: []
  },

  getTotalRanks: function() {
    return this.get('ranks').length;
  },

  getRank: function(exp) {
    exp = Math.max(0, exp);
    return find(this.get('ranks').clone().reverse(), function(level) {
      return exp >= level.experience;
    });
  },

  getNextRank: function(exp) {
    exp = Math.max(0, exp);
    var ranks = this.get('ranks').clone().reverse(),
      rank = findIndex(ranks, function(level) {
        return exp >= level.experience;
      });

    if(rank === 0) {
      return null;
    } else {
      return ranks[rank-1];
    }
  }
})
