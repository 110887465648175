import { newContainer } from './utils';
import { COLORS } from './consts';

const SHAPE_SIZE = 1
const SHAPE_OFFSET = 0.5
const BOUNDING_BOX_OFFSET = 0
const BOUNDING_BOX_MARGIN = 0

export default class BlueprintShape {

  constructor (data) {
    this.data = data

    this.el = newContainer()
    this.el.setAttribute('class', 'blueprints--shape')
    this.el.setAttribute('building-block', '')
    this.el.setAttribute('data-id', data.id)

    // handle clicks
    this.el.addEventListener('pointerup', this.onSelect)
  }

  update(data = this.data) {
    this.data = data

    this.el.innerHTML = ''
    const { id, type, x, y, attrs, color } = data
    const hex = COLORS[color]
    const url = `/dist/shared/images/blueprints.svg#${type}`

    // create the SVG element to display
    const shape = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    shape.setAttribute('width', SHAPE_SIZE)
    shape.setAttribute('height', SHAPE_SIZE)
    shape.setAttribute('transform', `translate(-${SHAPE_OFFSET}, -${SHAPE_OFFSET})`)
    shape.setAttributeNS('http://www.w3.org/1999/xlink', 'href', url)

    // add an element to act as a bounding box when this
    // element is selected
    const boundingBox = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
    boundingBox.setAttribute('class', 'blueprints--shape--bounding-box')
    boundingBox.setAttribute('width', SHAPE_SIZE + BOUNDING_BOX_MARGIN)
    boundingBox.setAttribute('height', SHAPE_SIZE + BOUNDING_BOX_MARGIN)
    boundingBox.setAttribute('transform', `translate(-${SHAPE_OFFSET + BOUNDING_BOX_OFFSET}, -${SHAPE_OFFSET + BOUNDING_BOX_OFFSET})`)

    // put both inside of a shared container
    let el = newContainer()
    el.appendChild(shape)
    el.appendChild(boundingBox)

    // this system is capable of several other transforms
    // and local animations. They have been disabled for now
    // but we can consider adding again later, if needed
    // nest this inside of attrs, if any
    ;[
      // ['skew-x', 'wiggle-x'],
      // ['skew-y', 'wiggle-y'],
      // ['size', 'scale'],
      ['rotate', 'spin'],
    ].forEach(([prop, modifier]) => {
      // if not active, there's nothing to do
      if (!attrs || !attrs[prop]) {
        return
      }
    
      // determine the class to apply
      let key = prop
      let value = attrs[prop]
    
      // // if the modifier is active, use it instead
      // if (attrs[modifier]) {
      //   key = modifier
      // }
    
      // wrap with the attribute layer
      const wrapper = newContainer()
      wrapper.setAttribute('class', `blueprints--${key}--${value}`)
      wrapper.appendChild(el)
    
      // copy the wrapper up a level
      el = wrapper
    })

    // put everything inside of a translate container that can be used
    // for moving the object around with the pointer
    const translate = newContainer()
    translate.setAttribute('id', `translate--${id}`)
    translate.appendChild(el)
    el = translate

    // add the actual contents
    this.el.appendChild(el)

    // update the main el
    this.el.style.color = hex
    this.el.setAttribute('style', `color:${hex};--image-color:${hex}`)
    this.setPosition(x, y)
  }

  setPosition(x, y) {
    this.el.setAttribute('transform', `translate(${x * SHAPE_SIZE}, ${y * SHAPE_SIZE})`)
  }
}
