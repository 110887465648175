import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import { googleClassroomAddon_main } from '@templates/student'
import {
  googleClassroomAddonLogin,
  GOOGLE_CLASSROOM_ADDON_LOGIN_SUCCESS_HANDLER,
  getTargetOrigin,
} from '../helpers';
import GoogleClassroomAddonModel from '../model';
import Alert from '../../global/views/alert';

export default Backbone.View.extend({

  template: googleClassroomAddon_main,

  modelClass: GoogleClassroomAddonModel,

  events: {
    'click .js-google-login': 'googleAuth',
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);
  },

  googleAuth: function() {
    this.setupHandleGoogleClassroomAddonLogin()

    googleClassroomAddonLogin('student')

    return false
  },

  setupHandleGoogleClassroomAddonLogin: function() {
    const urlParams = new URLSearchParams(window.location.search)
    window.localStorage.setItem('googleClassroomAddonData', JSON.stringify(Object.fromEntries(urlParams.entries())))
    window.addEventListener('message', this.messageHandler.bind(this))
  },

  messageHandler: async function(event) {
    if (!getTargetOrigin(event)) {
      return
    }
    if (event.data.type === GOOGLE_CLASSROOM_ADDON_LOGIN_SUCCESS_HANDLER) {
      try {
        await this.handleLoginSuccess(event.data.payload)
      } catch (error) {
        new Alert({
          title: 'shared.oops_text'.t(),
          text: error
        })
      }
    }
  },

  handleLoginSuccess: async function(data) {
    const url = '/student/lessons'

    Registry.get('student').loginWithData(data);

    const auth = Registry.get('auth')
    auth.token(data.token)

    await this.model.fetch();

    const { lessonId, name } = JSON.parse(window.localStorage.getItem('googleClassroomAddonData'))
    window.location.href = `${url}/${lessonId}/${name}`;
  },
})
