import Backbone from 'backbone'
import Registry from '@registry'
import $ from 'jquery'
import Alert from '../../global/views/alert';
import Velocity from 'velocity-animate'
import { account_join_code } from '@templates/student'

export default Backbone.View.extend({

  template: account_join_code,

  formSelector: 'form',

  keyboardId: 0,

  initialize: function() {
    this.user = Registry.get('student');
  },

  serialize: function() {
    return {
      user: Registry.get('student').toJSON()
    };
  },

  submitCallback: function(data) {
    const user = Registry.get('student'),
      joinCodeEle = $('.js-joincode')

    user.set(data);

    Velocity(joinCodeEle, 'slideUp', () => joinCodeEle.parent().remove());

    new Alert({
      title: 'shared.success_text'.t(),
      text: 'account.self_join_pending_success'.t(),
      ok: 'shared.ok_text'.t(),
      cancel: '',
      closeButton: false
    }).show().on('ok', function(){
      // If the new class has a different product_id, we need to change it
      if(data && data.section && data.section.product_id !== user.getSetting('product_id')) {
        user.setSettings({ product_id: data.section.product_id });
        window.location.reload()
      }
    })
  }
})
