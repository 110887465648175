import ScreenClickDrag from './screen_click_drag';
import $ from 'jquery'
import { shuffle, trim } from 'lodash-es'
import { lesson_screen_drag_match_pairs, } from '@templates/student'

import { includeSoundEffects, includeVisualEffects } from '../common';


export default ScreenClickDrag.extend({

  lessonTemplate: lesson_screen_drag_match_pairs,

  sound: includeSoundEffects(),
  effects: includeVisualEffects(),

  // keeps the svg container in sync with the workspace
  syncConnectionsContainer() {
    const [ container ] = this.container
    const [ connections ] = this.connections
    const { width, height } = container.getBoundingClientRect()
    connections.setAttribute('viewBox', `0 0 ${width} ${height}`)
  },

  setup() {
    // read the lesson data
    this.lessonContent = this.parseLesson()

    // tracking resolved shapes
    this.resolved = [ ]
  },

  build() {
    this.container = this.$el.find('.match-pairs')
    this.connections = this.$el.find('.match-pairs--connections')

    // sync initial size
    this.syncConnectionsContainer()
    // window.addEventListener('resize', this.syncConnectionsContainer)

    this.$el.find('.match-pairs--item')
      //  when starting a drag over an item
      .on('dragstart', this.onBeginConnection.bind(this))
      // when releasing over an item, check for connections
      .on('pointerup', this.onResolveConnection.bind(this))

    this.$el
      // when moving the pointer, update the active connection, if any
      .on('pointermove', this.onUpdateActiveConnection.bind(this))

    // when releasing, always clear the connection
    $(window).on('pointerup', this.onCancelConnection.bind(this))
  },

  checkForLessonComplete() {
    if (this.resolved.length === this.lessonContent.left.length) {
      this.completeScreen()
    }
  },

  // finds an event position relative to the container
  getEventPosition(event) {
    const { container } = this
    const { top, left } = container.offset()
    let { pageX: x, pageY: y } = event
    x -= left
    y -= top
    return { x, y }
  },

  getShapeData(target) {
    const el = $(target).closest('.match-pairs--item')
    const side = el.data('side')
    const shape = el.data('shape')
    return { side, shape }
  },

  // updates the current connection line, if any
  onUpdateActiveConnection(event) {
    const { connection } = this
    if (connection) {
      const { x, y } = this.getEventPosition(event)
      connection.x = x
      connection.y = y
    }
  },

  // starts a new connection attempt
  onBeginConnection(event) {
    event.preventDefault?.()
    event.stopPropagation?.()

    // add the connection
    this.origin = this.getEventPosition(event)
    const { shape, side } = this.getShapeData(event.target)

    // if this has already been done, ignore it
    if (this.resolved.includes(shape)) {
      return
    }

    //save the connection
    this.connection = new Connector({ shape, side, ...this.origin })
    this.connections.append(this.connection.el)
  },

  // tries to connect to another point
  onResolveConnection(event) {
    const { shape, side } = this.getShapeData(event.target)

    // not already been done and successful
    if (!this.resolved.includes(shape) && this.connection?.resolve({ shape, side })) {
      this.connection = null
      this.resolved.push(shape)
      this.sound.play('pop')

      this.effects.celebrate(event.target)

      this.checkForLessonComplete()
    }
    else {
      this.sound.play('error')
    }
  },

  // cancel the active connection, if any
  onCancelConnection(event) {
    this.connection?.dispose()
    this.connection = null
  },

  serialize() {
    const data = ScreenClickDrag.prototype.serialize.call(this)
    const { left, right } = this.lessonContent
    return { ...data, left, right, direction: 'horizontal' }
  },

  parseLesson() {
    const content = this.screen.get('content')
    const [top, bottom] = content.split('\n')
    const randomize = /random/.test(bottom) || trim(bottom) === ''
    let left = top.split(/,/g).map(trim)
    let right = [...(randomize ? left : bottom.split(/,/g).map(trim))]
    let usingText

    // finalize each type
    ;[left, right].forEach(side => side.forEach((value, i) => {
      // if this contains a dot, then assume it's a localized
      // string and update it now
      const type = /\./.test(value) ? 'text' : 'image'
      const isText = type === 'text'
      usingText = usingText || isText
      side[i] = {
        index: i,
        value: isText ? value.t() : value,
        type
      }
    }))

    // when one side is text, always shuffle
    // NOTE: if we want this to be optional, configuration will be required
    if (usingText) {
      right = shuffle(right)
      left = shuffle(left)
    }
    // if order is the same, shuffle again
    else if (randomize && needsShuffle(left, right)) {
      right = shuffle(left)
    }

    return { left, right }
  }

})

// shuffle if the order is more than 50% the same as the top
function needsShuffle(left, right) {
  return left.filter((_, i) => left[i].value === right[i].value).length > left.length * 0.5
}

// simplify working with the connector lines
// jQuery seems to mess up SVG manipulation, so we do direct element
// manipulation in this case. This might not be needed - I should
// go back and review this more later
class Connector {
  constructor ({ side, shape, x, y }) {
    this.side = side
    this.shape = shape

    // create the element
    const el = this.el = document.createElementNS("http://www.w3.org/2000/svg",'line')
    el.setAttribute('x1', x)
    el.setAttribute('y1', y)
    el.setAttribute('x2', x)
    el.setAttribute('y2', y)
    el.setAttribute('class', 'match-pairs--connection')
  }

  set x(value) {
    this.el.setAttribute('x2', value)
  }

  set y(value) {
    this.el.setAttribute('y2', value)
  }

  resolve({ side, shape }) {
    const match = this.shape === shape && this.side !== side

    // mark as success
    if (match) {
      this.el.classList.add('success')
      $(`[data-shape='${shape}']`).addClass('matched')
    }

    return match
  }

  dispose() {
    this.el.remove()
  }
}

