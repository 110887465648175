import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import PanelView from './panel'
import AdButtonsView from '../../global/views/ad_buttons'
import ProblemKeysView from './problem_keys'
import AssignmentsView from './assignments'
import GuestBannerView from '../../global/views/guest_banner'
import moment from 'moment'
import Velocity from 'velocity-animate'
import { sortBy, each, find, filter, isEmpty } from 'lodash-es'
import { lessons_main } from '@templates/student'

export default Backbone.View.extend({

  events: {
    'click div.js-tab': 'showUnit',
    'change select.js-tab': 'showUnit',
    'click .js-start': 'goToLesson',
    'keydown .js-tab': function(e) {
      if(e.which === 13 && this.$(document.activeElement).hasClass("js-tab")) {
        this.showUnit(e);
      }
    },
    'keydown .js-start': 'escapePanel'
  },

  template: lessons_main,

  activeTab: '',

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    if(AdView.canShowAds()) {
      AdView.initAds('dashboard');
      // grab all the ads and assign them to the right divs
      each(AdView.getAds(), (ad, size) => {
        if(!ad) { return; }
        this.addChild('.js-'+size+'-ad', ad);
      });
    }

    this.assignments = Registry.get('assignments');

    var adButtonsView = new AdButtonsView();
    this.addChild('.js-ad-buttons', adButtonsView, false);

    this.views.panel = new PanelView({ activeTab: this.activeTab });
    this.addChild('.js-panel-item', this.views.panel, true);

    this.views.problemKeys = new ProblemKeysView();
    this.addChild('.js-panel-item', this.views.problemKeys, true);

    this.views.assignments = new AssignmentsView({ assignments: this.assignments });
    this.addChild('.js-panel-item', this.views.assignments, true);

    // Always show assignments when present
    if(this.assignments.length > 0) {
      this.views.panel.$el.addClass('hide');
    } else {
      this.views.assignments.$el.addClass('hide');
    }

    this.listenTo(this.assignments, 'remove', function() {
      if(this.assignments.length > 0) {
        this.$('.js-assignments-jewel span').html((this.assignments.length > 99 ? '99+' : this.assignments.length));
      } else {
        this.$('.js-assignments-jewel').fastHide();
      }
      this.views.assignments.render();
    }.bind(this));

    if(!Registry.get('loggedIn')) {
      this.guestBannerView = new GuestBannerView({ page: 'lessons' });
      this.addChild('.js-guest-banner', this.guestBannerView);
    }
  },

  serialize: function() {
    var userLessons = Registry.get('userLessons'),
      user = Registry.get('student'),
      lessons = Registry.get('lessons'),
      units = Registry.get('units'),
      filteredUnits = units.toJSON().filter(function(unit){
        if(unit.type === 'test') { return false; }
        if(lessons.where({unit_id: unit.unit_id}).length === 0) { return false; }

        return true;
      }),
      lastTypedLesson = sortBy(userLessons.toJSON()
        .filter(function(row){
          var type;
          try {type = units.get(lessons.get(row.lesson_id).get('unit_id')).get('type');} catch(e){return;}    // get the unit type
          if(type === 'lesson' || type === 'custom') { return lessons.get(row.lesson_id); } }), // if its the right type of lesson show it
      function(row){return -row.updated_at;})[0], // last lesson typed so we know which unit they are currently on
      currentUnit = (this.assignments.length > 0 ? 'assignments' : (lastTypedLesson && lessons.get(lastTypedLesson.lesson_id) ? lessons.get(lastTypedLesson.lesson_id).get('unit_id') : filteredUnits[0].unit_id)),
      forceUnit = window.location.hash.replace('#','') || (this.assignments.length > 0 ? undefined : user.get('_currentUnit')),
      problemKeys = Registry.get('userLettersTyped').topProblemKeys(4).filter((val) => val.percent > 0).map((val) => val.id.toUpperCase());

    // hardcode some SEO level landing pages
    if(window.location.pathname.match('digital-literacy')) {
      forceUnit = 44;
    } else if(window.location.pathname.match('coding-curriculum')) {
      forceUnit = 48;
    } else if(window.location.pathname.match('conocimiento-digital')) {
      forceUnit = 49;
    } else if(window.location.pathname.match('estudios-de-programacion')) {
      forceUnit = 51;
    } else if(window.location.pathname.match('aprendizagem-digital')) {
      forceUnit = 58;
    } else if(window.location.pathname.match('currículo-de-programacao')) {
      forceUnit = 61;
    }

    if(forceUnit && units.get(parseInt(forceUnit))) {
      currentUnit = parseInt(forceUnit);
    }

    let msg = user.getSetting('message'),
      message = ''
    if(!isEmpty(msg) && moment(msg.expiration).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) {
      // Old messages have a text attribute, new messages contain a message attribute
      message = (msg.text || msg.message).stripHTML()
    }

    // Always point to the assignments if there are pending assignments
    this.activeTab = currentUnit;
    this.views.panel.activeTab = (currentUnit === 'assignments' ? filteredUnits[0].unit_id : currentUnit);

    return {
      lodash: { find, filter },
      loggedIn: Registry.get('loggedIn'),
      message: message,
      ads: AdView.canShowAds(),
      adNetwork: window.__adNetwork,
      pageWidth: $(window).width(),
      currentUnit: currentUnit,
      assignmentCount: this.assignments.length,
      units: filteredUnits,
      problemKeysHasAIEnabled: user.hasOption('problemkeysai'),
      lessons: lessons.toJSON(),
      user: user.toJSON(),
      isPremium: user.isPremium(),
      productGrade: user.getCurriculumGrade(),
      problemKeys: problemKeys,
      scoreboard: Registry.get('student').hasOption('scoreboard')
    };
  },

  /**
   * Switch to a different unit
   * @param e
   * @returns {boolean}
   */
  showUnit: function(e) {
    var target = (e ? $(e.currentTarget) : $('div.js-tab')[1]),
      id = (target.is('select')) ? target.val() : target.data('id'),
      selectTarget = this.$('select.js-tab'),
      tabTarget = this.$('div.js-tab[data-id=' + id +']');

    if (target.hasClass('is-active')) {
      return true;
    } else {
      this.$('.path.is-active').removeClass('is-active').attr('aria-selected', 'false');

      Registry.get('student').set({_currentUnit: id});

      this.activeTab = id;

      // show new lessons
      tabTarget.addClass('is-active').attr('aria-selected', 'true');
      tabTarget.blur(); // removes 'focus' on click
      selectTarget.val(id);

      if(this.activeTab === 'problem-keys') {
        this.views.problemKeys.$el.removeClass('hide');
        this.views.assignments.$el.addClass('hide');
        this.views.panel.$el.addClass('hide');
      } else if(this.activeTab === 'assignments') {
        this.views.assignments.$el.removeClass('hide');
        this.views.problemKeys.$el.addClass('hide');
        this.views.panel.$el.addClass('hide');
      } else {
        this.views.problemKeys.$el.addClass('hide');
        this.views.assignments.$el.addClass('hide');
        this.views.panel.$el.removeClass('hide');
        this.views.panel.activeTab = parseInt(this.activeTab);
        this.views.panel.render();
      }

      var scrollTop = $(window).scrollTop(),
        dashboardTop = this.$('.dashboard').offset().top;
      if(scrollTop - dashboardTop > 20) {
        Velocity($('html'), 'scroll', {offset: dashboardTop - 45});
      }

      // Focus on the first lesson's start button
      if(e.type === 'keydown') {
        this.$('a.lesson-btn.js-start[tabindex=0]').focus();
      }
    }
  },

  goToLesson: function(e) {
    var id = $(e.currentTarget).data('id'),
      userLesson = Registry.get('userLessons').get(id),
      lessons = Registry.get('lessons'),
      lesson = lessons.get(id);

    if(userLesson && !userLesson.get('_screenStats') && lesson.get('content_type') !== 'adventure') {
      userLesson.unset('_state');
    }
    return true;
  },

  escapePanel: function(e) {
    if(e.which === 27) {
      this.$('.path.is-active').focus();
    }
  }

})
