/**
 * Shared global helpers between all apps
 */
import { each, isArray, findIndex, isObject } from 'lodash-es'

export default (templates) => {
  window.FORM = window.FORM || {};

  window.FORM.textGroup = function(
    name,
    value,
    label,
    params,
    classes,
    first,
    disabled,
    groupClasses,
    required
  ) {
    params = params || {};

    if (required) {
      label = label + ' *';
    }
  
    return templates.global_form_helper_group({
      label: label || name.replace(/_/g, ' ').ucWords(),
      name: name,
      element: window.FORM.text(name, value, params, classes, required),
      first: first,
      disabled: disabled,
      groupClasses: groupClasses || {},
      required: required
    });
  };

  window.FORM.checkboxGroup = function(
    name,
    value,
    label,
    params,
    classes,
    first,
    disabled,
    groupClasses
  ) {
    params = params || {};
    return templates.global_form_helper_group({
      label: label || name.replace(/_/g, ' ').ucWords(),
      name: name,
      element: window.FORM.checkbox(name, value, params, classes),
      first: first,
      disabled: disabled,
      groupClasses: groupClasses || {}
    });
  };

  window.FORM.fileGroup = function(
    name,
    value,
    label,
    params,
    classes,
    first,
    disabled,
    groupClasses
  ) {
    params = params || {};
    return templates.global_form_helper_group({
      label: label || name.replace(/_/g, ' ').ucWords(),
      name: name,
      element: window.FORM.file(name, value, params, classes),
      first: first,
      disabled: disabled,
      groupClasses: groupClasses || {}
    });
  };

  window.FORM.textareaGroup = function(
    name,
    value,
    label,
    params,
    classes,
    first,
    disabled,
    groupClasses
  ) {
    params = params || {};
    return templates.global_form_helper_group({
      label: label || name.replace(/_/g, ' ').ucWords(),
      name: name,
      element: window.FORM.textarea(name, value, params, classes),
      first: first,
      disabled: disabled,
      groupClasses: groupClasses || {}
    });
  };

  window.FORM.passwordGroup = function(
    name,
    value,
    label,
    params,
    classes,
    first,
    disabled,
    groupClasses,
    showToggle,
    required
  ) {
    params = params || {};

    if (required) {
      label = label + ' *';
    }

    return templates.global_form_helper_group({
      label: label || name.replace('_', ' ').ucWords(),
      name: name,
      showToggle: showToggle || {},
      element: window.FORM.password(name, value, params, classes, showToggle, required),
      first: first,
      disabled: disabled,
      groupClasses: groupClasses || {},
      required: required
    });
  };

  window.FORM.selectGroup = function(
    name,
    value,
    options,
    label,
    params,
    classes,
    first,
    disabled,
    groupClasses
  ) {
    params = params || {};
    return templates.global_form_helper_group({
      label: label || name.replace('_', ' ').ucWords(),
      name: name,
      first: first,
      disabled: disabled,
      element: window.FORM.select(name, value, options, params, classes),
      groupClasses: groupClasses || {}
    });
  };

  window.FORM.dateGroup = function(
    name,
    value,
    label,
    params,
    classes,
    first,
    disabled,
    groupClasses
  ) {
    params = params || {};
    return templates.global_form_helper_group({
      name: name,
      label: label || name.replace('_', ' ').ucWords(),
      element: window.FORM.date(name, value, params, classes),
      first: first,
      disabled: disabled,
      groupClasses: groupClasses || {}
    });
  };

  window.FORM.hiddenGroup = function(
    name,
    value,
    displayValue,
    label,
    params,
    classes,
    first,
    groupClasses
  ) {
    params = params || {};
    return templates.global_form_helper_group({
      name: name,
      label: label || name.replace(/_/g, ' ').ucWords(),
      element: window.FORM.hidden(name, value, displayValue, params, classes),
      first: first,
      groupClasses: groupClasses || {}
    });
  };

  window.FORM.text = function(name, value, params, classes, required) {
    params = params || {};
    classes = classes || [];
    required = required || false;
    
    return templates.global_form_helper_text({
      lodash: { each },
      name: name,
      value: isObject(value) ? value[name] : value,
      params: params,
      classes: classes,
      required: required
    });
  };

  window.FORM.checkbox = function(name, value, params, classes) {
    params = params || {};
    classes = classes || [];
    value = value || 1;
    return templates.global_form_helper_checkbox({
      lodash: { each },
      name: name,
      value: isObject(value) ? value[name] : value,
      params: params,
      classes: classes
    });
  };

  window.FORM.file = function(name, value, params, classes) {
    params = params || {};
    classes = classes || [];
    return templates.global_form_helper_file({
      lodash: { each },
      name: name,
      value: isObject(value) ? value[name] : value,
      params: params,
      classes: classes
    });
  };

  window.FORM.textarea = function(name, value, params, classes) {
    params = params || {};
    classes = classes || [];
    return templates.global_form_helper_textarea({
      lodash: { each },
      name: name,
      value: isObject(value) ? value[name] : value,
      params: params,
      classes: classes
    });
  };

  window.FORM.password = function(name, value, params, classes, showToggle, required) {
    params = params || {};
    classes = classes || [];
    required = required || false;

    return templates.global_form_helper_password({
      lodash: { each },
      name: name,
      value: isObject(value) ? value[name] : value,
      params: params,
      classes: classes,
      showToggle: showToggle,
      required: required
    });
  };

  window.FORM.select = function(name, value, options, params, classes) {
    params = params || {};
    classes = classes || [];
    return templates.global_form_helper_select({
      lodash: { each, isArray, isObject, findIndex },
      name: name,
      value: isArray(value) ? value : isObject(value) ? [value[name]] : [value], // Value must always be an array so that you can select multiple options
      options: options,
      params: params,
      classes: classes
    });
  };

  window.FORM.date = function(name, value, params, classes) {
    params = params || {};
    classes = classes || [];
    value = isObject(value) ? value[name] : value;
    value = value === '0000-00-00' ? '' : value;
    return templates.global_form_helper_date({
      lodash: { each },
      name: name,
      value: value,
      params: params,
      classes: classes
    });
  };

  window.FORM.hidden = function(name, value, displayValue, params, classes) {
    params = params || {};
    classes = classes || [];
    return templates.global_form_helper_hidden({
      lodash: { each },
      name: name,
      displayValue: displayValue,
      value: isObject(value) ? value[name] : value,
      params: params,
      classes: classes
    });
  };
}
