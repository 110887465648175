import FormModel from '@shared/form_model'

export default FormModel.extend({
  url: null,

  validationRules: {
    confirm_text: {
      required: true,
      equalsValue: 'shared.confirm_text'.t()
    }
  },

  validationMessages: {
    confirm_text: {
      equalsValue: 'shared.confirm_input_invalid'.t()
    }
  }

})
