import FormModel from '@shared/form_model'

export default FormModel.extend({
  url: '/apiv1/student/contact/form',

  validationRules: {
    name: {
      required: true
    },
    email: {
      required: true
    },
    reason: {
      required: true
    },
    message: {
      required: true
    }
  }

})
