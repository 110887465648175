import Backbone from 'backbone'
import Model from '../models/skin'

export default Backbone.Collection.extend({
  model: Model,

  getTotalRanks: function() {
    return this.at(0).getTotalRanks() * this.filter(function(row){ return !row.get('noAvatar'); }).length;
  }
})
