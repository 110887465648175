import { playSound } from '@shared/helpers';
import { times } from 'lodash-es'
import $ from 'jquery'
import CelebrationEffect from '@shared/celebration_effect';

export function includeSoundEffects() {
  const error = new Audio('/dist/student/extras/sounds/error.mp3')
  const pop = new Audio('/dist/student/extras/sounds/pop.mp3')
  pop.volume = 0.3

  // create
  return {
    pop,
    error,

    // general sound player
    play(name) {
      playSound(this[name])
    }
  }
}

export function includeVisualEffects() {
  function getPosition(el) {
    const target = $(el)
    const x = target.offset().left + target.width() / 2
    const y = target.offset().top + target.height() /2
    return { x, y }
  }

  return {
    celebrate: function (el) {
      const { x, y } = getPosition(el)
      CelebrationEffect.explodeSingle({ type: 'custom', x, y, count: 12, angle: 360, spread: 360, speed: 9 });
    }
  }

}

export function starAnimation({ showOverlay } = { }) {
  
  // if there's an overlay to show
  showOverlay?.css({ display: 'flex', opacity: 'unset' })
    .hide()
    .fadeIn()

  // play the stars/screen-completion sound if they have any sounds enabled
  let star1, star2, star3

  const timeBetweenStars = 850,
    playSounds = this.user.hasOption('allowsounds') && (this.user.getSetting('error_sounds') || this.user.getSetting('typing_sounds'))

  try {
    star1 = new Audio('/dist/student/extras/sounds/star1.mp3');
    star1.volume = 0.3;
    star2 = new Audio('/dist/student/extras/sounds/star2.mp3');
    star2.volume = 0.3;
    star3 = new Audio('/dist/student/extras/sounds/star3.mp3');
    star3.volume = 0.3;
  } catch (e) {}

  $('body').append('<canvas class="celebration js-star-effect" style="z-index: 11;"></canvas>')

  window.requestTimeout(() => { // Wait for screen to render before attempting to add confetti or display stars
    const celebrationEle = $('.js-star-effect');
    celebrationEle.css({
      'top': -celebrationEle.offset().top,
      'left': -celebrationEle.offset().left
    });
    CelebrationEffect.init(celebrationEle[0], {
      screenHeight: $(document).height(),
      screenWidth: $(document).width(),
      colors: this.user.getSkinColors(),
      assets: [
        '/dist/shared/images/particles/star-1.png',
        '/dist/shared/images/particles/star-2.png',
        '/dist/shared/images/particles/star-3.png']
    });

    this.$('.js-star').addClass('star--animate')

    // Play SFX and show Confetti as stars burst into existence
    if (playSounds) {
      times(3, (index) => { // Loop through the number of stars earned by the user
        window.requestTimeout(() => {
          const starEle = this.$('.star--screen-' + (index + 1));
          starEle.addClass('star--floating');
          if (playSounds && eval('star' + (index + 1))) {
            playSound(eval('star' + (index + 1)));
          }
          CelebrationEffect.explodeSingle({
            type: 'custom',
            x: starEle.offset().left + (starEle.width() / 2),
            y: starEle.offset().top + (starEle.height() / 2),
            count: 20,
            angle: 230 + (index * 40),
            spread: 85,
            speed: 10
          });
        }, index * timeBetweenStars);
      })
    }
  }, 0);
}
