import Backbone from 'backbone'
import Scoring from '@shared/scoring'

const Model = Backbone.Model.extend({

  idAttribute: 'user_test_id',

  defaults: {
    lesson_id: 0,
    seconds: 0,
    errors: 0,
    typed: 0,
    completed: 0,
    created_at: 0
  }
});

export default Backbone.Collection.extend({
  model: Model,

  comparator: function(row){
    return -row.get('created_at');
  },

  url: function() {
    return '/apiv1/student/tests';
  },

  getCompiled: function() {
    return Object.values(this.toJSON().reduce(function(memo, row) {
      row.speed = Scoring.speed(row.typed, row.seconds, row.errors);
      if(memo[row.lesson_id]) {
        if(row.created_at > memo[row.lesson_id].created_at) {
          memo[row.lesson_id].typed += parseInt(row.typed);
          memo[row.lesson_id].seconds += parseInt(row.seconds);
          memo[row.lesson_id].errors += parseInt(row.errors);
          memo[row.lesson_id].created_at = row.created_at;
        }
        memo[row.lesson_id].completed += 1;
        memo[row.lesson_id].speed_sum += row.speed;    // for average speed
        memo[row.lesson_id].fastest = Math.max(memo[row.lesson_id].fastest, row.speed);
      } else {
        memo[row.lesson_id] = row;
        memo[row.lesson_id].completed = 1;
        memo[row.lesson_id].created_at = row.created_at;    // bit of a hack, because user_tests don't have updated_at
        memo[row.lesson_id].fastest = row.speed;
        memo[row.lesson_id].speed_sum = row.speed;    // for average speed
      }
      return memo;
    }, {}));
  }
})
