import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import { global_growl_alert, global_growl_alert_content } from '@templates/student'

/**
 * The global popup when achievements are achieved
 */
export default Backbone.View.extend({

  template: global_growl_alert,

  contentTemplate: global_growl_alert_content,
  lastFocusedElement: null,
  focusableElements: null,

  events: {
    'mouseover .js-growlWrap': 'clearTimeout',
    'click .js-close' : 'hide'
  },

  initialize: function() {
    this.user = Registry.get('student');

  this.render();
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    if($('.js-growls').length === 0) { // Only append the growls element if one hasn't already been added
      $('body').append(this.el);
    }
    return this;
  },

  show: function() {
    this.lastFocusedElement = document.activeElement;

    const growlElements = $('.js-growlWrap');

    // Header Count
    this.$('.js-growls').attr('data-count', growlElements.length);

    // After showing, it automatically hides after a bit
    this.clearTimeout();
    this.timeout = setTimeout(this.hide.bind(this), 9000);

    setTimeout(function(){
      $(document).on('keydown.achievements', function(e){
        // esc or enter
        if(e.which === 13 || e.which === 27) {
          this.hide();
        }
      }.bind(this));
    }.bind(this), 500);

    this.focusableElements = growlElements.find('input[type=text]:eq(0),input[type=email]:eq(0), a, button:not(:hidden), input:not(.hidden), textarea, select, details,[tabindex]:not([tabindex="-1"])');
    growlElements.on('keydown', this.trapTabKey.bind(this));
  },

  hide: function() {
    Registry.set('preventKeyboardInput', false);
    $(document).off('keydown.achievements');

    this.$('.js-growlWrap').removeClass('animate--growl').addClass('animate--fadeOut');

    setTimeout(function(){
      this.$('.js-moreCount').hide();
      this.$('.js-growl-content').empty();
    }.bind(this), 250);

    this.clearTimeout();

    this.$('.js-growlWrap').off('keydown', this.trapTabKey.bind(this));
    this.lastFocusedElement.focus();

  },

  clearTimeout: function() {
    clearTimeout(this.timeout);
  },

  trapTabKey: function(e) {
    const $activeModal = this.$('.js-growlWrap');
    if (!$activeModal.length) {
      console.log('No active growl modal found.');
      return;
    }

    if (e.key === 'Tab') {
      this.focusableElements = $activeModal.find('input[type=text]:eq(0),input[type=email]:eq(0), a, button:not(:hidden), input:not(.hidden), textarea, select, details,[tabindex]:not([tabindex="-1"])');
      const firstFocusableElement = this.focusableElements.first().get(0);
      const lastFocusableElement = this.focusableElements.last().get(0);
      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          e.preventDefault();
          lastFocusableElement.focus();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          e.preventDefault();
          firstFocusableElement.focus();
        }
      }
    }
  }

})
