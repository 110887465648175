import Backbone from 'backbone'
import Model from '../models/game'

export default Backbone.Collection.extend({
  model: Model,

  comparator: 'display_order'
})


