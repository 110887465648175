import Backbone from 'backbone'
import Registry from '@shared/registry';
import Model from '../models/signup'
import { assessments_signup } from '@templates/student'

export default Backbone.View.extend({

  section: {},

  formSelector: '#reg-form',

  modelClass: Model,

  events: {
    'click .js-switch': function() { this.trigger('switch'); return false }
  },

  template: assessments_signup,

  submitCallback: function(data) {
    // On modals, this button becomes clickable after form submission and we don't want that
    this.$('.js-submit').addClass('btn--loading').prop('disabled', true)

    Registry.get('student').loginWithData(data)

    window.location.href = '/student/certificacion'
    window.location.reload()
  }
})
