import $ from 'jquery'
import Modal from '@shared/modal'
import Model from '@shared/language_form'
import LanguagesView from '@shared/keyboard_helper_languages'
import KeyboardsView from '@shared/keyboard_helper_keyboards'
import IdentifiedView from '@shared/keyboard_helper_identified'
import { shared_keyboard_helper_modal } from '@templates/shared'
import { filter, find } from 'lodash-es';

export default Modal.extend({
  titleText: 'shared.lesson.keyboard_helper_title_step_languages'.t(),

  template: shared_keyboard_helper_modal,

  modelClass: Model,

  formSelector: 'form',

  okText: '',
  cancelText: '',
  customFooter: true,

  width: 'm',

  step: 'languages',

  events: {
    'click .js-next': 'clickNext',
    'click .js-back': 'clickBack',
    'keydown .js-next': 'handleKeydown'
  },

  initialize: function(options) {
    Modal.prototype.initialize.apply(this, arguments);
    var parts = window.location.pathname.split('/'),
      keyboardLanguage = find(FTWGLOBALS('keyboards'), { keyboard_id: parseInt(options.currentKeyboardId) });

    if(parts[1] === 'teacher')
      this.model.url = null;

    this.languagesPanel = new LanguagesView({ selectedLanguageId: keyboardLanguage ? keyboardLanguage.language : 'en' });
    this.listenTo(this.languagesPanel, 'next', this.showKeyboardsPanel);

    this.keyboardsPanel = new KeyboardsView();
    this.listenTo(this.keyboardsPanel, 'back', this.showLanguagesPanel);
    this.listenTo(this.keyboardsPanel, 'next', this.showIdentifiedPanel);

    this.identifiedPanel = new IdentifiedView({ user: this.user });
    this.listenTo(this.identifiedPanel, 'back', this.showKeyboardsPanel);
    this.listenTo(this.identifiedPanel, 'submit', this.updateKeyboard);
  },

  render: function() {
    Modal.prototype.render.apply(this, arguments);

    this.switchPanel('languages');

    return this;
  },

  serialize: function() {
    return {
      keyboardId: this.identifiedPanel.selectedKeyboardId
    };
  },

  handleKeydown: function(e) {
    if (e.which === 13) {
      this.clickNext(e);
    }
  },

  switchPanel: function(step) {
    this.step = step;
    this.$('.modal-title').html(('shared.lesson.keyboard_helper_title_step_' + step).t());

    // .detach will remove the elements from the DOM, but keeps all jquery data (event handlers)
    // This is useful because we remove and reinsert the views
    this.$('.js-panel-content').detach();
    this.$('.js-panel').append(this[step + 'Panel'].render().el);
  },

  showLanguagesPanel: function() {
    this.$('.js-back').html('shared.cancel_text'.t());
    this.$('.js-next').html('shared.next'.t());

    this.switchPanel('languages');
  },

  showKeyboardsPanel: function() {
    // The Keyboards panel can be skipped if there's only one viable keyboard
    var keyboard = filter(FTWGLOBALS('keyboards'), { language: this.languagesPanel.selectedLanguageId }, this);
    if(keyboard.length === 1) {
      this.keyboardsPanel.selectedKeyboardId = keyboard[0].keyboard_id;
      // Accounts for navigating forward and backwards between panels
      if(this.step === 'languages') {
        this.showIdentifiedPanel();
      } else {
        this.showLanguagesPanel();
      }
    } else {
      this.keyboardsPanel.selectedLanguageId = this.languagesPanel.selectedLanguageId;
      this.$('.js-back').html('shared.back_text'.t());
      this.$('.js-next').html('shared.next'.t());

      this.switchPanel('keyboards');
    }
  },

  showIdentifiedPanel: function() {
    this.identifiedPanel.selectedKeyboardId = this.keyboardsPanel.selectedKeyboardId;
    this.$('input[name=keyboard_id]').val(this.keyboardsPanel.selectedKeyboardId);
    this.$('.js-back').html('shared.back_text'.t());
    if(this.user && this.user.get('in_class')) {
      this.$('.js-next').html('shared.ok_text'.t());
    } else {
      this.$('.js-next').html('shared.lesson.update_keyboard_layout'.t());
    }

    this.switchPanel('identified');
  },

  captureKeys: function(e) {
    if(e.which === 27) {
      this.cancel();
      return false;
    }
  },

  clickNext: function() {
    if(this.step === 'languages') {
      this.showKeyboardsPanel();
    } else if(this.step === 'keyboards') {
      this.showIdentifiedPanel();
    } else if(this.step === 'identified') {
      if(this.user && this.user.get('in_class')) {
        this.cancel();
      } else {
        this.$('.js-submit').click();
      }
    }
    this.$('.js-next').blur();
  },

  clickBack: function() {
    if(this.step === 'languages') {
      this.cancel();
    } else if(this.step === 'keyboards') {
      this.showLanguagesPanel();
    } else if(this.step === 'identified') {
      this.showKeyboardsPanel();
    }
    this.$('.js-back').blur();
  }
})
