import Backbone from 'backbone'
import $ from 'jquery'
import { assessments_verificaciones } from '@templates/student'

export default Backbone.View.extend({

  template: assessments_verificaciones,

  events: {
    'click .js-print-certificate': 'printCertificate',
    'click .js-search': 'search'
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments)

    if (this.userId) {
      window.setTimeout(() => {
          this.search()
      }, 0)
    }
  },

  serialize: function() {
    return {
      userId: this.userId
    }
  },

  search: function() {
    const userId = $('input[name=user_id]').val()

    if(userId) {
      $.ajax({
        url: `/apiv1/student/certificacion/${userId}/verify`,
        method: 'get'
      }).fail(() => {
        this.userId = null
        $('.js-certificate').fastHide()
        $('.js-not-found').fastShow()
      }).done(() => {
        this.userId = userId
        $('.js-certificate').fastShow()
        $('.js-not-found').fastHide()
      })
    }

    return false
  },

  printCertificate: function() {
    window.open('/apiv1/student/certificacion/' + this.userId + '/certificate?language=es');

    return false;
  },
})
