import Backbone from 'backbone'
import $ from 'jquery'
import { sortBy, filter, take, map, times } from 'lodash-es'
import { postJSON } from '@shared/helpers'

const Model = Backbone.Model.extend({
  defaults: {
    id: '',
    typed: 0,
    errors: 0
  }
});

export default Backbone.Collection.extend({
  model: Model,

  trackCorrect: function(letter) {
    if (!this.valid(letter)) {
      return;
    }

    letter = letter.toLowerCase();
    var model = this.get(letter);
    if (model) {
      model.inc({typed:1});
    } else {
      this.add({id: letter, typed: 1});
    }
  },

  /**
   * Track if they typed a letter
   * @param letter The letter they should have typed
   */
  trackError: function(letter) {
    if (this.valid(letter)) {
      letter = letter.toLowerCase();
      var model = this.get(letter);
      if (model) {
        model.inc({errors:1});
      } else {
        this.add({id: letter, errors: 1});
      }
    }

    // Also tracks the correct letter for proper stat tracking
    this.trackCorrect(letter);
  },

  valid: function(letter) {
    const englishCharacters = 'A-Za-z';
    const characters = new RegExp(`[${englishCharacters}]`);

    if (!letter) {
      return;
    }
    if (letter.length > 1) { return false; }
    if ($.trim(letter) === '') { return false; }
    return letter.match(characters);
  },

  topProblemKeys: function(limit) {
    limit = limit || 4;

    const keyScores = this.map((model) => ({
        id: model.id,
        percent: model.attributes.typed ? model.attributes.errors / model.attributes.typed : 0
      }))

    // descending sort by percent, then filter out rows without errors, then return 'limit' amount
    return take(sortBy(keyScores, (row) => -row.percent), limit)
  },

  typedLetters: function() {
    return map(filter(this.toJSON(), (row) => row.typed > 0), 'id')
  },

  allLetters: function() {
    return map(this.toJSON(), 'id')
  },

  /**
   * Merge letters_typed data into this collection
   * @param data
   */
  merge: function(data) {
    var old;
    data.forEach(function(row){
      old = this.get(row.id);
      if(old) {
        old.inc({
          typed: row.typed,
          errors: row.errors
        });
      } else {
        this.add(row);
      }
    }.bind(this));
  },

  clear: function() {
    this.reset();
    return postJSON('/apiv1/student/user/reset-problem-keys');
  }

})
