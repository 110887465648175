import Registry from '@registry'
import $ from 'jquery'
import LayoutBasic from '../../global/views/layout_basic'
import Dictation from '../../lesson/classes/dictation'
import LessonsCollection from '../../global/collections/lessons'
import SkinsCollection from '../../global/collections/skins'
import Notice from '@shared/notice'
import LanguageModal from '../../global/views/language_modal'
import UnitsCollection from '../../global/collections/units'
import { isMac } from '@shared/helpers'
import AuthModal from '../../global/views/auth_modal';
import SignupView from './signup';
import { assessments_layout } from '@templates/student'

export default LayoutBasic.extend({

  template: assessments_layout,

  events: {
    'click .js-logout': 'logOut',
    'click .js-language': 'showLanguageModal',
    'click .js-dictation': 'toggleDictation',
    'click .js-layout-signup': 'signup',
    'click .js-layout-login': 'login'
  },

  initialize: function() {
    LayoutBasic.prototype.initialize.apply(this, arguments);

    this.user = Registry.get('student');
    this.userLettersTyped = Registry.get('userLettersTyped');
    this.userActivity = Registry.get('userActivity');

    this.skins = new SkinsCollection(FTWGLOBALS('skins'));

    this.lessons = new LessonsCollection(FTWGLOBALS('lessons'));
    this.units = new UnitsCollection(FTWGLOBALS('units'));

    // Hide the "Get Plus" after user upgrades their account
    this.listenTo(this.user, 'change:licensed_at', () => {
      this.$('.header-callout').fastHide()
    })

    $(document).keydown(function(e) { // Used to turn dictation on and off from any screen
      if((e.metaKey || e.ctrlKey) && e.shiftKey && e.key === 'd') {
        this.toggleDictation();

        return false;
      }
    }.bind(this));
  },

  serialize: function() {
    const user = Registry.get('student');

    return {
      loggedIn: Registry.get('loggedIn'),
      user: this.user.toJSON(),
      osType: isMac() ? 'mac' : 'win',
      language: Registry.get('languages').get(FTWGLOBALS('language')).toJSON(),
    };

  },

  logOut: function () {
    Registry.get('student').logOut();
    window.location.href = __url('/student/certificacion');
    return false;
  },

  signup: function(e) {
    new AuthModal({ authType: 'signup', isAssessment: true, signupView: new SignupView() }).open()
  },

  login: function(e) {
    new AuthModal({ authType: 'login', isAssessment: true, signupView: new SignupView() }).open()
  },

  showLanguageModal: function() {
    new LanguageModal({
      user: this.user
    }).open().done((data) => {
      data.keyboard_id = parseInt(data.keyboard_id);
      // Will set language and product_id
      this.user.setSettings(data);

      var url = (window.location.pathname + window.location.hash).replace(new RegExp('^/'+FTWGLOBALS('language')), '');
      window.location.href = (data.language === FTWGLOBALS('defaultLanguage')) ? url : ('/'+data.language+url);
    })

    return false;
  },

  toggleDictation: function() {
    this.user.setSettings({ dictation: !this.user.getSetting('dictation') });
    new Notice({ text: 'global.dictation_confirmation'.t({dictationStatus: this.user.getSetting('dictation') ? 'shared.switch_label_on'.t() : 'shared.switch_label_off'.t()}) }).show(this.button);

    var dictation = new Dictation();
    if(this.user.getSetting('dictation')) {
      dictation.resume();
    } else {
      dictation.turnOff();
    }
  }
})
