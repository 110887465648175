import Backbone from 'backbone'
import { password_main } from '@templates/student'

export default Backbone.View.extend({

  formSelector: 'form',

  template: password_main,

  successNotice: 'shared.password.success_notice'.t(),

  submitCallback: function() {
    Backbone.View.prototype.submitCallback.apply(this, arguments);
    window.setTimeout(function(){
      window.location.href = __url('/student/login');
    }, 3000);
  },

  serialize: function() {
    return {
      data: this.model.toJSON()
    };
  }

})
