import $ from 'jquery'
import Backbone from 'backbone'
import { lesson_screen_concept_map_overview, lesson_component_concept_map_tier } from '@templates/student'
import Registry from '@registry'
import { bindSelectors } from '@shared/utils';

export default Backbone.View.extend({

  template: lesson_screen_concept_map_overview,

  selectors: {
    $overview: '.concept-map--overview',
    $levels: '.concept-map--overview--level',
    $cycle: '.concept-map--overview--cycle',
    $tiers: '.concept-map--overview--tier'
  },

  initialize() {
    Backbone.View.prototype.initialize.apply(this, arguments)
    bindSelectors(this)
  },

  serialize() {
    const { lesson, layoutType, rootNodes } = this
    const name = lesson.get('name')
    const settings = lesson.get('settings')
    const background = settings?.concept_map_background
    const theme = settings?.concept_map_theme

    // construct the concept map (tree, cyclical, linear)
    const options = { type: layoutType, flat: layoutType !== 'tree' }
    const { locked, children } = createConceptMap(this, options, rootNodes)

    // the generated data
    this.data = {
      type: layoutType,
      name,
      children: children.html(),
      locked,
      background,
      theme
    }

    return this.data
  },

  render() {
    Backbone.View.prototype.render.apply(this, arguments);

    // some elements need to be rendered before they can
    // be appropriately measured and displayed - kick off
    // a check to see when these elements are available
    setTimeout(() => {
      this.tryFinalizeLayout()
    }, 500)
  },

  tryFinalizeLayout() {
    const success = this.finalizeLayout()
    if (!success) {
      return setTimeout(this.tryFinalizeLayout.bind(this), 500)
    }

    const data = { }

    // when finished, the next step is to submit
    if (this.lessonCompleted) {
      data.submit = true
    }
    // if it's not a tree, we know which node needs
    // to be finished next
    else if (this.data.type !== 'tree') {

      // find the next screen to use
      for (let i = 0; i < this.screens.length; i++) {
        const screen = this.screens.at(i)
        const userScreen = this.userLessonScreens.find(userScreen => userScreen.get('lesson_screen_id') === screen.id)
        if (!userScreen?.get('completed')) {
          data.node = screen.get('settings')?.cm_node || screen.get('title')
          break
        }
      }
    }

    // let dictation explain the overview
    // we add a fake screen object here to pass the 'canDictate' option since
    // the overview itself is not a screen with a dictation type
    this.dictation.screen = new Backbone.Model({ dictation_type: 'any' })
    this.dictation.speakConceptMapOverviewInstructions(data)

    // mark as ready to display
    this.$el.addClass('layout-ready')
  },

  finalizeLayout() {
    const { layoutType } = this

    switch (layoutType) {
      case 'tree':
        return finalizeTreeLayout(this, this.$el)
      case 'cycle':
        return finalizeCyclicalLayout(this, this.$el)
      case 'linear':
        return true
      default:
        return false
    }

  }

})

function getUserLessonScreen(lessonScreenId) {
  return Registry.get('userLessonScreens').where({ 'lesson_screen_id': lessonScreenId })?.[0]
}

// builds the nodes and structure for a concept map and
// identifies locked nodes
function createConceptMap(instance, options, nodes, depth = 0) {
  const isTree = !['cycle', 'linear'].includes(options.type)
  let locked = false
  let previous

  const levels = nodes.map((node, i) => {
    // for each node, find any child nodes
    const id = node.get('lesson_screen_id')
    const parentIndex = node.get('settings')?.cm_parent
    const selfIndex = instance.screens.findIndex(find => find.get('lesson_screen_id') === id).toString()
    const parent = instance.screens.at(parentIndex?.length ? (0 | parentIndex) : -1)
    const parentLessonId = parent?.get('lesson_screen_id')
    const self = getUserLessonScreen(id)
    const record = getUserLessonScreen(parentLessonId)
    const done = !!self?.get('completed')
    const title = node.get('settings')?.cm_node || node.get('title')
    const type = node.get('screen_type')
    const below = (options.flat || !selfIndex?.length) ? [ ] : instance.screens.filter(item => item.get('settings')?.cm_parent === selfIndex)
    const generated = below.length ? createConceptMap(instance, options, below, depth + 1) : null
    const unavailable = depth > 0 && !record
    const isRoot = isTree && depth === 0

    // manage the lock status
    let lockSelf
    if (isTree) {
      lockSelf = !record?.get('completed')
    }
    else {
      lockSelf = previous && !previous.done
    }

    // if not finished, mark the top level as not done
    if (lockSelf || unavailable) {
      locked = true
    }

    // if this screen is already done, then it's not locked
    if (done || isRoot) {
      locked = false
    }

    // save the current record as the previous so it
    // can be checked in some cases
    previous = {
      id,
      record,
      done
    }

    return { title, screenId: id, children: generated?.children, done, locked, type }
  })

  const top = !!depth
  const bottom = levels.length === 0

  // for non-tree versions, don't lock the top level
  if (!isTree) {
    locked = false
  }

  const el = lesson_component_concept_map_tier({ levels, top, bottom, locked })
  return { children: $(el), locked }
}

// applies positioning for a cyclical layout
function finalizeCyclicalLayout(instance) {
  const { $overview, $levels, $cycle } = instance.selectors

  // center origin
  const { width, height } = $overview[0].getBoundingClientRect()
  const ox = width >> 1
  const oy = height >> 1

  // cyclical concept map visual config
  const scaleY = 0.85
  const scaleX = 1.4
  const connectorGap = 0.3
  const connectorSpread = 0.05
  const distance = height * 0.475
  const tau = Math.PI * 2
  const radian = 180 / Math.PI
  const step = tau / $levels.length
  const shift = Math.PI * -0.5
  const arrowOrientation = Math.PI * 0.66
  const svgNs = 'http://www.w3.org/2000/svg'

  // set up each node and connecting line
  $levels.each((i, node) => {

    // determine the origin of each node
    const doneCss = $(node).is('.done') ? 'done' : ''
    const direction = (step * i) + shift
    const x = ox + (Math.cos(direction) * distance * scaleX)
    const y = oy + (Math.sin(direction) * distance * scaleY)

    // set the node position
    $(node).attr('style', `--offset-x: ${x}px; --offset-y: ${y}px`)

    // calculate connecting line
    const start = step * i + (step * connectorGap) + shift
    const end = step * (i + 1) - (step * connectorGap) + shift
    const control = (start + end) * 0.5
    const sx = 0 | ox + Math.cos(start) * distance * scaleX
    const ex = 0 | ox + Math.cos(end) * distance * scaleX
    const cx = 0 | ox + Math.cos(control) * distance * (scaleX + connectorSpread)
    const sy = 0 | oy + Math.sin(start) * distance * scaleY
    const ey = 0 | oy + Math.sin(end) * distance * scaleY
    const cy = 0 | oy + Math.sin(control) * distance * (scaleY + connectorSpread)

    // calculate the direction the arrow head faces
    // it's easiest to just use the node origin compared
    // to the end of the connector with a slight correction
    const angle = (Math.atan2(y - ey, x - ex ) + arrowOrientation) * radian

    // connecting line (quadratic curve from start to end)
    const path = document.createElementNS(svgNs, 'path')
    path.setAttributeNS(null,'class',`concept-map--overview--connector ${doneCss}`)
    path.setAttributeNS(null,'d',`M ${sx} ${sy} Q ${[ cx, cy, ex, ey ].join(' ')}`)

    // arrow head
    const arrow = document.createElementNS(svgNs, 'path')
    const container = document.createElementNS(svgNs, 'g')
    arrow.setAttributeNS(null, 'class', `concept-map--overview--arrow ${doneCss}`)
    arrow.setAttributeNS(null, 'd', 'M -10 -10 L 0 10 10 -10')
    container.setAttributeNS(null, 'transform', `translate(${ex} ${ey}) rotate(${angle})`)

    // append items
    container.appendChild(arrow)
    $cycle[0].appendChild(path)
    $cycle[0].appendChild(container)
  })

  return true
}

// create connecting lines for a tree layout
function finalizeTreeLayout(instance) {

  // find all tiers
  const { $tiers } = instance.selectors

  // not ready yet
  if (!$tiers.length) {
    return false;
  }

  for (const tier of $tiers) {
    const $tier = $(tier)
    const top = $tier.is('.top')

    // calculate measurements
    if (!top) {
      const nodes = $tier.children('.concept-map--overview--level')

      // for single nodes, make sure to mark the style a
      let left = 0
      let width = 0

      // if there are nodes to work with, recalculate
      if (nodes.length > 1) {

        // calculate to get the center of the first item
        // and the center of the last item - the line starts
        // at the first item center - the width is the last
        // item center minus the first item center
        const first = nodes.first()
        const firstWidth = first.width()
        const firstLeft = first.position().left
        const last = nodes.last()
        const lastWidth = last.width()
        const lastLeft = last.position().left

        // calculate sizes
        left = firstWidth * 0.5
        width = lastLeft + (lastWidth * 0.5) - (firstLeft + left)

        // if the width is zero, it's possible that the
        // DOM isn't ready yet - just try again shortly
        if (isNaN(width) || width === 0) {
          return false
        }
      }

      // update this tier
      $tier.attr('style', `--connector-left: ${left}px;--connector-width: ${width}px`)
    }

  }

  return true
}
