import Backbone from 'backbone'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import { lesson_demo_complete } from '@templates/student'

export default Backbone.View.extend({

  template: lesson_demo_complete,

  initialize: function(options) {
    this.lessons = Registry.get('lessons');
    this.units = Registry.get('units');

    this.lesson = options.lesson;
  },

  serialize: function() {
    return {
      ads: AdView.canShowAds(),
      lesson: this.lesson.toJSON(),
      unit: this.units.get(this.lesson.get('unit_id')).toJSON(),
    };
  }

})
