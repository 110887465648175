import Backbone from 'backbone';
import $ from 'jquery';
import { global_toast } from '@templates/student'

export default Backbone.View.extend({
  template: global_toast,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments)
    this.render()
  },

  serialize: function() {
    return {
      text: this.text,
      imgSrc: this.imgSrc || ''
    }
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);
    $('body').append(this.el);

    return this;
  },

  close: function() {
    this.$el.remove();
  }
});
