import Backbone from 'backbone'
import { proclamation_main, proclamation_rereview } from '@templates/student'

export default Backbone.View.extend({
  template: proclamation_main,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    if (this.rereview) {
      this.template = proclamation_rereview
    }
  }
})
