import Prism from 'prismjs'
import { isArray } from 'lodash-es'

let syntaxColors = [];

// Function Closure - used only as a private function: 
// recursively map syntax colors to Prism Tokens (which ultimately break down into strings)
const mapTokenColor = function(token, type) {
  type = type || '';

  if(typeof token === 'string') {
    token.split('').forEach(function() {
      syntaxColors.push(mapSyntaxType(type));
    });
  } else if(isArray(token)) {
    token.forEach(function(token) {
      mapTokenColor(token.content || token, token.type || type);
    });
  } else if(typeof token === 'object') {
    mapTokenColor(token.content, token.type);
  }
};

// Function Closure - used only as a private function: 
// We only have 5 syntax colors for all syntax types. This maps all the types to our 5 colors
const mapSyntaxType = function(type) {
  switch(type) {
  case 'prolog':
  case 'doctype':
  case 'cdata':
  case 'atrule':
  case 'rule':
  case 'rest':
  case 'entity':
    return ''; // No highlighting
  case 'comment':
    return 'syntax1'; // Comments
  case 'string':
  case 'template-string':
  case 'interpolation':
  case 'interpolation-punctuation':
  case 'regex':
  case 'script':
    return 'syntax2'; // Strings
  case 'function-variable':
  case 'constant':
  case 'boolean':
  case 'number':
  case 'operator':
  case 'namespace':
  case 'property':
  case 'tag':
    return 'syntax3'; // Variables
  case 'attr-name':
  case 'class-name':
  case 'style-attr':
  case 'keyword':
    return 'syntax4'; // Attributes
  case 'attr-value':
  case 'style':
  case 'url':
  case 'selector':
  case 'important':
  case 'function':
  case 'punctuation':
    return 'syntax5'; // Other
  case 'default':
    return '';
  }
};

export default {
  /**
   * Returns an array of strings representing the syntax color for each character of the passed 'content' string
   * @param {string} content The content we want to syntax highlight
   * @param {string} language The language to add syntax highlighting (html, javascript, css, markup)
   */
  getColorArray: function(content, language) {
    language = language || 'html';
    content = content || '';
    syntaxColors = [];

    var tokens = Prism.tokenize(content, Prism.languages[language]);

    // Recursively set the color of each Prism token
    tokens.forEach(function(token) {
      mapTokenColor(token, token.type);
    });

    return syntaxColors;
  }
}