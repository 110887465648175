import $ from 'jquery'
import Registry from '@registry'
import moment from 'moment'
import LayoutBasic from '../../global/views/layout_basic'
import ScreenProgressView from './screen_progress'
import SoundModal from './sound_modal'
import AdView from '../../global/views/ad'
import GuestBannerView from '../../global/views/guest_banner'
import KeyboardModal from './keyboard_modal'
import { lesson_layout } from '@templates/student'
import { showSoftwareKeyboard } from '@shared/helpers'

export default LayoutBasic.extend({

  template: lesson_layout,

  events: {
    'click .js-restart-screen': 'restart',
    'click .js-pause,.js-resume': 'pause',
    'click .js-keyboard-settings': 'showKeyboardSettings',
    'click .js-sound-settings': 'showSoundSettings',
    'click .js-dictation-settings': 'clickDictation',
    'blur .js-software-input': 'blurSoftwareKeyboard'
  },

  initialize: function() {
    LayoutBasic.prototype.initialize.apply(this, arguments);

    if(showSoftwareKeyboard()) {
      this.setupSoftwareKeyboard();
    }

    if(this.testType === 'timed') {
      this.timeLimit = parseInt(this.lesson.get('time_limit'))
    } else if(this.lesson.get('content_type') === 'written-prompt' && this.screen?.get('settings')?.time_limit) {
      this.timeLimit = parseInt(this.screen.get('settings').time_limit)
    }
    this.screenProgress = new ScreenProgressView({
      unit: this.unit,
      timer: this.timer,
      lesson: this.lesson,
      screen: this.screen,
      timeLimit: this.timeLimit,
      progressComplete: this.progressComplete
    });
    this.addChild('.js-screen-progress', this.screenProgress);

    this.guestBannerView = new GuestBannerView({
      page: this.userLesson.get('_state'),
      isTest: this.testType,
      customTitle: (this.userLesson.get('progress') > 0 ? 'lesson.guest_banner_prgress_title'.t() : ''),
      customMessage: (this.userLesson.get('progress') > 0 ? 'lesson.guest_banner_prgress_text'.t() : '')
    });
    this.addChild('.js-guest-banner', this.guestBannerView);

    this.listenTo(this.input, 'shortcut', this.handleKeyboardShortcut);
    this.listenTo(this.userLesson, 'change:_state', this.lessonStateChanged);
  },

  serialize: function() {
    return {
      moment: moment,
      loggedIn: Registry.get('loggedIn'),
      ads: AdView.canShowAds(),
      softwareKeyboard: showSoftwareKeyboard(),
      lesson: this.lesson.toJSON(),
      unit: (this.unit) ? this.unit.toJSON() : {},
      testType: this.testType,
      isUnitTest: this.unit && this.unit.get('type') === 'test',
      isProblemKeys: this.problemkeys,
      showButtons: !this.hasGoogleClassroomAddon,
      screen: (this.screen) ? this.screen.toJSON() : {},
      allowsounds: this.user.hasOption('allowsounds'),
      allowdictation: this.user.hasOption('allowdictation'),
      showKeyboard: this.user.hasOption('showkeyboard'),
      hidepause: this.user.hasOption('hidepause'),
      showrestart: this.unit && !this.user.isAssessment() && ((this.lesson.get('content_type') !== 'qa' && this.user.hasOption('showrestart')) || (this.lesson.get('content_type') === 'qa' && this.user.hasOption('allowquizretakes'))),
      user: this.user.toJSON(),
      rank: this.user.getSkinRank(),
      isCongrats: this.userLesson.get('progress') >= this.lesson.get('screens') && this.userLesson.get('_state') === 'intro',
      lessonState: this.userLesson.get('_state'),
      showGuestBanner: this.guestBannerVisible(),
      timeLimit: this.timeLimit,
      isAssessment: this.user.isAssessment(),
      logoPath: this.user.get('district')?.logo,
      progressIndex: this.screen?.get('display_order') ?? this.userLesson?.get('progress')
    };
  },

  render: function() {
    LayoutBasic.prototype.render.apply(this, arguments);

    if(this.testType || (this.lesson.get('content_type') === 'written-prompt' && this.screen?.get('settings')?.time_limit)) {
      this.listenTo(this.timer, 'change:seconds', this.updateTimer);
    }

    this.toggleSoundIcon();

    return this;
  },

  timerEl: null,
  timerLast: 0,
  updateTimer: function(model, seconds) {
    seconds = Math.floor(seconds);
    if (!this.timerEl) {
        this.timerEl = this.$('.js-test-countdown');
    }
    var prevSeconds = this.timerLast;
    this.timerLast = seconds;
    if (prevSeconds === seconds) {
        return;
    }

    // Either count down or count up depending on the test type
    if (this.testType === 'timed' || (this.lesson.get('content_type') === 'written-prompt' && this.screen?.get('settings')?.time_limit)) {
        this.timerEl.html((this.timeLimit - seconds).countdownSeconds());
    } else {
        this.timerEl.html(seconds.countdownSeconds());
    }

    // Check if it's time to announce
    if ((this.timeLimit - seconds) <= 10 && (this.timeLimit - seconds) % 5 === 0) {
        // Announce every 5 seconds when there are 10 seconds or less left
        this.dictation.speakTimeRemaining({text: this.timeLimit - seconds});
    } else if (this.testType === 'timed' && (this.timeLimit - seconds) % 20 === 0) {
        // Every 20 seconds elapsed, announce
        this.dictation.speakTimeRemaining({text: this.timeLimit - seconds});
    }
},

  restart: function() {
    this.trigger('restart');
    return false;
  },

  pause: function() {
    if(this.timer.isPaused()) {
      if(this.timer.unpause()) {
        this.$('.js-pause').fastShow();
        this.$('.js-resume').fastHide();
      }
    } else {
      if(this.timer.pause()) {
        this.$('.js-pause').fastHide();
        this.$('.js-resume').fastShow();
      }
    }

    return false;
  },

  showKeyboardSettings: function() {
    var modal = new KeyboardModal({
      input: this.input,
      user: this.user
    });
    modal.open();
    modal
      .done((data) => {
        this.user.setSettings(data);
      })
      .always(() => {
        if(this.input) { // QA screens do not have this.input
          this.input.refocusInput();
        }
      })

    return false;
  },

  showSoundSettings: function() {
    var modal = new SoundModal({
      screen: this.screen,
      user: this.user
    });
    modal.open();
    modal
      .done((data) => {
        this.user.setSettings(data);
        this.toggleSoundIcon();
      })
      .always(() => {
        if(this.input) { // QA screens do not have this.input
          this.input.refocusInput();
        }
      });

    return false;
  },

  toggleSoundIcon: function() {
      const hasSound = this.user.getSetting('error_sounds') || this.user.getSetting('typing_sounds') || this.user.getSetting('dictation')

    if(hasSound) {
      this.$('.js-sounds-on').fastShow();
      this.$('.js-sounds-off').fastHide();
    } else {
      this.$('.js-sounds-on').fastHide();
      this.$('.js-sounds-off').fastShow();
    }
  },

  toggleDictationIcon: function() {
    if(this.user.getSetting('dictation')) {
      this.dictation.resume();
      this.$('.js-dictation-on').fastShow();
      this.$('.js-dictation-off').fastHide();
    } else {
      this.dictation.turnOff();
      this.$('.js-dictation-on').fastHide();
      this.$('.js-dictation-off').fastShow();
    }
  },

  clickDictation: function() {
    this.user.toggleDictation();

    this.toggleDictationIcon();

    return false;
  },

  /**
   * Handles various keyboard shortcuts pressed by the user
   * @param {string} shortcut String representing the keystroke shortcut pressed
   */
  handleKeyboardShortcut: function(shortcut) {
    if(shortcut === 'sound') {
      this.showSoundSettings();
    } else if(shortcut === 'restart' && !this.user.isAssessment() && this.user.hasOption('showrestart')) {
      this.restart();
    } else if(shortcut === 'keyboard' && this.user.hasOption('showkeyboard')) {
      this.showKeyboardSettings();
    } else if(shortcut === 'dictation' && this.user.hasOption('allowdictation')) {
      this.clickDictation();
    }
  },

  softwareKeyboardFocused: false,
  lastScrollTop: 0,
  setupSoftwareKeyboard: function (e) {
    $(window).scroll(function(e){
      // see if they're focused in the input field that toggles software keyboard
      if(e.target.activeElement.classList.contains('js-software-input')) {
        if(!this.softwareKeyboardFocused) { // if they weren't before, they are now
          this.softwareKeyboardFocused = true;
          $(window).scrollTop(this.lastScrollTop);  // scroll them to the top, because focusing on the little tab at the bottom will scroll to the bottom
        }
      } else {
        this.lastScrollTop = window.scrollY;  // they aren't in the input, so they're scrolling as normal. store this, so when they do focus on the input, we'll know where to scroll them back to
      }

    }.bind(this));
  },

  blurSoftwareKeyboard: function() {
    this.softwareKeyboardFocused = false;
  },

  /**
   * Determines whether the guest banner should be shown or hidden
   * @returns {boolean}
   */
  guestBannerVisible: function() {
    const screensComplete =  Registry.get('userActivity').getOrAdd(0).get('screens');

    // No screen means we are on the screen complete page
    return !Registry.get('loggedIn') && (screensComplete > 0 || (this.lesson && this.unit && (this.lesson.get('content_type') === 'qa' || this.unit.get('type') === 'test' || this.lesson.get('content_type') === 'coding' || this.unit.get('type') === 'practice'))) && (!this.screen || (this.screen.screen_type !== 'keyboard-jump' && this.userLesson.get('_state') !== 'typing'));
  },

  /**
   * Shows the guest banner if the lesson is on the complete screen
   */
  lessonStateChanged: function() {
    // Don't show the guest banner when moving from complete to intro or else the banner will pop into existence when clicking "next screen"
    if(this.userLesson.get('_state') !== 'intro' && this.userLesson.get('_state') !== 'typing' && this.guestBannerVisible()) {
      if(this.userLesson.get('progress') > 0) {
        this.guestBannerView.customTitle = 'lesson.guest_banner_prgress_title'.t()
        this.guestBannerView.customMessage = 'lesson.guest_banner_prgress_text'.t()
      }
      this.guestBannerView.render();
      this.$('.js-guest-banner').show();
      this.$('#app').addClass('has-guestBanner');
    }
  }
})
