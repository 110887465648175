import { getIntroAIHtml, formatKeys } from './problem_keys_lesson'
import { generatePKWordList } from '../lesson/collections/problemkey_screens';

const TOP_KEYS = 2;
const DEFAULT_KEYS = ['j', 'f'];

/**
 * Calculates the maximum word length based on the user's grade.
 * @param {Object} user - The user object
 * @returns {number} The maximum word length
 */
function getMaxWordLength(user) {
  return Math.max(4, user.getCurriculumGrade() * 2);
}

/**
 * Retrieves the top problem keys for the user.
 * @param {Object} userLettersTyped - Contains user typing data
 * @returns {Array} The problem keys array or default keys if none found
 */
export function getProblemKeysScreenPracticeKeys(userLettersTyped) {
  const problemKeys = userLettersTyped.topProblemKeys(TOP_KEYS).filter((val) => val.percent > 0).map((val) => val.id.toUpperCase());
  return problemKeys.length ? problemKeys : DEFAULT_KEYS;
}

/**
 * Generates word list for Problem Key Lesson
 * @param {Object} userLettersTyped - Contains user typing data
 * @param {Object} user - The user object
 * @returns {Array} An array of generated words
 */
export function generateWords(userLettersTyped, user) {
  const wordsInCurriculumLanguage = FTWGLOBALS('words')[user.getCurriculumLanguage()] || FTWGLOBALS('words');
  const typedLetters = userLettersTyped.typedLetters();

  return generatePKWordList(userLettersTyped.topProblemKeys(4).map((row) => row.id), wordsInCurriculumLanguage, typedLetters, getMaxWordLength(user));
}

/**
 * Generates the content and intro of Problem Key lesson based on user's options
 * @param {Object} user - The user object
 * @param {Object} userLettersTyped - Contains user typing data
 * @param {Array} words - An array of words for the lesson content
 * @returns {Object} An object containing the content and intro
 */
export function getProblemKeysScreenContentAndIntro(user, userLettersTyped, words, screen) {
  const aiIntroHtml = `
    <span>
      ${'lesson.personalized_practice.intro'.t({
        keys: formatKeys(getProblemKeysScreenPracticeKeys(userLettersTyped)),
        ai: getIntroAIHtml(),
      })}
    </span>
  `;

  return {
    content: user.hasOption('problemkeysai') && !screen.get('settings')?.force_original_problem_keys ? (user.getSetting('AIContent') || words) : words,
    intro: user.hasOption('problemkeysai') && !screen.get('settings')?.force_original_problem_keys ? aiIntroHtml : '',
  };
}
