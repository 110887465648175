import Backbone from 'backbone'
import Registry from '@registry'
import MainView from './views/play'
import LayoutView from '../global/views/layout'
import { isIE11 } from '@shared/helpers'

export default Backbone.View.extend({

  layout: LayoutView,

  initialize: function() {
    if(!Registry.get('student').hasGame(FTWGLOBALS('game').game_id)) {
      window.location.href = __url('/student/lessons');
      return;
    }

    var game = FTWGLOBALS('game');
    if(isIE11() && (game.folder === 'ztype' || game.folder === 'tommyq' || game.folder === 'keyboard-jump')) {
      window.alert('games.browser_out_of_date'.t({game: game.name}));
      window.location.href = __url('/student/games');
      return;
    }

    Backbone.View.prototype.initialize.apply(this, arguments);

    this.user = Registry.get('student');

    this.views.mainView = new MainView();

    this.addChild(this.el, this.views.mainView, true);
    this.render();
  }
})
