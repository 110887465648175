import Backbone from 'backbone'
import Registry from '@registry'
import { tests_zero_state_test } from '@templates/student'

export default Backbone.View.extend({
  template: tests_zero_state_test,

  serialize: function() {
    var curriculumLanguage = Registry.get('student').getCurriculumLanguage();

    return {
      dataLength: this.dataLength,
      testType: this.testType,
      urlSuffix: curriculumLanguage !== FTWGLOBALS('defaultLanguage') ? ('/' + curriculumLanguage) : ''
    };
  },
})
