import Backbone from 'backbone'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import ModalView from '../../global/views/modal'
import TypingQuestionView from './screen_qa_question'
import { lesson_transcript_modal, lesson_screen_qa } from '@templates/student'

export default Backbone.View.extend({

  template: lesson_screen_qa,

  questions: [],
  currentQuestion: 0,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    var introArray = this.screens.at(0).get('intro').match(/(<div class="embed">[^]*?<\/div>)?([^]*)?/);

    this.videoHTML = (introArray[1] ? introArray[1].trim() : '');
    this.transcriptHTML = (introArray[2] ? introArray[2].trim() : '');

    this.dictation.setProperties(this.screen, null);

    // Add each question as its own view
    this.screens.each((screen, index) => {
      this.appendQuestion(index);
    });

    this.listenTo(this.input, 'character', this.handleKeydown);

    // Allows user to restart the lesson OR continue from where the last were within the quiz
    this.currentQuestion = this.userLesson.get('restart') ? 0 : this.userLesson.get('progress');

    if (this.isStandAloneQuestion) {
      this.currentQuestion = 0
    }

    // Navigate to the current question
    this.animateQuestion(this.currentQuestion, true);
  },

  serialize: function() {
    var userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens');

    return {
      ads: AdView.canShowAds() && totalScreens > 0,
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens, isSubScreen: this.isSubScreen })
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    this.speakCurrentQuestion();
    return this;
  },

  appendQuestion: function(screenIndex) {
    const screen = this.screens.at(screenIndex)

    // check to restore an answer, if so grab it
    // from the current user lesson
    let startingSelection
    if (this.restoreAnswer) {
      const choices = screen.get('content')?.split(/\n/g)
      const current = this.userLessonScreens.at(screenIndex + 1)
      const completed = current?.get('completed')
      const index = current?.get('std')
      startingSelection = completed ? choices?.[index] : null
    }

    var question = new TypingQuestionView({
      lesson: this.lesson,
      screen,
      questionIndex: screenIndex + 1,
      hasVideo: this.videoHTML !== '',
      hasText: this.transcriptHTML !== '',
      isStandAloneQuestion: !!this.isStandAloneQuestion,
      startingSelection,
      dictation: this.dictation
    });

    question.$el.fastHide();
    question.$el.addClass('multiple is-hidden');

    this.listenTo(question, 'show-transcript', this.showTranscript);
    this.listenTo(question, 'watch-video', this.watchVideo);
    this.listenTo(question, 'submit-answer', this.submitAnswer);

    this.addChild('.js-content', question, true, true);
    this.questions.push(question);
  },

  submitAnswer: function() {
    this.trigger('complete');
  },

  watchVideo: function() {
    new ModalView({
      template: lesson_transcript_modal,
      titleText: this.lesson.get('name'),
      okText: '',
      cancelText: 'shared.close'.t(),
      width: 'm',
      model: new Backbone.Model({ text: this.videoHTML })
    }).open();
  },

  showTranscript: function() {
    new ModalView({
      template: lesson_transcript_modal,
      titleText: 'lesson.transcript_for_text'.t() + ' ' + this.lesson.get('name'),
      headerType: 'qa',
      okText: '',
      cancelText: 'shared.close'.t(),
      width: 'm',
      model: new Backbone.Model({ text: this.transcriptHTML })
    }).open();
  },

  /**
   *
   * @param {Number} index Indicates the index of the question that needs to be SHOWN
   */
  animateQuestion: function(index, firstQuestion) {
    var duration = 2000;//(this.lowPerformance) ? 100: 250;

    if(firstQuestion) {
      // The first question should immediately be shown without hiding the previous question
      this.questions[index].$el.show();
      this.questions[index].$el.removeClass('is-hidden');
    } else {
      // Mark the previous question as complete
      this.questions[index-1].$el.addClass('is-complete');
      this.questions[index-1].$el.on('transitionend', function() {
        this.questions[index-1].$el.hide();
        this.questions[index].$el.show();
        this.questions[index].$el.removeClass('is-hidden');
      }.bind(this));
    }
  },

  screenSaved: function() {
    this.currentQuestion++;
    this.animateQuestion(this.currentQuestion);

    this.speakCurrentQuestion();
  },

  handleKeydown: function(character) {
    if(character.keyCode === 13) {
      this.questions[this.currentQuestion].submitAnswer();
    } else if(!isNaN(Number(parseInt(character.key))) && this.questions[this.currentQuestion].answers.length >= character.key) {
      if(parseInt(character.key) === 0) {
        this.speakCurrentQuestion();
      } else {
        this.questions[this.currentQuestion].chooseAnswer(parseInt(character.key));
        this.questions[this.currentQuestion].clickedAnswer();
      }
    }
  },

  speakCurrentQuestion: function() {
    this.dictation.speakQAQuestion(this.questions[this.currentQuestion].getDictationContent());
  }
})
