import Backbone from 'backbone'
import TypingQAModel from '../models/qa_form'
import { shuffle } from 'lodash-es'
import { lesson_screen_qa_question } from '@templates/student'

export default Backbone.View.extend({

  template: lesson_screen_qa_question,

  modelClass: TypingQAModel,

  formSelector: 'form',

  events: {
    'click .js-read-transcript': function() { this.trigger('show-transcript'); },
    'click .js-watch-video': function() { this.trigger('watch-video'); },
    'click .js-submit-answer': 'submitAnswer',
    'change input[name="answers"]': 'clickedAnswer'
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.answers = this.screen.get('content').split(/\r?\n/);

    // check if we're using images instead of text
    const settings = this.screen.get('settings')
    this.displayAsImages = settings?.display_as === 'images'

    // Used by dictation in order to speak the answers as they appear on screen
    this.answersShuffled = shuffle(this.answers);
  },

  serialize: function() {
    return {
      lesson: this.lesson.toJSON(),
      screen: this.screen.toJSON(),
      questionIndex: this.questionIndex,
      answers: this.answersShuffled,
      displayAsImages: this.displayAsImages,
      isStandAloneQuestion: !!this.isStandAloneQuestion,
      startingSelection: this.startingSelection,
      hasVideo: this.hasVideo,
      hasText: this.hasText
    };
  },

  // Manually handle form submission so we can display an error message properly.
  // The form validator will display an error message below the first checkbox (which is no bueno)
  submitAnswer: function() {
    // Sometimes the submit button on the next question screen can trigger this question to "submit"
    // Therefore, we only allow one submission per question
    if(!this.answerSubmitted) {
      var answer = this.$('input[name="answers"]:checked').val();

      if(answer) {
        this.$('.js-submit-answer').addClass('btn--loading');
        this.validator.form.submit();
      } else {
        this.$('.js-error-message').show();
      }
    }
  },

  submitCallback: function() {
    var answer = this.$('input[name="answers"]:checked').val();

    this.screen.set({
      std: this.answers.indexOf(answer),
      typed: 1,
      errors: answer !== this.answers[0]
    }, { silent: true });

    this.answerSubmitted = true;
    this.trigger('submit-answer');
  },

  chooseAnswer: function(index) {
    this.$('input[data-id="' + index + '"]').prop('checked', true)
  },

  clickedAnswer: function() {
    var ele = this.$('input[name="answers"]:checked');

    // clear the selected state
    this.$('.multiple-answer').removeClass('selected')

    // update the container for the selection
    ele.closest('.multiple-answer').addClass('selected')

    this.dictation.speakQAAnswer({ text: ele.data('id') + '. ' + ele.val() });
  },

  getDictationContent: function() {
    return {
      questionCount: this.lesson.get('screens'),
      questionIndex: this.questionIndex,
      question: this.screen.get('title'),
      answers: this.answersShuffled
    };
  }
})
