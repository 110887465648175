import $ from 'jquery'
import FormModel from '@shared/form_model'

export default FormModel.extend({

  initialize: function() {
  },

  url: '/apiv1/student/auth/password-update',

  defaults: {
    hash: '',
    user_id: '',
    password: '',
    password2: ''
  },

  validationRules: {
    password: {
      required: true,
      minlength: 4
    },
    password2: {
      required: true,
      minlength: 4,
      equalTo: '#password-form input[name=password]'
    }
  },

  validationMessages: {
    password: {
      required: 'shared.password.required_password'.t()
    },
    password2: {
      required: 'shared.password.required_password2'.t(),
      equalTo: 'shared.passwords_do_not_match'.t()
    }
  },

  validateHash: function(hash, id) {
    this.set({
      hash: hash,
      user_id: id
    });

    return $.post('/apiv1/student/auth/password-validate', {hash: hash, user_id: id}).done(function(data){this.set(data); }.bind(this));
  }
})
