import Backbone from 'backbone'
import Registry from '@registry'
import MainView from './views/main'
import LayoutView from '../global/views/layout'

export default Backbone.View.extend({

  layout: LayoutView,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.user = Registry.get('student');
    this.tests = Registry.get('userTests');

    this.views.main = new MainView({
      hasLoaded: !Registry.get('loggedIn') || this.tests.length > 0,
      user: this.user,
      tests: this.tests,
      miniNav: this.miniNav
    });

    this.addChild(this.el, this.views.main, true);
    this.render();

    if(Registry.get('loggedIn') && this.tests.length === 0) {
      this.tests.fetch({reset: true});
    }
  }

})
