import ScreenClickDrag from './screen_click_drag'
import $ from 'jquery'
import Velocity from 'velocity-animate'


import { lesson_screen_click_drag_default } from '@templates/student'
import { includeSoundEffects, includeVisualEffects} from '../common';

export default ScreenClickDrag.extend({

  lessonTemplate: lesson_screen_click_drag_default,

  sound: includeSoundEffects(),
  effects: includeVisualEffects(),


  setup() {
    const lessonContent = this.screen.get('content')
    const slug = this.screen.get('settings')?.display
    const generated = slug === 'patterns'
    const variant = generated ? null : this.screen.get('settings')?.cd_variant

    // helper for splitting then trimming content
    const extract = (str, delimiter) =>
      str?.split(delimiter).map(item => item.trim()).filter(item => item.length)

    // check for content, including items to match and a pattern to use
    let [items, pattern] = extract(lessonContent, '\n')
      .map(line => extract(line, ','))

    // if there's a pattern, then parse the string and determine
    // which item belongs to which blank
    if (pattern && !slug) {
      pattern = this.parsePattern(pattern, items)
    }
    // if there's no pattern, create a default for compatibility with
    // older lessons that do not hse this
    else {
      pattern = [
        ...items.map(type => ({ type })),
        ...items.map(type => ({ type })),
        ...items.map(type => ({ type, isBlank: true })),
      ]
    }

    this.lessonContent = {
      pattern,
      items,
      slug,
      variant,
      generated
    }
  },

  build() {

    // Context is lost within jquery draggable callbacks, therefore we save the onComplete function to local scope
    const onComplete = this.completeScreen.bind(this),
      that = this

    // Define all the draggable items
    $('.js-draggable').draggable({
      revert: function(dropEle) {
        if(dropEle) {
          return false
        } else {
          that.sound.play('error')

          // is reverting
          Velocity($(this), { scale: 1 }, 250)
          return true
        }
      },
      classes: {
        'ui-draggable-dragging': 'is-dragging'
      },
      start: function(e, ui) {
        $('.js-cursor-animation').removeClass('is-preview js-cursor-animation')
        // quit the animation and remove the js reference class to prevent the animation from showing
        window.setTimeout(() => {
          const dropEle = $(".js-droppable.is-active"),
            scale = dropEle.width() / ui.helper.width()
          // Sometimes the dropEle is no longer on the screen. If this happens, don't scale the image
          if(scale) {
            Velocity(ui.helper, { scale: scale }, 250)
          }
        }, 100)
      }
    })
    $('.js-droppable').droppable({
      accept: function(draggedItem) {
        return $(this).data('id') === draggedItem.data('id')
      },
      classes: {
        'ui-droppable-hover': 'is-hovered',
        'ui-droppable-active': 'is-active'
      },
      drop: function(ele, ui) {
        let dropEle = $(this),
          pos = dropEle.position(),
          placed = ui.draggable.is('.is-placed')

        // generated lessons have a different layout
        if (that.lessonContent.generated) {
          const container = $('.screenDrag-canvas')[0]?.getBoundingClientRect()
          const bounds = this.getBoundingClientRect()
          const left = bounds.left - (container?.left || 0)
          const top = bounds.top - (container?.top || 0)
          pos = { top, left }
        }

        dropEle.addClass('is-complete')
        ui.draggable.addClass('is-placed')

        Velocity(
          ui.draggable,
          { top: pos.top, left: pos.left },
          {
            duration: 50,
            complete: () => {
              that.sound.play('pop')

              Velocity($('.screenDrag-introArrow'), { opacity: 0 }, 250)
              if(that.mode === 'how_to_confirm') {
                Velocity(dropEle,{opacity: 1, scale: 1 }, {easing: 'easeInOut', duration: 300} )
              } else {

                // make sure to only play the celebration effect once
                if (!placed) {
                  that.effects.celebrate(dropEle)
                }
              }
            }
          }
        )
        ui.draggable.draggable('disable')

        // Check to see if we've dropped all the elements
        if($('.js-droppable.is-complete').length === $('.js-droppable').length) {
          onComplete()
        }

        that.speakScreen({ drop: true })
      }
    })
  },

  serialize() {
    const data = ScreenClickDrag.prototype.serialize.call(this)
    const { items, pattern, variant } = this.lessonContent

    return {
      ...data,
      variant,
      items,
      pattern
    }
  },

  parsePattern(parts, items) {
    const availableItems = [...items]

    const pattern = [ ]
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i]

      // check if this part contains a question mark
      // which denotes it's a "blank" to fill in
      const isBlank = !!part.includes?.('?')

      // check if there's a named type as part of this
      // space. If not, then use one of the items
      let type = part.replace('?', '')
      if (!type) {
        type = availableItems.shift()
      }

      // save this pattern block
      pattern.push({ type, isBlank })
    }

    return pattern
  }
})
