import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import moment from 'moment'
import { find, times, compact } from 'lodash-es'
import { isChromeOS, wordAtPosition } from '@shared/helpers'

export default Backbone.Model.extend({
  idAttribute: 'lesson_screen_id',

  defaults: {
    content: '',
    formatted_content: '',
    original_content: '',
    lastKey: '',
    line: 0,
    typed: 0,
    errors: 0,
    seconds: 0,
    two_stars: 90,
    three_stars: 95,
    lastKeyCode: 0,
    lastKeyError: false
  },

  /**
   * Takes coding content and replaces inline variables surrounded by double curly brackets like {{time}}. See code for the full list.
   * @param content
   * @param userModel
   * @returns string
   */
  replaceCodingVariables: function(content, userModel) {
    var user = userModel.toJSON();

    return content.replace(/{{[a-zA-Z_]+}}/g, (key) => {
      key = key.replace(/[{}]/g, '');
      switch(key) {
      case 'first_name':
        return user[key] || 'Marty';
      case 'last_name':
        return user[key] || 'McFly';
      case 'username':
        if(user.login_type === 'username') {
          if(user.username.indexOf('@') !== -1) {
            return user.username.substr(0, user.username.indexOf('@'));
          } else {
            return user.username;
          }
        } else {
          return (user.first_name) ? user.first_name.toLowerCase() : 'username';
        }
      case 'date':
        return moment().format('L');
      case 'time':
        return moment().format('LTS');
      case 'hostname':
        return window.location.hostname;
      case 'avatar_path':
        var skin = find(FTWGLOBALS('skins'), {id: user.skin_id || 1}),
          rank = userModel.getSkinRank();
        return skin.file+'/avatar-'+String(rank.id).pad(2, '0', 'STR_PAD_LEFT')+'_v'+user.variant_id+'.png';
      }
      return key;
    });
  },

  /**
   * Strip away extra content so it doesn't over burden the DOM with untyped content
   * @param contentArray Array of lines of typing content
   * @param nonTypeableLinesToKeep Number of lines to keep above and below for display
   * @returns Array
   */
  stripCodingComments: function(contentArray, nonTypeableLinesToKeep = 3) {
    var foundTypableContent = false,
      startingNonTypable = [];

    contentArray = contentArray.filter(function(line) {
      if(!foundTypableContent && line[0] !== '~')  { foundTypableContent = true; }
      if(!foundTypableContent) {
        startingNonTypable.push(line);
      }
      return foundTypableContent;
    });
    times(nonTypeableLinesToKeep, function(){contentArray.unshift(startingNonTypable.pop());});
    return contentArray;
  },

  formatContent: function(user) {
    var content = this.get('content').rtrim(),
      title = this.get('title'),
      intro = this.get('intro'),
      screenSettings = this.get('settings'),
      contentSetting = '',
      codingStartingLineNumberOffset = 0;

    // The Coding and Inline screens have special content settings that might be embedded into the content. Remove for all content incase games fallback for old browsers
    var removeShabang = function(content){
      var split = content.split('\n');
      if(split[0].startsWith('#!')) {
        contentSetting = split[0].replace('#!', '');
        split.splice(0, 1);
      }
      return split.join('\n');
    };

    // very first thing is to make it clean content without extra trailing and starting spaces.
    // we may add a space, like in the new_key type screen learning space, but that is done below in code
    content = removeShabang(content).replace(/^ +/g, '').replace(/\n+ +/g, '\n').replace(/\r/g, '').replace(/[’‘]/g, '\'').replace(/[”“]/g, '"');

    if((this.get('screen_type') === 'coding' || this.get('screen_type') === 'standard') && content.match(/^\^/m)) {
      // if any lines begin with ^ then that will become the only stuff to type
      content = content.replace(/^~/gm, '');  // if there are some ~ get rid of them
      content = content.split('\n').map((line) => {
        if (line[0] === '!') return line;  // if there are !, keep them
        if (line[0] === '^') return line.substr(1);  // the lines with ^ get rid of
        return '~' + line;  // put ~ on all others
      }).join('\n')
    }

    var originalContent = content;

    if(this.get('new_key') === 'space') {
      this.set({new_key: ' '});
    }

    if(this.get('screen_type') === 'coding' || this.get('screen_type') === 'standard') {
      // special /** **/ code comments should be removed and can be shown as tooltips
      content = content.replace(/^~*\s*(\/\*\*\*.+\*\*\*\/|<!---.*--->).*\n/gm, '');

      // special code comments should be removed and shown as tooltips
      content = content.replace(/^~*\s*(\/\*\*[^*]+\*\*\/|<!--.*-->).*\n/gm, '');
      // remove anything starting with !, and then split up lines and format
      content = content.replace(/^!(.*)(\n*)/gm, '').split('\n').map((line) => {
        if (line[0] !== '~' && line[0] && this.get('screen_type') === 'coding') {
          return line + '⏎'
        }
        return line
      }).join('\n').rtrim()
    }

    if(this.get('screen_type') === 'coding') {
      let fullContentArray = content.split('\n'),
        contentArray = this.stripCodingComments(fullContentArray)

      codingStartingLineNumberOffset = Math.max(0, fullContentArray.length - contentArray.length)
      contentArray = this.stripCodingComments(contentArray.reverse(), 4).reverse();
      content = compact(contentArray).join('\n');

      if(!contentSetting && screenSettings?.language) {
        contentSetting = screenSettings.language
      }
    }

    // Remove title marker placed on some resume screens
    if(content.startsWith('# ')) {
      content = content.substring(2)
    }

    if(this.get('new_key') && (this.get('screen_type') === 'block' || this.get('screen_type') === 'single-key')) {
      if(content[0] !== this.get('new_key')) {
        content = this.get('new_key') + content;
      }
    }

    // Remove new lines
    if(this.get('screen_type') === 'keyboard-jump') {
      content = content.replace(/\n/g, ' ');
    }

    // clean up the annoying line feeds if they are in there.
    var specialCharRegex = new RegExp(String.fromCharCode(160), 'g');
    content = content.replace(specialCharRegex, ' ').replace(/\s+\n/g, '\n').replace(/ {2,10}/g, ' ');

    // Replace <bold> and <italic> styling with special character
    content = content.replace(/<+\/?bold>/g, '«').replace(/<+\/?italic>/g, '»');

    // keep the original content
    let oldContent = content;

    if(this.get('screen_type') === 'block') {
      content = content.replace(/\n|\r/g, '');
      // We are no longer segmenting by 8 because EduTyping uses multiple sized blocks -BS 2/23
      // if(content.length > 8 && content.length%8) {  // be divisible by 8 so it doesn't have a segment kinda empty
      //   content = content.substr(0, content.length - content.length%8);
      // }
    }

    // Remove bold and italic special character from typable content
    content = content.replace(/«/g, '').replace(/»/g, '');

    // Remove the spaces from the screen content because inline games do not use spaces
    if(this.get('screen_type') === 'keyboard-jump') {
      content = content.replace(/\s/g, '');
    } else if (this.get('screen_type') === 'coding' || this.get('screen_type') === 'standard') {
      content = content.replace(/~(.*)(\n|)/g, '');  // Remove all lines that start with ~
      if(this.get('screen_type') === 'coding') {
        content = content.replace(/\t/g, ''); // Remove all the tabs, because those don't need to be typed to support auto-indent. template disables them
      }
    } else {
      content = this.periodSpacing(content, user.get('spaces'));
    }

    // replace ENTER + newline with just newline.  Will use raw content to know if an enter should really be typed
    if(this.get('screen_type') !== 'block') {
      content = content.replace(/⏎\n/g, '⏎');
    }

    if(!content.match(/⏎[^\n]/)) {  // if there are no enter-then-non-newline (aka, inline enters for enter key lesson) then replace enter+spaces with enters
      content = content.replace(/⏎ +/g, '⏎');
    }

    if(this.get('screen_type') === 'coding' || this.get('screen_type') === 'standard') {  // replace variables like {{first_name}} with the users's name
      content = this.replaceCodingVariables(content, user);
      oldContent = this.replaceCodingVariables(oldContent, user);
      originalContent = this.replaceCodingVariables(originalContent, user);
      // Email responses need coding variables replaced
      if(screenSettings?.response) {
        screenSettings.response = this.replaceCodingVariables(screenSettings.response, user)
        this.set({ settings: screenSettings })
      }
    }

    this.set({title: title, intro: intro, content: content, formatted_content: oldContent, original_content: originalContent, content_setting: contentSetting, codingStartingLineNumberOffset: codingStartingLineNumberOffset});
  },

  /**
   * Returns the typing content as an array of arrays (lines of characters)
   * @returns array
   */
  getContentByLine: function() {
    return compact(this.get('formatted_content').split(/\n/)).map((line, index, array) => {
      line = line.split('');
      if (index < array.length-1 && (this.get('screen_type') === 'block' || line[line.length-1] !== '⏎')) {
        line.push('\n');
      }
      return line;
    })
  },

  /**
   * Returns the typing content as an array of arrays (words of characters)
   * @returns {Array}
   */
  getContentByWord: function(content) {
    content = content || this.get('formatted_content');

    return content.splitByWord();
  },

  /**
   * Get the character at the typed position
   * @param index
   */
  charAt: function(index) {
    var content = this.get('content') || '';
    return content[index];
  },

  handleInput: function(config) {
    var startTyped = this.get('typed'),
      screenType = this.get('screen_type'),
      isAssessment = Registry.get('student').isAssessment(),
      errors = this.get('errors'),
      typed = startTyped,
      typedChar = config.key,
      content = this.get('content'),
      currentChar = this.charAt(typed),
      newLine = currentChar == '\n' || currentChar == '⏎',
      isError = false,
      nextKeyError = false;

    // If it's an actual end of line, but not specifically an ENTER symbol, allow either enter or space typed
    if (currentChar == '\n' && typedChar == ' ') {
      currentChar = ' ';
    }

    // If it's specifically an ENTER symbol, and they typed enter (which is a \n) then it's correct
    if (currentChar == '⏎' && typedChar == '\n') {
      currentChar = '\n';
    }

    // ignore input if they are done
    if (typed >= this.get('content').length) {
      return;

      // go back if it's a backspace
    } else if (typedChar === 'Backspace') {
      if (screenType == 'falling' || screenType == 'block' || screenType === 'keyboard-jump') {
        return;
      }

      if (!Registry.get('student').hasOption('allowbackspace') || isAssessment) {
        return;
      }

      if (typed > 0) {
        typed -= 1;
      }

      // if it's a special character (shift, ctrl, etc) ignore
    } else if (config.dead) {
      this.set({dead: true});
      return;
    } else if (config.special){
      return;

      // if the key is correct, move forward
    } else if (currentChar == typedChar) {
      typed += 1;
      // if it's incorrect, move forward if last was not an error
    } else if (currentChar != typedChar) {
      isError = true;
      if (!this.get('lastKeyError') || isAssessment) { // EduTec Assessments can have multiple incorrect keys typed
        typed += (screenType === 'falling' || screenType === 'block' || screenType === 'keyboard-jump') ? 0 : 1; // if you stop on errors, then there's no moving forward on error
        errors += 1;
      } else {
        nextKeyError = true;
      }
    }

    // all this mess is to determine if a word is all caps, so the capslock lesson tells them to press the caps
    var currentWord = wordAtPosition(content, typed),
      cleanedWord = currentWord.replace(/^[^a-zA-Z]|[^a-zA-Z]$/g, ''),
      isCapsLockWord = cleanedWord.length > 1 && cleanedWord.toUpperCase() === cleanedWord && !cleanedWord.match(/[^a-zA-Z]/) && !isChromeOS();

    var data = {
      isError: isError,
      typed: typed,
      dead: false,
      nextKey: this.charAt(typed),
      letterTyped: typedChar,
      lastKeyError: isError,
      errors: errors,
      correctLetter: currentChar,
      keyStamp: Date.now(),
      currentWord: currentWord,
      isCapsLockWord: isCapsLockWord,
      nextKeyError: nextKeyError
    };
    this.set(data);

    // Keybord Jump game will trigger an event after a small animation
    if (typed >= content.length && screenType !== 'keyboard-jump') {
      this.trigger('complete');
    }

    return data;

  },

  periodSpacing: function(text, spacesAfterPeriod) {
    spacesAfterPeriod = spacesAfterPeriod || 1;
    if (spacesAfterPeriod < 1 || spacesAfterPeriod > 2) { throw new Error('Only 1 or 2 spaces after a period'); }

    var spaces = (spacesAfterPeriod == 1) ? ' ' : '  ';

    return text.replace(/(\.|\?|!) +/g, '$1'+spaces).replace(/(mrs\.|mr\.|sr\.|st\.|ms\.|\s\w\.|inc\.|lt\.|jr\.|dr\.|\sgt\.|ft\.|st\.|ave\.|rd\.|in\.|hrs\.|e\.g\.|ot\.|lol\.|ed\.|fl\.|off\.|ord\.|rt\.|rds\.|asstd\.|ea\.|ltd\.|ins\.|reg\.|c\.o\.d\.) /gi, '$1'+' ');
  },

  quickComplete: function() {
    this.set({
      typed: 1,
      fastForward: 1
    });

    this.trigger('complete');
  },

  setPeriodSpacing: function(spacing) {
    this.set({
      content: this.periodSpacing(this.get('content'), spacing),
      period_spacing: spacing
    });
  }


})
