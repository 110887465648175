/**
 * ModelEngine.Localstorage
 *
 * A local storage engine for models
 */

var models = [];

var localStoragePrefix = '';

var encryptionFn = function(data) {
  return data;
};

var Engine = function(table) {
  this.table = table;
};

Engine.prototype.setModel = function(model) {
  models.push(model);
  this.model = model;
};

Engine.prototype.getData = function() {
  try {
    if (typeof window.localStorage[encryptionFn(localStoragePrefix + this.table)] === 'undefined') {
      return null;
    }
    return JSON.parse(encryptionFn(window.localStorage[encryptionFn(localStoragePrefix + this.table)]));
  } catch (e) {
    console.error('Error parsing local storage', e); // eslint-disable-line
    window.localStorage.clear();
    return null;
  }
};

Engine.prototype.setData = function(data) {
  try {
    window.localStorage[encryptionFn(localStoragePrefix + this.table)] = encryptionFn(JSON.stringify(data));
  } catch (e) {
    console.error('Error parsing local storage', e); // eslint-disable-line
    window.localStorage.clear();
  }
};

Engine.prototype.change = function() {
  this.setData(this.model.toJSON());
};

Engine.prototype.add = Engine.prototype.change;

Engine.prototype.remove = Engine.prototype.change;

Engine.prototype.reset = Engine.prototype.change;

Engine.prototype.sort = Engine.prototype.change;

/**
   * This 'static' method clears everything
   */
Engine.clear = function() {
  models.forEach(function(model) {
    model.off('change');
  });
  localStorage.clear();
};

Engine.setPrefix = function(prefix) {
  localStoragePrefix = prefix + '_';
};

Engine.setEncryption = function(fn) {
  encryptionFn = fn;
};

export default Engine;
