import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import Scoring from '@shared/scoring'
import Tooltip from '../../global/views/tooltip'
import Alert from '../../global/views/alert'
import LessonScreensCollection from '../../global/collections/lesson_screens'
import { find, filter, times } from 'lodash-es'
import { lessons_screen_tooltip, lessons_lesson_tooltip, lessons_panel } from '@templates/student'

export default Backbone.View.extend({

  events: {
    'click .js-restart-lesson': 'restartLesson',
    'click .js-screen': 'goToScreen',
    'click .js-print': 'print',
    'mouseover .js-screen': 'showScreenTooltip',
    'mouseover .js-show-lesson-stars': 'showLessonStarsTooltip',
    'mouseout .js-screen': 'hideScreenTooltip',
    'focus .js-screen': 'showScreenTooltip',
  },

  template: lessons_panel,

  activeTab: '',

  className: 'dashboard-listInner',

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.userLessons = Registry.get('userLessons');
    this.userLessonScreens = Registry.get('userLessonScreens');
    this.lessonScreens = new LessonScreensCollection(FTWGLOBALS('all_screens'));
    this.user = Registry.get('student');
    this.lessons = Registry.get('lessons');
    this.units = Registry.get('units');

  },

  serialize: function() {
    return {
      lodash: { find, times },
      scoring: Scoring,
      isPremium: this.user.isPremium(),
      inClass: this.user.get('in_class'),
      unit: this.units.get(this.activeTab).toJSON(),
      lessons: filter(this.lessons.toJSON(), {unit_id: this.activeTab}),
      lessonScreens: this.lessonScreens,
      userLessonScreens: this.userLessonScreens,
      unlockedScreens: this.user.hasOption('unlockedscreens'),
      unlockedCompleted: this.user.hasOption('unlockedlessons'),
      nonsequential: this.user.hasOption('nonsequential'),
      allowQuizRetake: this.user.hasOption('allowquizretakes')
    };
  },

  restartLesson: function(e) {
    var target = $(e.currentTarget),
      id = parseInt(target.data('id'), 10);

    this.userLessons.get(id).set({restart: true, _state: ''});

    return true;
  },

  goToScreen: function(e) {
    var target = $(e.currentTarget),
      id = parseInt(target.data('id')),
      screen = this.userLessonScreens.get(id);
    if(!this.user.hasOption('unlockedscreens') || !screen || target.hasClass('disabled')) { return false; }

    var userLesson = this.userLessons.get(screen.get('lesson_id')),
      lesson = this.lessons.get(screen.get('lesson_id')),
      contentType = lesson.get('content_type'),
      completed = !!screen.get('completed'),
      lessonScreens = new LessonScreensCollection(filter(FTWGLOBALS('all_screens'), {lesson_id: screen.get('lesson_id')})),
      index = lessonScreens.indexOf(lessonScreens.get(id));

    // check if they may access the screens
    let allowAccess
    if (['blueprints'].includes(contentType) && completed) {
      allowAccess = true
    }
    else {
      allowAccess = screen.get('typed') > 0 || screen.get('stars') > 0
    }

    if(allowAccess) {
      userLesson.set({progress: index, restart: false, _state: null});
      window.location.href = __url('/student/lesson/' + lesson.id + '/' + lesson.get('name').slug());
    }

    return false;
  },

  screenTooltipTmpl: null,
  screenTooltip: null,
  showScreenTooltip: function(e) {
    let ele = this.$(e.currentTarget),
      screenId = parseInt(ele.data('id')),
      lessonId = parseInt(ele.data('lesson-id')),
      displayOrder = parseInt(ele.data('display-order')) + 1,
      userScreen = this.userLessonScreens.get(screenId),
      lesson = this.lessons.get(lessonId),
      data = { slug: undefined, userScreen: false, hideStats: false, showStars: true, stars: 0 };
    if(userScreen){
      data = {
        ...data,
        userScreen: userScreen.get('typed') > 0, // Only show this data if the user has typed on the screen
        scoringSpeed: Scoring.speed(userScreen.get('typed'), userScreen.get('seconds'), userScreen.get('errors')),
        scoringAccuracy: Scoring.accuracy(userScreen.get('typed'), userScreen.get('errors')),
        scoringSeconds: parseInt(userScreen.get('seconds') || 0).countdownSeconds(),
        stars: userScreen.get('stars')
      };
    }
    data.title = lesson.get('name') + ' - ' + displayOrder
    if(lesson.get('content_type') === 'blueprints') {
      const screen = this.lessonScreens.get(screenId)
      data.screenType = screen.get('screen_type')
      data.title = screen.get('title') || 'shared.screen_type_blueprint'.t()

    } else if(lesson.get('content_type') === 'adventure') {
      data.slug = lesson.get('settings')?.slug
      data.screenType = lesson.get('settings')?.display || 'standard'
      data.showStars = false
    } else {
      data.screenType = this.lessonScreens.get(screenId).get('screen_type')
    }

    if (lesson.get('content_type') === 'written-prompt') {
      data.hideStats = true
    }

    if(!this.screenTooltipTmpl) {
      this.screenTooltipTmpl = lessons_screen_tooltip
    }

    this.screenTooltip = Tooltip.show(e.currentTarget, {
      force: true,
      text: this.screenTooltipTmpl({...data, lodash: { times }}),
      skipAnimation: true
    });
  },

  hideScreenTooltip: function() {
    Tooltip.hideAll();
  },

  lessonStarsTooltip: null,
  showLessonStarsTooltip: function(e) {
    var id = parseInt($(e.currentTarget).data('id')),
      userLesson = this.userLessons.get(id),
      lesson = this.lessons.get(id),
      data = {screens: lesson.get('screens'), stars: 0},
      showStars = !(lesson.get("content_type") === 'adventure' && lesson.get("settings")?.display === 'story');

    if(userLesson){
      data.stars = userLesson.get('stars');
    }

    if(!this.lessonStarsTooltip) {
      this.lessonStarsTooltip = lessons_lesson_tooltip;
    }

    Tooltip.show(e.currentTarget, {
      force: true,
      text: this.lessonStarsTooltip({...data, showStars: showStars , lodash: { times }})
    });
  },

  print: function(e){
    var id = $(e.currentTarget).data('id'),
      user = Registry.get('student');

    if(!(user.get('first_name') && user.get('last_name'))) {
      if(!Registry.get('loggedIn')) {
        (new Alert({
          error: false,
          text: 'lesson.log_in_to_print'.t({
            linkStart1: '<a class="link" href="' + __url('/student/login') + '">',
            linkStart2: '<a class="link" href="' + __url('/student/signup') + '">',
            linkEnd: "</a>"
          })
        }).show());
      } else if(user.get('in_class') && user.hasOption('lockaccount')) {
        (new Alert({
          error: false,
          title: 'lesson.unable_to_print'.t(),
          text: 'lesson.contact_teacher_to_print'.t()
        }).show());
      } else {
        (new Alert({
          error: false,
          title: 'lesson.unable_to_print'.t(),
          cancel: 'shared.cancel_text'.t(),
          text: 'lesson.enter_name_to_print'.t(),
          ok: 'lesson.go_to_account_settings'.t()
        }).show().on('ok', function(){
          window.location.href = __url('/student/account');
        }));
      }

      return;
    }

    window.open('/apiv1/student/units/' + id + '/' + user.id + '/certificate?language=' + FTWGLOBALS('language') + '&product_id=' + user.getSetting('product_id'));
  }
})
