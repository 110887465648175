import FormModel from '@shared/form_model'

export default FormModel.extend({

  defaults: {

  },

  validationRules: {
    first_name: {
      required: true
    },
    last_name: {
      required: true
    },
    email: {
      email: true
    }
  },
  validationMessages: {

  },

  url: '/apiv1/student/user/profile'

})
