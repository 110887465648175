import FormModel from '@shared/form_model'

export default FormModel.extend({
  url: '/apiv1/student/auth/password-reset',

  defaults: {
    email: ''
  },

  validationRules: {
    email: {
      email: true,
      // emailExtended: true,
      required: true
    }
  }
})
