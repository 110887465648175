import Backbone from 'backbone'
import Registry from '@registry'
import MainView from './views/main'
import Dictation from '../lesson/classes/dictation'
import LayoutView from './views/layout'

  export default Backbone.View.extend({
    initialize: function() {
      this.dictation = new Dictation(null, null, Registry.get('student'));

      this.layout = new LayoutView({ dictation: this.dictation });

      Backbone.View.prototype.initialize.apply(this, arguments);

      this.views.mainView = new MainView({ assignmentId: this.assignment_id, dictation: this.dictation });
      this.addChild(this.el, this.views.mainView, true);

      this.render();
    }
})
