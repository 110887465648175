import Backbone from 'backbone'
import Registry from '@registry'
import { upgrade_main } from '@templates/student'

export default Backbone.View.extend({
  template: upgrade_main,

  serialize: function() {
    return {
      hasLicense: Registry.get('student').get('licensed_at'),
      miniNav: this.miniNav
    }
  }
})
