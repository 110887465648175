import FormModel from '@shared/form_model'

export default FormModel.extend({
  urlRoot: '/apiv1/student/contact/report-ad',

  validationRules: {
    file: {
      required: true
    },
    reason: {
      required: true,
      maxlength: 255
    },
    email: {
      required: true,
      email: true
    }
  }
})
