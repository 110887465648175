import BrowserView from './screen_complete_coding_browser'
import ScreenCompleteView from './screen_complete'
import { lesson_screen_complete_coding } from '@templates/student'

export default ScreenCompleteView.extend({

  template: lesson_screen_complete_coding,

  initialize: function() {
    ScreenCompleteView.prototype.initialize.apply(this, arguments);

    this.views.browser = new BrowserView({
      userLesson: this.userLesson,
      screens: this.screens,
      screen: this.screen,
      lesson: this.lesson
    });
    this.addChild('.js-browser', this.views.browser, true);
  }

})

