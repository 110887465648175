import Backbone from 'backbone'
import Registry from '@registry'
import Scoring from '@shared/scoring'
import GoalView from '../../global/views/goal'
import GoalAchievedView from '../../global/views/goal_achieved';
import ProgressBarView from '../../global/views/progress_bar'
import SkinsCollection from '../../global/collections/skins'
import AdView from '../../global/views/ad'
import { lesson_screen_failed } from '@templates/student'

export default Backbone.View.extend({

  saved: false,

  events: {
    'click .js-continue-button': 'restart'
  },

  template: lesson_screen_failed,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    if(this.progressView) {
      this.addChild('.js-progress', this.progressView, true);
    }

    this.user = Registry.get('student');
    this.userActivity = Registry.get('userActivity');
    this.skins = new SkinsCollection(FTWGLOBALS('skins'));
    this.skin = this.skins.get(this.user.get('skin_id') || 1);

    this.initializeGoal()
  },

  serialize: function() {
    var screen = this.screen.toJSON();
    var lesson = this.lesson.toJSON();
    var speed = Scoring.speed(this.screen.get('typed'), this.screen.get('seconds'), this.screen.get('errors'));
    var accuracy = Scoring.accuracy(this.screen.get('typed'), this.screen.get('errors'));

    // Inline games should show an accuracy of zero if no keys were typed
    if((this.screen.get('screen_type') === 'keyboard-jump') && this.screen.get('typed') === 0) {
      accuracy = 0;
    }

    const userActivity = Registry.get('userActivity'),
      totalSeconds = (userActivity.getCompiled(1)?.seconds || 0),
      goalSeconds = this.user.getSetting('daily_goal') * 60,
      activity = this.userActivity.getOrAdd(0).toJSON(),
      recentSeconds = this.screen.get('seconds');

    this.rank = this.skin.getRank(activity.typed - activity.errors);
    this.nextRank = this.skin.getNextRank(activity.typed - activity.errors);
    this.rankXPOld = (activity.typed - activity.errors) - (this.screen.get('typed') - this.screen.get('errors'));
    this.rankXPTotal = activity.typed - activity.errors;
    this.rankProgressOld = (this.nextRank) ? (this.rankXPOld - this.rank.experience)/(this.nextRank.experience - this.rank.experience)*100 : 100;
    this.rankProgressTotal = (this.nextRank) ? (this.rankXPTotal - this.rank.experience)/(this.nextRank.experience - this.rank.experience)*100 : 100;

    this.updateGoal(this.screen.get('seconds'))

    return {
      renderInline: this.renderInline,
      ads: AdView.canShowAds(),
      lesson: lesson,
      screen: screen,
      accuracy: accuracy,
      speed: speed,
      finishedScreen: this.screen.get('typed') >= this.screen.get('content').length,
      time: (screen.seconds || 0).countdownSeconds(),
      screenType: this.screenType,
      rank: this.rank,
      nextRank: this.nextRank,
      skin: this.skin.toJSON(),
      variantId: this.user.get('variant_id'),
      productGrade: this.user.getCurriculumGrade(),
      showGoal: !!this.goalView,
      isFasterThanMinSpeed: screen.min_speed >= speed,
      isBetterThanMinAccuracy: screen.min_acc >= accuracy
    };
  },

  render: function() {
    Registry.set('preventKeyboardInput', false);

    Backbone.View.prototype.render.apply(this, arguments);

    this.listenTo(this.input, 'character', this.handleKeyPress);

    var data = this.serialize();
    data.accuracy = Scoring.accuracy(this.screen.get('typed'), this.screen.get('errors'));
    data.speed = Scoring.speed(this.screen.get('typed'), this.screen.get('seconds'), this.screen.get('errors'));
    data.seconds = this.screen.get('seconds');

    this.dictation.setProperties(this.screen, null);
    this.dictation.speakFailed(data);


    // The progress bar needs to be rendered after the student finishes typing, which is on render of this screen
    if (this.$('.js-progress-bar').length) {
      var progressBarView = new ProgressBarView({
        user: this.user,
        rank: this.rank,
        totalRanks: this.skin.getTotalRanks(),
        nextRank: this.nextRank,
        rankXPOld: this.rankXPOld,
        rankXPTotal: this.rankXPTotal,
        rankProgressOld: this.rankProgressOld,
        rankProgressTotal: this.rankProgressTotal
      });
      this.$('.js-progress-bar').append(progressBarView.render().el);
    }

    return this;
  },


  restart: function() {
    this.trigger('continue', 'failed', this.$('.js-continue-button'));
    return false;
  },

  handleKeyPress: function(e) {
    if (e.key === '\n') {
      this.restart();
    }
  },

  initializeGoal: function() {
    // Do not show if goal is ZERO and they are in a class
    if(this.screen.get('screen_type') !== 'coding' && (!this.user.get('in_class') || (this.user.get('in_class') && this.user.getSetting('daily_goal') > 0))) {
      // Create a goal view that will be updated in serialize after we get all the most recent values
      this.goalView = new GoalView({
        noAnimation: true,
        type: 'screenComplete',
        totalSeconds: this.userActivity.getCompiled(1)?.seconds || 0,
        goalSeconds: this.user.getSetting('daily_goal') * 60,
        editable: !this.user.get('in_class')
      })
      // Do not render initially, the goal view will get rendered in serialize
      this.addChild('.js-goal', this.goalView, true, false)
    }
  },

  updateGoal: function(recentSeconds) {
    if(this.goalView) {
      const totalSeconds = (this.userActivity.getCompiled(1)?.seconds || 0),
        goalSeconds = this.user.getSetting('daily_goal') * 60

      this.goalView.updateValues(totalSeconds, recentSeconds)
      if(!this.goalAchieved && goalSeconds > 0) {
        // if user's recent progress made them achieve their goal time.
        if (totalSeconds - recentSeconds < goalSeconds && totalSeconds >= goalSeconds) {
          this.goalAchieved = new GoalAchievedView({
            recentSeconds: recentSeconds,
            totalSeconds: totalSeconds,
            goalSeconds: goalSeconds
          }).show()
        }
      }
    }
  }

})
