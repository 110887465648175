import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import Scoring from '@shared/scoring'
import ProgressBarView from './progress_bar'
import GoalView from './goal'
import { find, isArray } from 'lodash-es'
import { global_dashboard, global_dashboard_mini } from '@templates/student'

export default Backbone.View.extend({

  template: global_dashboard,

  events: {
    'click .js-time': function(e) { this.trigger('change-time', e); }
  },

  // date changing event is moved up to layout because of re-render needs
  dateChoices: [
    [1, 'dashboard.today'.t()],
    [2, 'dashboard.yesterday'.t()], 
    ['this_week', 'shared.this_week'.t()],
    ['last_week', 'shared.last_week'.t()],
    [7, 'dashboard.last_7_days'.t()],
    [30, 'dashboard.last_30_days'.t()],
    [0, 'dashboard.all_time'.t()]
  ],

  showGoal: false,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.user = Registry.get('student');

    const skin = this.user.getSkin(),
      rank = this.user.getSkinRank(),
      nextRank = this.user.getSkinNextRank()

    this.userActivity = Registry.get('userActivity');
    this.activity = this.userActivity.getOrAdd(0).toJSON();

    this.rankXPTotal = this.activity.typed - this.activity.errors;
    this.rankXPOld = this.user.get('_rankXPOld') || this.rankXPTotal;
    this.rankProgressTotal = (nextRank) ? 100-((nextRank.experience - (this.activity.typed - this.activity.errors))/(nextRank.experience - rank.experience))*100 : 100;
    this.rankProgressOld = this.user.get('_rankProgressOld') || this.rankProgressTotal;

    if(!this.mini) { // Only set the previous rank progress when viewing the main dashboard
      this.user.set({ _rankProgressOld: this.rankProgressTotal, _rankXPOld: this.rankXPTotal });
    }

    if(this.mini) {
      this.template = global_dashboard_mini;
    } else if (skin.get('file') !== 'basic') {
      this.progressBarView = new ProgressBarView({
        user: this.user,
        totalRanks: skin.getTotalRanks(),
        rankXPTotal: this.rankXPTotal,
        rankXPOld: this.rankXPOld,
        rankProgressTotal: this.rankProgressTotal,
        rankProgressOld: this.rankProgressOld
      });
      this.addChild('.js-progress-bar', this.progressBarView, true);

      // Do not show if goal is ZERO and they are in a class
      if(!this.user.get('in_class') || (this.user.get('in_class') && this.user.getSetting('daily_goal') > 0)) {
        this.showGoal = true
        const goalView = new GoalView({
          type: 'dashboard',
          noAnimation: true,
          totalSeconds: this.userActivity.getCompiled(1)?.seconds,
          goalSeconds: this.user.getSetting('daily_goal') * 60,
          editable: !this.user.get('in_class')
        })
        this.addChild('.js-goal', goalView, true);
      }
    }

    this.listenTo(this.user, 'change:skin_id change:variant_id', function(){
      this.render();
      window.setTimeout(function(){
        $('linearGradient').hide().show(0);
      },50);
    }).bind(this);
  },

  serialize: function() {
    const studentStatsRange = parseInt(this.user.get('studentStatsRange')) || 0,
      dashActivity = this.userActivity.getCompiledData(studentStatsRange),
      skin = this.user.getSkin(),
      rank = this.user.getSkinRank(),
      nextRank = this.user.getSkinNextRank()

    const daysBackChoice = find(this.dateChoices, function(choice) { return choice[0] === studentStatsRange; })

    return {
      ads: this.ads,
      dateChoices: this.dateChoices,
      daysBackText: daysBackChoice ? daysBackChoice[1] : '',
      loggedIn: Registry.get('loggedIn'),
      user: this.user.toJSON(),
      skin: skin.toJSON(),
      skins: Registry.get('skins').toJSON(),
      scoreboard: Registry.get('student').hasOption('scoreboard'),
      lockAccount: Registry.get('student').hasOption('lockaccount'),
      changePassword: Registry.get('student').hasOption('changepassword'),
      noGames: (isArray(Registry.get('student').get('games')) && Registry.get('student').get('games').length === 0),
      speed: Scoring.speed(dashActivity.typed, dashActivity.seconds, dashActivity.errors),
      accuracy: Scoring.accuracy(dashActivity.typed, dashActivity.errors),
      time: dashActivity.seconds || 0,
      totalRanks: skin.getTotalRanks(),
      rank: rank,
      nextRank: nextRank,
      rankProgressTotal: this.rankProgressTotal,
      rankProgressOld: this.rankProgressOld,
      activity: this.activity,
      showGoal: this.showGoal,
      product_id: Registry.get('student').getSetting('product_id'),
      logoPath: this.user.get('district')?.logo
    };

  }
})
