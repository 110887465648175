import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import { postJSON } from '@shared/helpers'
import { find } from 'lodash-es';
import SVG from '../views/svg';

const UserLessonModel = Backbone.Model.extend({

  idAttribute: 'lesson_id',

  defaults: {
    progress: 0,
    max_progress: 0,
    typed: 0,
    errors: 0,
    seconds: 0,
    completed: 0,
    updated_at: 0,
    created_at: 0,
    last_save_response: null
  },

  saveStats: function(stats, keys) {
    if(stats) {
      const user = Registry.get('student');
      if(Registry.get('loggedIn')) {
        stats.user_id = user.id;
        stats.licensed_at = user.get('licensed_at')
      }
      stats.language = user.getSetting('language')
      stats.product_id = user.getSetting('product_id')
      stats.product_id_pk = user.getSetting('product_id_pk')

      if(user.get('section_id')) {
        stats.section_id = user.get('section_id');
      }
      if(user.get('teacher_id')) {
        stats.teacher_id = user.get('teacher_id');
      }

      delete stats.lastTry;

      return postJSON('/apiv1/student/stats', {stats: stats, keys: keys}).done((data) => {
        if(data) {
          // save the response incase any views need it (like test screen knowing ID of last score to print cert)
          this.set({ last_save_response: data })
        }
      });
    }

    return $.Deferred().resolve();
  },

  saveTYOAProgress: function(stats) {
    if(stats) {
      const user = Registry.get('student');
      if(Registry.get('loggedIn')) {
        stats.user_id = user.id;
        stats.licensed_at = user.get('licensed_at')
      }

      if(user.get('section_id')) {
        stats.section_id = user.get('section_id');
      }
      if(user.get('teacher_id')) {
        stats.teacher_id = user.get('teacher_id');
      }

      stats.tyoa_pick = true
      stats.lesson_id = this.id
      return postJSON('/apiv1/student/stats', { stats: stats }).done((data) => {
        this.set({ last_save_response: data, progress: stats.progress })
      })
    }

    return $.Deferred().resolve();
  },

  saveResponseProgress: function(stats) {
    const user = Registry.get('student');

    if(stats && Registry.get('loggedIn')) {
      stats.user_id = user.id

      return postJSON('/apiv1/student/save-response-progress', { stats: stats })
    }

    return $.Deferred().resolve();
  },

  restart: function() {
    if (Registry.get('loggedIn')) {
      return postJSON('/apiv1/student/lessons/restart', {lesson_id: this.id});
    }
    return $.Deferred().resolve();
  }
})

export default Backbone.Collection.extend({
  model: UserLessonModel,

  getBadge: function(unit, lesson) {
    const path = '/dist/student/images/badges/badge-lesson-' + unit.badge + '.svg';

    let unitName = unit.name.replace("& Communication", "").replace("Curricular Typing", "Curricular");

    if(unitName.match('Punctuation')) {
      unitName = "Punctuation"
    }

    // grab the SVG by ajax so we can modify it and slap it on the page
    return new SVG({
      path: path,
      preprocess: function(svgEle){ // downloads the elements and switches out necessary text
        const titleText = svgEle.querySelector('#pathTitleText')
        titleText.textContent = lesson.name.escapeHTML();
        svgEle.querySelector('#subTitleText').textContent = unitName.escapeHTML();
        if(titleText.textContent.length >= 35) {
          titleText.setAttribute('class', 'achievement-title--xxs');
        } else if(titleText.textContent.length >= 30) {
          titleText.setAttribute('class', 'achievement-title--xs');
        } else if(titleText.textContent.length >= 25) {
          titleText.setAttribute('class', 'achievement-title--sm');
        }
      }.bind(this)
    });
  },

  getBadgeForId: function(lessonId) {
    const lesson = find(FTWGLOBALS('lessons'), {lesson_id: parseInt(lessonId)}),
      unit = find(FTWGLOBALS('units'), {unit_id: lesson.unit_id});

    if(!lesson || !unit) { return false; }

    return this.getBadge(unit, lesson)
  }
})

