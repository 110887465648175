import Backbone from 'backbone'
import Registry from '@registry'
import PasswordView from './password'
import ProfileView from './profile'
import JoincodeView from './join_code'
import ProfileForm from '../models/profile_form'
import PasswordForm from '../models/password_form'
import JoincodeForm from '../models/joincode_form'
import ConfirmWithInputModal from '../../global/views/confirm_with_input'
import AdButtonsView from '../../global/views/ad_buttons'
import Notice from '@shared/notice'
import Alert from '../../global/views/alert'
import AdView from '../../global/views/ad'
import moment from 'moment'
import { each } from 'lodash-es'
import { account_main } from '@templates/student'

export default Backbone.View.extend({

  template: account_main,

  events: {
    'click .js-delete-self': 'deleteSelf'
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    if(AdView.canShowAds()) {
      AdView.initAds('other');
      // grab all the ads and assign them to the right divs
       each(AdView.getAds(), (ad, size) => {
        if(!ad) { return; }
        this.addChild('.js-'+size+'-ad', ad);
      });

      var adButtonsView = new AdButtonsView();
      this.addChild('.js-ad-buttons', adButtonsView, false);
    }

    this.user = Registry.get('student');
    const loginType = this.user.get('login_type')

    // Only users who login with a username/password can change their name/password
    if(loginType === 'username') {
      if (!this.user.get('in_class') || !this.user.hasOption('lockaccount')) {
        this.views.profile = new ProfileView({
          model: new ProfileForm()
        });
        this.addChild('.js-profile', this.views.profile, false);
      }

      if (!this.user.get('in_class') || this.user.hasOption('changepassword')) {
        this.views.password = new PasswordView({
          model: new PasswordForm()
        });

        this.addChild('.js-password', this.views.password, false);
      }
    }

    if((loginType !== 'clever' && loginType !== 'clever_library' && loginType !== 'classlink') && !this.user.get('must_update_name') && !this.user.get('must_change_password')) {
      this.views.joincode = new JoincodeView({
        model: new JoincodeForm()
      });
      this.addChild('.js-joincode', this.views.joincode, false);
    }

  },

  serialize: function() {
    return {
      moment: moment,
      ads: AdView.canShowAds(),
      adsClass: AdView.getLeftMarginClass(),
      user: this.user.toJSON(),
      lockAccount: this.user.get('in_class') && this.user.hasOption('lockaccount'),
      changePassword: this.user.hasOption('changepassword')
    };
  },

  deleteSelf: function() {
    new ConfirmWithInputModal({
      titleText: 'account.delete_self_title'.t(),
      description: 'account.delete_self_text'.t(),
      destructive: true,
      okText: 'account.delete_self'.t(),
      successNotice: undefined
    }).open().done(() => {
      const loadingModal = new Alert({
        title: 'shared.processing_title'.t(),
        text: 'shared.processing_text'.t(),
        ok: '',
        cancel: '',
        closeButton: false
      });

      return this.user.destroy()
        .done(() => {
          this.user.logOut();
          Registry.set('loggedIn', false);
          // Tell the user goodbye
          new Alert({
            title: 'account.goodbye_title'.t(),
            text: 'account.goodbye_text'.t(),
            closeButton: false
          }).on('ok', function() {
            window.location.href = __url('/');
          });
        })
        .fail(() => {
          new Notice({
            error: true,
            text: 'account.error_deleting_self'.t()
          }).show();
        })
        .always(() => {
          loadingModal.close()
        });
    });
  }
})
