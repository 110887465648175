import Backbone from 'backbone'
import $ from 'jquery'
import LoadingView from './views/loading'
import FailedView from './views/failed'
import JoinOptionsView from './views/join_options'
import LayoutView from '../global/views/layout_basic'

export default Backbone.View.extend({

  layout: LayoutView,

  section: {},

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.layout = new LayoutView({ joinCode: this.joinCode });

    this.views.loading = new LoadingView();
    this.views.joinOptions = new JoinOptionsView({ joinCode: this.joinCode });
    this.views.joinOptions.$el.fastHide();

    this.views.failed = new FailedView();

    // Validate the join code
    if(this.joinCode) {
      $.post('/apiv1/student/auth/join-validate', { join_code: this.joinCode })
        .done((data) => {
          this.section = data;
          this.showJoinCodeOptions();
        })
        .fail(this.showFailed.bind(this));

      this.addChild(this.el, this.views.joinOptions, true);
      this.addChild(this.el, this.views.loading, true);
      this.views.failed.$el.fastHide();
    }

    this.addChild(this.el, this.views.failed, true);

    this.render();

    if(!this.joinCode) {
      window.setTimeout(() => this.showFailed(), 0);
    }
  },

  showJoinCodeOptions: function () {
    this.views.loading.$el.fastHide();

    this.views.joinOptions.section = this.section;
    // joinOptions form needs to render again in order to show the Join Code's Section data
    this.views.joinOptions.render().$el.fastShow();
  },

  showFailed: function (request) {
    if(request && request.responseJSON && request.responseJSON.error) {
      this.views.failed.error = request.responseJSON.error;
      this.views.failed.render();
    }
    this.views.loading.$el.fastHide();
    this.views.failed.$el.fastShow();
  }
})
