import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import { uniq, find, indexOf, isObject } from 'lodash-es'
import { global_keyboard } from '@templates/student'

const ACCENT_KEYS = [
  {accentKey: 'á', key: 'a', accent: 'acute'},
  {accentKey: 'â', key: 'a', accent: 'circumflex'},
  {accentKey: 'ä', key: 'a', accent: 'umlaut'},
  {accentKey: 'à', key: 'a', accent: 'grave'},
  {accentKey: 'ã', key: 'a', accent: 'tilde'},

  {accentKey: 'é', key: 'e', accent: 'acute'},
  {accentKey: 'ê', key: 'e', accent: 'circumflex'},
  {accentKey: 'ë', key: 'e', accent: 'umlaut'},
  {accentKey: 'è', key: 'e', accent: 'grave'},

  {accentKey: 'í', key: 'i', accent: 'acute'},
  {accentKey: 'î', key: 'i', accent: 'circumflex'},
  {accentKey: 'ï', key: 'i', accent: 'umlaut'},
  {accentKey: 'ì', key: 'i', accent: 'grave'},

  {accentKey: 'ó', key: 'o', accent: 'acute'},
  {accentKey: 'ô', key: 'o', accent: 'circumflex'},
  {accentKey: 'ö', key: 'o', accent: 'umlaut'},
  {accentKey: 'ò', key: 'o', accent: 'grave'},
  {accentKey: 'õ', key: 'o', accent: 'tilde'},

  {accentKey: 'ú', key: 'u', accent: 'acute'},
  {accentKey: 'û', key: 'u', accent: 'circumflex'},
  {accentKey: 'ü', key: 'u', accent: 'umlaut'},
  {accentKey: 'ù', key: 'u', accent: 'grave'},

  {accentKey: 'ç', key: 'c', accent: 'cedila'},

  {accentKey: 'ñ', key: 'n', accent: 'tilde'},
];

const ACCENTS = {
  grave: '`',
  acute: '´',
  circumflex: 'ˆ',
  umlaut: '¨',
  cedila: 'ç',
  tilde: '˜'
};

for(var i=0, len = ACCENT_KEYS.length; i < len; i++) {
  ACCENT_KEYS.push({
    accentKey: ACCENT_KEYS[i].accentKey.toUpperCase(),
    key: ACCENT_KEYS[i].key.toUpperCase(),
    accent: ACCENT_KEYS[i].accent
  });
}

export default Backbone.View.extend({

  highlighted: [],
  lastFingers: ['right-resting-hand', 'left-resting-hand'],
  fingerCache: {},
  keysCache: {},

  template: global_keyboard,

  interactive: true,
  showSettings: true, // the "Keyboard Settings" box
  hasShadow: false,

  awaitingDead: false,

  structure: null,
  keyMetaData: {},
  accentKeys: {},

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.user = Registry.get('student');

    if(this.showHands) {
      var classes = 'hands keyboard-hands';
      var isNumpad = this.model.get('type') === 'keypad';

      this.screenType = this.screen.get('screen_type');
      if(this.screenType === 'falling') {
        classes += ' is-falling';
      }
      if(isNumpad) {
        classes += ' hands--numpad';
      }
    }

    this.listenTo(this.user, 'change', this.toggleWarning);

    this.setupKeyStructures();

    this.cacheFingers();
  },

  render: function() {
    this.setupKeyStructures();

    Backbone.View.prototype.render.call(this);

    if(this.screen) {
      this.cacheKeys();
    }

    this.hands = {left: this.$('.js-left-hand'), right: this.$('.js-right-hand')};
    return this;
  },

  toggleHands: function() {
    if(this.screenType !== 'block' && !this.user.getSetting('show_hands')) {
      this.$('.js-hands').addClass('hide');
    } else {
      this.$('.js-hands').removeClass('hide');
    }
  },

  toggleWarning: function() {
    if(this.user.get('_invalidKeyboard')) {
      this.$('.js-keyboard-link-error').fastShow();
    } else {
      this.$('.js-keyboard-link-error').fastHide();
    }
  },

  serialize: function() {
    return {
      type: (this.model.get('type') === 'keypad') ? '10key' : this.model.get('type'),
      test: this.test,
      noHands: this.noHands || !this.user.getSetting('show_hands'),
      screenType: this.screenType,
      interactive: this.interactive,
      keyboardMismatch: this.user.get('_invalidKeyboard'),
      structure: this.structure,
      showKeyboard: this.user.getSetting('show_keyboard') || this.screenType === 'falling',
      skinName: this.user.getSkinName(),
      showSettings: this.showSettings,
      hasShadow: this.hasShadow,
      imageDensity: window.devicePixelRatio >= 2 ? '@2x' : '',
    };
  },

  setupKeyStructures: function() {
    var type = this.model.get('type');
    var usKeyboard = find(FTWGLOBALS('keyboards'), { keyboard_id: FTWGLOBALS('languageLibraryMap')['keyboard'][FTWGLOBALS('defaultLanguage')] });

    // add the key's column to the structure.  we will need this to know how to move fingers around
    this.structure = this.model.get('structure');

    var offsetsColumn1 = {};

    this.structure.forEach(function(row, rowIndex){
      row.forEach(function(key, keyIndex) {
        if(!isObject(key.main)) { // data can be like "main":"s", make it "main": {key:"s", "code":155}
          key.main = {
            key: key.main
          };
        }
        if(!key.main.code && (key.main.key || key.main.dead)){
          key.main.code = (key.main.key || ACCENTS[key.main.dead]).charCodeAt(0);
        }

        if(key.shifted) {
          if(!isObject(key.shifted)) {
            key.shifted = {
              key: key.shifted
            };
          }
          if(!key.shifted.code && (key.shifted.key || key.shifted.dead)){
            key.shifted.code = (key.shifted.key || ACCENTS[key.shifted.dead]).charCodeAt(0);
          }
        }
        if(key.hidden) {
          if(!isObject(key.hidden)) {
            key.hidden = {
              key: key.hidden
            };
          }
          if(!key.hidden.code && (key.hidden.key || key.hidden.dead)){
            key.hidden.code = (key.hidden.key || ACCENTS[key.hidden.dead]).charCodeAt(0);
          }
        }
        if(key.alt) {
          if(!isObject(key.alt)) {
            key.alt = {
              key: key.alt
            };
          }
          if(!key.alt.code && (key.alt.key || key.alt.dead)){
            key.alt.code = (key.alt.key || ACCENTS[key.alt.dead]).charCodeAt(0);
          }
        }
        if(key.shiftalt) {
          if(!isObject(key.shiftalt)) {
            key.shiftalt = {
              key: key.shiftalt
            };
          }
          if(!key.shiftalt.code && key.shiftalt.key){
            key.shiftalt.code = (key.shiftalt.key || ACCENTS[key.shiftalt.dead]).charCodeAt(0);
          }
        }

        if(type === 'dvorak') {
          if(rowIndex === 0 && key.main.key === '1') {
            offsetsColumn1[rowIndex] = Math.abs(2-keyIndex);
          }
          if(rowIndex === 1 && key.main.key === '\'') {
            offsetsColumn1[rowIndex] = Math.abs(1-keyIndex);
          }
          if(rowIndex === 2 && key.main.key === 'a') {
            offsetsColumn1[rowIndex] = Math.abs(1-keyIndex);
          }
          if(rowIndex === 3 && key.main.key === ';') {
            offsetsColumn1[rowIndex] = Math.abs(1-keyIndex);
          }
        } else if(type === 'colemak') {
          if(rowIndex === 0 && key.main.key === '1') {
            offsetsColumn1[rowIndex] = Math.abs(1-keyIndex);
          }
          if(rowIndex === 1 && key.main.key === 'q') {
            offsetsColumn1[rowIndex] = Math.abs(1-keyIndex);
          }
          if(rowIndex === 2 && key.main.key === 'a') {
            offsetsColumn1[rowIndex] = Math.abs(1-keyIndex);
          }
          if(rowIndex === 3 && key.main.key === 'z') {
            offsetsColumn1[rowIndex] = Math.abs(1-keyIndex);
          }
        } else if(type === 'keypad') {
          if(rowIndex === 0 && key.main.key === '↹') {
            offsetsColumn1[rowIndex] = keyIndex-6;
          }
          if(rowIndex === 1 && key.main.key === '7') {
            offsetsColumn1[rowIndex] = keyIndex-6;
          }
          if(rowIndex === 2 && key.main.key === '4') {
            offsetsColumn1[rowIndex] = keyIndex-6;
          }
          if(rowIndex === 3 && key.main.key === '1') {
            offsetsColumn1[rowIndex] = keyIndex-6;
          }
          if(rowIndex === 4 && key.main.key === '0') {
            offsetsColumn1[rowIndex] = keyIndex-6;
          }
        } else {
          // here I'm looking at keys that are always in the same "finger column" and creating offsets, then I go back and set the column based on those
          if(rowIndex === 0 && key.main.key === '1') {  // 1 key is always in the same position, 1 key from column one
            offsetsColumn1[rowIndex] = Math.abs(2-keyIndex);
          }
          if(rowIndex === 1 && key.main.key === 'e') {  // e key is always in the same position, 2 keys from column one
            offsetsColumn1[rowIndex] = Math.abs(3-keyIndex);
          }
          if(rowIndex === 2 && key.main.key === 's') {  // s is always in the same position, 1 key from column 1
            offsetsColumn1[rowIndex] = Math.abs(2-keyIndex);
          }
          if(rowIndex === 3 && key.main.key === 'x') {  // x is always in the same position, 1 key from column 1
            offsetsColumn1[rowIndex] = Math.abs(2-keyIndex);
          }
        }

      });
    });

    this.structure.forEach(function(row, rowIndex){
      row.forEach(function(key, keyIndex) {
        key.column = (keyIndex - ((offsetsColumn1[rowIndex]) ? offsetsColumn1[rowIndex] : 0)) + 1;
        key.row = rowIndex;
        key.finger = parseInt(key.finger);
        this.keyMetaData[key.main.code] = key;
        if(key.main.dead) {
          this.accentKeys[key.main.dead] = {key: ACCENTS[key.main.dead] || key.main.key};
          if(key.main.trigger) {
            ACCENT_KEYS.push({accentKey: key.main.key, key: key.main.trigger, accent: key.main.dead });
          }
        }
        if(key.shifted) {
          if(key.shifted.dead) {
            this.accentKeys[key.shifted.dead] = {modifier: 'shift', key: ACCENTS[key.shifted.dead] || key.shifted.key};
            if(key.shifted.trigger) {
              ACCENT_KEYS.push({accentKey: key.shifted.key, key: key.shifted.trigger, accent: key.shifted.dead });
            }
          }
          this.keyMetaData[key.shifted.code] = key;
        }
        if(key.hidden) {
          this.keyMetaData[key.hidden.code] = key;
        }
        if(key.alt) {
          if(key.alt.dead) {
            this.accentKeys[key.alt.dead] = {modifier: 'alt', key: ACCENTS[key.alt.dead] || key.alt.key};
            if(key.alt.trigger) {
              ACCENT_KEYS.push({accentKey: key.alt.key, key: key.alt.trigger, accent: key.alt.dead });
            }
          }
          this.keyMetaData[key.alt.code] = key;
        }
        if(key.shiftalt) {
          if(key.shiftalt.dead) {
            this.accentKeys[key.shiftalt.dead] = {modifier: 'shiftalt', key: ACCENTS[key.shiftalt.dead] || key.shiftalt.key};
            if(key.shiftalt.trigger) {
              ACCENT_KEYS.push({accentKey: key.shiftalt.key, key: key.shiftalt.trigger, accent: key.shiftalt.dead });
            }
          }
          this.keyMetaData[key.shiftalt.code] = key;
        }
        if(!key.hardware && usKeyboard.structure[rowIndex][keyIndex] !== undefined) {
          key.hardware = usKeyboard.structure[rowIndex][keyIndex].hardware;
        }
      }.bind(this));
    }.bind(this));

  },

  /**
   * Translate away newlines and nulls
   * @param {String} key
   * @returns {String} the letter
   */
  translateKey: function(key) {
    key = key || '';

    if (key === '\n') {
      key = ' ';
    }

    return key;
  },

  /**
   * Get the Char Code for the letter
   * @param {String} key
   * @returns {Number}
   */
  getKeyCode: function(key) {
    // key = this.translateKey(key);

    return (key === '⏎' || key === '\n') ? 13 : key.toLowerCase().charCodeAt(0); // char code
  },

  /**
   * Get the jQuery html element for the letter on the virtual keyboard
   * @param {String} key
   * @param awaitingDead
   * @returns {$.Element}
   */
  getKeyElement: function(key, awaitingDead) {
    if(!this.keysCache[key]) {
      var className = '.key-' + this.getKeyCode(key) + (awaitingDead ? '-dead' : '');
      this.keysCache[key] = this.$(className);
      if(this.keysCache[key] && this.keysCache[key].length && !this.keysCache[key][0].defaultClasses) {
        this.keysCache[key][0].defaultClasses = this.keysCache[key][0].className;
      }
    }

    return this.keysCache[key]; // the actual key html element
  },

  /**
   * Get the finger integer for the key
   * @param key
   * @returns {Number} 1 for left pinkie through 10 for right pinkie
   */
  getFingerFor: function(key) {
    return (this.keyMetaData[this.getKeyCode(key)]) ? this.keyMetaData[this.getKeyCode(key)].finger : 0;
  },

  /**
   * Get the column integer for the key
   * @param key
   * @returns {Number} 1 for left pinky on A key, and key going from there to the right
   */
  getKeyboardColumnFor: function(key) {
    return (this.keyMetaData[this.getKeyCode(key)]) ? this.keyMetaData[this.getKeyCode(key)].column : undefined;
  },

  /**
   * Return the keyboard row index.  0 is number keys, 1 is top row, 2 is homerow, 3 is bottom row
   * @param key
   * @returns {Number} row of keys
   */
  getRowFor: function(key) {
    return (this.keyMetaData[this.getKeyCode(key)]) ? this.keyMetaData[this.getKeyCode(key)].row : undefined;
  },

  getKeyRequiresShift: function(key) {
    // if we need a dead key then it's an shift thing but only if the symbol map for this OS starts with a shift key
    if(this.awaitingDead && this.awaitingDead.modifier === 'shift') { return true; }

    var data = this.keyMetaData[this.getKeyCode(key)];
    if(!data) { return false; }
    return key !== key.toLowerCase() || (data.shifted && data.shifted.code === this.getKeyCode(key)) || (data.shiftalt && data.shiftalt.code === this.getKeyCode(key));
  },

  getKeyRequiresAlt: function(key) {
    // if we need a dead key then it's an alt thing but only if the symbol map for this OS starts with an alt key
    if(this.awaitingDead && this.awaitingDead.modifier === 'alt') { return true; }

    var data = this.keyMetaData[this.getKeyCode(key)];
    if(!data) { return false; }
    return (data.alt && data.alt.code === this.getKeyCode(key)) || (data.shiftalt && data.shiftalt.code === this.getKeyCode(key));
  },

  getKeyRequiresDead: function(key) {
    var accentMap = find(ACCENT_KEYS, {accentKey: key});
    if(!accentMap || !this.accentKeys[accentMap.accent]) {
      return false;
    }

    return (accentMap.accent && this.accentKeys[accentMap.accent])
      ? {deadKey: this.accentKeys[accentMap.accent].key, modifier: this.accentKeys[accentMap.accent].modifier, key: accentMap.key, accent: accentMap.accent}
      : false;
  },

  getFingerIds: function(key) {
    var finger = this.getFingerFor(key),    // which finger
      isCapsLockWord = this.screen.get('isCapsLockWord'),
      caps = (isCapsLockWord) ? false : this.getKeyRequiresShift(key),
      alt = this.getKeyRequiresAlt(key),
      row = this.getRowFor(key),
      column = this.getKeyboardColumnFor(key),
      hand = (column <= ((row === 0) ? 5 : 6)) ? 'left' : 'right',
      rowWord, fingerIndex;

    if(this.model.get('type') === 'keypad' && key.charCodeAt(0) === 32) { // keypads do not have this char
      return [];
    }

    if (finger && column !== undefined && row !== undefined) {
      // column = finger ;
      var fingerId = '',
        altFingerId = '';
      if(key === ' ' && this.model.get('type') !== 'keypad') {
        fingerId = 'space';
        altFingerId = 'left-resting-hand';
      } else if(key === '\n'  && this.model.get('type') !== 'keypad') {
        fingerId = 'right-home-row-7';
        altFingerId = 'left-resting-hand';
      } else {
        if(!this.user.getSetting('animated_hands')) {
          rowWord = 'home';
          fingerIndex = ((hand === 'right') ? Math.max(2, Math.min(5, column-6)) :  Math.max(2, Math.min(5, 7 + (-1 * column))));
        } else {
          rowWord = this.fingerRowMap[row];
          fingerIndex = ((hand === 'right') ? column-6 :  7 + (-1 * column));
        }

        // crazy translation to the hand's IDs.  They are finger indexed starting from the index finger, but the 'finger' int is from pinky to pinky 1 - 10
        fingerId = hand + '-' + rowWord + '-row-' + fingerIndex;
        if(caps) {
          if(!this.user.getSetting('animated_hands')) {
            altFingerId = (hand === 'right') ? 'left-home-row-5' : 'right-home-row-5';
          } else {
            altFingerId = (hand === 'right') ? 'left-bottom-row-7' : 'right-bottom-row-6';
          }
        } else {
          altFingerId = ((hand === 'right') ? 'left' : 'right') + '-resting-hand';
        }
        if(alt) {
          if(!this.user.getSetting('animated_hands')) {
            altFingerId = (hand === 'right') ? 'left-home-row-5' : 'right-home-row-5';
          } else {
            if(caps){
              altFingerId = (hand === 'right') ? 'left-option-shift-a' : 'right-option-shift-a';
            } else {
              altFingerId = (hand === 'right') ? 'left-option' : 'right-option';
            }
          }
        }
      }

      if(this.model.get('type') === 'keypad') {
        return [fingerId];
      }

      return [fingerId, altFingerId];
    }

    return [];
  },

  fingerRowMap: ['num', 'top', 'home', 'bottom', 'opt'],
  highlightKey: function(key) {
    if(!key) { return; }

    var dead = this.getKeyRequiresDead(key);  // see if the key requires a dead key hit
    if(key === 'dead' && this.awaitingDead) { // if the key hit was a dead key, and we are waiting for it, then unset awaitingDead
      key = this.awaitingDead.key;
      this.awaitingDead = false;
    } else if(dead) { // if this key requires a dead key, but we aren't currently awaiting, mark us so we show the right alt keys required
      this.awaitingDead = dead;
    } else if (this.awaitingDead && key !== 'dead') {
      this.awaitingDead = false;
    }
    if(key === 'dead') {
      return;
    }
    if(this.awaitingDead) {
      key = this.awaitingDead.deadKey;
    }


    key = this.translateKey(key);

    var code = this.getKeyCode(key), // char code
      ele = this.getKeyElement(key, this.awaitingDead), // the actual key html element
      isCapsLockWord = this.screen.get('isCapsLockWord'),
      caps = (isCapsLockWord) ? false : this.getKeyRequiresShift(key),
      alt = this.getKeyRequiresAlt(key),
      finger = this.getFingerFor(key),    // which finger
      capsKey, altKey;

    if (alt) {
      if (finger <=5) {
        altKey = this.rightAlt;
      } else {
        altKey = this.leftAlt;
      }
    }

    if (caps) {
      if (finger <=5) {
        capsKey = this.rightCaps;
      } else {
        capsKey = this.leftCaps;
      }
    } else {
      capsKey = this.capsLock;
    }

    // remove previous active key and finger
    this.highlighted.forEach(function(ele){
      ele[0].className = ele[0].defaultClasses;
    });
    this.highlighted = [];


    //this element is raw DOM because it's an SVG and jquery can't query this
    // this.lastFingers.forEach(function(fingerId){
    //   this.clearFingerEle(fingerId);
    // }.bind(this));
    this.lastFingers = [];


    // if there's nothing, don't continue
    if (key === '' || key === null) {
      return;
    }

    var fingerIds = this.getFingerIds(key);

    if(!fingerIds.length) {
      fingerIds = ['right-resting-hand', 'left-resting-hand'];
    }
    this.highlightFingerEle(fingerIds[0]);
    this.lastFingers.push(fingerIds[0]);

    if(this.model.get('type') !== 'keypad') {
      this.highlightFingerEle(fingerIds[1]);
      this.lastFingers.push(fingerIds[1]);
    }

    if ((caps || isCapsLockWord) && capsKey[0]) {
      this.highlighted.push(capsKey);
      capsKey[0].className += capsKey[0].defaultClasses + ' is-active';
    }
    if (alt && altKey[0]) {
      this.highlighted.push(altKey);
      altKey[0].className += altKey[0].defaultClasses + ' is-active';
    }
    if(ele.length) {    // incase the key isn't on their virtual keyboard
      this.highlighted.push(ele);
      ele[0].className = ele[0].defaultClasses + ' is-active';
    }

    ele.addClass('is-animating');
    window.setTimeout(function(){
      ele.removeClass('is-animating');
    }, 250);
  },

  highlightErrorKey: function(key) {
    var code = this.getKeyCode(key),
      ele = this.getKeyElement(key, false);

    ele.addClass('is-wrong');
    window.setTimeout(function(){
      ele.removeClass('is-wrong');
    }, 250);
  },

  cacheKeys: function() {
    var content = this.screen.get('content');
    if(this.model.get('type') !== 'keypad') {
      content += ' \n'; // its possible to never have space, and maybe enter but code will make them show sometimes
    }
    var letters = uniq(content.split(''));
    letters.forEach(function(letter) {
      var ele = this.$('.key-'+this.getKeyCode(letter));
      if(ele.length) {
        ele[0].defaultClasses = ele[0].className;
        this.keysCache[letter] = ele;
      } else if(!this.getKeyRequiresDead(letter)) {
        console.error('Keyboard is missing key: ', letter, ' with keycode ', this.getKeyCode(letter));
      }
    }.bind(this));

    this.rightAlt = this.$('.key-18.right');
    if(this.rightAlt.length) {
      this.rightAlt[0].defaultClasses = this.rightAlt[0].className;
    }

    this.leftAlt = this.$('.key-18.left');
    if(this.leftAlt.length) {
      this.leftAlt[0].defaultClasses = this.leftAlt[0].className;
    }

    this.rightCaps = this.$('.key-16.right');
    if(this.rightCaps.length) {
      this.rightCaps[0].defaultClasses = this.rightCaps[0].className;
    }

    this.leftCaps = this.$('.key-16.left');
    if(this.leftCaps.length) {
      this.leftCaps[0].defaultClasses = this.leftCaps[0].className;
    }

    this.capsLock = this.$('.key-20');
    if(this.capsLock.length) {
      this.capsLock[0].defaultClasses = this.capsLock[0].className;
    }

  },

  cacheFingers: function() {
    var content = this.screen.get('content') + ' \n', // its possible to never have space, and maybe enter but code will make them show sometimes
      letters = uniq(content.split('')),
      awaitingDeadState = this.awaitingDead,
      imageDensity = window.devicePixelRatio >= 2 ? '@2x' : '',
      skin = find(FTWGLOBALS('skins'), {id: this.user.get('skin_id') || 1}).hands + '_v' + this.user.get('variant_id'),
      fingerIds;

    var cacheFinger = function(fingerId){
      if(!this.fingerCache[fingerId]) {
        this.fingerCache[fingerId] = $('<img src="/dist/student/images/hands/'+((this.model.get('type') === 'keypad') ? 'keypad/' : '') + skin+'/'+fingerId+imageDensity+'.png" />');
      }
    }.bind(this);

    letters.forEach(function(letter){
      this.getFingerIds(letter).forEach(cacheFinger);
      var dead = this.getKeyRequiresDead(letter);
      if(dead) {
        this.getFingerIds(dead.deadKey).forEach(cacheFinger);
        if(indexOf(letters, dead.key) === -1) {
          this.getFingerIds(dead.key).forEach(cacheFinger);
        }
        this.awaitingDead = false;
      }
    }.bind(this));
    this.awaitingDead = awaitingDeadState;
    if(this.model.get('type') !== 'keypad') {
      cacheFinger('right-resting-hand');
      cacheFinger('left-resting-hand');
      cacheFinger('left-option');
      cacheFinger('left-option-shift-a');
      cacheFinger('left-option-shift-a');
      cacheFinger('right-option');
      cacheFinger('right-option-shift-a');
      cacheFinger('right-option-shift-a');
    }
  },

  replayLastFinger: function(el) {

    this.hands = el[0];
    this.lastFingerIds.forEach(function(row){
      this[row.method](row.fingerId);
    }.bind(this));
    this.lastFingerIds = [];
  },

  lastFingerIds: [],
  highlightFingerEle: function(fingerId) {
    this.lastFingerIds.push({fingerId: fingerId, method: 'highlightFingerEle'});

    var fingerEle;
    if(this.fingerCache[fingerId]) {
      fingerEle = this.fingerCache[fingerId];
    } else {
      console.error('Hands are missing fingerId ', fingerId);
      return;
    }

    if(fingerEle[0].src.indexOf('left-') !== -1) {
      this.hands.left[0].src = fingerEle[0].src;
    } else {
      this.hands.right[0].src = fingerEle[0].src;
    }
  }

})
