import $ from 'jquery'

export default {
  track: function(user, typingStats){
    var data = {
      t: user.get('teacher_id'),
      u: user.id
    };
    if(window.location.pathname.match(/game\//)){
      data.x = FTWGLOBALS('game').game_id;
    }
    if (typingStats) {
      data.l = {
        t: typingStats.typed,
        s: typingStats.seconds,
        e: typingStats.errors,
        p: typingStats.progress
      };
    }

    $.get('/apiv1/student/radar/' + window.btoa(JSON.stringify(data)));
  }
}
