import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import AchievementModel from '../models/achievement'
import AchievementAlertView from '../views/achievement_alert'
import { each, take, filter, size, sortBy, forEach } from 'lodash-es'
import { postJSON } from '@shared/helpers'

const Achievements = Backbone.Collection.extend({

  model: AchievementModel,

  comparator: 'display_order',

  /**
   * Sets the created_at based on another Achievements collection with those set.
   * @param {Achievements} completed
   */
  setCompleted: function(completed) {
    completed.each((model) => {
      var achModel = this.get(model.id);
      if(achModel) {
        achModel.set({created_at: model.get('created_at')});
      }
    });
  },

  getRecentlyCompleted: function(count) {
    count = count || 3;

    var completed = new Achievements();
    completed.add(
      take(
        sortBy(
          filter(this.toJSON(),
            (row) => row.created_at > 0),
          (row) => -1*row.created_at),
        count))

    return completed;
  }

},{
  checksRun: 0, // if this number gets high we're in some infinite loop
  /**
   * Static method that checks if achievements have been completed for the current user.
   */
  check: function() {
    if (!Registry.get('loggedIn') || this.paused || Registry.get('student').isAssessment()) { return; }

    if(this.checksRun > 2) {
      console.warn('Achievements possibly in an infinite loop');
      return;
    }

    var complete = Registry.get('userAchievements'),
      achievements = Registry.get('achievements'),
      newCompleteIDs = [],
      hints = {},
      user = Registry.get('student'),
      ach, reward;

    // Get a list of all achievements not yet completed
    achievements.each((ach) => {
      if (complete.get(ach.id)) {
        return;
      }

      // Looks like this wasn't completed, but is now
      if (ach.checkIfComplete()) {
        newCompleteIDs.push(ach.id);
        if(ach.get('hint')) {
          hints[ach.id] = ach.get('hint');
        }
      }
    });

    // Nothing?  Cancel
    if (!newCompleteIDs.length) {
      return;
    }

    // Send the list to the server to verify it's actually complete
    this.pause(true);
    var payload = {ids:newCompleteIDs, hints: hints, user_id: user.id, user_created_at: user.get('created_at')};
    if(FTWGLOBALS('env') === 'local') {
      console.info('Checking for achievements', payload);
    }
    this.checksRun++;
    postJSON('/apiv1/student/achievements/check', payload)
      .done(function(data){

        // Go through anything achieved
        if (size(data.achieved) > 0) {

          var newAchIds = [];
          each(data.achieved, (stamp, id) => {
            ach = achievements.get(id);

            complete.add({
              achievement_id: ach.id,
              created_at: stamp
            });
            newAchIds.push(ach.id);
          });

          var alert = new AchievementAlertView();
          alert.show(newAchIds);
        }

        // This call also gives a full list of all user variables to do a full account sync
        if(data.problems.length > 0) {
          // if there are some achievements that don't match, we have a problem with local data and need to resync
          user.resyncLocal(); // pull down the info they get as they log in
        }

        var allReturnedIds = [];
        data.problems.forEach(function(id){allReturnedIds.push(id)});
        forEach(data.achieved, function(val, id){allReturnedIds.push(id);});
        if(allReturnedIds.length != newCompleteIDs.length) {
          newCompleteIDs.forEach(function(id){
            if(allReturnedIds.indexOf(id) === -1) {
              complete.add({  // just give them the invalid achievement to stop the infinite loop check
                achievement_id: id,
                created_at: Date.getUnixTime()
              });
            }
          });
        }

        if(data.problems.length > 0 || size(data.achieved) === 0) {
          console.warn('Achievement call had a problem', data);
        } else {
          if(FTWGLOBALS('env') === 'local') {
            console.info('Earned ' + size(data.achieved) + ' achievements');
          }
        }

        this.pause(false);
      }.bind(this));
  },

  /**
   * Pause or unpause the checking of achievements. And when unpaused it will check again
   */
  pause: function(pause) {
    this.paused = pause;
    if(!this.paused) {
      this.check();
    }
  }
})

export default Achievements
