
export default function restoreContent(cacheKey, user, userLessonScreens, screen) {
  let content

  try {
    // check if this lesson has been saved before
    let userLessonScreen = userLessonScreens.find(child => child.get('lesson_screen_id') === screen.id)
    content = userLessonScreen?.get('blocks')

    // if nothing was found, check the cache
    if (!content) {
      const data = user.get(cacheKey)

      // if there's something there, parse it
      if (typeof data === 'string') {
        content = JSON.parse(data)
      }
    }
    // replace the cache
    else {
      user.set(cacheKey, JSON.stringify(content))
    }
  }
  catch (ex) {
    console.error('Failed to restore content', ex)
    content = null
  }

  // without any data, create the default lesson
  if (!content) {
    content = { shapes: [ ], groups: [ ] }

    // build out the defaults
    try {
      const defaults = screen.get('content') || '[]'
      let structure = JSON.parse(defaults)

      // normalize to always be an array
      if (!(structure instanceof Array)) {
        structure = [structure]
      }

      // add each object
      for (let i = 0; i < structure.length; i++) {
        const item = structure[i]
        const { x = 0, y = 0, color = 0, type, locked } = item

        // if missing the image type
        if (!type) {
          console.error(`Missing type for Blueprint object at index ${i}`)
        }

        // add each shape
        content.shapes.push({
          //unique IDs for each pre-defined shape
          id: `default_${i + 1}`,
          type,
          x,
          y,
          color,
          locked
        })
      }
    }
    catch (ex) {
      console.error('Failed to parse admin Blueprint!')
    }
  }

  // validate the document to prevent any
  // tampering with data
  return validateContent(content)
}


// TODO: ensure all data is present
function validateContent(content) {

  if (!(content.shapes instanceof Array)) {
    content.shapes = [ ]
  }

  if (!(content.groups instanceof Array)) {
    content.groups = [ ]
  }

  // validate

  return content
}
