import Backbone from 'backbone'
import Registry from '@registry'
import MainView from './views/main'
import LayoutView from '../global/views/layout'

export default Backbone.View.extend({

  layout: LayoutView,

  initialize: function() {

    Backbone.View.prototype.initialize.apply(this, arguments);

    this.user = Registry.get('student');

    if(this.user.hasOption('lockaccount') && !this.user.hasOption('changepassword')) {
      window.location.href = __url('/student/lessons');
      return;
    }

    this.views.mainView = new MainView();

    this.addChild(this.el, this.views.mainView, true);
    this.render();
  }
})
