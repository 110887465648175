import Backbone from 'backbone'
import MainView from './views/main'
import LayoutView from '../global/views/layout_basic'

export default Backbone.View.extend({

  layout: LayoutView,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.layout = new LayoutView({ showButtons: !window.localStorage.getItem('googleClassroomAddonData') });
    this.views.main = new MainView();

    this.addChild(this.el, this.views.main, true);
    this.render();
  },
})
