import Backbone from 'backbone'
import Model from '../../global/models/keyboard'

export default Backbone.Collection.extend({
  model: Model,

  comparator: 'display_order'
})


