import Backbone from 'backbone'
import Registry from '@registry'
import Model from '../models/unit'
import Scoring from '@shared/scoring'
import { maxBy, filter, indexOf } from 'lodash-es'

export default Backbone.Collection.extend({
  model: Model,

  comparator: 'display_order',

  /**
   * Set progress on units in order to determine next active lesson etc
   * @param lessons
   */
  setProgress: function(lessons) {
    // get the latest lesson typed per unit
    var allLessons = lessons.toJSON(),
      unitLessons, nonsequential;

    this.forEach((unit) => {
      let seconds,
        typed,
        errors = 0,
        type = unit.get('type'),
        nonsequential = type === 'lesson' && Registry.get('student').hasOption('nonsequential'),
        activeLesson = null;

      if(type === 'problemkeys' || type === 'instruction' || type === 'test' || type === 'custom_test') return;
      unitLessons = filter(allLessons, { unit_id: unit.id, type: 'lesson' });   // filter for this unit
      let progress = unitLessons.reduce((memo, row) => memo + row.max_progress, 0); // see if they have worked on this unit at all

      if (progress > 0) { // if they have completed anything
        var lastCompletedIndex = indexOf(unitLessons, maxBy(unitLessons, (row) => (row.max_progress >= row.screens ? row.updated_at : 0))),   // get the index of the last completed lesson
          lastTypedIncomplete = maxBy(unitLessons, (row) => (row.max_progress >= row.screens ? 0 : row.updated_at)),    // find the last lesson worked on, ordered by not being complete
          remainingLessons = unitLessons.filter((row) => row.max_progress < row.screens),   // find any lessons they have not completed
          lastTyped = maxBy(unitLessons, 'updated_at');    // and find the last lesson typed, even if it was complete

        seconds = unitLessons.reduce((memo, row) => memo + row.seconds, 0)
        typed = unitLessons.reduce((memo, row) => memo + row.typed, 0)
        errors = unitLessons.reduce((memo, row) => memo + row.errors, 0)

        if(lastTypedIncomplete.max_progress >= lastTypedIncomplete.screens) {
          lastTypedIncomplete = null;
        }

        if(remainingLessons.length) {
          if (lastCompletedIndex === -1) {  // if nothing has been completed
            if (!nonsequential) {    // if sequential, then the first lesson is next
              activeLesson = filter(unitLessons, {unit_id: unit.id, type: 'lesson'})[0];
            } else { // if not sequential, then the last worked on lesson is next
              activeLesson = maxBy(unitLessons, (row) => row.updated_at)
              if (activeLesson.max_progress >= activeLesson.screens) {
                activeLesson = null;
              }
            }
          } else {    // something has been completed
            if (!nonsequential) {
              activeLesson = remainingLessons[0];
            } else {
              if (lastTyped.max_progress < lastTyped.screens) {  // if that last lesson is not complete, then it's next
                activeLesson = lastTyped;
              } else {
                if (lastCompletedIndex === unitLessons.length - 1) {   // if the last completed lesson is the last in the list
                  activeLesson = null;
                } else {    // lastly, just do the next lesson after the last completed
                  let availableToTypeEnd = unitLessons.slice(lastCompletedIndex).filter((row) => row.max_progress < row.screens)
                  if (availableToTypeEnd.length) {
                    activeLesson = availableToTypeEnd[0];
                  } else {    // otherwise they're done
                    activeLesson = null;
                  }
                }
              }
            }
          }

          // if after all the checks, we still don't have one
          if(!activeLesson) {
            if(lastTypedIncomplete) {       // give them the last typed if it exists
              activeLesson = lastTypedIncomplete;
            } else {    // or find anything that they can type
              var availableToType = unitLessons.filter((row) => row.max_progress < row.screens);
              if(availableToType.length) {    // give them the first
                activeLesson = availableToType[0];
              } else {    // there's nothing, sot he unit must be complete
                activeLesson = null;
              }
            }
          }
        }
      } else {
        // otherwise the first lesson is the active one
        activeLesson = filter(unitLessons, { unit_id: unit.id, type: 'lesson' })[0];
      }

      unit.set({
        progress: progress,
        active_lesson: (activeLesson) ? activeLesson.lesson_id : null,
        speed: Scoring.speed(typed, seconds, errors),
        accuracy: Scoring.accuracy(typed, errors),
        screens: unitLessons.reduce((prev, curr) => prev + curr.screens, 0)
      });
    });
  }
})
