import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import Scoring from '@shared/scoring'
import AdView from '../../global/views/ad'
import Alert from '../../global/views/alert'
import { times } from 'lodash-es'
import CelebrationEffect from '@shared/celebration_effect'
import { lesson_congrats } from '@templates/student'
export default Backbone.View.extend({

  template: lesson_congrats,

  events: {
    'click .js-print': 'print',
    'click .js-restart': 'restart'
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.userLessons = Registry.get('userLessons');
    this.lessons = Registry.get('lessons');
    this.units = Registry.get('units');

    if(this.progressView) {
      this.addChild('.js-progress', this.progressView, true);
    }
  },

  serialize: function() {
    this.lessons.setProgress(this.userLessons);
    this.units.setProgress(Registry.get('lessons'));
    const activeLessonId = this.units.get(this.lesson.get('unit_id')).get('active_lesson'),
      productGrade = this.user.getCurriculumGrade(),
      contentType = this.lesson.get('content_type'),
      activeLesson = this.lessons.get(activeLessonId);

    // This little thing puts the stars in order
    var starList = [];

    // concept maps and blueprint lessons are always full
    if(['concept-map', 'blueprints' ].includes(contentType)) {
      times(this.lesson.get('screens') * 3, () => starList.push(1))
    }
    // check for adventure types
    else if(['adventure'].includes(contentType)) {
      times(this.lesson.get('screens') * 3, (index) => starList.push(this.userLesson.get('stars') >= index + 1 ? 1 : 0))
    } else {
      this.userLessonScreens.forEach(function (row) {
        var stars = row.get('stars');
        for (var i = 0; i < stars; i++) {
          starList.push(1);
        }
        for (i = 0; i < 3 - stars; i++) {
          starList.push(0);
        }
      });
    }

    return {
      ads: !this.isSubScreen && AdView.canShowAds(),
      lesson: this.lesson.toJSON(),
      unit: this.units.get(this.lesson.get('unit_id')).toJSON(),
      starList: starList,
      stars: this.userLesson.get('stars'),
      totalStars: this.lesson.get('screens') * 3,
      accuracy: Scoring.accuracy(this.userLesson.get('typed'), this.userLesson.get('errors')),
      speed: Scoring.speed(this.userLesson.get('typed'), this.userLesson.get('seconds'), this.userLesson.get('errors')),
      time: (Math.floor(this.userLesson.get('seconds')) || 0).countdownSeconds(),
      seconds: Math.floor(this.userLesson.get('seconds')) || 0,
      activeLesson: (activeLesson) ? activeLesson.toJSON() : null,
      showMascot: !(this.lesson.get('content_type') === 'adventure' && this.lesson.get('settings')?.display) && productGrade < 7,
      isClickDrag: this.lesson.get('content_type') === 'click-and-drag',
      allowLessonRedo: this.user.hasOption('unlockedlessons'),
      congratsText: this.lesson.get('settings')?.congrats,
      showButtons: !this.hasGoogleClassroomAddon,
      hideTypingStats: !!this.hideTypingStats,
      hideStars: !!this.hideStars
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    if(this.units.get(this.lesson.get('unit_id')).get('badge')) {
      this.$('.js-badge-img').each((index, badge) => {
        this.$(badge).append(Registry.get('userLessons').getBadgeForId(this.lesson.id).el);
      });
    }

    window.setTimeout(() => {
      var celebrationEle = $('.js-celebration-effect');
      // Reposition effect so that it is over the entire screen
      celebrationEle.css({ 'top': -celebrationEle.offset().top, 'left': -celebrationEle.offset().left });
      // Instantiate confetti
      CelebrationEffect.init(celebrationEle[0], { screenHeight: $(document).height(), screenWidth: $(document).width(), launchCount: 20, colors: this.user.getSkinColors() });
    }, 0);

    const { hideStars, hideTypingStats } = this
    var data = this.serialize();
    this.dictation.speakLessonComplete({
      ...data,
      hideStars,
      hideTypingStats
    });

    document.body.addEventListener('keydown', (e) => {
      if(e.which === 13) { // prevent scrolling
        location.href= this.$('.js-continue').attr("href");
      }
    }, false);

    return this;
  },

  print: function(e){
    var id = $(e.currentTarget).data('id'),
      user = Registry.get('student');

    if(!(user.get('first_name') && user.get('last_name'))) {
      if(!Registry.get('loggedIn')) {
        (new Alert({
          error: false,
          text: 'lesson.log_in_to_print'.t({
            linkStart1: '<a class="link" href="' + __url('/student/login') + '">',
            linkStart2: '<a class="link" href="' + __url('/student/signup') + '">',
            linkEnd: "</a>"
          })
        }).show());
      } else if(user.get('in_class') && user.hasOption('lockaccount')) {
        (new Alert({
          error: false,
          title: 'lesson.unable_to_print'.t(),
          text: 'lesson.contact_teacher_to_print'.t()
        }).show());
      } else {
        (new Alert({
          error: false,
          title: 'lesson.unable_to_print'.t(),
          cancel: 'shared.cancel_text'.t(),
          text: 'lesson.enter_name_to_print'.t(),
          ok: 'lesson.go_to_account_settings'.t()
        }).show().on('ok', function(){
          window.location.href = __url('/student/account');
        }));
      }

      return;
    }

    window.open('/apiv1/student/units/' + id + '/' + user.id + '/certificate?language=' + FTWGLOBALS('language') + '&product_id=' + user.getSetting('product_id'));
  },

  restart: function() {
    // If this is a story_with_ending we need to reset their max progress so they can retype the entire lesson
    this.userLesson.set({
      _state: 'intro',
      progress: 0,
      max_progress: (this.lesson.get('settings')?.display === 'story_with_ending' ? 0 : this.userLesson.get('max_progress'))
    });
    window.setTimeout(window.location.safeReload, 0);

    return false;
  },
})
