import Backbone from 'backbone'
import { global_loading_animated } from '@templates/student'

export default Backbone.View.extend({

  template: global_loading_animated,

  serialize: function(){
    return {
      loadingText: this.loadingText || 'shared.loading'.t()
    };
  }
})
