import Backbone from 'backbone'
import $ from 'jquery'
import { filter, find, isObject } from 'lodash-es'
import { shared_keyboard_helper_keyboards } from '@templates/shared'

export default Backbone.View.extend({

  template: shared_keyboard_helper_keyboards,

  selectedKeyboardId: null,

  events: {
    'click .js-keyboard-placement': 'selectKeyboardPlacement',
    'keydown .js-keyboard-placement': 'handleKeydown'
  },

  serialize: function() {
    var keyboards = filter(FTWGLOBALS('keyboards'), { language: this.selectedLanguageId }, this);

    if(keyboards.length > 0) {
      keyboards = keyboards.map(function(keyboard){
        var structure = [];

        structure[0] = keyboard.structure[1].slice(-3);
        structure[1] = keyboard.structure[2].slice(-2);
        structure[2] = keyboard.structure[3].slice(-2);

        keyboard.minifiedStructure = structure;
        return keyboard;
      });

      // Ensure the selected keyboard is one of the keyboards being displayed
      if(!find(keyboards, {keyboard_id: this.selectedKeyboardId})) {
        this.selectedKeyboardId = keyboards[0].keyboard_id;
      }
    }

    return {
      lodash: { isObject },
      languageKeyboards: keyboards,
      selectedKeyboardId: this.selectedKeyboardId,
    };
  },

  handleKeydown: function(e) {
    if (e.which === 13) {
      this.selectKeyboardPlacement(e);
    }
  },

  selectKeyboardPlacement: function(e) {
    this.$('.js-keyboard-placement').removeClass('is-active');
    $(e.currentTarget).addClass('is-active');
    this.selectedKeyboardId = $(e.currentTarget).data('id');

    return false;
  }
})
