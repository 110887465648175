import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import Velocity from 'velocity-animate'
import AuthModal from './auth_modal'
import { find, filter } from 'lodash-es'
import { global_sidebar_nav } from '@templates/student'

export default Backbone.View.extend({

  events: {
    'click .js-accordion-trigger': 'accordionToggle',
    'click .js-start-beginning': function(e){ this.goToLesson(e, true); return false; },
    'click .js-create-account': 'openAuthModal',
    'click .js-lesson': 'goToLesson'
  },

  template: global_sidebar_nav,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.units = FTWGLOBALS('units').filter((row) => row.type === 'lesson');
    this.lessons = FTWGLOBALS('lessons');
  },

  serialize: function () {
    const otherLinks = [
        {name: 'shared.site.typing_test'.t(), linkSlug: 'tests', imageSlug: 'tests' },
        {name: 'shared.site.typing_games'.t(), linkSlug: 'games', imageSlug: 'games' },
      ],
      unitIndex = this.units.findIndex((row) => row.unit_id === this.lesson.get('unit_id'))

    return {
      lodash: { filter },
      lesson: this.lesson.toJSON(),
      currentUnit: this.units[unitIndex],
      currentUnitIndex: unitIndex,
      units: this.units,
      lessons: this.lessons,
      otherLinks: otherLinks
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    this.scrollToLesson();

    return this;
  },

  scrollToLesson: function() {
    window.setTimeout(() => {
      if(this.$('.sidebarNav-lesson.is-active').index() >= 4) {
        this.$('.sidebarNav-lesson.is-active').parent().animate({
          scrollTop: this.$('.sidebarNav-lesson.is-active').offset().top
        }, 500);
      }
    }, 10);
  },

  accordionToggle: function(e) {
    var id = $(e.currentTarget).data("id"),
      accordion = this.$('.js-accordion[data-id=' + id + ']'),
      closeOnly = accordion.hasClass("is-open");
    Velocity($(".js-accordion .accordion-content"), "slideUp",
      {
        duration: 250,
        complete: function() {
          this.$(".js-accordion").removeClass("is-open");
        }.bind(this)
      }
    );
    if(!closeOnly) {
      Velocity($('.js-accordion[data-id=' + id + '] .accordion-content'), "slideDown",
      {
        duration: 250,
        complete: function() {
          accordion.addClass("is-open");
        }.bind(this)
      })
    }
  },

  openAuthModal: function() {
    new AuthModal({ authType: 'signup' }).open()
  },

  goToLesson: function(e, start) {
    var lessonId = !start ? this.$(e.currentTarget).data('id') : find(this.lessons, { unit_id: this.units[0].unit_id, display_index: 1 }).lesson_id,
      lesson = find(this.lessons, { lesson_id: lessonId });

    window.location.href = __url('/student/lesson/' + lessonId + '/' + lesson.name.slug());
  }
})
