import Registry from '@registry'

const themeColor = function() {
  var skinId = Registry.get('student') && Registry.get('student').get('skin_id') ? Registry.get('student').get('skin_id') : 1;

  switch(skinId) {
  case 3:
    return '247,150,151';
  case 4:
    return '0,170,164';
  case 5:
    return '26,96,142';
  case 6:
    return '161,184,69';
  case 7:
    return '65,60,88';
  case 8:
    return '33,118,174';
  case 9:
    return '211,0,6';
  default:
    return '50,149,219';
  }
}

export default {
  // Gets default options for dataset. Needs to be a function in order to set color based on user's theme
  getDatasetOptions: function(type) {
    return {
      borderColor: 'rgb(' + themeColor() + ')',
      backgroundColor: type === 'bar' ? 'rgba(' + themeColor() + ',0.5)' : 'transparent',
      pointBackgroundColor: '#fff',
    };
  },
  getFontFamily: function() {
    return "'museo-sans-rounded', sans-serif"
  },
  getLabelColor: function() {
    return 'rgb(' + themeColor() + ')';
  },
  line: {
    dataset: {
      lineTension: 0.3,
      pointRadius: 4,
      pointHoverRadius: 6
    },
    dataObj: {
      type: 'line',
      data: {
        labels: [],
        datasets: []
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              ticks: {
                minRotation: 0,
                maxRotation: 90
              }
            }]
        },
        plugins: {
          datalabels: {
            align: 'start',
            backgroundColor: 'rgb(' + themeColor() + ')',
            borderRadius: 3,
            color: '#fff',
            display: 'auto',
            font: {
              size: 10
            },
            offset: 5,
            padding: 3
          }
        },
        tooltips: {
          backgroundColor: 'rgba(74,74,74,0.95)',
          titleFontSize: 14,
          titleFontColor: '#eaeaea',
          titleMarginBottom: 10,
          bodyFontSize: 16,
          bodyFontStyle: 'bold',
          xPadding: 15,
          yPadding: 15,
          caretSize: 10,
          cornerRadius: 10,
          displayColors: false,
          intersect: false
        }
      }
    }
  },
  bar: {
    dataset: {
      hoverBackgroundColor: 'rgba(113, 177, 107, 0.9)'
    },
    dataObj: {
      type: 'bar',
      data: {
        labels: [],
        datasets: []
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              ticks: {
                minRotation: 0,
                maxRotation: 90
              }
            }]
        },
        plugins: {
          datalabels: {
            font: {
              size: 10
            },
            color: '#ffffff',
            clamp: true
          }
        },
        tooltips: {
          backgroundColor: 'rgba(74,74,74,0.95)',
          titleFontSize: 14,
          titleFontColor: '#eaeaea',
          titleMarginBottom: 10,
          bodyFontSize: 16,
          bodyFontStyle: 'bold',
          xPadding: 15,
          yPadding: 15,
          caretSize: 10,
          cornerRadius: 10,
          displayColors: false
        }
      }
    }
  }
}
