import Backbone from 'backbone'
import UserScreenModel from '../models/user_lesson_screen'
import { sortBy, slice, reduce, filter } from 'lodash-es'

export default Backbone.Collection.extend({
  model: UserScreenModel,

  url: function() {
    return '/apiv1/student/lessons/' + this.options.lesson_id + '/screens';
  },

  initialize: function(data, options) {
    this.options = options;
  }
})
