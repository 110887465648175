import $ from 'jquery'
import { extend, map } from 'lodash-es';
import ScreenCompleteView from './screen_complete'
import { lesson_screen_complete_adventure } from '@templates/student'

export default ScreenCompleteView.extend({

  template: lesson_screen_complete_adventure,

  initialize: function() {
    ScreenCompleteView.prototype.initialize.apply(this, arguments);

    var events = this.events || {};

    this.delegateEvents(
      extend(events, {
        'click .js-continue-screen-1': 'nextFrame',
      })
    );

  },

  render: function() {
    ScreenCompleteView.prototype.render.apply(this, arguments);

    const complete = this.lesson.get('screens') === this.userLesson.get('max_progress') + 1;
    
    if(complete) {
      this.$('.js-split-nav').fastHide()
      this.$('.js-story-frame-2').fastShow().addClass('well well--t');
    };
  },

  serialize: function() {
    return extend(ScreenCompleteView.prototype.serialize.apply(this, arguments), {
      display: this.lesson.get('settings')?.display,
      slug: this.lesson.get('settings')?.slug,
      userLesson: this.userLesson.toJSON()
    });
  },

  continue: function(e) {
    const id = $(e.currentTarget).data('id'),
      screen = this.screens.get(id),
      progress = this.screens.indexOf(screen);

    if(!this.saved) {
      return false;
    }

    if(screen) {
      this.userLesson.set({ new_progress: progress, not_tracked: screen.get('settings')?.not_tracked === '1' });
    }

    this.trigger('continue', 'tyoa_pick', $(e.currentTarget));
    return false
  },

  handleKeyPress: function(character) {
    const complete = this.lesson.get('screens') === this.userLesson.get('max_progress') + 1,
      options = map(this.screen.get('paths'), 'title')

    if(character.keyCode === 13) {
      // Choosing an option
      if(options.length === 1 || complete) {
        this.$('.js-continue-button').click();
      } else if(this.selectedOptionIndex !== undefined) {
        this.$(`.js-continue-button[data-index=${this.selectedOptionIndex}]`).click();
      }
    } else if(!complete && character.key <= options.length) {
      if(parseInt(character.key) === 0) {
        // Repeat the intro dictation for this screen
        this.dictation.speakOptionPaths({ options: options });
      } else {
        // Read individual option to user
        const option = this.screen.get('paths')[character.key - 1]
        if(option) {
          this.dictation.speakOption({ text: option.title + '. ' });
          this.selectedOptionIndex = character.key - 1
        }
      }
    }

    return false;
  },

  restart: function() {
    this.trigger('continue', 'complete_restart', this.$('.js-restart'));

    return false;
  },

  speakScreenComplete(data) {
    if(this.lesson.get('screens') === this.userLesson.get('max_progress') + 1) {
      this.dictation.speakTYOAScreenWithEndingComplete({})
    } else {
      this.dictation.speakOptionPaths({ options: map(this.screen.get('paths'), 'title') })
    }
  },

  speakOptionPaths: function() {
    this.dictation.speakOptionPaths({ options: map(this.screen.get('paths'), 'title') })
  },

  nextFrame: function() {
    this.$('.js-story-frame-1').fastHide();
    this.$('.js-story-frame-2').fastShow();
  },
})

