import Backbone from 'backbone'

export default Backbone.Model.extend({

  idAttribute: 'achievement_group_id',

  defaults: {
    name: '',
    description: '',
    subdescription: '',
    display_order: ''
  }

})
