import $ from 'jquery'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import TypingView from './typing'
import Velocity from 'velocity-animate'
import { lesson_typing_falling, lesson_typing_falling_letters } from '@templates/student'

// has to be manually set because the elements aren't rendered when we need this value!
const VIEWPORT_LEFT_OFFSET = 16
const ROW_HEIGHT = 65

export default TypingView.extend({

  template: lesson_typing_falling,

  initialize: function(options) {
    this.lettersTemplate = lesson_typing_falling_letters;

    // just so it's cached
    this.lessonLength = options.screen.get('content').length;

    return TypingView.prototype.initialize.call(this, options);
  },

  /**
   * Override this
   * @returns {*}
   */
  render: function() {
    var userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens');

    Registry.set('preventKeyboardInput', true);

    this.$el.append(this.template({
      ads: AdView.canShowAds() && userActivity.getOrAdd(0).get('screens') > 0,
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens }),
      lesson: this.lesson.toJSON(),
      screen: this.screen.toJSON(),
      user: this.user.toJSON()
    }));

    TypingView.prototype.render.call(this);

    this.startCursorAnimation({
      offset: '0, 25',
      placement: 'right',
      start: true
    });

    // Do this in a 0 ms setTimeout so it gives the DOM a sec to complete.  The keyboard needs to be rendered so the key positions can be found
    window.setTimeout(this.renderLetters.bind(this), 0);

    return this;
  },

  renderLetters: function() {
    var lettersPos = {},
      keyboard = this.keyboardView.$el,
      code;


    // Go through every letter in the lesson and find out what pixel position the key is on the keyboard
    this.screen.get('content').split('').forEach(function(letter){
      letter = letter.toLowerCase();

      code = letter.charCodeAt(0);
      if(letter == '⏎') code = 13;    // hardcode enter

      if(lettersPos[code]) return;

      var key = keyboard.find('.key-' + code);
      if(!key.length) {
        var dead = this.keyboardView.getKeyRequiresDead(letter);
        if(dead) {
          key = keyboard.find('.key-' + dead.key.charCodeAt(0));
        }
      }
      // do a math.min because sometimes a key (like enter) can be even smaller than the falling letter, and far to the edge, so the falling letter can't be that far right
      if(key.length) {
        lettersPos[code] = Math.round(key.position().left);
      }
    }.bind(this));


    this.$('.js-falling-lines').html(this.lettersTemplate({
      lettersPos: lettersPos,
      typingContent: this.screen.get('content'),
      viewportLeftOffset: VIEWPORT_LEFT_OFFSET
    }));

    // cache these elements
    this.lettersContent = this.$('.js-falling-lines');
    this.cacheAnimations();
    this.setPosition(0);

    window.setTimeout(function(){
      this.lettersContent = this.$('.js-falling-lines');
      this.cacheAnimations();
    }.bind(this), 10);

    window.setTimeout(function(){
      this.lettersContent = this.$('.js-falling-lines');
      this.cacheAnimations();
    }.bind(this), 1000);
  },

  /**
   * Override this method so errors can be handled and mark the row as an error
   * @param model
   */
  handleErrorTyped: function(model) {
    this.lettersTyped.trackError(model.get('correctLetter'));
    this.setPosition(model.get('typed'), true);
    this.playSounds('error');
  },

  cacheAnimations: function() {
    var toCache = this.$('.screenFalling-lines .screenFalling-line');
    if(toCache.length === 0) {
      return;
    }
    // cache all default classNames to speed up class changes
    toCache.each((index, ele) => {
      ele.defaultClasses = ele.className;
    })
    this.lettersCache = toCache;

    this.keysCache = this.$('.screenFalling-lines .letter');
    this.keysCache.each((index, ele) => {
      ele.defaultClasses = ele.className;
    })

    if(this.lettersCache.length) {
      Registry.set('preventKeyboardInput', false);
    }
  },

  /**
   * Must overwrite this.  Specific behavior for moving the cursor about
   * @param index
   * @param error
   */
  setPosition: function(index, error) {
    var reverseIndex = this.lessonLength - index - 1,   // reverse because the rows are stacked with the current letter on the bottom
      currentEle = this.lettersCache[reverseIndex],
      currentKey = this.keysCache[reverseIndex],
      nextEle = this.lettersCache[reverseIndex+1],
      nextKey = this.keysCache[reverseIndex+1];

    // if this was an error, we don't move the line. Just mark it as an error
    if (error && currentEle) {
      currentEle.className = currentEle.defaultClasses + ' is-wrong';
      currentKey.className = currentKey.defaultClasses + ' is-wrong';
      if(!this.lowPerformance) {
        Velocity(currentKey, 'ftw.miniShake', {duration: 300});
      }
      return;
    }

    // the letter that was typed is active
    if(currentEle){
      currentEle.className = currentEle.defaultClasses + ' is-active';
      currentKey.className = currentKey.defaultClasses + ' is-active';
    }

    // the next down (previous one typed [yes it's confusing]) gets marked as active and it'll move down
    if(nextEle) {
      nextEle.className = nextEle.defaultClasses + ' is-right';
      nextKey.className = nextKey.defaultClasses + ' is-right';
    }

    if(index > 0 && index + 3 < this.lessonLength ) {
      this.animate(index);
    }
  },

  /**
   * Animate the rows down
   * @param index
   */
  animate: function(index) {
    var duration = (this.lowPerformance) ? 100: 250;
    Velocity(this.lettersContent, 'stop')
    Velocity(this.lettersContent, {translateY: index * ROW_HEIGHT}, {duration: duration, easing: 'ease-in-out'});
  }
})
