import { map } from 'lodash-es'

export const PROBLEM_KEYS_LESSON_ID = 999999;

/**
 * GRADES_MAP is a constant that represents a mapping object between
 * a given grade level and its corresponding string representation.
 *
 * The keys of this object represent the original numeric grade levels.
 * The values are the string representations for those grade levels.
 *
 * This mapping standard is necessary when interacting with external modules
 * or APIs that expect grade levels to be represented in a certain string format
 * (e.g., '1st', '4th', '6th', '9th', '12th').
 *
 * @constant
 * @type {Object.<number, string>}
 */
export const GRADES_MAP = {
  0: '1st',
  1: '1st',
  2: '1st',
  3: '1st',
  4: '1st',
  5: '5th',
  6: '5th',
  7: '5th',
  8: '5th',
  9: '9th',
  10: '9th',
  11: '9th',
  12: '9th',
  13: '9th',
};

/**
 * Returns the introduction text for the problem keys lesson.
 * The introduction text depends on whether the user has the 'problemkeys' option turned on or not.
 *
 * @param {Object} user - An object representing the user details.
 * @param {Array} practiceKeys - An array containing user's practice keys details.
 *
 * @return {string} - The introduction text for the problem keys lesson.
 */
export function getProblemKeysLessonIntro(user, practiceKeys) {
  if (user.hasOption('problemkeysai')) {
    return '';
  } else {
    return '<p>'+'lesson.problem_keys.intro1'.t({site: FTWGLOBALS('app_name')})+'</p><p>'+'lesson.problem_keys.intro2'.t()+'</p>';
  }
}

/**
 * Returns the title of the problem keys lesson.
 * The title depends on whether the user has the 'problemkeys' option turned on or not.
 *
 * @param {Object} user - An object representing the user details.
 *
 * @return {string} - The title of the problem keys lesson.
 */
export function getProblemKeysLessonName(user) {
  const problemKeysTitleText = 'lesson.problem_keys.title'.t();
  const usingAIText = 'lesson.name.using_ai'.t({ ai: getIntroAIHtml('white') });

  return user.hasOption('problemkeysai') ? `${problemKeysTitleText} ${usingAIText}` : problemKeysTitleText;
}

/**
 * Generates an HTML string for a stylized text with an icon. The text and icon colors can be customized.
 *
 * @param {string} textColor - The color for the text and icon as a CSS color string. Default is '#3082CF'.
 * @return {string} An HTML string that represents stylized text followed by a "sparkle" SVG icon.
 */
export function getIntroAIHtml(textColor = '#3082CF') {
  return `
    <span style="color: ${textColor};">
        ${'lesson.problem_keys.typeAI'.t()}
        <svg class="icon-sparkle" style="color: ${textColor};">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/dist/student/images/icons.svg#sparkle"></use>
        </svg>
    </span>`
}

/**
 * Formats the practice keys into a string where each key is uppercased and bolded.
 * The keys are separated by commas and the final key is prepended with 'and'.
 *
 * @param {Array} practiceKeys - Array of practice keys to format.
 * @return {string} A string representation of the practice keys where each key is uppercased, bolded, separated by commas with the final key prepended with 'and'. If there are more than two keys, a comma is added before 'and'.
 */
export function formatKeys (practiceKeys) {
  let formattedKeys = practiceKeys.map(key => `<b class="tfh twh">${key.toUpperCase()}</b>`);
  let conjunction = (formattedKeys.length > 2) ? ', and ' : ' and ';
  let lastKey = formattedKeys.pop();
  let titleString = formattedKeys.join(', ');

  // Join last key with 'and' if there are multiple keys
  titleString += formattedKeys.length ? conjunction + lastKey : lastKey;

  return titleString;
}

/**
 * Determines the practice keys based on user's top problem keys and (optionally) location hash.
 *
 * @param {Object} userLettersTyped - The object storing information about user's typed letters.
 * @return {Array} An array of practice keys either based on top problem keys of the user or given location hash. If location hash cannot be correctly parsed, returns top problem keys.
 */
export function getPracticeKeys(userLettersTyped) {
  const locationHash = window.location.hash
  let practiceKeys = map(userLettersTyped.topProblemKeys(3), 'id');

  if (locationHash) {
    try {
      const decodedHash = decodeURIComponent(locationHash.replace('#', ''));
      practiceKeys = decodedHash.split('');
      // eslint-disable-next-line no-empty
    } catch(e) {}
  }

  return practiceKeys;
}
