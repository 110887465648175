import $ from 'jquery'
import Registry from '@registry'
import AdView from '../../global/views/ad'
// import TypingStandardView from './typing_standard'
import TypingView from './typing'
import { extend } from 'lodash-es'
import { lesson_typing_code_review } from '@templates/student'

export default TypingView.extend({

  template: lesson_typing_code_review,

  initialize: function() {
    TypingView.prototype.initialize.apply(this, arguments);

    const events = this.events || {}
    this.delegateEvents(
      extend(events, {
        'click .js-run-code': 'runCode',
        'click .js-submit': 'continue',
        'keydown input': 'handleEnter'
      })
    );
  },

  render: function() {
    Registry.set('preventKeyboardInput', true);

    this.listenTo(this.screen, 'change:letterCacheIndex', this.animate);

    const userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens'),
      unitType = this.unit ? this.unit.get('type') : ''

    let displayContent = this.screen.get('content').trim().replaceAll(/\t/g, '&nbsp;&nbsp;').split("\n");

    let inputIndex = 0

    displayContent = displayContent.map((row) => {
      return row.replaceAll(/___(\[.*])?/g, (val) => {
        const value = val.length > 3 ? val.match(/\[(.*?)]/)[1] : ''
        
        return `<input type="text" placeholder="${'lesson.typing_code_placeholder'.t()}" class="input js-code-review-input" name="code-review-${inputIndex++}" value="${value}" style="width:126px;border-radius:8px;font-weight:bold;text-align:center;"></input>`
      })
    })

    this.$el.append(this.template({
      ads: AdView.canShowAds() && (unitType === 'test' || (unitType !== 'test' && totalScreens > 0)), // Always show ads on the test page
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens, forceAd: unitType === 'test' }),
      mode: this.mode,
      words: this.words,
      displayContent: displayContent,
      codeReviewIntro: this.screen.get('settings')?.code_review_intro
    }));

    TypingView.prototype.render.apply(this, arguments);

    window.setTimeout(() => {
      this.$('input[name=code-review-0]').focus()
    }, 100)

    return this;
  },

  runCode: function() {
    const inputEle = this.$('.js-code-review-input'),
      inputValues = [...inputEle].map(element => $(element).val());

    let code = this.screen.get('settings')?.code_review_code || ''

    try {
      code = code.replaceAll(/___/g, () => inputValues.length ? JSON.stringify(inputValues.shift()) : 'MISSING')

      const codeFn = new Function(code)

      this.processResponse(codeFn())
    } catch (e) {
      this.processResponse({ success: false, msg: 'lesson.code_review_error_running'.t() })
    }
  },

  processResponse: function(response) {
    if(response.success) {
      // Allow user to go to next screen
      this.$('.js-submit').removeClass('is-disabled').prop('disabled', false)
    } else {
      // User cannot go to next screen
      this.$('.js-submit').addClass('is-disabled').prop('disabled', true)
    }

    this.$('.js-results').html('> ' + response.msg)
  },

  continue: function(e) {
    const ele = this.$(e.currentTarget),
      fastForward = false

    ele.addClass('btn--loading')

    this.screen.set({
      fastForward: fastForward,
      typed: 1,
      errors: 0,
      seconds: 0,
    }, { silent: true })

    this.trigger('complete')

    return false
  }
})
