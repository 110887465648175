import Backbone from 'backbone'
import AdView from '../../global/views/ad'
import { lesson_caps_lock_warning } from '@templates/student'

export default Backbone.View.extend({

  template: lesson_caps_lock_warning,

  events: {
    'click .js-close': 'hide'
  },

  capsLockWarning: null,

  serialize: function() {
    return {
      ads: AdView.canShowAds()
    };
  },

  isOpen: false,

  toggle: function(show) {
    if(!this.capsLockWarning) {
      this.capsLockWarning = this.$('.js-capslock-warning');
    }

    if(this.screen.get('isCapsLockWord') && show) {
      if(this.isOpen) {
        this.capsLockWarning.removeClass('is-open');
        this.isOpen = false;
      }
      return;
    }
    if(show && !this.isOpen) {
      this.capsLockWarning.addClass('is-open');
      this.isOpen = true;
    } else if (!show && this.isOpen) {
      this.capsLockWarning.removeClass('is-open');
      this.isOpen = false;
    }
  },

  hide: function() {
    this.toggle(false);
    return false;
  }

})
