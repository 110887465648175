import $ from 'jquery'
import moment from 'moment'
import { global_multiple_users_modal, global_modal } from '@templates/student'
import Modal from './modal'

export default Modal.extend({
  modalBaseTemplate: global_modal,
  template: global_multiple_users_modal,

  titleText: 'shared.multiple_accounts_found'.t(),

  successNotice: null,

  okText: 'shared.log_in'.t(),

  events: {
    'click .js-submit': 'clickOk',
    'click .js-row': 'clickRow'
  },

  accountId: 0, // set as they click the rows
  accountKey: '', // this will be user_id or teacher_id

  initialize: function() {
    Modal.prototype.initialize.apply(this, arguments);

    this.data = this.data.sort(function(a, b){ return (a.last_login > b.last_login) ? -1 : 1; });

    this.accountId = this.data[0][this.accountKey]
    this.loginType = this.data[0].login_type
  },

  serialize: function() {
    return {
      moment: moment,
      accountKey: this.accountKey,
      data: this.data
    };
  },

  clickOk: function(e) {
    $(e.currentTarget).addClass('pending');
    this.resolve({ accountId: this.accountId, loginType: this.loginType });
  },

  clickRow: function(e) {
    this.$('.js-row.is-selected').removeClass('is-selected')
    this.$(e.currentTarget).addClass('is-selected')
    this.accountId = $(e.currentTarget).data('id')
    this.loginType = this.$(e.currentTarget).data('login-type')
  }
})
