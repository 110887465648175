import Registry from '@registry'
import $ from 'jquery'
import svg4everybody from 'svg4everybody'
import lazyLoad from 'lazyload'
import '@shared/globals';
import './global/configs/form_helpers'
import { Cookies } from '@shared/helpers'
import BugsnagConfig from '@shared/bugsnag'
import moment from 'moment';
import { mapLanguageToLibrary, loginRedirect, rot47 } from '@shared/helpers'
import Router from './router'
import Authentication from '@shared/authentication'
import Analytics from '@shared/analytics'
import Alert from './global/views/alert'
import Notice from '@shared/notice'
import AdView from './global/views/ad'
import LocalstorageBackend from '@shared/model_backends/localstorage'
import UserModel from './global/models/user'
import CurriculumModal from './global/views/curriculum_modal';
import Scoring from '@shared/scoring'
import RadarTracker from '@shared/radar_tracker'
import AchievementsCollection from './global/collections/achievements'
import AchievementGroupsCollection from './global/collections/achievement_groups'
import AssignmentsCollection from './global/collections/assignments'
import SupportModal from './global/views/support_modal'
import ProductsCollection from './global/collections/products'
import UserActivityCollection from './global/collections/user_activity'
import UserLessonsCollection from './global/collections/user_lessons'
import UserLessonScreensCollection from './global/collections/user_lesson_screens'
import UserGamesCollection from './global/collections/user_games'
import UserTestsCollection from './global/collections/user_tests'
import LettersTypedCollection from'./global/collections/letters_typed'
import LanguagesCollection from './global/collections/languages'
import SkinsCollection from './global/collections/skins'
import UnitsCollection from './global/collections/units'
import LessonsCollection from './global/collections/lessons'
import { find, filter, debounce, throttle } from 'lodash-es'
import exportLocation from '@shared/geolocation';
import '@shared/link_tracker'

/**
 * All App Initialization Here
 */
const App = {
  prefix: 'student',

  authRequired: function(){
    var noAuthRequired = [/\/student$/, /student\/login$/, /\/student\/signup$/, /\/student\/oauth$/, /\/certificacion/, /\/student\/verify$/, /\/student\/join$/, /\/student\/join\/login$/, /\/student\/join\/signup$/, /\/student\/password$/];
    return !noAuthRequired.reduce(function(current, path){ if(window.location.pathname.match(path)) { return current + 1; } return current; }, 0);
  },

  initialize: function() {
    // Bugsnag.leaveBreadcrumb('Loaded app file', {file: 'student'});

    moment.locale(mapLanguageToLibrary('moment', FTWGLOBALS('language')));

    if(!this.browserCheck()) {
      new Alert({
        title: 'shared.errors.uh_oh'.t(),
        text: 'resource_error'.t(),
        closeButton: false
      }).on('ok', () => {
        window.location.reload()
      })

      return;
    }

    if(!this.hostCheck()) {
      return;
    }

    this.globals();
    if(!this.authentication()) {
      return;
    }

    // Sometimes students login or signup from modals. This redirects them to their last page
    if(this.authRequired() && window.localStorage.getItem('_oneTimeRedirect')) {
      const href = window.localStorage.getItem('_oneTimeRedirect');
      window.localStorage.removeItem('_oneTimeRedirect');
      window.location.href = href;
      return false;
    }

    if(!this.anonDefaults()) {
      return;
    }

    // Add Registry to window for easier debugging in browser
    if(FTWGLOBALS('env') !== 'production') {
      window.registry = Registry
    }

    this.bugsnag()
    this.initLazyload()
    this.analytics()
    this.setCountry()
    this.tracker()
    this.router()
    this.views()
    this.support()
    this.dropdown()
    this.survey()
    // this.curriculumModal()
    this.usingMouse()
    this.flashMessage()
    this.setVHvalue()
  },

  browserCheck: function() {
    if(!FTWGLOBALS('units') || (FTWGLOBALS('includesLessonScreens') && !FTWGLOBALS('lesson_screens'))) {
      return false
    }

    return !window.__noLocalStorage;
  },

  hostCheck: function() {
    // Only allow whitelabel or typing.com host names
    if(FTWGLOBALS('app_hostname') !== window.location.hostname) {
      window.location.href = 'https://www.typing.com/student/login';
      return false;
    }

    return true;
  },

  /**
   * This function updates the 'problemkeysai' option within the settings
   * of a user based on the user's district.
   *
   * It retrieves the user from the registry and the district of the user.
   * The district is considered enabled if its ID modulo 100 is less than or equal to 10.
   *
   * If the user has the 'problemkeysai' option within their settings,
   * the function sets the 'problemkeysai' option to be the value of 'isDistrictEnabled'.
   * The user's settings are then updated with the new options.
   */
  updateProblemKeysAIStatus: function() {
    const user = Registry.get(this.prefix);

    const teacherHasEnabledProblemKeysAI = user.hasOption('problemkeysai')
    const hasAccessToProblemKeysAI = user.get('licensed_at') >= 1

    let currentSettings = user.get('settings') || {};
    let currentOptions = currentSettings.options || {};
    currentOptions['problemkeysai'] = teacherHasEnabledProblemKeysAI && hasAccessToProblemKeysAI;
    currentSettings.options = currentOptions;

    user.setSettings(currentSettings);
  },

  /**
   * Set up global registry variables
   */
  globals: function() {
    window.__registry = Registry

    Registry.set('isMobile', window.navigator.userAgent.match(/iPad|iPhone|Android/));

    LocalstorageBackend.setPrefix(this.prefix);
    LocalstorageBackend.setEncryption(rot47);

    var products = new ProductsCollection(FTWGLOBALS('products'));
    Registry.set('products', products);

    var languages = new LanguagesCollection(Object.values(FTWGLOBALS('languages')));
    Registry.set('languages', languages);

    var skins = new SkinsCollection(Object.values(FTWGLOBALS('skins')));
    Registry.set('skins', skins);

    var units = new UnitsCollection();  // these will be set on the dashboard, and then keep a copy of them for later use
    Registry.set('units', units);

    var lessons = new LessonsCollection();  // these will be set on the dashboard, and then keep a copy of them for later use
    Registry.set('lessons', lessons);

    Registry.set('achievements', new AchievementsCollection(FTWGLOBALS('achievements')));
    Registry.set('achievementGroups', new AchievementGroupsCollection(FTWGLOBALS('achievement_groups')));

    var customLessons = new LessonsCollection();  // these will be set on the dashboard, and then keep a copy of them for later use
    customLessons.setBackend(new LocalstorageBackend('customLessons'), 'backend');
    Registry.set('customLessons', customLessons);

    var userLessons = new UserLessonsCollection();
    userLessons.setBackend(new LocalstorageBackend('userLessons'), 'backend');
    Registry.set('userLessons', userLessons);

    var userLessonScreens = new UserLessonScreensCollection();
    userLessonScreens.setBackend(new LocalstorageBackend('userLessonScreens'), 'backend');
    Registry.set('userLessonScreens', userLessonScreens);

    var userActivity = new UserActivityCollection();
    userActivity.setBackend(new LocalstorageBackend('userActivity'), 'backend');
    Registry.set('userActivity', userActivity);

    var userGames = new UserGamesCollection();
    userGames.setBackend(new LocalstorageBackend('userGames'), 'backend');
    Registry.set('userGames', userGames);

    var userAchievements = new AchievementsCollection();
    userAchievements.setBackend(new LocalstorageBackend('userAchievements'), 'backend');
    Registry.set('userAchievements', userAchievements);

    var assignments = new AssignmentsCollection();
    assignments.setBackend(new LocalstorageBackend('assignments'), 'backend');
    Registry.set('assignments', assignments);

    var userTests = new UserTestsCollection();
    userTests.setBackend(new LocalstorageBackend('userTests'), 'backend');
    Registry.set('userTests', userTests);

    var userLettersTyped = new LettersTypedCollection();
    userLettersTyped.setBackend(new LocalstorageBackend('userLettersTyped'), 'backend');
    Registry.set('userLettersTyped', userLettersTyped);

    // Set the player models
    var userModel = new UserModel();    // Create the global model
    userModel.setBackend(new LocalstorageBackend(this.prefix), 'backend');   // set up the backend storage
    Registry.set(this.prefix, userModel);

    this.updateProblemKeysAIStatus();

    var check = debounce(AchievementsCollection.check.bind(AchievementsCollection), 300);
    userActivity.on('all', check);
    userGames.on('all', check);
    userLessons.on('all', check);
    userLessonScreens.on('all', check);
    userTests.on('all', check);

    var assignmentsCheck = debounce(AssignmentsCollection.check.bind(AssignmentsCollection), 300);
    userLessons.on('all', assignmentsCheck);
    userTests.on('all', assignmentsCheck);
    userGames.on('all', assignmentsCheck);
  },

  anonDefaults: function() {
    var user = Registry.get(this.prefix),
      language = FTWGLOBALS('language');

    const anonId = Registry.get('loggedIn') ? '' : Date.now() + (100+Math.floor(Math.random()*999))
    if(!Cookies.get('anonId') || Cookies.get('anonId') < 1000 || Registry.get('loggedIn')) {
      Cookies.set('anonId', anonId, {path: '/', expires: 30});
    }

    if(!window.location.pathname.match(/student\/signup$/) && !window.location.pathname.match(/student\/oauth$/)) {
      // this cookie stores all student data typed while not logged in.  the cookie is sent on registration to save their scores. it's potentially a big cookie so we don't want to keep it unless they're registring
      Cookies.set('student_anon', '', {path: '/'});
    }

    // this _anonDefaultsSet will be set here just the once, so we know if they're new
    if(!Registry.get('loggedIn')) {
      if(language !== user.getSetting('language') || !user.get('_anonDefaultsSet')) {
        // Bootstrap will send the units for the correct product_id, so get the product_id from the units
        user.setSettings({
          product_id: FTWGLOBALS('units')[0].product_id,
          language: language,
          keyboard_id: mapLanguageToLibrary('keyboard', language)
        })
        user.set({
          _anonDefaultsSet: true
        })
      }
    }

    return true;
  },

  /**
   * Authentication based checks and stuff
   */
  authentication: function(options) {
    var user = Registry.get(this.prefix),
      auth = new Authentication(this.prefix);

    Registry.set('auth', auth);

    if(user.get('demo')) {
      return true;
    }

    $(document).ajaxError(function(ajax, response) {
      var status = parseInt(response.status);
      if (status === 401 || status === 403 || (response.responseJSON && response.responseJSON.logOut)) {
        Registry.set('loggedIn', false);
        Registry.get(this.prefix).logOut();
        this.loggedOutNotice();
      }
    }.bind(this));

    // Checks for EduTec users with assessment access
    if(user.isAssessment() && !window.location.pathname.match(/student\/certificacion*/) && !window.location.pathname.match(/student\/typing-test\/*/) && !window.location.pathname.match(/student\/verify*/)) {
      window.location.href = __url('/' + this.prefix + '/certificacion')
      return false
    }

    if (FTWGLOBALS('whitelabel') && !auth.valid() && this.authRequired()) {
      user.logOut();
      window.location.href = __url('/' + this.prefix + '/login');
      return false;

    } else if(this.authRequired() && ((!auth.valid() && user.id) || (user.id && parseInt(auth.payload()?.sub) !== user.id))) {
      user.logOut();
      window.location.href = __url('/' + this.prefix + '/login');
      return false;
    }

    if(auth.valid() && user.id) {
      Analytics.on('activityTimeout', function() {
        this.inactivityNotice();
        Analytics.off('activityTimeout');

        // Schedule logout and redirect after 60 seconds
        setTimeout(() => {
          Registry.get(this.prefix).logOut();
          loginRedirect(this.prefix);
        }, 60000);

      }.bind(this));

      var setLoggedOutNotice = function() {
        // Log them out 60 seconds before their auth token expires
        window.setTimeout(() => this.loggedOutNotice(), (auth.expiration()*1000 - Date.now()) - 60*1000);
      }.bind(this);

      Registry.set('loggedIn', true);
      // when the jwt token expires, let them know and log them out
      if(auth.expiration()*1000 - Date.now() < 60*60*2*1000) {  // if it's 2 hours until token expiration, refresh it on page load.  means they're still active
        user.refreshSession().done(setLoggedOutNotice);
      } else {
        setLoggedOutNotice();
      }
    }

    Registry.set('token', auth.token());

    if(Registry.get('loggedIn') && (user.get('must_change_password') || user.get('must_update_name')) && user.get('login_type') === 'username' && !window.location.pathname.match(/\/student\/account/)) {
      if(user.get('must_change_password') && user.get('must_update_name')) {
        user.set({_flashMessage: 'app.must_set_name_and_password'.t()});
      } else if(user.get('must_change_password')) {
        user.set({_flashMessage: 'app.must_change_password'.t()});
      } else {
        user.set({_flashMessage: 'app.must_set_name'.t()});
      }
      window.location.href = __url('/student/account');
      return false;
    }

    if(Registry.get('loggedIn') && user.get('licensed_at') > 0 && auth.payload().licensed_at > 0) {
      window.localStorage.setItem('student_license', true);  // blade layout will use this to load or not load google
    } else {
      window.localStorage.removeItem('student_license');
    }

    return true;
  },

  curriculumModal: function() {
    const user = Registry.get('student'),
      productId = user.getSetting('product_id')

    if(Registry.get('loggedIn') && !user.get('_sawCurriculumModal') && !user.get('in_class') && !user.get('last_login')) {
      new CurriculumModal({
        okText: 'curriculum.set_text'.t(),
        user: user,
        productId: productId,
        firstSet: true
      }).open()

      user.set({ _sawCurriculumModal: true })
    }
  },

  flashMessage: function() {
    var user = Registry.get(this.prefix);

    if(user.get('_flashMessage')) {
      (new Notice({
        text: user.get('_flashMessage')
      })).show();
      user.unset('_flashMessage');
    }
  },

  loggedOutNotice: function(){
    Registry.get(this.prefix).logOut();

    (new Alert({
      title: 'app.logged_out_title'.t(),
      text: 'app.logged_out_body'.t(),
      closeButton: false
    })).on('ok', function(){
      loginRedirect(this.prefix);
    }.bind(this));
  },

  inactivityNotice: function(){
    const alertView = new Alert({
      title: 'app.inactivity_title'.t(),
      text: 'app.inactivity_body'.t(),
      action: 'app.inactivity_action'.t(),
      okText: 'global.log_out_link'.t(),
      closeButton: false
    });

    // Define the action callback method
    const actionCallback = function(){
      Analytics.setActivityTimeout();
    };

    // Set the action callback method for the alert view
    alertView.actionCallback = actionCallback;

    // Listen for the 'ok' event
    alertView.on('ok', function(){
      Registry.get(this.prefix).logOut();
      loginRedirect(this.prefix);
    }.bind(this));

    // Listen for the 'action' event
    alertView.on('action', actionCallback);

    // Show the alert view
    (alertView.bind(this));
  },

  _getCustomDimensions: function() {
    const user = Registry.get(this.prefix);

    let activity = Registry.get('userActivity').getCompiled(999),
      speed = Scoring.speed(activity.typed, activity.seconds),
      lessons = Registry.get('userLessons').toJSON().filter(function(row){ return row.progress > 0; }).length,
      teacherId = Registry.get('auth').getAuthPayloadId('teacher'),
      keyboard = find(FTWGLOBALS('keyboards'), { keyboard_id: parseInt(user.getSetting('keyboard_id'))})

    if(!keyboard){
      keyboard = find(FTWGLOBALS('keyboards'), { keyboard_id: 1 });
      user.setSettings({ keyboard_id: 1 });
    }

    let orgSubtype = 'individual'
    let orgType = 'individual'
    const district = user.get('district')

    if (user.getSetting('individual_org_type')) {
      orgSubtype = user.getSetting('individual_org_type')
    } else if (user.get('teacher_id') && district) {
      orgType = district?.license_type || ''
      orgSubtype = district?.org_type || ''

      if(orgSubtype !== 'home_school' && orgSubtype !== 'district' && orgSubtype !== 'school' && orgSubtype !== 'teacher' && orgSubtype !== 'corpgov') {
        if (orgSubtype.includes('parent') || orgSubtype.includes('home') || orgSubtype.includes('house')) {
          orgSubtype = 'home_school'
        } else if (orgSubtype.includes('business') || orgSubtype.includes('company') || orgSubtype.includes('corporation') || orgSubtype.includes('government')) {
          orgSubtype = 'corpgov'
        } else if (orgSubtype.includes('public') || orgSubtype.includes('private') || orgSubtype.includes('college') || orgSubtype.includes('librar')) {
          orgSubtype = orgType === 'classroom' ? 'teacher' : orgType
        } else {
          orgSubtype = 'other'
        }
      }
    }

    return {
      gaId: (teacherId ? 't' + teacherId : (Registry.get('loggedIn') ? 's' + user.id : '(page:undefined)')), // The system user ID of the logged-in user.
      studentId: (Registry.get('loggedIn') ? 's' + user.id : 'anon'),
      teacherId: (teacherId ? 't' + teacherId : 'anon'),
      studentTeacherID: user.get('teacher_id'),
      studentType: (user.get('teacher_id')) ? 'in class' : 'individual', // Individual or in class
      membership: user.get('licensed_at') > 0 ? 'premium' : 'free', // Free or premium
      accountAge: Registry.get('loggedIn') ? user.get('created_at') : '(page:undefined)', // Age of the account in days
      emails: (user.get('email')) ? rot47(user.get('email')) : 'none', // Array of strings; the email addresses associated with the logged-in user.
      emailDomains: (user.get('email')) ? user.get('email').substr(user.get('email').indexOf('@') + 1) : 'none',
      curriculumLang: user.getCurriculumLanguage(),
      siteLang: FTWGLOBALS('language'), // User language setting
      skin: user.getSkinName(user.get('skin_id'), user.get('variant_id')), // Skin name with variant
      keyboardLayout: keyboard ? keyboard.name : '(page:undefined)', // Keyboard layout name
      loginType: user.get('login_type') ? user.get('login_type') : '(page:undefined)',
      typingSpeed: speed,
      lessonsStarted: lessons,
      adblock:  FTWGLOBALS('adBlocker'),
      orgType,
      orgSubtype,
      districtId: district?.district_id,
      loggedIn: teacherId || Registry.get('loggedIn') ? 'logged in' : 'logged out',
      lastLogin: Registry.get('loggedIn') ? user.get('last_login') : '(page:undefined)',

      // 'ad-count': Object.keys(AdView.getAds()).length,
      'ad-network': window.__adNetwork
    };

  },

  analytics: function() {
    try {
      ga('send', 'pageview');
      Analytics.setActivityTimeout();
    } catch (e) {}
  },

  setCountry: function() {
    exportLocation()
  },

  tracker: function() {
    // Lesson page has it's own tracking
    var user = Registry.get('student');
    if (user.hasOption('realtime') && user.isPremium() && Registry.get('loggedIn') && user.get('in_class') && !window.location.pathname.match(/\/student\/lesson\/[0-9]+/)) {
      RadarTracker.track(Registry.get(this.prefix));
    }
  },

  router: function() {
    Router.initialize();
  },

  support: function() {
    $('.js-support-link').click(function(){
      var view = new SupportModal({});
      view.open();
      return false;
    });
  },

  dropdown: function() {
    const dropdownEle = $('.js-dropdown')
    // If the user tabs a dropdown into focus, open it and focus on the first item when enter is clicked
    dropdownEle.keydown(function(e) {
      if(e.which === 13  && (!$(e.target).hasClass('dropdown-item') || !$(e.target).hasClass('popdown-item'))) {
        $(this).addClass('is-hover').attr('aria-expanded', true);
        $('.js-dropdown.is-hover .dropdown-item:first-of-type, .js-dropdown.is-hover .popdown-item:first-of-type').focus();
      }
    });

    // If the user clicks anywhere on the page while an accessibility dropdown is open, close it
    dropdownEle.focus(function() {
      $('body').click(function() {
        $('.js-dropdown').removeClass('is-hover').attr('aria-expanded', false);
        $('body').unbind('click');
      });
    });

    // Handle various key inputs while the dropdown items are in focus
    $('.dropdown-item, .popdown-item').keydown(function(e) {
      var dropdown = $(this).closest('.js-dropdown'),
        dropdownId  = dropdown.attr('id'),
        hasLinks = $(this).children().hasClass('dropdown-link') || $(this).children().hasClass('popdown-link'),
        dropdownItems = hasLinks ? $('.js-dropdown#'+dropdownId+' .dropdown-link, .js-dropdown#'+dropdownId+' .popdown-link' ) : $('.js-dropdown#'+dropdownId+' .dropdown-item, .js-dropdown#'+dropdownId+' .popdown-item'),
        index = hasLinks ? dropdownItems.parent().index(this) : dropdownItems.index(this);

      if(e.which === 27) {
        dropdown.removeClass('is-hover').attr('aria-expanded', false);
        window.setTimeout(() => dropdown.focus(), 10 );
      }
      if(e.shiftKey && e.which === 9) {
        e.preventDefault();
        if (index ===  0) {
          window.setTimeout(() => dropdownItems.eq(dropdownItems.length - 1).focus(), 10 );
        } else {
          window.setTimeout(() => dropdownItems.eq(index - 1).focus(), 10 );
        }
      } else if(e.which === 9) {
        e.preventDefault();
        if (index < dropdownItems.length - 1) {
          window.setTimeout(() => dropdownItems.eq(index + 1).focus(), 10 );
        } else {
          window.setTimeout(() => dropdownItems.eq(0).focus(), 10 );
        }
      }
    });
  },

  usingMouse: function() {
    document.body.addEventListener('mousedown', function() {
      document.body.classList.add('using-mouse');
    });
    document.body.addEventListener('keydown', function() {
      document.body.classList.remove('using-mouse');
    });
  },

  views: function () {
    if(!window.location.href.match(/\/student\/oauth/)) {
      window.setTimeout(AchievementsCollection.check.bind(AchievementsCollection), 500);   // After a short delay, check in case something was missed on page refresh
      window.setTimeout(AssignmentsCollection.check.bind(AssignmentsCollection), 500); // After a short delay, check in case something was missed on page refresh
    }
  },

  survey: function () {
    if(!FTWGLOBALS('whitelabel')) {
      window._kiq = window._kiq || [];
      if(Registry.get('loggedIn')) {
        window._kiq.push(['identify', 's'+Registry.get(this.prefix).id])
      }
      window._kiq.push(['set', this._getCustomDimensions()])
    }
  },

  bugsnag: function() {
    let metadata = {}

    metadata.user = Registry.get(this.prefix).toJSON();

    var match = window.location.href.match(/lesson\/(\d+)/);
    if(match) {
      metadata.userLessons = find(Registry.get('userLessons').toJSON(), {lesson_id: parseInt(match[1])})
      metadata.userLessonsScreens = filter(Registry.get('userLessonScreens').toJSON(), {lesson_id: parseInt(match[1])})
    }

    metadata.ads = {
      showAds: AdView.canShowAds(),
      network: window.__adNetwork,
      adNetworkRandom: window.__adNetworkRandom,
      // adTypes: Object.keys(AdView.getAds())
    }

    if(window.location.href.match('/test/')) {
      metadata.userTests = Registry.get('userTests').toJSON()
    }

    BugsnagConfig.init(metadata)
  },

  initLazyload: () => {
    new lazyLoad();
  },

  setVHvalue: function() {
    const setVH = () => {
      let vh = window.innerHeight * 0.01;
      return document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    setVH();
    $(window).resize(throttle(setVH, 1000, false));
  }

}

// Start the show
$(() => {
    App.initialize();
    svg4everybody();
});
