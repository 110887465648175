import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import ContentView from './content'
import GuestBannerView from '../../global/views/guest_banner'
import AdView from '../../global/views/ad'
import AdButtonsView from '../../global/views/ad_buttons'
import Velocity from 'velocity-animate'
import ChartView from '../../global/views/chart'
import ZeroStateTestView from './zero_state_test'
import { groupBy, each, times } from 'lodash-es'
import { tests_main } from '@templates/student'

export default Backbone.View.extend({

  template: tests_main,

  events: {
    'click div.js-tab': 'changeTab',
    'change select.js-tab': 'changeTab',
    'click .js-accordion-trigger': 'accordionToggle',
    'click .js-sort': 'sort',
    'click .js-test': 'goToTest',
    'keydown .js-tab': function(e) {
      if(e.which === 13 && this.$(document.activeElement).hasClass('js-tab')) {
        this.changeTab(e);
      }
    },
    'keydown .js-panel.is-active': 'escapePanel'
  },

  testTypes: [
    { name: 'timed', slug: '1-minute', testLength: 1 },
    { name: 'timed', slug: '3-minute', testLength: 3 },
    { name: 'timed', slug: '5-minute', testLength: 5 },
    { name: 'page', slug: '1-page', testLength: 1 },
    { name: 'page', slug: '2-page', testLength: 2 },
    { name: 'page', slug: '3-page', testLength: 3 }],
  sortBy: 'created_at',
  activeTab: '1-minute',

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    if(AdView.canShowAds()) {
      AdView.initAds('tests_list');
      // grab all the ads and assign them to the right divs
      each(AdView.getAds(), (ad, size) => {
        if(!ad) { return; }
        this.addChild('.js-'+size+'-ad', ad);
      });

      var adButtonsView = new AdButtonsView();
      this.addChild('.js-ad-buttons', adButtonsView, false);
    }

    this.initialState = !Registry.get('loggedIn') && this.tests.length === 0;

    if(!Registry.get('loggedIn') && this.tests.length > 0) {
      var guestBannerView = new GuestBannerView({ page: 'tests', customTitle: 'tests.guest_banner_progress_title'.t({smart_count: Registry.get('userTests').length}) });
      this.addChild('.js-guest-banner', guestBannerView);
    }

    if(!this.initialState) {
      this.testTypes.forEach(function (testType) {
        const validUnitIds = FTWGLOBALS('units').filter(row => row.type === 'test').map(row => row.unit_id)
        const validLessonIds = FTWGLOBALS('lessons').filter(row => validUnitIds.includes(row.unit_id)).map(row => row.lesson_id)
        // Only gather tests by requested type. Do not include tests from the standard curriculum
        var tests = this.tests.toJSON().
            filter(function (row) { return validLessonIds.includes(row.lesson_id) && (testType.name === 'timed' ? row.seconds === (testType.testLength*60) : row.testType === testType.slug); }),
          testsLength = tests.length,
          chartOverlayView = null;

        this.views['content' + testType.slug] = new ContentView({
          sortBy: this.sortBy,
          hasLoaded: this.hasLoaded,
          tests: tests,
          seconds: (testType.name === 'timed' ? testType.testLength*60 : testType.testLength)
        });
        // Build applicable ChartOverlayView to place over the chart (if needed)
        if (testsLength === 0 && Registry.get('userTests').length === 0 && !Registry.get('loggedIn')) {
          chartOverlayView = new GuestBannerView({ isChart: true });
        } else if (testsLength === 0 || testsLength === 1) {
          chartOverlayView = new ZeroStateTestView({ dataLength: testsLength, testType: testType });
        }
        this.addChild('.js-panel-content[data-id=' + testType.slug + ']', this.views['content' + testType.slug]);

        this.views['charts' + testType.slug] = new ChartView({
          chartId: 'tests-' + testType.slug,
          title: ('tests.' + testType.name + '_test_title_text').t({ testLength: testType.testLength }),
          height: (testsLength > 1 && $(window).height() < 675) ? 80 : 100,
          width: 300,
          fullWidth: true,
          toggles: (testType.name === 'timed' ? ['speed', 'accuracy'] : ['speed', 'accuracy', 'time']),
          dataLength: testsLength,
          overlayView: chartOverlayView
        });
        this.addChild('.js-chart-tests-' + testType.slug, this.views['charts' + testType.slug]);
      }.bind(this));
    }

    this.listenTo(this.tests, 'reset', this.handleReset);
  },

  serialize: function(){

    var user = Registry.get('student'),
      curriculumLanguage = user.getCurriculumLanguage(),
      suffix = curriculumLanguage !== FTWGLOBALS('defaultLanguage') ? ('/' + curriculumLanguage) : '';

    return {
      ads: AdView.canShowAds(),
      adsClass: AdView.getLeftMarginClass(),
      sortBy: this.sortBy,
      activeTab: this.activeTab,
      testTypes: groupBy(this.testTypes, function(row) { return row.name; }),
      showGuestBanner: !Registry.get('loggedIn'),
      urlSuffix: suffix,
      initialState: this.tests.length === 0,
      loggedIn: Registry.get('loggedIn'),
      isPremium: user.get('licensed_at') > 0,
      productGrade: user.getCurriculumGrade(),
      miniNav: this.miniNav,
      lodash: { times },
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    window.setTimeout(() => {
      this.testTypes.forEach((testType) => {
        if(Registry.get('loggedIn') || this.tests.length > 0) { // For zero-state we don't display charts
          var chartData = this.getChartData(testType);

          this.views['charts' + testType.slug].resetChart(chartData);
        }
      });
    }, 0);

    return this;
  },

  getChartData: function(testType) {
    var chartData = {},
      data = this.views['content' + testType.slug].data.reverse();

    if (data.length <= 1) {
      data = [
        {
          speed: (data[0]) ? data[0].speed : 14,
          created_at: (data[0]) ? data[0].created_at : Date.now() / 1000,
          time: (data[0]) ? data[0].time : testType.testLength * 60
        },
        {
          speed: (data[0]) ? Math.ceil(data[0].speed * 1.01) : 16,
          created_at: Date.now() / 1000 + 86400,
          time: (data[0]) ? data[0].time : testType.testLength * 60
        },
        {
          speed: (data[0]) ? Math.ceil(data[0].speed * 1.015) : 19,
          created_at: Date.now() / 1000 + 86400 * 2,
          time: (data[0]) ? data[0].time : testType.testLength * 60
        },
        {
          speed: (data[0]) ? Math.ceil(data[0].speed * 1.025) : 23,
          created_at: Date.now() / 1000 + 86400 * 3,
          time: (data[0]) ? data[0].time : testType.testLength * 60
        },
        {
          speed: (data[0]) ? Math.ceil(data[0].speed * 1.05) : 29,
          created_at: Date.now() / 1000 + 86400 * 4,
          time: (data[0]) ? data[0].time : testType.testLength * 60
        }
      ];
      chartData.options = { // Only force a y-axis padding for zero/one-state charts
        speed: {
          scales: {
            yAxes: [
              {
                ticks: {
                  min: (data[0] ? data[0].speed : 14) - 2
                }
              }]
          }
        }
      };
    }

    chartData.labels = data.map(function (row) { return row.created_at * 1000; });
    chartData.data = data.map(function (row) { return { speed: row.speed, accuracy: row.accuracy, time: row.seconds }; });

    return chartData;
  },

  renderContent: function() {
    this.testTypes.forEach(function(testType){
      this.views['content' + testType.slug].sortBy = this.sortBy;
      this.views['content' + testType.slug].render();
    }.bind(this));
  },

  handleReset: function() {
    this.testTypes.forEach(function(testType){
      this.views['content' + testType.slug].hasLoaded = true;
    }.bind(this));
    this.renderContent();
  },

  sort: function(e) {
    this.$('.js-sort').removeClass('is-active').attr('aria-selected', false);
    var target = $(e.currentTarget);
    this.sortBy = target.addClass('is-active').data('id');
    target.attr('aria-selected', true);

    this.renderContent();
    return false;
  },

  changeTab: function(e) {
    var target = $(e.currentTarget),
      id = (target.is('select')) ? target.val() : target.data('id'),
      selectTarget = this.$('select.js-tab');

    this.$('.js-panel[data-id=' + this.activeTab+'], .js-tab[data-id='+this.activeTab+']').removeClass('is-active').attr('aria-selected', "false");

    this.activeTab = id;

    this.$('.js-panel[data-id=' + this.activeTab+'], .js-tab[data-id='+this.activeTab+']').addClass('is-active').attr('aria-selected', "true");

    selectTarget.val(this.activeTab);

    if(e.type === 'keydown') {
      this.$('.js-panel.is-active a').eq(0).focus();
    }
  },

  escapePanel: function(e) {
    if(e.which === 27) {
      this.$('.js-tab.is-active').focus();
    }
  },

  goToTest: function(e) {
    var target = $(e.currentTarget),
      id = target.data('id');

    window.location.href = __url('/student/typing-test/' + id + this.getUrlSuffix());

    return false;
  },

  getUrlSuffix: function() {
    var user = Registry.get('student'),
      curriculumLanguage = user.getCurriculumLanguage();

    return curriculumLanguage !== FTWGLOBALS('defaultLanguage') ? ('/' + curriculumLanguage) : '';
  },

  accordionToggle: function(e) {
    var id = $(e.currentTarget).data("id"),
      accordion = this.$('.js-accordion[data-id=' + id + ']'),
      closeOnly = accordion.hasClass("is-open");

    Velocity($(".js-accordion .accordion-content"), "slideUp",
      {
        duration: 250,
        complete: function() {
          this.$(".js-accordion").removeClass("is-open").attr('aria-expanded', false);
        }.bind(this)
      }
    );
    if(!closeOnly) {
      Velocity($('.js-accordion[data-id=' + id + '] .accordion-content'), "slideDown",
      {
        duration: 250,
        complete: function() {
          accordion.addClass("is-open").attr('aria-expanded', true);
        }.bind(this)
      })
    }
  },
})
