import $ from 'jquery'
import Modal from './modal'
import { global_section_modal } from '@templates/student'

export default Modal.extend({
  titleText: 'login.log_into_class_text'.t(),

  successNotice: null,

  template: global_section_modal,

  events: {
    'click .js-submit': 'clickOk'
  },

  serialize: function() {
    return {
      sections: this.sections
    };
  },

  clickOk: function(e) {
    $(e.currentTarget).addClass('pending');
    this.resolve(this.$('select[name=section_id]').val());
  }
})
