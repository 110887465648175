import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import SkinsCollection from '../../global/collections/skins'
import Velocity from 'velocity-animate'
import { global_level_up } from '@templates/student'

export default Backbone.View.extend({

  template: global_level_up,

  events: {
    'click .js-lvlUp-btn': 'levelUp',
    'click .js-close' : 'hide'
  },

  timeout: 0,

  initialize: function() {
    this.user = Registry.get('student');
    this.userActivity = Registry.get('userActivity');
    this.skins = new SkinsCollection(FTWGLOBALS('skins'));

    this.listenTo(this.user, 'change:skin_id', this.setLevelListener);

    this.setLevelListener();
    this.render();
  },

  serialize: function() {
    var skin = this.skins.get(this.user.get('skin_id') || 1),
      activity = this.userActivity.getOrAdd(0);

    var rank = skin.getRank(activity.get('typed') - activity.get('errors')),
      nextRank = skin.getNextRank(activity.get('typed') - activity.get('errors'));

    return {
      rank: rank,
      nextRank: nextRank,
      variantId: this.user.get('variant_id') || 1
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);
    $('body').append(this.el);

    return this;
  },

  setLevelListener: function() {
    var skin = this.skins.get(this.user.get('skin_id') || 1),
      activity = this.userActivity.getOrAdd(0);

    // global userAchievements
    this.listenTo(activity, 'change:typed', this.handleUpdate);
  },

  handleUpdate: function(model) {
    var skin = this.skins.get(this.user.get('skin_id') || 1);

    var activity = model.toJSON(),
      prevActivity = model.previousAttributes(),
      prevRank = skin.getRank(prevActivity.typed - prevActivity.errors),
      rank = skin.getRank(activity.typed - activity.errors);

    if(prevRank.id !== rank.id) {
      window.setTimeout(() => this.show(), 100);
    }
  },

  levelUp: function() {
    var avatar = this.$('.js-lvlUp-avatar'),
      bubble = this.$('.js-lvlUp-bubble'),
      overlay = this.$('.js-overlay'),
      rank = avatar.data('rank'),
      lvl1 = this.$('.js-lvlUp-1'),
      lvl2 = this.$('.js-lvlUp-2'),

      rankText = String(rank).pad(2, '0', 'STR_PAD_LEFT'),
      skin = this.skins.get(this.user.get('skin_id') || 1),
      activity = this.userActivity.getOrAdd(0).toJSON(),
      nextRank = skin.getRank(activity.typed - activity.errors),
      nextRankText = String(nextRank.id).pad(2, '0', 'STR_PAD_LEFT');

    // start the animation
    avatar.removeClass('is-ready rank--' + rankText).addClass('is-evolving');
    bubble.addClass('is-evolving');

    // Halfway through, Switch the scenes
    window.setTimeout(() => {
      avatar.addClass('avatar--' + nextRankText);
      overlay.addClass('lvlUp-bg--' + nextRankText);

      Velocity(lvl1, 'fadeOut');
      Velocity(lvl2, 'fadeIn');
    }, 1000);
  },

  show: function() {
    Registry.set('preventKeyboardInput', true);

    this.$('.js-overlay').fastShow();
    this.$('.js-modal').addClass('is-open');

    window.setTimeout(() => {
      this.$('.js-lvlUp-avatar').addClass('is-ready');
      Velocity(this.$('.js-lvlUp-bubble'), 'fadeIn');
    }, 250);

    window.setTimeout(() => {
      $(document).on('keydown.level_up', (e) => {
        // esc or enter
        if(e.which === 13 || e.which === 27) {
          this.hide();
        }
      });
    }, 500);
  },

  hide: function() {
    Registry.set('preventKeyboardInput', false);
    $(document).off('keydown.level_up');

    this.$el.css({'position': 'relative', 'z-index': '99'});
    this.$el.fadeOut();

    this.trigger('hide');
  }
})
