import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import { rot47} from '@shared/helpers';

export default Backbone.Model.extend({

  initialize: function() {
  },

  urlRoot: '/apiv1/student/stats/games',

  defaults: {
    game_id: 0,
    score: 0,
    seconds: 0,
    created_at: 0
  },

  saveScore: function() {
    const data = this.toJSON(),
      user = Registry.get('student');

    if(!Registry.get('loggedIn')) {
      data.anon = 1
    } else {
      data.user_id = user.id
      data.product_id_pk = user.getSetting('product_id_pk')
    }
    return $.ajax({
      type: 'POST',
      url: this.urlRoot,
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({ stats: data })
    })
  }
})
