import Registry from '@registry'
import Modal from '../../global/views/modal'
import { tests_unable_to_print_modal } from '@templates/student'

export default Modal.extend({

  template: tests_unable_to_print_modal,

  titleText: 'lesson.unable_to_print'.t(),

  okText: '',

  cancelText: '',

  serialize: function() {

    return {
      loggedIn: Registry.get('loggedIn'),
      user: Registry.get('student')
    }
  }
})
