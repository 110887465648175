import Backbone from 'backbone'
import Registry from '@registry'
import $ from 'jquery'
import Model from '../models/student_survey'
import { global_student_survey } from '@templates/student'
import Velocity from 'velocity-animate';

export default Backbone.View.extend({

  template: global_student_survey,

  formSelector: 'form',

  successNotice: 'upgrade.thank_you_title'.t(),

  events: {
    'click .js-close': 'close',
  },

  modelClass: Model,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments)

    this.render()

    window.setTimeout(() => {
      $('body').append(this.$el);
      this.show()
    }, 1500)
  },

  show: function() {
    Velocity(
      this.$('.js-survey'),
      {
        opacity: [1,0],
        translateY: ['0','50%'],
        scale: [1, 0.3],
      },
      {
        duration: 250,
        easing: 'easeIn'
      }
    )
  },

  buttonClick: function(e) {
    this.$('input[name=response]').val(this.$(e.currentTarget).data('id'))

    this.close()

    Backbone.View.prototype.buttonClick.apply(this, arguments)
  },

  submitCallback: function() {
    const option = this.$('input[name=response]').val()

    Registry.get('student').setSettings({ individual_org_type: option })

    Backbone.View.prototype.submitCallback.apply(this, arguments)
  },

  errorCallback: function() {
    // DO NOTHING. The user doesn't need to know
  },

  close: function() {
    Registry.get('student').set({ _hideSurvey: true })
    Velocity(this.$el, 'fadeOut')
  }

})
