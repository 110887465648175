import ChartView from '@shared/chart'
import ChartDefaults from '../../global/configs/chart_defaults'
import { extend } from 'lodash-es'
import { global_chart } from '@templates/student'

export default ChartView.extend({

  template: global_chart,

  initialize: function() {
    ChartView.prototype.initialize.apply(this, arguments);

    this.chartDefaults = ChartDefaults

    if(this.overlayView) {
      this.addChild('.js-overlay', this.overlayView);
    }
  },

  serialize: function() {
    return extend(ChartView.prototype.serialize.apply(this, arguments), {
      overlay: !!this.overlayView,
      fullWidth: !!this.fullWidth
    });
  }
})
