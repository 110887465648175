import Backbone from 'backbone'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import Velocity from 'velocity-animate'
import { find } from 'lodash-es'
import { lesson_intro_video, lesson_intro_text } from '@templates/student'
import ProblemKeysModel from '../models/problem_keys';
import $ from 'jquery'
import { getIntroAIHtml } from '../../utils/problem_keys_lesson';
export default Backbone.View.extend({

  events: {
    'click .js-continue-button': 'continue',
    'click .js-book': 'animateAdventureOpen',
    'click .js-arrow': 'toggleCarousel'
  },

  bookIsOpen: false,

  initialize: function(options) {
    if(options.screen.get('settings')?.problem_keys) { // conditionally set modelClass based on screen settings
      this.modelClass = ProblemKeysModel;
    }
    Backbone.View.prototype.initialize.apply(this, arguments);
    const screenIntro = this.screen.get('intro')

    // NOTE: TC must now support EduTyping's various intro screens. We do not need to suport #!images because BEP did not utilize this intro type
    if(screenIntro.match('<video')) { // TC's version of an intro video
      this.screen.set({ hasVideo: true })
      this.template = lesson_intro_video

      if (typeof window !== "undefined" ) {
        this.handleKeypressBound = this.handleKeypress.bind(this);

        // Attach the event listener to the window object
        window.addEventListener('keypress', this.handleKeypressBound);
      }
    } else if(screenIntro.match('#!videos')) { // EduTyping's version of intro videos
      this.screen.set({ hasVideo: true })
      this.template = lesson_intro_video
      this.eduVideo = screenIntro.replace("#!videos\n","").split('\n')[0] // EduTyping only had one video, they never showed multiple videos
    } else if(screenIntro.match('#!code')) { // EduTyping's intro of code
      this.template = lesson_intro_text
      this.screen.set('intro', screenIntro.replace("#!code\n",""))
    } else if(screenIntro.match('#!text')) { // EduTyping's intro of text
      this.template = lesson_intro_text
      this.screen.set('intro', screenIntro.replace("#!text\n",""))
    } else { // Default to TC's text intro screen
      this.template = lesson_intro_text
    }
  },

  serialize: function() {
    var userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens'),
      productGrade =  this.user.getCurriculumGrade(),
      lesson = this.lesson,
      isProblemKeyScreen = Boolean(this.screen.get('settings')?.problem_keys) && this.user.hasOption('problemkeysai'),
      forceOriginalProblemKeys = Boolean(this.screen.get('settings')?.force_original_problem_keys);

    if (isProblemKeyScreen && !forceOriginalProblemKeys) {
      lesson.set('name', `${lesson.get('name')} ${'lesson.name.using_ai'.t({ ai: getIntroAIHtml('white') })}`)
    }

    return {
      ads: AdView.canShowAds() && totalScreens > 0,
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens }),
      test: this.test,
      userLesson: this.userLesson.toJSON(),
      screen: this.screen.toJSON(),
      isProblemKeyScreen: isProblemKeyScreen && !forceOriginalProblemKeys,
      lesson: lesson.toJSON(),
      product: Registry.get('product'),
      showGuestBanner: !this.showSidebarNav && !Registry.get('loggedIn') && this.screen.screen_type !== 'keyboard-jump',
      noAvatar: find(FTWGLOBALS('skins'), {id: this.user.get('skin_id') || 1}).noAvatar,
      showMascot: lesson.get('content_type') !== 'adventure' || productGrade < 7,
      display: lesson.get('settings')?.display,
      slug: lesson.get('settings')?.slug,
      eduVideo: this.eduVideo,
      continueLabel: this.label ?? 'lesson.continue_text'
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    const screen = this.screen.toJSON(),
      lesson = this.lesson;

    // We no longer set a title on screens. Use the lesson name
    screen.title = lesson.get('name').stripHTML();

    // don't do dictation if it's a video
    this.dictation.setProperties(this.screen, null);
    if(!screen.hasVideo) {
      let keyboard = find(FTWGLOBALS('keyboards'), { keyboard_id: parseInt(this.user.getSetting('keyboard_id')) }) || {},
        keyboardType = this.getKeyboardType(keyboard.name),
        introEle = this.$('.multi-intro-' + keyboardType)

      // While there may not be a keyboard-specific multi-intro element, there may be a default multi-intro element
      if(!introEle.length) {
        introEle = this.$('.multi-intro');
      }

      // Our intros can support multiple keyboard layouts. Hide all intros except the intro for our current keyboard
      if(introEle.length) {
        this.$('[class*=\'multi-intro\']').fastHide();
        introEle.fastShow();
        screen.intro = introEle.html(); // Update intro text or else dictation will speak all intro variants
      }
      this.dictation.speakIntro(screen);
    } else {
      this.dictation.speakVideoIntro(screen);
    }

    if(this.progressView) {
      this.addChild('.js-progress', this.progressView, true);
    }

    if(lesson.get('settings')?.slug && window.innerWidth >= 1024) {
      Velocity(this.$('.book.is-opening'),
        {
          rotateZ: ['0deg','-3deg'],
          translateX: ['0%', '-20%'],
          translateY: ['0%', '8%'],
          opacity: [1,0]
        },
        {
          duration: 1000,
          easing: 'easeInOut',
          complete: () => {
            if(!this.bookIsOpen) {
              window.setTimeout(() => {
                this.animateAdventureOpen();
              }, 3000)
            }
          }
        }
      );
    }

    if(!screen.hasVideo) {
      this.listenTo(this.input, 'character', this.handleKeypress);
    }

    if(screen.hasVideo) {
      var setupEndedVideo = function(){
        try {
          var player = this.$('video');
          if(player.length) {
            player[0].addEventListener('ended', this.videoEnded.bind(this));
          } else {
            window.setTimeout(setupEndedVideo, 1000);
          }
        } catch(e) { }
      }.bind(this);
      window.setTimeout(setupEndedVideo, 1000);
    }

    return this;
  },

  animateAdventureOpen: function() {
    const bookCover = this.$('.book-page--cover'),
      bookLeft = this.$('.book-page--left'),
      pageWidth = window.innerWidth;


    if(pageWidth >= 1024 && !this.bookIsOpen){
      Velocity(bookCover, {rotateY: '90deg',zIndex: 20}, {duration: 500, easing: 'linear', complete: () => {
        this.bookIsOpen = true;
        Velocity(bookCover.css({zIndex: 20}), {rotateY: '180deg'}, {duration: 500, easing: 'linear'});
      }});
      Velocity(bookLeft, {rotateY: '90deg',zIndex: 20}, {duration: 500, easing: 'linear', complete: () => {
        Velocity(bookLeft.css({zIndex: 21}), {rotateY: '180deg'}, {duration: 500, easing: 'linear'});
      }});
    }
  },

  videoEnded: function() {
    this.$('.js-continue-button').removeClass('btn--b').addClass('btn--a').text('shared.continue'.t());

    // if there's a video and they have dictation, let them know after
    if(this.dictation && this.screen.get('hasVideo')) {
      this.dictation.speakVideoIntroEnding(this.screen.toJSON());
    }
  },

  continue: async function() {
    if (this.screen.get('settings')?.problem_keys && this.user.hasOption('problemkeysai') && !this.screen.get('settings')?.force_original_problem_keys) { // Problem Key Lesson Screen
      await this.generateContentAndUpdateSettings(this.selectedOption);
    }
    this.trigger('continue', 'intro', this.$('.js-continue-button'));
    return false;
  },

  handleKeypress: function(character){
    if(character.key === 'd') {
      this.dictation.stop();
    }

    if(character.keyCode === 32) {
      var player = this.$('video');

      if(player.length) {
        player.get(0).play();
        this.dictation.stop();
      }
    } else if(character.key === 'Enter' || character.key === '\n') {
      this.continue();
    }
  },

  /**
   * Used for showing/hiding introductions based on which keyboard the user has.
   * @param keyboardName
   * @returns {string}
   */
  getKeyboardType: function(keyboardName) {
    // Remove intro text for duplicated intros based on accent keys
    switch (keyboardName) {
    case 'Português (Brazil ABNT)':
      return 'brazil-abnt';
    case 'Português (Brazil ABNT2)':
      return 'brazil-abnt2';
    case 'Português (Brazilian Pro)':
      return 'brazilian-pro';
    case 'Português (Portugal)':
      return 'portugal';
    default:
      return '';
    }
  },

  hide: function() {
    this.stopListening(this.input);
    this.$('svg').remove();   // specifically remove any hands because it clashes with on screen hands on falling letters
    this.$el.fastHide();
  },

  generateContentAndUpdateSettings: async function() {
    try {
      // Show the spinner and hide the form
      this.$('.js-saving-sparkle-spinner').fastShow();
      this.$('.js-content-ai-form').fastHide();
      this.dictation.speakLoadingAIContent();

      const topics = this.model.topics
      const randomTopic = topics[Math.floor(Math.random() * topics.length)];
      this.model.set('selectedOption', randomTopic);

      await this.model.fetch(this.lesson.get('time_limit'));
      this.user.setSettings({ AIContent: this.model.get('AIContent') });
    } catch(err) {
      console.error(err);
    } finally {
      // Hide the spinner whether the operation was successful or not
      this.$('.js-saving-sparkle-spinner').fastHide();
    }
  },

  remove: function() {
    // Remove the window event listener
    if (this.handleKeypressBound) {
      window.removeEventListener('keypress', this.handleKeypressBound);
    }

    // Call the parent class's remove method
    Backbone.View.prototype.remove.apply(this, arguments);
  },

})
