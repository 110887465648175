import Backbone from 'backbone'
import { join_join_options } from '@templates/student'

export default Backbone.View.extend({

  template: join_join_options,

  section: {},

  events: {
    'click .js-create-account': function(e) { this.trigger('createAccount'); return false; }
  },

  serialize: function() {
    return {
      section: this.section,
      joinCode: this.joinCode
    };
  }

})
