import Registry from '@registry'
import Model from '../models/report_content_form'
import Modal from './modal'
import { isWindows, isMac, isChromeOS } from '@shared/helpers'
import { global_report_content_modal } from '@templates/student'

export default Modal.extend({
  titleText: 'Report Ad',

  okText: '',

  cancelText: '',

  successNotice: 'report_ad.success_text'.t(),

  template: global_report_content_modal,

  width: 'm',

  modelClass: Model,

  fileUpload: true,

  className: 'modal--report',

  formSelector: 'form',

  serialize: function() {
    var adData = window.freestar ? Object.keys(freestar.dfpSlotInfo || {}).map(function(slot) {
      var data = freestar.reportingAdInfo(slot) || {};
      delete data.ad;
      return data;
    }) : [];

    return {
      loggedIn: Registry.get('loggedIn'),
      osType: isWindows() ? 'win' : isMac() ? 'mac' : isChromeOS() ? 'chromebook' : 'win',
      email: this.user.get('email'),
      adData: JSON.stringify(adData || []),
      network: this.getNetwork()
    };
  },

  getNetwork: function() {
    return window.__adNetwork;
  },

  submitCallback: function() {
    var user = Registry.get('student');
    user.inc({ reportAd: 1 });

    // Restrict users to reporting only 2 ads per session
    if(user.get('reportAd') > 2) {
      this.cancel();
    } else {
      Modal.prototype.submitCallback.apply(this, arguments);
    }
  }
})
