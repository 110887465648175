import Backbone from 'backbone'
import $ from 'jquery'
import Notice from './notice'
import Registry from '@registry'

export default Backbone.View.extend({

  events: {
    'click .js-ok': 'clickOk',
    'click .js-action': 'clickAction',
    'click .js-close,.js-cancel': 'clickCancel'
  },

  title: '',
  text: '',
  ok: 'shared.ok_text'.t(),
  action: '',
  cancel: '',
  okCallback: null,
  closeButton: true,
  width: 's',
  header: true,
  prose: null,
  lastFocusedElement: null,
  focusableElements: null,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);
    this.show();
  },

  serialize: function() {
    return {
      closeButton: this.closeButton,
      titleText: this.title,
      bodyText: this.text,
      okText: this.okText || this.ok,
      actionText: this.action,
      cancelText: this.cancel,
      width: this.width,
      header: this.header,
      prose: this.prose
    };
  },

  captureKeys: function(e) {
    if(e.which === 27) {
      this.clickCancel(e);
      return false;
    }

    if(e.which === 13 && e.target === this.$('.js-ok:visible').get(0)) {
      this.$('.js-ok:visible').click();
      if (!this.$('.modal:visible')) {
        this.lastFocusedElement.focus();
      }
      return false;
    }
  },

  clickOk: function(e){
    if(this.submitting){
      return false;
    }

    if(this.okCallback){
      this.submitting = true;
      $(e.target).addClass('btn--loading');
      this.okCallback().done(function(){
        this.trigger('ok');
        return this.close();
      }.bind(this))
        .fail(function(data){
          if(data.responseJSON && data.responseJSON.error) {
            (new Notice({error: true, text: data.responseJSON.error})).show();
          } else {
            window.alert('There was an error: ' + JSON.stringify(data));
          }
        })
        .always(function(){
          $(e.target).removeClass('btn--loading');
          this.submitting = false;
        }.bind(this));
      return false;
    } else {
      this.trigger('ok');
      return this.close();
    }
  },

  clickAction: function(e){
    if(this.submitting){
      return false;
    }
  
    if(this.actionCallback){ // Assuming you have an action callback method
      this.submitting = true;
      $(e.target).addClass('btn--loading');
      
      // Call the action callback method
      const result = this.actionCallback();
  
      // Check if the result is a promise
      if (result instanceof Promise) {
        // If it's a promise, wait for it to resolve
        result.then(() => {
          // Trigger the 'action' event
          this.trigger('action');
          // Close the modal
          this.close();
          // Reset submitting flag and remove loading class
          $(e.target).removeClass('btn--loading');
          this.submitting = false;
        }).catch(error => {
          // Handle promise rejection
          if (error.responseJSON && error.responseJSON.error) {
            (new Notice({error: true, text: error.responseJSON.error})).show();
          } else {
            window.alert('There was an error: ' + JSON.stringify(error));
          }
          // Reset submitting flag and remove loading class
          $(e.target).removeClass('btn--loading');
          this.submitting = false;
        });
      } else {
        // If it's not a promise, treat it as synchronous
        // Trigger the 'action' event
        this.trigger('action');
        // Close the modal
        this.close();
        // Reset submitting flag and remove loading class
        $(e.target).removeClass('btn--loading');
        this.submitting = false;
      }
      
      return false;
    } else {
      this.trigger('action');
      return this.close();
    }
  },

  clickCancel: function(){
    if(this.submitting){
      return false;
    }

    this.trigger('cancel');
    return this.close();
  },

  close: function() {
    Registry.set('preventKeyboardInput', false);
    $(document).off('keydown.close-modal');
    const $modal = this.$('.modal');

    $modal.removeClass('is-open');

    $('body').removeClass('has-modal');
    this.$('.modal-overlay').remove();

    setTimeout(() => this.remove(), 1000); // icky, but we're using css animations and there's no way to when its removed

    $modal.off('keydown', this.trapTabKey.bind(this));
    this.lastFocusedElement.focus();

    return false;
  },

  /**
   * Show the modal
   */
  show: function() {
    this.lastFocusedElement = document.activeElement;
    
    Registry.set('preventKeyboardInput', true);
    $(document).on('keydown.close-modal', this.captureKeys.bind(this));
    $('body').append(this.el).addClass('has-modal');

    this.render();

    const $modal = this.$('.modal');
    $modal
      .addClass('is-open')
      .find('input[type=text]:eq(0),input[type=email]:eq(0), a, button:not(:hidden), input, textarea, select, details,[tabindex]:not([tabindex="-1"])')
      .focus();
    
    this.focusableElements = $modal.find('input[type=text]:eq(0),input[type=email]:eq(0), a, button:not(:hidden), input:not(.hidden), textarea, select, details,[tabindex]:not([tabindex="-1"])');
    $modal.on('keydown', this.trapTabKey.bind(this));

    return this;
  },

  trapTabKey: function(e) {
    const $activeModal = this.$('.modal.is-open');

    if (e.key === 'Tab' || e.target === this.$('.js-next').get(0)) {
      this.focusableElements = $activeModal.find('input[type=text]:eq(0),input[type=email]:eq(0), a, button:not(:hidden), input:not(.hidden), textarea, select, details,[tabindex]:not([tabindex="-1"])');
      const firstFocusableElement = this.focusableElements.first().get(0);
      const lastFocusableElement = this.focusableElements.last().get(0);
      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          e.preventDefault();
          lastFocusableElement.focus();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          e.preventDefault();
          firstFocusableElement.focus();
        }
      }
    }
  }
})
