import $ from 'jquery'
import Tooltip from 'tooltip.js'
import { each } from 'lodash-es'

/**
 * Note that any element with class 'tooltip-trigger' will show this
 */
export default {
  cachedTooltips: {},

  init: function() {
    // init can be run any number of times and will clean up all the cached tips
    each(this.cachedTooltips, (tip) => tip.dispose())
    this.cachedTooltips = {};

    // it's written this way so we don't have to create all of the tooltips.. we lazy-create them as they hover
    $('body').off('mouseover.tooltip focus.tooltip blur.tooltip', '.has-tooltip,[data-tooltip]')
    .on('mouseover.tooltip focus.tooltip', '.has-tooltip,[data-tooltip]', (e) => this.show(e.currentTarget));
  },

  show: function(target, options) {
    options = options || {};

    var id = target.id,
      text = options.text,
      skipAnimation = options.skipAnimation || $(target).attr('data-skipAnimation');

    if(!id) {
      id = 'id' + Math.random();
      target.id = id;
    }

    var template;
    if (options.start) {
      template = '<div class="tooltip tooltip--start" role="tooltip"><div class="tooltip-arrow"></div><h3 class="tooltip-inner mbf"></h3></div>';
    } else if($(target).children('.tooltip-template').length > 0) {
      template = $(target).children('.tooltip-template').html();
    } else if(!skipAnimation) {
      template = `<div class="tooltip-wrapper ${options.blur ? 'tooltip--blur' : ''}" role="tooltip"><div class="tooltip is-visible"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div></div>`;
    } else {
      template = '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';
    }

    if(!this.cachedTooltips[id]) {
      if(!text) {
        text = $(target).data('tooltip');
      }
      if(!text) {
        text = $(target).children('.tooltip-html').html();
      }
      var tip = new Tooltip(target, {
        container: 'body',
        html: true,
        offset: options.offset || '0, 15',
        placement: (options.placement) ? options.placement : 'top',
        preventOverflow: { enabled: true },
        template: template,
        title: text,
        trigger: (options.trigger) ? options.trigger : 'hover focus',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              boundariesElement: 'viewport'
            }
          }
        }
      });
      
      window.setTimeout(() => {
        $(".tooltip--blur[aria-hidden=false]").addClass("is-active");
      }, 250)

      if(options.force) {
        tip.show();
      }
      this.cachedTooltips[id] = tip;
      return tip;
    }
  },

  /**
   * Force remove a tooltip
   * @param Tooltip
   */
  hide: function(tip) {
    tip?.dispose();
  },

  hideAll: function() {
    each(this.cachedTooltips, (tip) => {
      if (tip && tip?.options?.trigger !== 'manual') {
        try {
          tip?.hide()
        } catch(e) {}
      }
    });
  }
};
