import Modal from '../../global/views/modal'
import Model from '@shared/language_form'
import Registry from '@registry'
import { lessons_change_curriculum_modal } from '@templates/student'

export default Modal.extend({

  template: lessons_change_curriculum_modal,

  titleText: 'lesson.change_curriculum_title'.t(),
  okText: 'lesson.change_curriculum_title'.t(),

  formSelector: 'form',

  modelClass: Model,

  firstSet: false,

  baseModalOptions: {
    width: 's',
  },

  events: {
    'click .js-curriculum': 'switchCurriculum'
  },

  initialize: function(options) {


    Modal.prototype.initialize.apply(this, arguments);
  },

  serialize: function() {
    return {
      product: this.product
    }
  },

  /**
   * Override submitting so we don't close the modal.
   * @param data
   * @returns {boolean}
   */
  submitCallback: function(data) {
    const user = Registry.get('student')

    user.setSettings(data)
    window.location.reload()
  },

  cancel: function(e) {
    window.location.href = __url('/student/lessons')
  },


})
