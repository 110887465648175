import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import GoalView from './goal'
import Velocity from 'velocity-animate'
import CelebrationEffect from '@shared/celebration_effect'
import { global_goal_achieved } from '@templates/student'
import { extend } from 'lodash-es';

export default Backbone.View.extend({

  template: global_goal_achieved,

  events: {
    'click .js-submit': 'hide',
    'click .js-add-5': 'addFiveMinutes'
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.goalView = new GoalView({
      type: 'display',
      totalSeconds: this.totalSeconds,
      recentSeconds: this.recentSeconds,
      goalSeconds: this.goalSeconds
    });
    this.listenTo(this.goalView, 'goal_achieved', (positionX, positionY) => {
      CelebrationEffect.init(
        $('.js-goal-celebration-effect')[0], {
          screenHeight: $(document).height(),
          screenWidth: $(document).width(),
          colors: ["#F8CF60","#F3761C"]
        })
      CelebrationEffect.explodeSingle({ type: 'confetti', x: positionX, y: positionY, count: 40, angle: 90, spread: 270, speed: 35 });
    })
    this.render()

    this.$('.js-goal').append(this.goalView.render().el)

    // Need to return a promise so we can tell the instantiator when we close this view
    return extend(this, $.Deferred());
  },

  serialize: function() {

    const twinkles = [
      ['14','75','0.52'],
      ['41','91','0.77'],
      ['17','33','0.73'],
      ['12','30','0.78'],
      ['58','16','0.69'],
      ['44','7','0.51'],
      ['36','72','0.78'],
      ['2','19','0.39'],
      ['11','9','0.47'],
      ['1','71','0.81'],
      ['26','29','0.20'],
      ['45','31','0.61'],
      ['50','73','0.55'],
      ['16','57','0.64'],
      ['54','62','0.42'],
      ['23','85','0.87'],
      ['53','33','0.56'],
      ['20','40','0.97'],
      ['39','55','0.96'],
    ]

    return {
      totalSeconds: this.totalSeconds,
      twinkles: twinkles
    }
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);
    $('body').append(this.el);

    return this;
  },


  show: function() {
    Registry.set('preventKeyboardInput', true);

    this.achievedAnimation()

    window.setTimeout(() => {
      $(document).on('keydown.goal_achieved', (e) => {
        // esc or enter
        if(e.which === 13 || e.which === 27) {
          this.hide();
        }
      });
    }, 500)

    return this
  },

  hide: function() {
    Registry.set('preventKeyboardInput', false);
    $(document).off('keydown.goal_achieved');

    this.$('.js-goal-celebration-effect').fadeOut();
    this.$el.fadeOut();

    this.resolve()
  },

  achievedAnimation: function() {
    Velocity(
      this.$('.js-overlay'),
      {opacity: [1,0]},
      {
        display: "block",
        duration: 250,
        complete: () => {
          Velocity(this.$('.js-modal'), 'fadeIn', {display: 'flex', duration: 500, queue: false})
          Velocity(this.$('.js-twinkle'), 'fadeIn', {duration: 2000});
        }
      }
    )
  },

  addFiveMinutes: function() {
    const user = Registry.get('student'),
      newGoal = Number(user.getSetting('daily_goal')) + 5 || 5

    user.changeDailyGoal(newGoal, false);
    this.hide();
  }

})
