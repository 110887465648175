import Backbone from 'backbone'
import MainView from './views/list'
import LayoutView from '../global/views/layout'

export default Backbone.View.extend({

  layout: LayoutView,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.views.mainView = new MainView();

    this.addChild(this.el, this.views.mainView, true);
    this.render();
  }
})
