import Registry from '@registry'
import GrowlAlert from '../views/growl_alert'
import { takeRight } from 'lodash-es'
import { global_growl_alert } from '@templates/student';

/**
 * The global popup when achievements are achieved
 */
export default GrowlAlert.extend({

  template: global_growl_alert,

  initialize: function() {
    this.userAchievements = Registry.get('userAchievements');
    this.achievements = Registry.get('achievements');
    this.groups = Registry.get('achievementGroups');

    GrowlAlert.prototype.initialize.apply(this, arguments);
  },

  show: function(ids) {
    Registry.set('preventKeyboardInput', true);

    var achs = [];
    ids.forEach(function(id){
      if(this.achievements.get(id)) {
        achs.push(this.achievements.get(id));
      }
    }.bind(this));

    if (!achs.length || achs.length > 5) {
      return;
    }

    takeRight(achs, 5).forEach(function(ach){
      this.$('.js-growl-content').append(this.contentTemplate({
        growlType: 'achievement',
        group: this.groups.get(ach.get('achievement_group_id')),
        ach: ach
      }));

      var badge = ach.getBadgeView();
      this.$('.js-ach-image-' + ach.id).append(badge.el);

    }.bind(this));

    var i = takeRight(achs, 5).length - 1,
      animateGrowls = function() {
        this.$('.js-growlWrap[data-id=' + achs[i].id + ']').addClass('animate--growl growl--stars');
        this.$('.js-growlWrap[data-id=' + achs[i].id + '] .growl-achievementInnerWrap').addClass('growl--shine');
        this.$('.js-growlWrap[data-id=' + achs[i].id + '] .growl-achievement').addClass('animate--growl_s');
        i--;
        if(i >= 0) { window.setTimeout(() => animateGrowls(), 100); }
      }.bind(this);
    animateGrowls();

    GrowlAlert.prototype.show.apply(this, arguments);
  }
})
