import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import Tooltip from '../../global/views/tooltip'
import CapsLockWarning from './caps_lock_warning'
import Analytics from '@shared/analytics'
import KeyboardView from '../../global/views/keyboard'
import { intersection, find } from 'lodash-es'
import { isLowPerformanceBrowser, playSound } from '@shared/helpers'
import ToastView from '../../global/views/toast'

/**
 * This class is an abstract base class
 */
export default Backbone.View.extend({

  /**
   * Overwrite these
   */

  lettersCache: null,
  activeLetter: null,
  syntaxColors: [],

  lowPerformance: false,

  hidden: false,

  /**
   * @param options
   */
  initialize: function(options) {
    Backbone.View.prototype.initialize.apply(this, arguments);

    if(isLowPerformanceBrowser()) {
      this.lowPerformance = true;
    }

    // preload sounds
    if(this.user.hasOption('allowsounds')) {
      try {
        var star1 = new Audio('/dist/student/extras/sounds/star1.mp3'), // just cache these
          star2 = new Audio('/dist/student/extras/sounds/star2.mp3'),
          star3 = new Audio('/dist/student/extras/sounds/star3.mp3');
      } catch(e) {}

      var sound, i;
      this.keySoundIndex = 0;
      this.keySounds = [];
      try {
        for(i=0; i<4; i++) {  // repeating because you can't play the same sound twice
          sound = new Audio('/dist/student/extras/sounds/key.mp3');
          sound.volume = 0.3;
          this.keySounds.push(sound);
        }
      }catch(e){}
      this.errorSoundIndex = 0;
      this.errorSounds = [];
      try {
        for(i=0; i<4; i++) {  // repeating because you can't play the same sound twice
          sound = new Audio('/dist/student/extras/sounds/error.mp3');
          sound.volume = 0.5;
          this.errorSounds.push(sound);
        }
      }catch(e){}
    }

    const isFallingType = this.screenType === 'falling'
    var showKeyboard = this.keyboard &&
      ((this.unit && this.unit.get('type') !== 'test' &&  this.unit.get('type') !== 'custom_test') || (!this.unit && this.problemKeysLesson)) &&
      (this.user.hasOption('showkeyboard') || isFallingType);

    // check if the lesson disabled the keyboard
    if (['none', ''].includes(this.lesson.get('keyboard')) && !isFallingType) {
      showKeyboard = false
    }

    this.capslockView = new CapsLockWarning({
      screen: this.screen,
      keyboardVisible: showKeyboard && (this.user.getSetting('show_keyboard') || this.screenType === 'falling')
    });
    this.input.on('capslock', this.capslockView.toggle.bind(this.capslockView));

    if (showKeyboard) {
      this.keyboardView = new KeyboardView({
        // if they can't show they keyboard, then don't bother downloading hands.  but we instantiate the view anyways cause it has some things dictation uses
        showHands: this.user.hasOption('showkeyboard'),
        screen: this.screen,
        model: options.keyboard
      });

      this.listenTo(this.user, 'settings_changed', (data) => {
        // Reload the page if certain settings were changed
        if(intersection(data, ['show_keyboard', 'show_hands', 'keyboard_id', 'animated_hands']).length > 0) {
          window.location.reload()
        }
      })
    }

    this.dictation.setProperties(this.screen, this.keyboardView);

    // Every 28 minutes extend the session (if the user is typing). We do this so users
    // are not automatically logged out after 29:30 minutes while typing long lesson screens
    window.setInterval(function() {
      if(Registry.get('loggedIn') && this.userLesson.get('_state') !== 'complete' && (this.screen.get('screen_type') === 'written-prompt' || Date.now() - this.lastKeyTime < 1000 * 60 * 2)) {
        Analytics.setActivityTimeout();
      }
    }.bind(this), 1000 * 60 * 28); // 28 minutes
  },

  /**
   * This method should be overwritten
   * @returns {*}
   */
  render: function() {
    // This purposefully put into render() because we don't want it handled until this view is shown
    this.listenTo(this.input, 'character', this.handleInput);
    this.screen.on('change:typed', this.handleKeyTyped, this);
    this.screen.on('change:dead', this.handleDeadKeyTyped, this);
    this.screen.on('change:errors', this.handleErrorTyped, this);
    this.screen.on('change:keyStamp', this.handleKeyPressed, this);
    this.screen.once('complete', this.handleComplete.bind(this));

    this.$('.structure-content').append(this.capslockView.render().el);
    if (this.keyboardView) {
      this.$('.js-keyboard-holder').append(this.keyboardView.render().el);
      if(this.screenType !== 'inline_game') { // Inline games will post event when ready to highlight first key
        this.keyboardView.highlightKey(this.screen.charAt(0));
      }
    }

    this.renderChildren();

    if (typeof bugsnag === 'function' && typeof window.bugsnagClient === 'object') {
      window.bugsnagClient.leaveBreadcrumb('Typing screen rendered', {
        type: this.screenType
      });
    }

    this.dictation.speakScreen({begin: true, nextKey: this.screen.charAt(0)});

    return this;
  },

  handleComplete: function() {
    this.screen.off('complete', this.handleComplete.bind(this));
    this.timer.stop();

    var std = -1;
    if(this.keyTimes.length > 20) {
      std = Math.floor(this.std(this.keyTimes));
    }

    this.screen.set({
      seconds: this.timer.get('seconds'),
      std: std
    });

    this.trigger('complete');
  },

  startToolTip: null,
  startCursorAnimation: function(opts) {
    var defaultOpts = {force: true, text: 'lesson.start_typing_tooltip'.t()};
    if(opts) {
      defaultOpts = { ...defaultOpts, ...opts };
    }
    this.startCursorTimeout = window.setTimeout(() => {
      var ele = this.$('.letter.is-active');
      if(ele.length) {
        this.startToolTip = Tooltip.show(ele[0], defaultOpts);
      }

    }, (this.userLesson.get('progress') === 0 && this.screenType !== 'coding') ? 500 : 3000);
  },

  stopCursorAnimation: function() {
    window.clearTimeout(this.startCursorTimeout);
    if(this.startToolTip) {
      this.startToolTip.hide();
    }
  },

  keyTimes: [],
  lastKeyTime: 0,
  idleTimeout: null,
  idleToast: null,
  handleInput: function(input) {
    // INLINE ONLY - Don't let the user type content before the inline game loads (or after it is done)
    if(this.screenType === 'inline_game' && (!this.allowInput || input.key === ' ' || !this.screen.charAt(this.screen.get('typed')))) {
      return;
    }

    if(input.metaKey) {
      return; // these are command and windows keys
    }

    window.clearTimeout(this.idleTimeout);
    this.idleTimeout = window.setTimeout(() => this.autoPauseForIdle(), 10*1000); // if they are idle for 10 seconds, pause the timer

    if(this.pausedBecauseIdle) {
      this.pausedBecauseIdle = false;
      if(this.timer.isPaused()) {
        this.timer.unpause();
      }
      if (this.idleToast) {
        this.idleToast.close();
        this.idleToast = null;
      }
    }

    if(this.timer.isPaused()) {
      return;
    }

    if(this.lastKeyTime) {
      var now = Date.now();
      this.keyTimes.push(Date.now() - this.lastKeyTime);
      this.lastKeyTime = now;
    }

    // Checking for Keyboard discrepancy only works when there is a keyboardView
    if(this.keyboardView) {
      this.detectKeyboardDiscrepancy(this.screen.charAt(this.screen.get('typed')), input);
    }

    if (this.screen.get('typed') === 0 && !input.special) {
      this.timer.start();
      this.lastKeyTime = Date.now();
      this.stopCursorAnimation();
      if(!this.keyboardOverlay) { // this is only for falling letters
        this.keyboardOverlay = this.$('.keyboard-overlay');
        this.keyboardOverlay.remove();
      }
    }

    if (input.key === 'NEXT') {
      this.timer.stop().set({seconds: 10});
      this.screen.quickComplete();
    } else {
      var inputData;
      inputData = this.screen.handleInput(input, this.game);
      if(this.screenType !== 'inline_game') {
        if(inputData && !inputData.isError) {
          this.playSounds('key');
        } else if (inputData && inputData.isError) {
          this.playSounds('error');
        }
      }

      if(inputData && inputData.nextKey) { // No reason to speak dictation here when completing the screen
        this.dictation.speakScreen(inputData);
      }
    }
  },

  detectKeyboardDiscrepancy: function(nextKey, input, deadKeyResult) {
    var requiresAlt = this.keyboardView.getKeyRequiresAlt(nextKey),
      requiresShift = this.keyboardView.getKeyRequiresShift(nextKey),
      requiresDead = this.keyboardView.getKeyRequiresDead(nextKey),
      keyboardRow = this.keyboardView.getRowFor(nextKey),
      keyboardColumn = (keyboardRow !== undefined) ? this.keyboardView.getKeyboardColumnFor(nextKey) - (keyboardRow === 0 ? 0 : 1) : undefined,
      keyLocation;

    if(keyboardColumn !== undefined && keyboardRow !== undefined) {
      keyLocation = this.keyboard.get('structure')[keyboardRow][keyboardColumn];
    }

    if(nextKey === ' ') { // space key can capture and return dead keys previously typed, but isn't supposed to really type anything except space
      return;
    }

    if(nextKey === '⏎'){
      nextKey = '\n';
    }

    // Check to see if the key pressed is a valid key on the user's keyboard
    if(!keyLocation) {
      if(requiresDead && input.key !== 'Dead') {
        // console.log('running recursive check')
        return this.detectKeyboardDiscrepancy(requiresDead.deadKey, input, nextKey);
      }
      // console.warn('Missing hardware key window.location', {inputKey: input.key, nextKey: nextKey, inputHardware: input.hardware, requiresDead: requiresDead, requiresAlt: requiresAlt, requiresShift: requiresShift, keyboardRow: keyboardRow, keyboardColumn: keyboardColumn});
    }
    // console.log({input, nextKey, keyLocation, requiresAlt, requiresShift, requiresDead, deadKeyResult});
    if(
      keyLocation &&  // if we can't find a key window.location it could be all kinds of things so ignore
      !!requiresAlt === !!input.altKey && // stops false positives when a physical key contains the needed character, but they didn't press alt
      !!requiresShift === !!input.shiftKey &&  // same as alt above
      !!requiresDead === !!this.keyboardView.getKeyRequiresDead(input.key) && // catches an odd case of Alt+U+U makes ü on US. Should only catch an error if the dead status matches between what was returned and what was expected
      !input.hasCapsLock &&
      input.key !== (deadKeyResult || nextKey) &&  // what was typed does not equal what should have been typed, AND
      input.hardware === keyLocation.hardware // the keyevent.hardware window.location is the same as the hardware window.location of what is supposed to be typed,
    ) {
      console.log('Possible Invalid Keyboard Format', {input, nextKey, keyLocation, requiresAlt, requiresShift, requiresDead, deadKeyResult});
      this.user.set({ _invalidKeyboard: true });
    } else if((input.key === '¨' && nextKey === '"') || (input.key === '´' && nextKey === '\'') || (input.key === 'ñ' && nextKey === ';')) {
      this.user.set({ _invalidKeyboard: true });
    }
  },

  pausedBecauseIdle: false,
  autoPauseForIdle: function() {  // this will get called if no keys are hit for a period of time. this stops a lesson from taking inifinte seconds
    if(!this.timer.isPaused()) {
      this.timer.pause();
      this.pausedBecauseIdle = true;

      this.idleToast = new ToastView({
        text: 'timer.paused'.t(),
        imgSrc: '/dist/student/images/timer-hand.svg'
      });
    }
  },

  handleKeyTyped: function(model){
    if (model.changed.lastKeyError) {
      this.lettersTyped.trackError(model.get('correctLetter'));
    } else {
      this.lettersTyped.trackCorrect(model.get('letterTyped'));
    }

    if(this.keyboardView) {
      this.keyboardView.highlightKey(model.get('nextKey'));
    }

    this.setPosition(model.changed.typed, model.get('lastKeyError'));
  },

  handleDeadKeyTyped: function(model, value) {
    if(!value) { return; }

    if(this.keyboardView) {
      this.keyboardView.highlightKey('dead');
    }
  },

  playSounds: function(sound) {
    try {
      if(sound === 'error' && this.user.getSetting('error_sounds')) {
        if(this.errorSounds && this.errorSounds.length > 0) {
          this.errorSoundIndex++;
          if(this.errorSoundIndex >= this.errorSounds.length) {
            this.errorSoundIndex = 0;
          }
          playSound(this.errorSounds[this.errorSoundIndex]);
        }
      }
      if(sound === 'key' && this.user.getSetting('typing_sounds')) {
        if(this.keySounds && this.keySounds.length > 0) {
          this.keySoundIndex++;
          if(this.keySoundIndex >= this.keySounds.length) {
            this.keySoundIndex = 0;
          }
          playSound(this.keySounds[this.keySoundIndex]);
        }
      }
    }catch(e){}
  },

  /**
   * This does nothing by default but could be overwritten
   * @param model
   */
  handleErrorTyped: function(model) {
    if(this.activeLetter) {
      var index = model.get('letterCacheIndex'),
        activeLetter = this.$(this.activeLetter),
        wrongLetter = (index-1 >= 0) ? this.lettersCache[index-1] : null;

      if(wrongLetter) { // If the error was the last letter in the word
        $(wrongLetter).attr('data-wrong', model.get('letterTyped')); // Set the proper attr
      }
    }
  },

  handleKeyPressed: function(model) {
    var index = model.get('letterCacheIndex');
    if(model.get('nextKeyError') && model.get('isError')) {
      this.$(this.activeLetter).attr('data-wrong', model.get('letterTyped'));
      this.setPosition(index, true, true, true);
    }
    if(model.get('isError') && this.keyboardView) {
      this.keyboardView.highlightErrorKey(model.get('letterTyped'));
    }
  },

  /**
   * cacheAnimations performs two actions:
   * 1. Grab all ".letter" elements within ".js-screen-content" and cache them
   * 2. Reset the number of lines on the screen in case the window size changes
   */
  cacheAnimations: function() {
    if(this.hidden) {
      return false;
    }

    let content = this.$('.js-screen-content'), // element for visible area of content (mask)
      toCache = this.$('.js-screen-content .letter'), // grab all letters on the screen
      visibleHeight = content.height(),
      newCache = [], // used to store only the active letters on the screen (coding screen can have disabled letters)
      lastOffset = 0,
      line = 0,
      offset = 0,
      totalLines = 0,
      contentClasses = content.attr('class') || '',
      lineClass = find(contentClasses.split(' '), function(cls) { return cls.match(new RegExp('screen' + this.contentClassName + '--\\d')); }.bind(this));

    this.topOffset = 0;
    this.lastOffset = 0;

    if(toCache.length === 0) {
      return;
    }

    content.removeClass(lineClass).addClass('screen' + this.contentClassName + '--'+this.screen.get('lines'));

    // cache all default classNames to speed up class changes
    toCache.each((index, ele) => {
      if(!$(ele).hasClass('screen-letter-disabled')) { // Only add the element to the cache if it is NOT disabled
        if(this.syntaxColors[newCache.length]) { // Coding screen uses syntax highlighting
          // Set syntax highlighting BEFORE storing the defaultClasses or else the syntax highlighting will be removed
          $(ele).addClass('screenCode-letter--' + this.syntaxColors[newCache.length]);
        }
        newCache.push(ele);
      }

      ele.defaultClasses = ele.defaultClasses || ele.className;
      offset = $(ele).offset().top;

      if(!this.topOffset) {
        this.topOffset = offset;
        this.lastOffset = offset;
        lastOffset = offset;
      }

      if(lastOffset !== offset) {
        line++;
      }
      lastOffset = offset;

      ele.animationOffset = offset;
      ele.animationLine = line;
      totalLines = line
    })

    this.rowHeight = this.$('.screen' + this.contentClassName + '-word:first').outerHeight(true)
    this.screen.set({
      lines: totalLines,
      visibleLines: Math.round(visibleHeight / this.rowHeight)
    });

    this.lettersCache = newCache;

    if(this.lettersCache.length > 0) {
      Registry.set('preventKeyboardInput', false);
    }
  },

  /**
   * Can overwrite this.  Specific behavior for moving the cursor about
   * @param index
   * @param prevWasError
   * @param currentIsError
   */
  setPosition: function(index, prevWasError, currentIsError, nextIsError) {
    if(!this.lettersCache) {  // random key event before the letters cache
      return;
    }

    var prevEle = (index-1 >= 0) ? this.lettersCache[index-1] : null,
      currentEle = this.lettersCache[index] || null,
      nextEle = (this.lettersCache.length > index+1) ? this.lettersCache[index+1] : null,
      prevIndex = this.screen.get('letterCacheIndex') || 0,
      direction = index - prevIndex;

    // Set the current cursor to active, and remove typed or error, which helps if they backspaced
    if(currentEle) {
      if (this.screenType === 'block') {
        if(currentIsError) {
          currentEle.classList.add('is-active-wrong');
          window.setTimeout(() => currentEle.classList.remove('is-active-wrong'), 250);
        } else {
          currentEle.classList.add('is-active');
        }
      } else {
        if(nextIsError) {
          currentEle.classList.add('is-active-wrong');
        } else {
          currentEle.classList.add((currentIsError) ? 'is-wrong' : 'is-active');
        }
      }
      this.activeLetter = currentEle;
    }

    // If they backspace, this removes the active cursor from the letter in front of the new position
    if(nextEle) {
      if(this.screenType !== 'block') { currentEle.className = currentEle.defaultClasses + ' ' + 'is-active'; }
      nextEle.className = nextEle.defaultClasses;
    }

    if(prevEle && index > 0 && direction !== -1) {
      if(nextIsError) {
        if(this.screenType !== 'block') {
          if(currentEle.className.indexOf('is-active-wrong-pseudo') !== -1) {
            currentEle.className = currentEle.className.replace('is-active-wrong-pseudo', '');
          }
          // Adding a window.setTimeout here since you can't simultaneously add and remove a class to trigger a different style
          window.setTimeout(() => {
            currentEle.className = currentEle.className + 'is-active-wrong is-active-wrong-pseudo animate--shake animate--d-025';
            currentEle.className = currentEle.className.replace(' is-active', ' ');
          }, 0);
          window.setTimeout(() => {
            currentEle.className = currentEle.className.replace('is-active-wrong', ' ').replace('animate--shake animate--d-025', 'is-active');
          }, 250);
        }
      } else if (prevWasError) {
        prevEle.className = prevEle.defaultClasses + ' is-wrong animate animate--shake animate--d-025';
        // And as an error if necessary
      } else {
        prevEle.className = prevEle.defaultClasses + ' ' + 'is-right';
      }
    }
    this.screen.set({letterCacheIndex: index});
  },

  hide: function(){
    this.hidden = true;
    this.stopListening(this.input);
  },

  /**
   * Standard deviation function to see if they're cheating
   * @param values
   * @returns {number}
   */
  std: function (values){
    values.sort(function compare(a, b) { return a-b; });
    values.pop();
    values.pop();
    values.pop();
    values.pop();
    values.shift();
    values.shift();
    values.shift();
    values.shift();

    var average = function average(data){
      var sum = data.reduce(function(sum, value){
        return sum + value;
      }, 0);

      return sum / data.length;
    };

    var avg = average(values);

    var squareDiffs = values.map(function(value){
      var diff = value - avg;
      return diff * diff;
    });

    var avgSquareDiff = average(squareDiffs);

    return Math.sqrt(avgSquareDiff);
  }

})
