import Backbone from 'backbone'
import $ from 'jquery'
import Tooltip from './tooltip'
import { global_layout_basic } from '@templates/student'

export default Backbone.View.extend({

  template: global_layout_basic,

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    $('#js-page-loader').remove();
    Tooltip.init();

    return this;
  },

  serialize: function() {
    return {
      showButtons: this.showButtons !== false,
      hideLoginButtons: this.hideLoginButtons,
      joinCode: this.joinCode
    };
  }

})
