import Backbone from 'backbone'

/**
 * Timer object keeps time in seconds since start().
 * Fires off change events for 'seconds' property
 */
export default Backbone.Model.extend({

  default: {
    startTime: 0,
    seconds: 0
  },

  initialize: function() {
  },

  /**
   * @param {Date} startTime
   */
  start: function(startTime) {
    if(this.get('startTime')) return this;   // can only start once

    this.set({
      startTime: this.getTime(startTime),
      pauseTime: 0
    });

    this.interval = setInterval(this._updateSeconds.bind(this), 100);
    return this;
  },

  /**
   * Pauses the timer
   */
  pause: function() {
    if(!this.get('startTime')) return false;

    this.set({
      pauseTime: this.getTime()
    });
    return this;
  },

  /**
   * Resume the timer
   */
  unpause: function() {
    if(!this.get('startTime')) return false;

    this.inc({
      startTime:  this.getTime() - this.get('pauseTime')
    });
    this.set({
      pauseTime: 0
    });
    return this;
  },

  isPaused: function() {
    return !!this.get('pauseTime');
  },

  hasStarted: function() {
    return this.get('startTime')
  },

  /**
   * Kill the timer
   */
  stop: function(){
    clearInterval(this.interval);
    this.set({
      pauseTime: this.getTime()
    })
    return this;
  },

  /**
   * Get seconds since epoch
   * @param {Date} [stamp] optional
   * @return {number} seconds
   */
  getTime: function(stamp) {
    stamp = stamp || new Date();
    return stamp.getTime()/1000;
  },

  _updateSeconds: function() {
    var time = this.get('pauseTime') || this.getTime();

    this.set({
      seconds: time - this.get('startTime')
    });
  }
})

