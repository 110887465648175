import Backbone from 'backbone'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import ModalView from '../../global/views/modal'
import { lesson_transcript_modal, lesson_intro_qa } from '@templates/student'

export default Backbone.View.extend({

  events: {
    'click .js-continue-button': 'continue',
    'click .js-back-button': 'back',
    'click .js-read-transcript': 'readTranscript'
  },

  template: lesson_intro_qa,

  introStep: 0,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    if(this.screen.get('intro').startsWith('<intro_section>')) {
      this.multiStepIntro = this.screen.get('intro').
        split(/(<intro_section>[^]*?<\/intro_section>\s*)/).
        filter((row) => row).
        map((row) => row.replaceAll(/videos.edutyping/g, 'videos.typing').replaceAll(/resources.edutyping.com/g, 'resources.typing.com/edutyping').trim())
    } else {
      const introArray = this.screen.get('intro').match(/(<div class="embed">[^]*?<\/div>)?([^]*)?/)
      this.videoHTML = (introArray[1] ? introArray[1].trim() : '')
      this.transcriptHTML = (introArray[2] ? introArray[2].trim() : '')

      if(this.videoHTML) {
        this.screen.set({hasVideo: true});
      }
    }
  },

  serialize: function() {
    var userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens');

    return {
      ads: AdView.canShowAds() && totalScreens > 0,
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens, isSubScreen: this.isSubScreen }),
      multiStepIntro: this.multiStepIntro,
      videoHTML: this.videoHTML,
      transcriptHTML: this.transcriptHTML,
      showGuestBanner: !this.showSidebarNav && !Registry.get('loggedIn') && this.screen.screen_type !== 'keyboard-jump',
      lessonContentType: this.lesson.get('content_type')
    };
  },

  render: function() {
    // don't do dictation if it's a video
    this.dictation.setProperties(this.screen, null);
    if(!this.screen.get('hasVideo')) {
      this.dictation.speakQAIntro({ ...this.screen.toJSON(), ...{ title : this.lesson.get('name') }})
    } else {
      this.dictation.speakVideoIntro({ ...this.screen.toJSON(), ...{ title : this.lesson.get('name') }});
    }

    Backbone.View.prototype.render.apply(this, arguments);

    this.listenTo(this.input, 'character', this.handleKeypress);

    // if(this.screen.get('hasVideo')) {
      var setupEndedVideo = function(){
        try {
          var player = this.$('video');
          if(player.length) {
            player[0].addEventListener('ended', this.videoEnded.bind(this));
          } else {
            window.setTimeout(setupEndedVideo, 1000);
          }
        }catch(e){}
      }.bind(this);
      window.setTimeout(setupEndedVideo, 1000);
    // }

    return this;
  },

  videoEnded: function() {
    // if there's a video and they have dictation, let them know after
    if(this.dictation && this.screen.get('hasVideo')) {
      this.dictation.speakVideoIntroEnding(this.screen.toJSON());
    }
  },

  readTranscript: function() {
    new ModalView({
      template: lesson_transcript_modal,
      titleText: 'lesson.transcript_for_text'.t() + ' ' + this.lesson.get('name'),
      okText: '',
      cancelText: 'shared.close'.t(),
      width: 'm',
      headerType: 'qa',
      model: new Backbone.Model({ text: this.transcriptHTML })
    }).open();
  },

  continue: function() {
    if(!this.multiStepIntro || this.introStep === this.multiStepIntro.length - 1) {
      this.trigger('continue', 'intro', this.$('.js-continue-button'));
    } else {
      this.setIntroStep(this.introStep + 1);
    }

    return false;
  },

  back: function() {
    if(this.introStep !== 0) {
      this.setIntroStep(this.introStep - 1);
    }

    return false;
  },

  handleKeypress: function(character) {
    if(character.key == 'd') {
      this.dictation.stop();
    }

    if(character.keyCode === 32) {
      var player = this.$('video');

      if(player.length) {
        player.get(0).play();
        this.dictation.stop();
      }
    } else if(character.key == '\n') {
      this.continue();
    }
  },

  hide: function() {
    this.stopListening(this.input);
    this.$el.fastHide();
  },

  setIntroStep: function(step) {
    window.scrollTo(0,0);
    this.$('.js-intro-section').fastHide();
    this.$('.js-intro-section[data-id=' + step + ']').fastShow();

    this.introStep = step;

    if(this.introStep === this.multiStepIntro.length - 1) {
      this.$('.js-continue-button').html('lesson.begin_quiz_text'.t());
    } else {
      this.$('.js-continue-button').html('shared.continue'.t());
    }
    if(this.introStep === 0) {
      this.$('.js-back-button').fastHide();
    } else {
      this.$('.js-back-button').fastShow();
    }

    var player = this.$('video');

    if(player.length) {
      player.get(0).pause();
    }

    this.speakIntro();
  },

  speakIntro: function() {
    if(this.multiStepIntro[this.introStep] && this.multiStepIntro[this.introStep].indexOf('<video') !== -1) {
      this.dictation.speakVideoIntro(Object.assign(this.screen.toJSON(), { title: this.lesson.get('name'), intro: this.multiStepIntro[this.introStep] }));
    } else if(this.lesson.get('content_type') === 'qa') {
      this.dictation.speakQAIntro(Object.assign(this.screen.toJSON(), { title: this.lesson.get('name'), intro: this.multiStepIntro[this.introStep] }));
    } else {
      this.dictation.speakIntro(this.screen.toJSON());
    }
  }

})
