import Backbone from 'backbone'
import ScreenModel from '../../global/models/lesson_screen'
import { shuffle, flatten, difference, filter, times } from 'lodash-es'

export const generatePKWordList = (keys, words, lettersTyped = [], maxWordLength = 100, autopopulate = true) => {
  const defaultLetters = ['z','p','q','m'],
    originalWords = words

  // Populate at least 4 problem keys and lettersTyped so there are always words to type
  if(autopopulate && keys.length < 4) {
    times(4 - keys.length, (index) => keys.push(defaultLetters[index]))
  }
  if(autopopulate && lettersTyped.length < 4) {
    times(4 - lettersTyped.length, (index) => lettersTyped.push(defaultLetters[index]))
  }

  keys = keys.map((key) => key.toUpperCase())
  words =
    shuffle( // Randomize order of words
      filter(
        flatten( // Create single array from multiple objects { Z: [], W: [] }
          keys.map((letter) => words[letter]) // Get all words starting with the problem key being practiced
        ),
        (word) => word && word.length <= maxWordLength && (lettersTyped.length === 0 || difference(word.split(''), lettersTyped).length === 0) // Filter out any words with letters not yet typed by the user
      )
    )

  // If (for some crazy reason) there are no words to use, pick all the words with Z in them
  if(words.length === 0) {
    words = originalWords['Z'];
  }

  // Add more words if there are not enough
  while(words.length <= 200) {
    words = [...words, ...words]
  }

  // We probably only need 200 words to display
  return words.slice(0, 200).join(' ')
}

export default Backbone.Collection.extend({
  constructor: function(models, options) {
    let user = options.user
    let words = generatePKWordList(options.letters, options.words, options.lettersTyped, options.maxWordLength, false),
      keys = options.letters.map((key) => key.toUpperCase())

    if(!options.letters.length || !options.words) {
      throw new Error('Unable to find problem words');
    }

    models[0].intro = models[0].intro.formatWithVars(keys.join(', '));
    models[0].title += (' - ' + keys.join(', '));
    models[0].content = user.hasOption('problemkeysai') ? user.getSetting('AIContent') || words : words

    return Backbone.Collection.call(this, models, options);
  },

  model: ScreenModel,
  comparator: 'display_order'
})


