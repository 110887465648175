import $ from 'jquery'
import Registry from '@registry'
import LayoutBasic from '../../global/views/layout_basic'
import AdView from '../../global/views/ad'
import moment from 'moment'
import { prompt_layout } from '@templates/student'

export default LayoutBasic.extend({

  template: prompt_layout,

  events: {
    'click .js-dictation-settings': 'clickDictation',
  },

  initialize: function() {
    LayoutBasic.prototype.initialize.apply(this, arguments);

    this.user = Registry.get('student');

    $(document).keydown(function(e) { // Used to turn dictation on and off from any screen
      if((e.metaKey || e.ctrlKey) && e.shiftKey && e.key === 'd') {
        e.preventDefault();
        this.toggleDictation();
        return false;
      }
    }.bind(this));
  },

  serialize: function() {
    return {
      moment: moment,
      ads: AdView.canShowAds(),
      allowdictation: this.user.hasOption('allowdictation'),
      user: this.user.toJSON()
    };
  },

  clickDictation: function() {
    this.user.setSettings({ dictation: !this.user.getSetting('dictation') });

    this.toggleDictationIcon();

    return false;
  },

  toggleDictationIcon: function() {
    if(this.user.getSetting('dictation')) {
      this.dictation.resume();
      this.$('.js-dictation-on').fastShow();
      this.$('.js-dictation-off').fastHide();
    } else {
      this.dictation.turnOff();
      this.$('.js-dictation-on').fastHide();
      this.$('.js-dictation-off').fastShow();
    }
  }
})
