import Backbone from 'backbone'

export default Backbone.Model.extend({
  idAttribute: 'lesson_id',

  defaults: {
    name: '',
    unit_id: 0,
    seconds: 0,
    typed: 0,
    errors: 0,
    congrats: '',
    progress: 0,
    settings: {},
    caps_warning: 1,
    max_progress: 0
  },

  getTestType: function() {
    var time_limit = this.get('time_limit');
    if(time_limit >= 30) {
      return 'timed';
    } else if(time_limit > 0) {
      return 'page';
    }

    return '';
  }
})

