import { isEmpty } from 'lodash-es'

export default {
  speed: function(characters, seconds, errors, speedType) {
    if(typeof characters == 'object') {
      seconds = characters.seconds;
      errors = characters.errors;
      speedType = characters.speedType;
      characters = characters.typed;
    }
    speedType = speedType || 'wpm';
    errors = errors || 0;

    var minutes = seconds/60,
      words,
      speed;

    if (speedType === 'kph') {
      // KPH ignores errors.  gross === net
      speed = Math.floor(characters/seconds*3600);
    } else if (speedType === 'cpm') {
      words = (characters /*- (errors * 5)*/);
      speed = Math.max(Math.floor(words/minutes),0);
    } else {
      words = (characters /*- (errors * 5)*/)/5;
      speed = Math.max(Math.floor(words/minutes),0);
    }
    return (speed === Infinity || isNaN(speed))?0:speed;
  },

  accuracy: function(characters, errors) {
    if(typeof characters == 'object') {
      errors = characters.errors;
      characters = characters.typed;
    }

    var accuracy;
    characters = characters || 1;
    accuracy = 100-Math.round((errors/characters)*100);

    return Math.round((isNaN(accuracy)) ? 0 : accuracy);
  },

  /**
   * Get the number of stars per accuracy
   * @param {number} accuracy
   * @param {number} two Minimum accuracy to get two stars
   * @param {number} three Minimum accuracy to get three stars
   * @returns {number} number of stars
   */
  stars: function(accuracy, two, three) {
    two = two || 90;
    three = three || 95;
    if (accuracy < two) {
      return 1;
    } else if (accuracy < three) {
      return 2;
    }
    return 3;
  },

  grade: function(score, thresholds) {
    if(!thresholds || isEmpty(thresholds)) return '';

    let grades = Object.keys(thresholds),
      scores = Object.values(thresholds);

    // Hack attack. All point grades were saved in reverse order, so we need to put them in the right order before displaying
    if(!isNaN(grades[0])) {
      grades = grades.reverse()
      scores = scores.reverse()
    }

    for(let i = 0; i < scores.length; i++) {
      if(i === 0 && score >= scores[i] || score > scores[i])
        return grades[i];
    }

    return grades[grades.length - 1];
  },

  techLitGrade: function(correct, total) {
    var grade;
    total = total || 1;
    grade = Math.round(correct/total * 100);

    return Math.round((isNaN(grade)) ? 0 : grade);
  }
}
