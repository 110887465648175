import Backbone from 'backbone'
import $ from 'jquery'
import PanelView from './panel'
import AdView from '../../global/views/ad'
import AdButtonsView from '../../global/views/ad_buttons'
import { each, filter, find } from 'lodash-es'
import { achievements_main } from '@templates/student'
import Registry from '@registry';

export default Backbone.View.extend({

  template: achievements_main,

  events: {
    'click div.js-tab': 'switchTab',
    'change select.js-tab': 'switchTab',
    'keydown .js-tab': function(e) {
      if(e.which === 13 && this.$(document.activeElement).hasClass('js-tab')) {
        this.switchTab(e);
      }
    },
    'keydown .js-panel-item .screenReader': 'escapePanel'
  },

  activeTab: 'summary',

  initialize: function () {
    Backbone.View.prototype.initialize.apply(this, arguments);

    if(AdView.canShowAds()) {
      AdView.initAds('other');
      // grab all the ads and assign them to the right divs
       each(AdView.getAds(), (ad, size) => {
        if(!ad) { return; }
        this.addChild('.js-'+size+'-ad', ad);
      });

      var adButtonsView = new AdButtonsView();
      this.addChild('.js-ad-buttons', adButtonsView, false);
    }

    const activeProduct = Registry.get('student').getSetting('product_id')
    this.resetUnits(activeProduct)
    this.setSkins()

    this.views.panel = new PanelView({
      parent: this,
      activeTab: this.activeTab,
      userLessons: this.userLessons,
      userAchievements: this.userAchievements,
      achievements: this.achievements,
      achievementGroups: this.achievementGroups,
      lessons: this.lessons,
      skinId: this.user.get('skin_id'),
      variantId: this.user.get('variant_id'),
      activeProduct: activeProduct
    });

    this.addChild('.js-panel-item', this.views.panel);
  },

  serialize: function() {
    var groups = this.achievementGroups.getCounts(this.achievements);

    return {
      ads: AdView.canShowAds(),
      adsClass: AdView.getLeftMarginClass(),
      activeTab: this.activeTab,
      groups: groups
    };
  },

  switchTab: function(e) {
    const target = $(e.currentTarget),
      id = (target.is('select')) ? target.val() : target.data('id'),
      selectTarget = this.$('select.js-tab')

    this.$('.js-panel[data-id=' + this.activeTab+'], .js-tab[data-id='+this.activeTab+']').removeClass('is-active');

    this.activeTab = id;

    this.$('.js-panel[data-id=' + this.activeTab+'], .js-tab[data-id='+this.activeTab+']').addClass('is-active');

    selectTarget.val(this.activeTab);

    this.views.panel.activeTab = this.activeTab;
    this.views.panel.render();

    if(e.type === 'keydown') {
      this.$('.js-panel-item .screenReader').eq(0).focus();
    }
  },

  getTotalEarned: function() {
    var groups = this.achievementGroups.getCounts(this.achievements);

    return groups.reduce(function(memo, row){ return memo + row.completed; }, 0) + this.userLessons.filter(lesson => lesson.get('lesson_completed')).length + this.getRanksEarned();
  },

  getTotalAchievements: function() {
    var groups = this.achievementGroups.getCounts(this.achievements);

    return groups.reduce(function(memo, row){ return memo + row.total; }, 0) + this.lessons.length + this.skins.getTotalRanks();
  },

  getRanksEarned: function() {
    var activity = this.userActivity.getOrAdd(0);
    return this.skins.reduce(function(memo, skin){
      if(!skin.get('noAvatar') && activity && activity.get('typed') > 0) {
        var rank = skin.getRank(activity.get('typed') - activity.get('errors'));
        return memo + rank.id;
      }
      return memo;
    }.bind(this), 0);
  },

  resetUnits: function(productId) {
    this.units = this.getUnits(productId)
    this.lessons = this.getLessons(productId)
    this.badges = this.getBadges()
  },

  getUnits: function(productId) {
    return FTWGLOBALS('units').filter((unit) => {
      return unit.product_id === productId && !(
        unit.type === 'test' ||
        unit.type === 'custom_test' ||
        unit.type === 'custom' ||
        unit.type === 'instruction' ||
        !unit.badge)
    });
  },

  getLessons: function(productId) {
    return FTWGLOBALS('lessons').filter((lesson) => {
      return lesson.type === 'lesson' && find(this.units, { unit_id: lesson.unit_id })
    });
  },

  getBadges: function(productId) {
    return this.userLessons.filter((userLesson) => {
      return userLesson.get('lesson_completed') && this.lessons.find(lesson => lesson.lesson_id === userLesson.id)
    })
  },

  setSkins: function() {
    var activity = this.userActivity.getOrAdd(0);

    this.skins.forEach((skin) => {

      if((activity && activity.get('typed') > 0) || (this.user.get('skin_id') || 1) === skin.id) {
        var data = (activity) ? activity.toJSON() : {typed: 0, errors: 0},
          rank = skin.getRank(data.typed - data.errors),
          nextRank = skin.getNextRank(data.typed - data.errors);

        skin.set({rank: rank, nextRank: nextRank, typed: data.typed - data.errors});
      }
    });
  },

  escapePanel: function(e) {
    if(e.which === 27) {
      this.$('.js-path.is-active').focus();
    }
  }

})
