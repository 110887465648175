import Backbone from 'backbone'
import $ from 'jquery'

let downloadCache = {};

const done = function(data){
  if(!data.documentElement){
    return;
  }

  var doc = data.documentElement.cloneNode(true);

  if(this.preprocess && typeof this.preprocess === 'function') {
    // you can send a preprocess function to the SVG class to modify the doc before it renders
    this.preprocess(doc);
  }

  this.$el.html(doc);
  this.trigger('load', this.$('svg'));
};

export default Backbone.View.extend({
  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    if(downloadCache[this.path]) {
      downloadCache[this.path].done(done.bind(this));
    } else {
      // simple way to turn an external SVG image into a Backbone View
      downloadCache[this.path] = $.get(this.path).done(done.bind(this));
    }
  },

  render: function() {
    return this;
  }
})
