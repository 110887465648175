import Backbone from 'backbone'
import $ from 'jquery'
import { global_screentip } from '@templates/student'

export default Backbone.View.extend({

  template: global_screentip,

  initialize: function(options) {
    Backbone.View.prototype.initialize.apply(this, arguments);

    // this.offset.top -= 40;
    if(this.target) {
      // Hide the tooltip when its target element is clicked
      this.target.one(this.closeTrigger || 'mousemove', this.remove.bind(this));
    }
  },

  serialize: function() {
    return {
      id: this.id,
      text: this.text
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    window.setTimeout(function() {
      var ele = $('.js-screentip-' + this.id),
        arrowEle = ele.find('.screentip-arrow');
      ele.css({transform: 'translate(' + (this.offset.left - (ele.outerWidth() / 2) + 2) + 'px, ' + (this.offset.top - ele.outerHeight() - arrowEle.outerHeight()) + 'px)' });
    }.bind(this), 0);

    return this;
  }
})
