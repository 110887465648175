import Backbone from 'backbone'
import $ from 'jquery'
import LayoutView from '../global/views/layout_basic'
import MainView from './views/main'
import Registry from '@registry';
import Alert from '../global/views/alert';

export default Backbone.View.extend({

  layout: LayoutView,

  section: {},

  events: {
    'click .js-login': 'login'
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.layout = new LayoutView();

    this.mainView = new MainView({ rereview: this.rereview });
    this.addChild(this.el, this.mainView, true);

    this.render();
  },

  login: function(e) {
    const ele = $(e.currentTarget)

    const loadingModal = new Alert({
      title: 'Logging in...',
      text: '',
      ok: '',
      cancel: '',
      closeButton: false
    });

    $.ajax({
      url: '/apiv1/student/auth/login',
      data: {
        username: ele.data('username'),
        password: ele.data('password')
      },
      method: 'post'
    }).done((data) => {
      Registry.get('student').loginWithData(data)
      window.location.href = __url('/student/lessons')
    }).fail((data) => {
      loadingModal.close()
      new Alert({
        title: 'Missing Login',
        text: 'This user does not exist.',
      });
    })
  }
})
