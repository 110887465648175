import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import AdButtonsView from '../../global/views/ad_buttons'
import PanelView from './panel'
import { each } from 'lodash-es'
import { scoreboard_main } from '@templates/student'

export default Backbone.View.extend({
  template: scoreboard_main,

  events: {
    'click div.js-tab': 'changeType',
    'change select.js-tab': 'changeType',
    'keydown .js-tab': function (e) {
      if (e.which === 13 && this.$(document.activeElement).hasClass('js-tab')) {
        this.changeType(e)
      }
    },

    'click .js-period': 'changePeriod'
  },

  activeTab: 'speed',

  classScoreboardPeriods: [
    ['this_week', 'shared.this_week'.t()],
    ['last_week', 'shared.last_week'.t()],
  ],

  initialize: function () {
    Backbone.View.prototype.initialize.apply(this, arguments)

    if (AdView.canShowAds()) {
      AdView.initAds('other')
      // grab all the ads and assign them to the right divs
      each(AdView.getAds(), (ad, size) => {
        if (!ad) {
          return
        }
        this.addChild('.js-' + size + '-ad', ad)
      })

      var adButtonsView = new AdButtonsView()
      this.addChild('.js-ad-buttons', adButtonsView, false)
    }

    this.views.panel = new PanelView({
      scoreboard: this.scoreboard
    })

    this.addChild('.js-panel-item', this.views.panel)
  },

  changePeriod(e) {
    const value = $(e.target).data('id');

    // make sure this is valid
    if (!this.classScoreboardPeriods.find(([key]) => key === value)) {
      return;
    }

    this.scoreboard.period = value

    this.render()
    return false
  },

  serialize: function () {
    return {
      ads: AdView.canShowAds(),
      activeTab: this.activeTab,
      type: this.scoreboard.comparatorKey,
      period: this.scoreboard.period,
      classScoreboardPeriods: this.classScoreboardPeriods,
    }
  },

  changeType: function (e) {
    var target = $(e.currentTarget),
      id = target.data('id')

    this.activeTab = id
    this.scoreboard.comparatorKey = id
    this.scoreboard.sort()

    this.$('.js-tab.is-active').removeClass('is-active').attr('aria-selected', 'false')
    target.addClass('is-active').attr('aria-selected', 'true')

    return false
  }
})
