import { newContainer } from './utils';

export default class BlueprintGroup {
  constructor (data) {
    this.data = data

    // determine the size of the group
    const { bounds: { left, right, top, bottom } } = data
    const width = right - left
    const height = bottom - top
    const x = (right + left) * 0.5
    const y = (bottom + top) * 0.5

    // get the offset to use for appended shapes
    this.offset = { x: left * -1, y: top * -1 }
    this.x = x
    this.y = y

    // the main container
    this.el = newContainer()
    this.el.setAttribute('class', 'blueprints--group')
    this.el.setAttribute('data-id', data.id)

    // a helper translate object
    const translate = newContainer()
    translate.setAttribute('id', `translate--${data.id}`)

    // add an element to act as a bounding box when this
    // element is selected
    const boundingBox = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
    boundingBox.setAttribute('class', 'blueprints--group--bounding-box')
    boundingBox.setAttribute('width', width)
    boundingBox.setAttribute('height', height)

    // add an element to act as a bounding box when this
    // element is selected
    const transform = newContainer()
    transform.setAttribute('class', 'blueprints--group--transform')
    transform.setAttribute('transform', `translate(${width * -0.5} ${height * -0.5})`)

    // element is selected
    const rotate = newContainer()
    rotate.setAttribute('class', 'blueprints--group--rotate')

    // add to the container
    transform.appendChild(boundingBox)
    rotate.appendChild(transform)
    translate.appendChild(rotate)
    this.el.append(translate)

    // save references
    this.transformEl = transform
    this.rotateEl = rotate

    // handle clicks
    this.el.addEventListener('pointerup', this.onSelect)

    // set the starting point
    this.translate(x, y)
  }

  get bounds() {
    return this.data.bounds
  }

  get id() {
    return this.data.id
  }

  rotate(radians) {
    const deg = radians * (180 / Math.PI)
    this.rotateEl.setAttribute('transform', `rotate(${deg})`)
  }

  translate(x, y) {
    this.el.setAttribute('transform', `translate(${x} ${y})`)
  }

  containsShape(id) {
    return this.data.ids.includes(id)
  }

  append(shape) {
    const { x, y } = this.offset
    const contain = newContainer()

    // offset the shape so it's aligned with the group - this is to make
    // sure that rotations look correct
    contain.setAttribute('transform', `translate(${x}, ${y})`)
    contain.appendChild(shape.el)
    this.transformEl.appendChild(contain)
  }
}
