import Backbone from 'backbone'
import $ from 'jquery'
import moment from 'moment'
import Registry from '@registry'
import MainView from './views/main'
import SectionModalView from '../global/views/section_modal'
import MultipleUsersModalView from '../global/views/multiple_users_modal'
import Alert from '../global/views/alert';
import { map, join } from 'lodash-es'
import { Cookies } from '@shared/helpers'
import { GOOGLE_CLASSROOM_ADDON_POPUP_WINDOW_NAME, GOOGLE_CLASSROOM_ADDON_LOGIN_SUCCESS_HANDLER, getTargetOrigin } from '../googleClassroomAddon/helpers'

export default Backbone.View.extend({

  el: 'body',

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    if(window.localStorage.getItem('student_sso_id')) {
      this.logIn({user_id: window.localStorage.getItem('student_sso_id')});
      window.localStorage.removeItem('student_sso_id');
    } else {
      this.logIn();
    }

    this.mainView = new MainView();
    this.addChild(this.el, this.mainView, true);
    this.renderChildren();
  },

  userId: null,
  showMultipleUsers: function(data) {
    var modal = new MultipleUsersModalView({
        accountKey: 'user_id',
        data: data
      }).done((data) =>{
        this.userId = data.accountId;
        this.logIn({user_id: data.accountId, section_id: this.sectionId, login_type: data.loginType });
        modal.close();
      })
      .fail(() => {
        window.location.href = __url('/student/login');
      })
    modal.open();
  },

  sectionId: null,
  showSections: function(sections) {
    sections = map(sections, (row) => ([row.section_id, `(${(row.teacher_names?.length > 35 ? row.teacher_names.substr(0, 34) + '...' : row.teacher_names)}) ${row.section_name}`]));

    var modal = new SectionModalView({
      sections: sections
    }).done(function(sectionId){
      this.sectionId = sectionId;
      this.logIn({user_id: this.userId, section_id: this.sectionId});
      modal.close();
    }.bind(this));
    modal.open();
  },

  logIn: function(params) {
    params = params || {};
    params.screenWidth = screen.width;

    $.post('/apiv1/student/auth/oauth-login', params)
      .done((data) => {
        if (window.opener && window.self && window.self.name === GOOGLE_CLASSROOM_ADDON_POPUP_WINDOW_NAME) {
          window.opener.postMessage({ type: GOOGLE_CLASSROOM_ADDON_LOGIN_SUCCESS_HANDLER, payload: data }, getTargetOrigin(window.opener))
          window.close()
        } else {
          data.joinCode = Cookies.get('join_code');
          Registry.get('student').loginWithData(data);

          const url = '/student/lessons',
            settings = data.settings

          // if their language is explicitly set, and is different from the page then choose where they go
          if (settings.language && settings.language !==
            FTWGLOBALS('language')) {
            if (settings.language === FTWGLOBALS('defaultLanguage')) {
              window.location.href = url;  // english has no prefix
            } else {
              window.location.href = '/' + settings.language + url;
            }
          } else {
            window.location.href = __url(url);
          }
        }
      })
      .fail(function(data) {
        const response = data.responseJSON

        if(response?.status_data) {
          let statusData = {}
          try {
            statusData = JSON.parse(response.status_data)
          } catch(e) { }

          new Alert({
            title: 'auth.class_active_temp_title'.t(),
            closeButton: false,
            text: 'auth.class_active_temp_text'.t({
              activeStart: moment(statusData.active_start, 'LT').format('h:mm A'),
              activeEnd: moment(statusData.active_end, 'LT').format('h:mm A'),
              activeDays: join(map(statusData.active_days, (dayIndex) => new moment().day(dayIndex).format('ddd')), ', ')
            })
          }).on('ok', function(){
            window.location.href = __url('/student/login');
          })
        } else if(response?.message || response?.error) {
          this.mainView.showError('oauth.uh_oh_error'.t(), (response.message || response.error || '').t());
        } else if (response?.users) {
          this.showMultipleUsers(response.users, response.authenticated);
        } else if (response?.sections) {
          this.showSections(response.sections);
        } else {
          this.mainView.showError('oauth.uh_oh_error'.t(), (data.responseText || '').t());
        }
      }.bind(this));
  }
})
