import $ from 'jquery'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import TypingView from './typing'
import Velocity from 'velocity-animate'
import CelebrationEffect from '@shared/celebration_effect';
import { playSound } from '@shared/helpers';
import { extend, debounce, find, times } from 'lodash-es'
import { lesson_typing_single_key } from '@templates/student'

export default TypingView.extend({

  template: lesson_typing_single_key,

  // the state of the page: key | key_confirm | lesson
  mode: 'key',

  initialize: function() {
    TypingView.prototype.initialize.apply(this, arguments);

    var events = this.events || {};

    this.delegateEvents(
      extend(events, {
        'click .js-next-lesson': 'nextLesson'
      })
    );
  },

  /**
   * Override this
   * @returns {*}
   */
  render: function() {
    this.listenTo(this.screen, 'change:typed', this.animate);

    var user = Registry.get('student'),
      userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens'),
      fingerDetails = this.dictation.getFingerDetails(this.screen.get('new_key'), false);

    this.$el.append(this.template({
      skin: find(FTWGLOBALS('skins'), {id: this.user.get('skin_id') || 1}),
      ads: AdView.canShowAds() && totalScreens > 0,
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens }),
      fingerDetails: fingerDetails,
      lesson: this.lesson.toJSON(),
      screen: this.screen.toJSON(),
      productGrade: user.getCurriculumGrade(),
      mode: this.mode,
      user: user.toJSON()
    }));

    TypingView.prototype.render.apply(this, arguments);

    this.typingContent = this.$('.screenIntro-lines');

    if(this.mode === 'key') {
      this.toggleAnimateCorrectKey();
    }

    window.setTimeout(() => {  // let it render
      this.cacheAnimations();
    }, 0);

    Velocity(this.$('.js-single-letter .frame-avatar'),
      {scaleX: ['1','0.2'], scaleY: ['1','0.5'], opacity: 1},
      {duration: 300, easing: [0.68, -0.55, 0.46, 1.59],}
    )
    Velocity(this.$('.js-single-letter .frame-bubble'),
      {scaleX: ['1','0'], scaleY: ['1','0.2'], opacity: 1},
      {delay: 100, duration: 500, easing: [0.68, -0.55, 0.46, 1.39]}
    )

    window.setTimeout(() => {  // let it render
      this.cacheAnimations();
    }, 10);

    window.setTimeout(() => {  // let it render
      this.cacheAnimations();
    }, 1000);

    $(window).resize(debounce(this.cacheAnimations.bind(this), 1000, false));

    return this;
  },

  /**
   * Animate the keys sideways
   */
  animate: function(model, typed) {
    this.letterWidth = this.letterWidth || this.$('.screenIntro-line .letter').outerWidth(true);
    if(typed % this.keysPerView === 0 && model.get('content').length > typed) {
      var duration = (this.lowPerformance) ? 100: 250;
      Velocity(this.typingContent, {translateX: '-'+typed * this.letterWidth+'px'}, {duration: duration, easing: 'ease-in-out'});
    }
  },

  keyBlinkTimeout: 0,
  toggleAnimateCorrectKey: function() {
    if(this.keyBlinkTimeout) {
      window.clearInterval(this.keyBlinkTimeout);
      this.keyBlinkTimeout = 0;
    } else {
      var key = this.$('.key.is-active');
      this.keyBlinkTimeout = window.setInterval(function(){
        key.toggleClass('is-active');
      }.bind(this), 500);
    }
  },

  handleInput: function(input) {
    if(input.dead) {
      // Handle dead keys in order to display the "Keyboard discrepancy" modal
      this.handleDeadKeyTyped(null, true);
      return;
    }

    if(this.mode === 'key') {
      if(input.special) {
        return false;
      }

      const singleLetter = this.$('.js-single-letter .js-typing-content'),
        keyPressed = input.key.toLowerCase(), // don't care if it's wrong case
        correctKey = (this.screen.get('new_key') === '⏎') ? "\n" : this.screen.get('new_key');

      if(keyPressed === correctKey) {
        this.mode = 'complete'
        this.$('.js-single-letter').fastHide()
        this.$('.js-canvas-overlay').fastShow()
        Velocity(this.$('.js-canvas-overlay .frame-bubble'),
          {scaleX: ['1','0'], scaleY: ['1','0.2'], opacity: 1},
          {delay: 100, duration: 500, easing: [0.68, -0.55, 0.46, 1.39]}
        )
        window.setTimeout(() => {
          this.dictation.speakScreen({
            singleKeyComplete: true
          });
          this.starAnimation()
        }, 500)
      } else {
        this.dictation.speakScreen({
          isError: true,
          nextKey: correctKey,
          letterTyped: keyPressed
        });
        this.playSounds('error');
        singleLetter.addClass('is-wrong');
        if(this.keyboardView) {
          this.keyboardView.highlightErrorKey(keyPressed);
        }
      }
    } else if(this.mode === 'complete') {
      if(input.key === '\n') {
        this.nextLesson()
      }
    }
  },

  handleErrorTyped: function(model) {
    if(this.mode === 'lesson') {
      this.lettersTyped.trackError(model.get('correctLetter'));
      this.setPosition(model.get('typed'), false, true);
    }

    TypingView.prototype.handleErrorTyped.call(this, model);
  },

  starAnimation: function() {
    // play the stars/screen-completion sound if they have any sounds enabled
    let star1, star2, star3

    const timeBetweenStars = 850,
      playSounds = this.user.hasOption('allowsounds') && (this.user.getSetting('error_sounds') || this.user.getSetting('typing_sounds'))

    try {
      star1 = new Audio('/dist/student/extras/sounds/star1.mp3');
      star1.volume = 0.3;
      star2 = new Audio('/dist/student/extras/sounds/star2.mp3');
      star2.volume = 0.3;
      star3 = new Audio('/dist/student/extras/sounds/star3.mp3');
      star3.volume = 0.3;
    } catch (e) {}

    $('body').append('<canvas class="celebration js-star-effect" style="z-index: 11;"></canvas>')

    window.requestTimeout(() => { // Wait for screen to render before attempting to add confetti or display stars
      const celebrationEle = $('.js-star-effect');
      celebrationEle.css({
        'top': -celebrationEle.offset().top,
        'left': -celebrationEle.offset().left
      });
      CelebrationEffect.init(celebrationEle[0], {
        screenHeight: $(document).height(),
        screenWidth: $(document).width(),
        colors: this.user.getSkinColors(),
        assets: [
          '/dist/shared/images/particles/star-1.png',
          '/dist/shared/images/particles/star-2.png',
          '/dist/shared/images/particles/star-3.png']
      });

      this.$('.js-star').addClass('star--animate')

      // Play SFX and show Confetti as stars burst into existence
      if (playSounds) {
        times(3, (index) => { // Loop through the number of stars earned by the user
          window.requestTimeout(() => {
            const starEle = this.$('.star--screen-' + (index + 1));
            starEle.addClass('star--floating');
            if (playSounds && eval('star' + (index + 1))) {
              playSound(eval('star' + (index + 1)));
            }
            CelebrationEffect.explodeSingle({
              type: 'custom',
              x: starEle.offset().left + (starEle.width() / 2),
              y: starEle.offset().top + (starEle.height() / 2),
              count: 20,
              angle: 230 + (index * 40),
              spread: 85,
              speed: 10
            });
          }, index * timeBetweenStars);
        })
      }
    }, 0);
  },

  nextLesson: function() {
    this.$('.js-next-lesson').addClass('btn--loading')
    this.trigger('complete')
  }
})
