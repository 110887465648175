import Backbone from 'backbone'
import $ from 'jquery'
import Tooltip from './tooltip'
import Registry from '@shared/registry'
import Velocity from 'velocity-animate'
import { times, reduce } from 'lodash-es'
import { global_goal } from '@templates/student'

const DASH_LENGTH = 291

export default Backbone.View.extend({

  events: {
    'click .js-goal-info': 'showTooltip',
    'click .js-add-5-minutes': 'addFiveMinutes'
  },

  template: global_goal,

  editable: false,
  forceTooltip: false,
  recentSeconds: 0, // Recently typed seconds
  goalSeconds: 0, // Seconds needed to complete goal
  totalSeconds: 0, // Total seconds typed
  totalProgress: 0, // Total progress towards goal
  oldProgress: 0, // Recently typed progress towards goal

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments)

    this.delay = this.type === 'display' ? 0 : 500;

    const user = Registry.get('student')

    if(isNaN(this.goalSeconds)) {
      this.goalSeconds = 15 * 60
    }

    this.forceTooltip = this.totalSeconds && !user.get('_dailyGoalSet');
  },

  serialize: function () {
    // Initialize variables inside serialize because this view rerenders after page load
    this.oldSeconds = this.totalSeconds - this.recentSeconds
    this.totalProgress = Math.min(this.totalSeconds / this.goalSeconds, 1)
    this.oldProgress = Math.min(this.oldSeconds / this.goalSeconds, 1)

    const twinkles = [
      [7,75,0.69],[16,15,0.65],[37,28,0.56],[80,58,0.60],[22,44,0.60],[18,3,0.64],[86,3,0.64],[41,63,0.66],[62,89,0.59],[94,11,0.53],[13,31,0.71],[90,82,0.71]
    ]

    const strokeProgress =  DASH_LENGTH - (DASH_LENGTH * this.oldProgress)

    return {
      oldSeconds: this.oldSeconds,
      totalSeconds: this.totalSeconds,
      goalSeconds: this.goalSeconds,
      strokeDashLength: DASH_LENGTH,
      strokeProgress: strokeProgress,
      editable: this.editable,
      twinkles: twinkles,
      isAchieved: this.goalSeconds && this.totalSeconds >= this.goalSeconds,
      type: this.type,
      noAnimation: this.noAnimation
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    if(this.goalSeconds > 0) {
      if(this.totalSeconds > this.goalSeconds) {
        Velocity(this.$('.js-twinkles'), 'fadeIn');
      } else if (this.editable && this.forceTooltip) {
        // On render, display the tooltip to encourage user to pick a goal
        setTimeout(() => {
          this.forceTooltip = false
          // Once the tooltip is shown, mark that the user has accepted the goal. This will stop the tooltip from appearing later in this session
          Registry.get('student').set({ '_dailyGoalSet': true })

          this.showTooltip()
        }, 1000)
      }

      window.setTimeout(() => {
        this.animateProgress()
      }, this.delay);
    }

    return this;
  },

  animateProgress: function() {
    const duration = this.totalProgress < 0.5 ? 1000 : 1500,
      goalElem = this.$('.js-goal'),
      goalContentElem = this.$('.js-goal-content'),
      progressTextEle = this.$('.goal-progress-text')

    if(progressTextEle.length && this.totalSeconds < this.goalSeconds) {
      progressTextEle.countdown(
        [Math.min(this.goalSeconds, (this.totalSeconds || 0)), this.oldSeconds], // [end value, start value]
        { formatter: (num) => num.countdownSeconds(false, false, true) },
        400
      )
    }

    const animateStroke = () => {
      const strokeElem = this.$(".js-goal-progress"),
      PROGRESS_THRESHOLD = 0.02, // the minimal amount of progress a user must have made on their goal in order to be visually accurate (otherwise, we compensate it to make it more impressive)
      DASH_VISUAL_OFFSET = 1, // the thickness of the stroke prevents it from ever disappearing in the dashoffset, this integer helps accommodate that visually
      totalProgress = (this.totalProgress - this.oldProgress < PROGRESS_THRESHOLD && this.totalProgress < (1 - PROGRESS_THRESHOLD)) ? this.totalProgress + PROGRESS_THRESHOLD : this.totalProgress,
      oldVisualProgress = Math.min(DASH_LENGTH-(this.oldProgress*DASH_LENGTH), DASH_LENGTH-DASH_VISUAL_OFFSET),
      visualProgress = DASH_LENGTH-(totalProgress*DASH_LENGTH)

      if (this.totalProgress <= DASH_LENGTH-DASH_VISUAL_OFFSET/DASH_LENGTH) { // if the totalProgress is less than or equal to the offset proportion of the dash offset, hide the stroke (since it's not accurate)
        this.$('.js-goal-progress').addClass('is-zero')
      }

      Velocity(
        strokeElem, // The  stroke
        {
          "stroke-dashoffset": [visualProgress, oldVisualProgress], // only show recent progress on the screen_complete
        },
        {
          duration: duration,
          easing: "easeIn",
          begin: () => {
            if(this.totalProgress > 0 && this.goalSeconds !== 0) {
              strokeElem.removeClass('is-zero')
            }
            if(this.type === 'display') {
              window.setTimeout(() => {
                animatePop();
              }, 1000)
            }
          }
        }
      )

    }

    // const animateOrangeStroke = () => {
    //   const visualTotalProgress = (this.totalProgress - this.oldProgress < 0.01 && this.totalProgress < 0.99) ? this.totalProgress + 0.01 : this.totalProgress,
    //   visualProgress = DASH_LENGTH-(visualTotalProgress*DASH_LENGTH)
    //     }
    //   )
    // }

    const animatePop = () => {

      Velocity(
        goalElem,
        {
          scale: [0.5, 4],
          opacity: 1
        },
        {
          easing: 'easeIn',
          duration: 150,
          complete: () => {
            Velocity(
              goalElem,
              {scale: [1.25,1]},
              {
                duration: 100,
                easing: 'easeOut',
                begin: () => {
                  animateAchievedGoalContent();
                },
                complete: () => {
                  Velocity(goalElem, {scale: 1}, 100)
                }
              }
            )
          }
        }
      )
    }

    const animateAchievedGoalContent = () => {
      let achieved = false;
      Velocity(
        goalContentElem,
        {opacity: [0,1]},
        {
          duration: 50,
          begin: () => {
            Velocity(this.$('.js-goal-info'), 'fadeOut');
            Velocity(
              this.$('.goal-title, .goal-progress-text'),
              {
                opacity: [1,0]
              },
              {
                delay: 25,
                duration: 50,
                begin: () => {
                  Velocity(goalContentElem, 'fadeIn', { display: 'flex' });
                  Velocity(
                    this.$('.js-achieved'),
                    {opacity: [1,0]},
                    {
                      display: 'block',
                      duration: 250,
                      easing: 'easeInQuart',
                      begin: () => {
                        this.$('.goal-title, .goal-progress-text, .goal-achieved').addClass("goal-textInset")
                      },
                      complete: () => {
                        // cue twinkle animations
                        animateConfetti();
                        window.setTimeout(() => {
                          addOverlayAnimation();
                        }, 500)
                      }
                    }
                  )
                }
              }
            )
          },
        }
      )
    }

    const animateConfetti = () => {
      const positionX = goalElem.offset().left + goalElem.width() /2;
      const positionY = goalElem.offset().top + goalElem.height() /2;

      this.trigger('goal_achieved', positionX, positionY);
    }

    const addOverlayAnimation = () => {
      Velocity(
        $('.js-overlay-element'),
        {opacity: [0.3,0]},
        {
          display: "block",
          duration: 2000
        }
      )
    }

    animateStroke();

  },

  updateValues: function(totalSeconds, recentSeconds) {
    if((totalSeconds && totalSeconds !== this.totalSeconds) || (recentSeconds && recentSeconds !== this.recentSeconds)) {
      this.totalSeconds = totalSeconds ? totalSeconds : this.totalSeconds
      this.recentSeconds = recentSeconds ? recentSeconds : this.recentSeconds

      this.render()
    }
  },

  showTooltip: function() {
    if(!this.editable) return

    if(this.tip) {
      this.closeTooltip()
      return false
    }

    const toolTipEle = this.$('.js-goal-info'),
      goalsOptionString = reduce( // Reduce an array of integers down a single string of multiple select options
        times(13, (i) => i * 5), // Create an array [0, 5, 10, 20, 30, etc]
        (acc, cur) => acc + (`<option value='${cur}' ${(cur === (this.goalSeconds / 60) ? 'selected' : '')} ${(cur === 0 || cur > (this.totalSeconds / 60) ? '' : 'disabled')}>${(cur === 0 ? 'shared.class_settings.no_goal'.t() : 'shared.number_minutes'.t({ smart_count: cur }))}</option>`), '')

    this.tip = Tooltip.show(
      toolTipEle,
      {
        text: (`
          <svg class="icon icon--xs tooltip-close js-close-goal">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/dist/student/images/icons.svg#close"></use>
          </svg>
          <div class="tsxl twb">${'goals.tooltip_title'.t()}</div>
          <div class="list list--inline list--xs mts tsn">
            <div class="wsnw list-item">${'goals.tooltip_text_1'.t()}</div>
            <div class="list-item has-select--inline">
              <select class="select select--i select--inline tsl js-goal-select">
                ${goalsOptionString}
              </select>
            </div>
            <div className="list-item">${'goals.tooltip_text_2'.t()}</div>
          </div>
          <div class="list list--r list--inline mtm">
            <button class="list-item dif btn btn--a btn--s js-submit-goal">${Registry.get('student').get('_dailyGoalSet') ? 'goals.tooltip_update_cta'.t() : 'goals.tooltip_set_cta'.t()}</button>
          </div>
        `),
        force: true,
        offset: '-40, 15',
        placement: 'left',
        trigger: 'manual',
        blur: true
      }
    )

    const submitEle = $('.js-submit-goal')
    submitEle.off('click');
    submitEle.on('click', (e) => {
      const goalMinutes = $('.js-goal-select').val() || 0
      const save = (goalMinutes * 60) !== this.goalSeconds

      Registry.get('student').changeDailyGoal(goalMinutes, save)

      this.closeTooltip()

      // Update the goal and rerender
      this.goalSeconds = goalMinutes * 60
      this.delay = 0
      this.recentSeconds = 0 // will not animate countdown seconds on update;
      this.render()
    })

    const closeEle = $('.js-close-goal')
    closeEle.off('click')
    closeEle.on('click', (e) => {
      this.closeTooltip()
    })
  },

  closeTooltip: function() {
    if(this.tip) {
      this.tip.dispose();
      this.tip = undefined;
    }
  },

  addFiveMinutes: function() {
    const user = Registry.get('student');
    const currentGoal = Number(user.getSetting('daily_goal'));
    const newGoal = currentGoal + 5 || 5;

    // Update the daily goal with the new goal
    user.changeDailyGoal(newGoal, false);

    // Update the goalSeconds in the view to reflect the new goal
    this.goalSeconds = newGoal * 60;

    // Re-render the view to reflect the changes
    this.render();
}

})
