import p2 from 'p2-es'

import {
  CIRCLES,
  RENDER_SCENE_HORIZONTAL,
  RENDER_SCENE_VERTICAL,
  RENDER_SCENE_GRAVITY, SPECIAL_SHAPES,
} from './consts';

// handles animating a blueprint
export default class BlueprintAnimator {

  // calculate speed
  static calculateSpeed = t => Math.sqrt(1 - Math.pow((t / 4) - 1, 2)) * ((10 * t) / 4)

  // references to each wall/ceiling
  edges = { }

  // the rendering world itself
  world = new p2.World({
    gravity: [0, RENDER_SCENE_GRAVITY]
  })

  constructor (blueprint) {
    this.blueprint = blueprint
    this.content = blueprint.getContent()

    // setup the scene
    this.createBoundaries()
    this.createBodies()
  }

  // creates all bodies for a scene
  createBodies() {
    const { blueprint, world } = this

    // make each object for the scene
    this.objs = blueprint.groups.map(group => {
      const result = this.makeBody({ group })

      // add to the scene
      world.addBody(result.body)

      // save instances
      return result
    })
  }

  // make a shape collection
  makeBody({ group }) {
    const { left, right, top, bottom } = group.data.bounds
    const x = (right + left) * 0.5
    const y = (bottom + top) * 0.5

    // determine
    const params = this.content.groups.find(({ id }) => id === group.data.id) || { }
    const { speed = 0, dir = 0, float, bounce, locked, jump } = params
    const options = { x, y, locked, jump, speed, dir, float }

    // create the default body
    const body = locked
      ? this.makeStaticBody(options)
      : this.makeActiveBody(options)

    // add each part to the group
    group.data.ids.forEach((id, i) => {
      const { shape, x: ox, y: oy } = this.makeShape({ id, x, y, bounce })
      body.addShape(shape, [ox, oy])
    })

    // save the instance
    return { body, group }
  }

  // unmoving object, like a wall
  makeStaticBody({ x, y }) {
    return new p2.Body({ position: [x, y] })
  }

  // interactive movable object
  makeActiveBody({ x, y, locked, float, dir, speed, jump }) {
    const momentum = BlueprintAnimator.calculateSpeed(speed)
    const body = new p2.Body({
      mass: locked ? 0 : 1,
      gravityScale: float ? 0 : 1,
      angularVelocity: momentum > 0 ? between(-0.1, 0.1) : 0,
      damping: 0,
      velocity: [Math.cos(dir) * momentum, Math.sin(dir) * momentum],
      position: [ x, y ]
    })

    // if this can jump, add a function to handle it
    if (jump) {
      let pending
      let lastY = body.position[1]
      body.jump = () => {
        const y = body.position[1]
        const idle = y === lastY
        lastY = y

        // sitting still, apply a jump force
        if (idle && !pending) {
          pending = setTimeout(() => {
            body.applyForce([ between(-100, 100) , -220 ], 0, 5)
            pending = null
          }, between(400, 500))
        }
      }
    }

    return body
  }

  // makes a shape to add to a body
  makeShape({ id, x, y, bounce }) {
    const { content, world, edges } = this

    // find the shape to make
    const source = content.shapes.find(find => find.id === id)
    const ox = source.x - x
    const oy = source.y - y

    // determine the kind of bounding box to use
    const circle = CIRCLES.includes(source.type)
    const params = circle ? { radius: 0.5 } : { width: 1, height: 1 }
    const special = SPECIAL_SHAPES[source.type]
    const material = new p2.Material()

    // uses a special bounding box
    let shape
    if (special) {
      const dir = ((source.attrs?.rotate || 0) + 6) % 4
      const vertices = special(dir)
      shape = new p2.Convex({
        vertices,
        material
      })
    }
    // uses a typical shape
    else {
      const Type = circle ? p2.Circle : p2.Box
      shape = new Type({
        ...params,
        material
      })
    }

    // for bouncy objects, add the value;
    if (bounce) {
      const stiffness = [0, 100000, Number.MAX_VALUE][1]
      const config = { restitution: 1, stiffness }

      // make the object bouncy off all edges (walls and floor)
      Object.keys(edges).forEach(edge => {
        world.addContactMaterial(new p2.ContactMaterial(edges[edge].material, shape.material, config));
      })
    }

    // return the result
    return { x: ox, y: oy, shape }
  }

  // create the walls and ceilings
  createBoundaries() {
    const { edges, world } = this

    ;[
      ['roof', 0, -1.55],
      ['right', 1, 0],
      ['floor', 0, 1],
      ['left', -1, 0]
    ].forEach(([name, x, y], i) => {
      const plane = new p2.Plane()
      const barrier = new p2.Body({
        position: [ x * RENDER_SCENE_HORIZONTAL, y * RENDER_SCENE_VERTICAL ],
        angle: (Math.PI * 0.5) * i
      })

      barrier.addShape(plane)
      plane.material = new p2.Material()

      // add to the view
      edges[name] = plane
      world.addBody(barrier)
    })
  }

  // begin auto rendering
  start() {
    this.render()
  }

  // stop auto rendering
  stop() {
    delete this.nextFrame
  }

  // updates the world and shape positions
  render = () => {
    this.nextFrame = requestAnimationFrame(this.render)

    // animate forward - we may need to do some frame
    // delta math here eventually
    this.world.step(1 / 60)

    // update all objects
    this.objs.forEach(item => {
      const { angle, position: [ x, y ] } = item.body
      item.group.translate(x, y)
      item.group.rotate(angle)

      // check for jumpy objects
      item.body.jump?.()
    })

  }
}

// helpers
const between = (min, max) => (Math.random() * (max - min)) + min
