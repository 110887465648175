import $ from 'jquery'
import GrowlAlert from './growl_alert'
import Registry from '@registry'
import SVG from './svg'

/**
 * The global popup when achievements are achieved
 */
export default GrowlAlert.extend({

  initialize: function() {
    this.assignments = Registry.get('assignments');

    GrowlAlert.prototype.initialize.apply(this, arguments);
  },

  show: function(ids) {
    Registry.set('preventKeyboardInput', true);

    var assignments = [];
    ids.forEach(function(id){
      if(this.assignments.get(id)) {
        assignments.push(this.assignments.get(id));
      }
    }.bind(this));

    if (!assignments.length) {
      return;
    }

    assignments.forEach(function(assignment){
      $('.js-growl-content').append(this.contentTemplate({
        growlType: 'assignment',
        assignmentsLength: this.assignments.length - assignments.length,
        assignment: assignment.toJSON()
      }));
    }.bind(this));

    var assignmentImage = new SVG({path: '/dist/student/images/ach/assignments/assignment-complete.svg'});
    window.setTimeout(() => {
      this.$('.js-assignment-image').append(assignmentImage.el)
    }, 100);

    var i = assignments.length - 1,
      animateGrowls = function() {
        this.$('.js-growlWrap[data-id=' + assignments[i].id + ']').addClass('animate--growl growl--stars');
        this.$('.js-growlWrap[data-id=' + assignments[i].id + '] .growl-achievementInnerWrap').addClass('growl--shine');
        this.$('.js-growlWrap[data-id=' + assignments[i].id + '] .growl-achievement').addClass('animate--growl_s');
        i--;
        if(i >= 0) { window.setTimeout(function() { animateGrowls(); },100); }
      }.bind(this);
    animateGrowls();

    GrowlAlert.prototype.show.apply(this, arguments);
  }
})
