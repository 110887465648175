import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import AssignmentModel from '../models/assignment'
import AssignmentAlertView from '../views/assignment_alert'
import moment from 'moment'
import { postJSON } from '@shared/helpers'
import { pick, size } from 'lodash-es'

export default Backbone.Collection.extend({

  model: AssignmentModel,

  // Sorts the collection in DESCENDING order by due date
  comparator: function(model) {
    // "No date" is between due dates and overdue dates
    if(!model.get('due_date')) return -0.1;

    var diff = new moment(model.get('due_date')).diff(new moment(), 'days') + 1;

    return -1 * (diff > 0 ? 1 / diff : diff);
  },

  setLessons: function() {
    let toRemove = []

    this.forEach((assignment) => {
      if(typeof assignment.get('lessons') === 'string') {
        assignment.convertLessons()
      } else if (typeof assignment.get('games') === 'string') {
        assignment.convertGames()
      }
      if(assignment.get('invalid')) {
        toRemove.push(assignment)
      }
    })
    // Remove any assignments where we are unable to set the lessons (these assignments are not completable)
    this.remove(toRemove)
  },
},{
  checking: false, // Make sure check() isn't called while in the middle of checking

  /**
   * Static method that checks if assignments have been completed for the current user.
   */
  check: function() {
    if (!Registry.get('loggedIn') || this.checking) { return; }

    this.checking = true;
    var assignments = Registry.get('assignments'),
      user = Registry.get('student'),
      completeAssignments = [];

    // Get a list of all newly completed assignments
    assignments.forEach((row) => {
      if (row.checkIfComplete()) {
        completeAssignments.push(pick(row.toJSON(), ['assignment_id', 'google_assignment_id']));
      }
    });

    // Nothing?  Cancel
    if (!completeAssignments.length) {
      this.checking = false;
      return;
    }

    if(FTWGLOBALS('env') === 'local') {
      console.info('Checking for assignments', completeAssignments);
    }

    // Send the list of completed assignments to the server
    postJSON('/apiv1/student/assignments/check', { assignments: completeAssignments, sync_id: user.get('section_sync_id') })
      .done(function(ids) {
        // Display growl for all completed assignments
        if (size(ids) > 0) {
          var alert = new AssignmentAlertView();
          alert.show(ids);
          Registry.get('assignments').remove(ids);
        }
      })
      .always(function() {
        this.checking = false;
      }.bind(this));
  }
})
