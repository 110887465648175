import { GRADES_MAP } from '../../utils/problem_keys_lesson';
import Registry from '@registry';

const ProblemKeysModel = Backbone.Model.extend({

  defaults: {
    selectedOption: '',
    practiceKeys: null
  },

  topics: ['nature', 'cats', 'cars', 'food', 'science', 'sports', 'games', 'dogs'],

  url: function() {
    return '/apiv1/student/problem-keys';
  },

  fetch: function(timeLimit = 60) {
    const user = Registry.get('student')
    const userGrade = Number(user.getCurriculumGrade() || 0);

    const options = {
      data: {
        grade: GRADES_MAP[userGrade],
        topic: this.get('selectedOption'),
        keys: this.get('practiceKeys').map(key => key.toLowerCase()).sort().join(','),
        language: user.getCurriculumLanguage()
      }
    };

    this.set('timeLimit', timeLimit);

    return Backbone.Model.prototype.fetch.call(this, options);
  },

  parse: function(response) {
    const story = response?.data?.story;

    if (!story) {
      window?.bugsnagClient?.notify?.(new Error('Failed to generate AI content due to : ' + (response?.message || 'unknown error')));
      return {AIContent: null};
    }

    const timeLimit = this.get('timeLimit');
    // If the time limit is greater than 60 seconds, we will repeat the story multiple times
    let repetitions = timeLimit > 60 ? Math.ceil(timeLimit / 60) : 1;

    // If the story is less than 500 characters, we will repeat it multiple times
    if(story.length < 500) repetitions *= 2;

    return {AIContent: Array(repetitions).fill(story).join('\n')};
  },
});

export default ProblemKeysModel;
