import $ from 'jquery'
import Backbone from 'backbone'
import { lesson_screen_blueprints } from '@templates/student'
import Registry from '@registry';

import BlueprintShape from '../classes/blueprints/shape';
import BlueprintGroup from '../classes/blueprints/group';
import BlueprintAnimator from '../classes/blueprints/animator';

import {
  createVisualGroups,
  nextFrame,
  nextID,
  reorderShapes,
} from '../classes/blueprints/utils';

import restoreContent from '../classes/blueprints/content';

import {
  ADVANCED_GRID,
  BASE_SIZE,
  BASIC_GRID,
  COLORS,
  SHAPES,
} from '../classes/blueprints/consts';
import { bindSelectors } from '@shared/utils';
import AdView from '../../global/views/ad';

export default Backbone.View.extend({

  template: lesson_screen_blueprints,

  selectors: {
    $root: '.blueprints',
    $modes: '.blueprints--modes--option',
    $tools: '.blueprints--tools',
    $canvas: '.blueprints--canvas',
    $direction: '.blueprints--editor--direction',
    $directionArrow: '.blueprints--editor--direction-arrow',
    $directionRotation: '.blueprints--editor--direction-rotation',
    $directionLine: '.blueprints--editor--direction-line',
    $editor: '.blueprints--editor',
    $viewport: '.blueprints--viewport',
    $scene: '.blueprints--scene',
    $selectedShape: '.blueprints--shape.selected',
    $selectedGroup: '.blueprints--group.selected',
    $grid: '.blueprints--grid',
    $gridPattern: '#blueprints--grid--pattern'
  },

  events: {
    'click [select-color]': 'replaceSelectedColor',
    'pointerdown .blueprints--shape--bounding-box': 'handleSelectShape',
    'pointerdown .blueprints--group--bounding-box': 'handleSelectGroup',
    'click [update-rotation]': 'updateRotation',
    'click [change-color]': 'updateColor',
    'click [add-shape]': 'addShape',
    'click [remove-shape]': 'removeShape',
    'click [animate-mode]': 'activateAnimationMode',
    'click [build-mode]': 'activateBuildMode',
    'click [toggle-animation]': 'toggleAnimation',
    'click [toggle-float]': 'toggleFloat',
    'click [toggle-locked]': 'toggleLocked',
    'click [toggle-bounce]': 'toggleBounce',
    'click [toggle-jump]': 'toggleJump',
    'click [change-direction]': 'changeDirection',
    'click [save-changes]': 'saveChanges',
    'pointermove': 'moveShape',
    // 'pointerup': 'releaseShape', // do this at window level
    'pointerup .blueprints--editor': 'captureEvent'
  },

  async initialize() {
    Backbone.View.prototype.initialize.apply(this, arguments)
    bindSelectors(this)

    this.user = Registry.get('student')
    this.cacheKey = `_blueprint${this.screen.id}`

    // restore, if possible
    this.restoreContent()

    // handle releasing a shape
    window.addEventListener('pointerup', this.clearSelection.bind(this))
    window.addEventListener('resize', this.updateViewport.bind(this))

    // displayed shapes on the canvas
    this.shapes = { }

    // update grids and view areas
    if (!this.delay) {
      await nextFrame()
      this.resetView()
    }
  },

  resetView() {
    this.updateViewport()

    // build the initial view
    const content = this.getContent()
    this.build(content)
  },

  // update grids and viewports
  updateViewport() {
    const { $canvas, $viewport, $grid } = this.selectors
    const buildMode = this.getBuildMode()
    const bounds = $canvas[0].getBoundingClientRect()
    const aspect = bounds.height / bounds.width
    const width = BASE_SIZE / 100
    const height = aspect * width
    const x = width * -0.5
    const y = height * -0.5

    // calculate grid snapping
    const snap = (width / 10) * (buildMode === 'advanced' ? ADVANCED_GRID : BASIC_GRID)
    this.screenToCanvasRatio = width / bounds.width

    // update the main view
    $viewport.attr({
      'viewBox': `${x} ${y} ${width} ${height}`
    })

    // update the grid
    $grid.attr({ x, y, width, height })

    // save the ratio for later
    this.updateGrid(snap)
  },

  clear() {
    const { $scene } = this.selectors
    $scene.html('')
    this.shapes = { }
  },

  // test animations
  startAnimatedScene() {
    const { $root } = this.selectors
    this.clear()
    const content = this.getContent()
    this.build(content)

    // notify of animations
    $root.addClass('is-animating')
    this.hideEditor()

    // kick off an animation simulation
    this.animation = new BlueprintAnimator(this)
    this.animation.start()
  },

  stopAnimatedScene() {
    if (!this.animation) {
      return
    }

    // stop the animation
    this.animation?.stop()
    delete this.animation

    // remove animation flags
    const { $root } = this.selectors
    $root.removeClass('is-animating')

    // rebuild
    this.clear()
    const content = this.getContent()
    this.build(content)
  },

  captureEvent(event) {
    event.stopPropagation?.()
    event.preventDefault?.()
  },

  toggleAnimation() {
    if (this.animation) {
      this.stopAnimatedScene()
      return
    }

    // if in the animation mode
    const editorMode = this.getEditorMode()
    if (editorMode !== 'animate') {
      this.setEditMode('animate')
    }

    this.startAnimatedScene()
  },

  activateBuildMode() {
    this.setEditMode('build')
  },

  activateAnimationMode() {
    this.setEditMode('animate')
  },

  async setEditMode(mode) {
    const { $modes, $root } = this.selectors
    this.editorMode = mode

    // reset
    this.clear()
    const content = this.getContent()
    this.build(content)

    // update the toggle
    $modes.removeClass('active')
    $(`[${mode}-mode]`).addClass('active')

    // update the mode
    $root.removeClass('build-mode', 'animate-mode')
    $root.addClass(`${mode}-mode`)

    // handle switching between views
    this.stopAnimatedScene()
  },

  getEditorMode() {
    return this.editorMode === 'animate' ? 'animate' : 'build'
  },

  getBuildMode() {
    return this.buildMode === 'advanced' ? 'advanced' : 'basic'
  },

  restoreContent() {
    this.content = restoreContent(this.cacheKey, this.user, this.userLessonScreens, this.screen)
  },

  getContent() {
    if (!this.content) {
      this.restoreContent()
    }

    return this.content
  },

  saveContent(content = this.content) {
    this.content = content
    const data = JSON.stringify(content)
    this.user.set(this.cacheKey, data)
  },

  saveChanges() {
    this.trigger('complete')
  },

  getSelectedShape() {
    const content = this.getContent()
    const { selectedShapeID } = this
    return content.shapes.find(({ id }) => id === selectedShapeID)
  },

  getSelectedGroup() {
    const content = this.getContent()
    const { selectedGroupID } = this
    let group = content.groups.find(({ id }) => id === selectedGroupID)

    // if this doesn't exist, create it
    if (!group) {
      group = { id: selectedGroupID }
      content.groups.push(group)
    }

    return group
  },

  setPaletteColor(color) {
    const { $editor } = this.selectors
    $editor.find('[change-color]').removeClass('selected')
    $editor.find(`[data-color="${color}"]`).addClass('selected')
  },

  serialize() {
    const buildMode = this.getBuildMode()
    const basic = buildMode === 'basic'
    const userActivity = Registry.get('userActivity')
    const totalScreens = userActivity.getOrAdd(0).get('screens')

    return {
      shapes: SHAPES,
      colors: COLORS,
      selectedColor: 0,
      basic,
      ads: AdView.canShowAds() && totalScreens > 0,
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens })
    }
  },

  refreshGroupEditorProperties() {
    const { $direction, $directionArrow, $directionLine, $directionRotation } = this.selectors
    const group = this.getSelectedGroup()

    // update the direction picker
    const speed = group.speed ?? 0
    const dir = group.dir ?? 0
    const range = $direction.width() >> 1
    const distance = ((speed / 3) * 0.275) * range
    $directionLine.attr('y2', `-${distance + 2}`)
    $directionArrow.attr('transform', `translate(0, -${distance})`)
    $directionRotation.attr('transform', `rotate(${(dir + Math.PI * 0.5) * 180 / Math.PI})`)

    // set toggle states
    $('[toggle-float]').toggleClass('active', !!group.float)
    $('[toggle-bounce]').toggleClass('active', !!group.bounce)
    $('[toggle-locked]').toggleClass('active', !!group.locked)
    $('[toggle-jump]').toggleClass('active', !!group.jump)
  },

  async showGroupEditor() {
    const { $editor } = this.selectors
    $editor.removeClass('for-shapes')
    $editor.addClass('for-groups')

    // populate selections
    this.refreshGroupEditorProperties()

    // show the editor
    await nextFrame()

    // display the editor
    const { selectedGroupID } = this
    const group = this.groups.find(find => find.id === selectedGroupID)

    // if there's a group, show it -- a group could be
    // missing if a merge takes place in the animation
    // editing mode
    if (!!group) {
      this.showEditor(group.x < 0)
    }
  },

  // update the parameters
  refreshShapeEditorProperties() {
    const shape = this.getSelectedShape()

    // update the color
    this.setPaletteColor(shape.color)
  },

  async showShapeEditor() {
    const { $editor } = this.selectors
    $editor.removeClass('for-groups')
    $editor.addClass('for-shapes')

    // update the parameters
    const shape = this.getSelectedShape()
    this.refreshShapeEditorProperties()

    // show the editor
    await nextFrame()

    // toggle protected states
    $editor[shape.locked ? 'addClass' : 'removeClass']('protected')
    this.showEditor(shape.x < 0)
  },

  showEditor(onRight) {
    const { $editor } = this.selectors

    // determine the position for the editor
    $editor[onRight ? 'addClass' : 'removeClass']('on-right')
    $editor.show()
  },

  hideEditor() {
    const { $editor } = this.selectors
    $editor.hide()
  },

  beginPointerInteraction(event, data) {
    const source = event.touches?.[0] ?? event
    this.activeInteraction = {
      ...data,
      x: source.pageX,
      y: source.pageY
    }
  },

  getPointerInteractionDiff(event) {
    const { snap } = this

    // update coordinates
    const { screenToCanvasRatio, activeInteraction } = this
    const source = event.touches?.[0] ?? event
    let x = (source.pageX - activeInteraction.x) * screenToCanvasRatio
    let y = (source.pageY - activeInteraction.y) * screenToCanvasRatio

    // apply snapping
    // TODO: snapping is applied to the transform and not the final position it might be a good idea to include a snap when releasing the shape
    x = Math.round(x / snap) * snap
    y = Math.round(y / snap) * snap

    return { x, y }
  },

  updateGrid(snap) {
    this.snap = snap

    // update the DOM
    const { $gridPattern } = this.selectors
    $gridPattern.attr({ width: snap, height: snap })
      .find('circle')
      .each((i, dot) => {
        dot.setAttribute('cx', (0 | dot.getAttribute('cx')) * snap)
        dot.setAttribute('cy', (0 | dot.getAttribute('cy')) * snap)
      })
  },

  updateGroup(update) {
    const group = this.getSelectedGroup()
    const instance = this.groups.find(({ id }) => id === group.id)

    update(group)
    this.saveContent()

    // update the data
    Object.assign(instance.data, group)

    // update the view
    this.refreshGroupEditorProperties()
  },

  updateShape(update) {
    const content = this.getContent()
    const shape = this.getSelectedShape()
    shape.changed = true

    // make sure attributes are assigned
    shape.attrs = shape.attrs || { }

    // apply the update
    update(shape)

    // update after change
    this.saveContent(content)
    this.refreshShapeEditorProperties()

    // rebuild as needed
    this.build(content)
  },

  // handles using the 'movement/direction' control and calculates the
  // distance and angle from the center to set both values
  changeDirection(event, $el = $(event.target).closest('[change-direction]')) {
    const bounds = $el[0].getBoundingClientRect()
    const ox = (bounds.right + bounds.left) * 0.5
    const oy = (bounds.bottom + bounds.top) * 0.5
    const cx = event.pageX - window.scrollX
    const cy = event.pageY - window.scrollY
    const dir = Math.atan2(cy - oy, cx - ox)
    const dist = Math.hypot(ox - cx, oy - cy)

    // calculate the speed
    // stay within 0-3
    let speed = dist / ((bounds.right - bounds.left) * 0.45)
    speed = Math.min(0.99, Math.max(0, speed))
    speed = speed * 4

    // update the group params
    this.updateGroup(group => {
      group.dir = dir
      group.speed = speed
    })
  },

  toggleFloat() {
    this.updateGroup(group => {
      group.float = !group.float
    })
  },

  toggleBounce() {
    this.updateGroup(group => {
      group.bounce = !group.bounce
    })
  },

  toggleJump() {
    this.updateGroup(group => {
      group.jump = !group.jump
    })
  },

  toggleLocked() {
    this.updateGroup(group => {
      group.locked = !group.locked
    })
  },

  updateRotation(event) {
    const target = $(event.target).closest('[update-rotation]')
    const dir = target.is('.clockwise') ? 1 : -1
    const range = 4 // increments of 90
    this.updateShape(shape => {
      shape.attrs.rotate = (((shape.attrs.rotate || 0) + dir + range + (range >> 1)) % range) - (range >> 1)
    })
  },

  updateColor(event, $el = $(event.target)) {
    const value = 0 | $el.data('color')

    this.setPaletteColor(value)
    this.updateShape(shape => {
      shape.color = value
    })
  },

  reorderLayer(id, operation) {
    const { $scene } = this.selectors
    const content = this.getContent()

    // update
    content.shapes = reorderShapes(id, content.shapes, operation)
    this.saveContent(content)

    // reorder elements
    content.shapes.forEach(shape => {
      const svg = this.shapes[shape.id]
      $scene.append(svg.el)
    })
  },

  removeShape() {
    const { $selectedShape } = this.selectors

    // hide the editing popup
    this.hideEditor()

    // remove from the document
    const content = this.getContent()
    content.shapes = content.shapes.filter(item => item.id !== this.selectedShapeID)
    this.saveContent(content)

    // clear the ID
    delete this.shapes[this.selectedShapeID]
    delete this.selectedShapeID

    // delete the shape -- no need to rebuild
    // in this case
    $selectedShape.remove()
  },

  async addShape(event, $el = $(event.target).closest('[data-type]')) {
    // switch modes
    this.setEditMode('build')

    const type = $el.data('type')
    const id = nextID()
    const shape = { id, type, x: 0, y: 0, color: 0 }

    // add the shape
    const content = this.getContent()
    content.shapes.push(shape)
    this.saveContent(content)

    // rebuild
    this.build(content)

    // select the new shape
    await nextFrame()
    this.selectShape(id)
  },

  clearSelection(event) {
    const mode = this.getEditorMode()
    if (mode === 'animate') {
      this.releaseGroup(event)
    }
    else {
      this.releaseShape(event)
    }
  },

  // clears a shape
  releaseGroup(event) {

    // has something being worked with
    if (this.activeInteraction) {
      const { x, y } = this.getPointerInteractionDiff(event)
      const diff = Math.abs(x) + Math.abs(y) > 0

      // restore the editor
      this.showGroupEditor()

      // make sure there's a diff
      if (diff) {
        // this will apply to each inner shape
        const { selectedGroupID } = this
        const instance = this.groups.find(find => find.id === selectedGroupID)

        // update each position
        const content = this.getContent()
        instance.data.ids.forEach(id => {
          const shape = content.shapes.find(find => find.id === id)
          shape.x += x
          shape.y += y
        })

        // save the change
        this.saveContent()

        // clear the translate and rebuild
        this.clear()
        this.build(content)
      }
    }
    // clear selection
    else {
      this.selectors.$selectedGroup.removeClass('selected')
      this.hideEditor()
    }

    delete this.activeInteraction
  },

  // will let go of a shape, selecting it and ending a transform, if possible
  releaseShape(event) {

    // has something being worked with
    if (this.activeInteraction) {
      const { x, y } = this.getPointerInteractionDiff(event)
      const diff = Math.abs(x) + Math.abs(y) > 0

      // restore the editor
      this.showShapeEditor()

      // make sure there's a diff
      if (diff) {
        this.updateShape(shape => {
          shape.x += x
          shape.y += y
        })
      }
    }
    // clear selection
    else {
      this.selectors.$selectedShape.removeClass('selected')
      this.hideEditor()
    }

    delete this.activeInteraction
  },

  // handles moving a shape temporarily (mouse interactions)
  moveShape(event) {
    if (!this.activeInteraction) {
      return
    }

    // when moving a shape, hide the editor
    this.hideEditor()

    // check the diff and apply
    const { x, y } = this.getPointerInteractionDiff(event)
    $(`#translate--${this.activeInteraction.id}`).attr({
      'transform': `translate(${x}, ${y})`
    })
  },

  handleSelectGroup(event, $el = $(event.target).closest('.blueprints--group')) {
    const id = $el.data('id')

    // save the shape being interacted with
    if (!this.animation) {
      this.beginPointerInteraction(event, { id })
    }

    this.selectGroup(id)
  },

  // handles the initial selection of a shape
  handleSelectShape(event, $el = $(event.target).closest('[building-block]')) {
    const mode = this.getEditorMode()
    if (mode === 'animate') {
      return this.handleSelectGroup(event)
    }

    // select the targeted shape
    const id = $el.data('id')
    this.selectShape(id)

    // save the shape being interacted with
    this.beginPointerInteraction(event, { id })
  },

  async selectGroup(id) {
    this.selectedGroupID = id
    const { $scene } = this.selectors

    // update the selection
    $scene.find('.blueprints--group').removeClass('selected')
    $scene.find(`.blueprints--group[data-id="${id}"]`).addClass('selected')

    // when animating, just stop
    if (this.animation) {
      this.stopAnimatedScene()
      return
    }

    this.showGroupEditor()
  },

  // directly selects a shape (used for interactions and creating new shapes)
  async selectShape(id) {
    this.selectedShapeID = id
    const { $scene } = this.selectors

    // update the selection
    $scene.find('[building-block]').removeClass('selected')
    $scene.find(`[building-block][data-id="${id}"]`).addClass('selected')

    // display the edit controls
    this.showShapeEditor()

    // selecting layers moves it to the top
    this.reorderLayer(id, 'top')
  },

  // modify a color
  replaceSelectedColor(event, $el = $(event.target)) {
    const { $tools } = this.selectors
    const color = $el.data('value')

    // update the tools state
    $tools.css({ ['--selected-color']: color })
    $tools.find('[select-color]').removeClass('selected')
    $tools.find(`[data-value="${color}"]`).addClass('selected')
  },

  // rebuilds the screen using a scene
  async build(scene) {
    const { shapes } = scene
    const { $scene } = this.selectors
    const editorMode = this.getEditorMode()

    // reset animations
    $scene.addClass('stop-all-animations')

    // when in animation mode, the scene will use groups
    if (editorMode === 'animate') {
      const groups = createVisualGroups(scene)
      this.groups = groups.map(group => {
        const instance = new BlueprintGroup(group)
        $scene.append(instance.el)
        return instance
      })
    }
    else {
      delete this.groups
    }

    // add each shape
    for (const data of shapes) {
      let shape = this.shapes[data.id]
      let update = !!data.changed

      // clear any update flags
      delete data.changed

      // make the new shape
      if (!shape) {
        shape = new BlueprintShape(data)
        this.shapes[data.id] = shape

        // check if appending to a group
        const group = this.groups?.find(item => item.containsShape(data.id))

        // if using groups, reorient as needed
        if (group) {
          group.append(shape)
        }
        // using the scene only
        else {
          $scene.append(shape.el)
        }

        // it's new, so it requires an update
        update = true
      }

      // generate
      if (update) {
        shape.update()
      }
    }

    // enable animations again
    // await new Promise(resolve => setTimeout(resolve, 10))
    await nextFrame()
    $scene.removeClass('stop-all-animations')
  },

  render() {
    Backbone.View.prototype.render.call(this)
  }

})
