import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import Confirm from '../../global/views/confirm'
import { getIntroAIHtml } from '../../utils/problem_keys_lesson';
import { lessons_problem_keys } from '@templates/student'

export default Backbone.View.extend({

  events: {
    'click .js-start-btn': 'setIntroState',
    'click .js-clear-all': 'clearAll'
  },

  template: lessons_problem_keys,

  className: 'dashboard-listInner hide',

  serialize: function() {
    var keys = Registry.get('userLettersTyped').topProblemKeys(10),
      user = Registry.get('student'),
      minPercent = keys.reduce((memo, row) => Math.min(memo, row.percent), 100),
      maxPercent = keys.reduce((memo, row) => Math.max(memo, row.percent), 0);

    const filteredKeys = keys.filter((val) => val.percent > 0)

    return {
      typeAIText: getIntroAIHtml(),
      isPremium: user.isPremium(),
      problemKeysHasAIEnabled: user.hasOption('problemkeysai'),
      isZeroState: filteredKeys.length < 2,
      maxPercent: Math.floor(maxPercent * 100),
      minPercent: Math.floor(minPercent * 100),
      keys: filteredKeys
    };
  },

  setIntroState: function() {
    const userLessons = Registry.get('userLessons'),
      userLesson = userLessons.get(999999) || null;

    userLesson && userLesson.set({ _state: 'intro' });
  },

  clearAll: function() {
    if(Registry.get('userLettersTyped').topProblemKeys(1).length === 0) {
      return false;
    }

    (new Confirm({
      title: 'lessons.clear_problem_keys_title'.t(),
      text: 'lessons.clear_problem_keys_body'.t()
    })).on('ok', function(){
      Registry.get('userLettersTyped').clear();
      this.render();
    }.bind(this));
    return false;
  }

})
