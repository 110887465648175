import Backbone from 'backbone'
import moment from 'moment'

const Model = Backbone.Model.extend({

  idAttribute: 'hour',

  defaults: {
    hour: 0,
    typed: 0,
    seconds: 0,
    errors: 0,
    screens: 0,
    stars: 0
  }
})

export default Backbone.Collection.extend({
  model: Model,

  comparator: 'hour',

  getOrAdd: function(hour) {
    var activity = this.get(hour);
    if(!activity) {
      activity = this.add({hour: hour});
    }
    return activity;
  },

  /**
   * Merge activity data into this collection
   * @param data
   */
  merge: function(data) {
    data.hour = data.created_at - (data.created_at % 3600);
    data.screens = 1;

    var old = this.get(data.hour);
    if(old) {
      old.inc({
        typed: data.typed,
        seconds: data.seconds,
        errors: data.errors,
        screens: data.screens,
        stars: data.stars
      });
    } else {
      this.add(data);
    }

    data.hour = 0;
    old = this.get(0);
    if(old) {
      old.inc({
        typed: data.typed,
        seconds: data.seconds,
        errors: data.errors,
        screens: data.screens,
        stars: data.stars
      });
    } else {
      this.add(data);
    }
  },

  getCompiledForRange: function(startDate, endDate) {
    return this.toJSON().reduce(function(val, row) {
      if (row.hour >= startDate && row.hour <= endDate) {
        val.typed += parseInt(row.typed);
        val.seconds += parseInt(row.seconds);
        val.errors += parseInt(row.errors);
        val.screens += parseInt(row.screens);
        val.stars += parseInt(row.stars);
      }
      return val;
    }, {typed:0, seconds:0, errors:0, screens:0, stars: 0});
  },

  getCompiledForThisWeek: function() {
    const startOfWeek = moment().startOf('week').unix();
    const endOfWeek = moment().endOf('week').unix();
    return this.getCompiledForRange(startOfWeek, endOfWeek);
  },

  getCompiledForLastWeek: function() {
    const startOfLastWeek = moment().subtract(1, 'week').startOf('week').unix();
    const endOfLastWeek = moment().subtract(1, 'week').endOf('week').unix();
    return this.getCompiledForRange(startOfLastWeek, endOfLastWeek);
  },

  getCompiled: function(daysBack) {
    if(daysBack === 0) {
      var data = this.get(0);
      if(data) {
        return data.toJSON();
      }
      return {typed:0, seconds:0, errors:0, screens:0, stars: 0};
    }
    var startOfToday = moment().startOf('day').format('x') / 1000;
    var startDate = startOfToday - 60*60*24*(daysBack-1);
    var endDate = daysBack !== 2 ? moment().toDate() / 1000 : startOfToday;
    return this.getCompiledForRange(startDate, endDate);
  },

  getCompiledData: function(range) {
    if (range === 'this_week') {
      return this.getCompiledForThisWeek();
    } else if (range === 'last_week') {
      return this.getCompiledForLastWeek();
    } else {
      return this.getCompiled(parseInt(range));
    }
  },
})
