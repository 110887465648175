import Backbone from 'backbone'

export default Backbone.Model.extend({

  idAttribute: 'lesson_screen_id',

  defaults: {
    lesson_id: 0,
    lesson_screen_id: 0,
    seconds: 0,
    errors: 0,
    typed: 0,
    stars: 0,
    created_at: 0,
    state: ''
  }
})
