import Modal from './modal'
import Model from '@shared/confirm_with_input_form'
import { extend } from 'lodash-es';
import { global_modal } from '@templates/student'
import { shared_confirm_with_input } from '@templates/shared'

export default Modal.extend({
  modalBaseTemplate: global_modal,
  template: shared_confirm_with_input,

  modelClass: Model,

  description: '',

  confirmWithInput: true,

  serialize: function() {
    return extend(Modal.prototype.serialize.apply(this, arguments), {
      description: this.description
    });
  },

  submitCallback: function(data) {
    if(typeof this.successCallback === 'function') {
      // A successCallback function will return a promise and keep the modal open until
      // the promise is resolved.
      this.$('.js-submit').addClass('btn--loading');
      this.successCallback().always(function () {
        Modal.prototype.submitCallback.apply(this, arguments);
      }.bind(this));
    } else {
      Modal.prototype.submitCallback.apply(this, arguments);
    }
  }
})
