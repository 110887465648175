import Backbone from 'backbone'
import MainLogin from './main'
import Model from '../models/password'
import { login_login_basic } from '@templates/student'
import { extend } from 'lodash-es';

export default MainLogin.extend({

  template: login_login_basic,

  modelClass: Model,

  formSelector: 'form',

  initialize: function() {
    let events = this.events || {};

    this.delegateEvents(
      extend(events, {
        'click .js-switch': function() { this.trigger('switch'); return false }
      })
    );

    Backbone.View.prototype.initialize.apply(this, arguments);
  },

  serialize: function() {
    return {
      isAssessment: this.isAssessment
    }
  },

  handleSubmitButton: function() {
    this.buttonClick();

    return false;
  },

  errorCallback: function(data) {
    this.handleError(data)
  },

  submittingCallback: function(data) {
    this.handleSubmitting(data)
  },

  submitCallback: function(data) {
    // On modals, this button becomes clickable after form submission and we don't want that
    this.$('.js-submit').addClass('btn--loading').prop('disabled', true)

    window.localStorage.setItem('_oneTimeRedirect', window.location.href.replace(/\/typing-test\/.*/, '/tests'))
    if(window.location.pathname.match(/student\/upgrade/)) {
      window.localStorage.setItem('_forceUpgrade', 1)
    }

    this.login(data)
  },

  handleTab: function(e) {
    // Override the parent functionality and DO NOTHING
  }

})
