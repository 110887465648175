import Backbone from 'backbone'
import $ from 'jquery'
import Input from '@shared/keyboard_input'
import LayoutView from '../global/views/layout'
import { debug_keyinfo, debug_keyinfo_detail } from '@templates/student'

export default Backbone.View.extend({

  layout: LayoutView,

  template: debug_keyinfo,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.input = new Input();
    this.listenTo(this.input, 'keypress', this.renderDetail);
    this.listenTo(this.input, 'keydown', this.renderDetail);
    this.listenTo(this.input, 'keyup', this.renderDetail);
    this.listenTo(this.input, 'character', this.renderDetail);
    this.listenTo(this.input, 'input', this.renderDetail);
    this.listenTo(this.input, 'capslock', this.renderCapslock);

    this.detailTmpl = debug_keyinfo_detail;

    this.render();

    var ele = this.$('.js-input-box');
    this.input.initialize({boundElement: ele});
  },

  serialize: function() {
    return {
      softwareKeyboard: $.showSoftwareKeyboard()
    };
  },

  renderDetail: function(e) {
    console.log(e.type, e);
    var container = this.$('.js-' + e.type);
    container.html(this.detailTmpl({
      event: e,
      key: this.formatKey(e.key),
      data: this.formatKey(e.data),
      targetValue: (e.target.value) ? this.formatKey(e.target.value.substr(-1)) : ''
    }));

    if(e.type === 'character') {
      var output = this.$('.js-output-box')[0],
        raw = this.$('.js-raw-output-box')[0];
      raw.value += this.formatKey(e.key);

      if(e.key === 'Backspace') {
        output.value = output.value.substr(0, output.value.length-1);
      } else if(!e.dead && !e.special) {
        output.value += this.formatKey(e.key);
      }
    }
  },


  formatKey: function(key){
    switch(key){
    case '\n':
      return '⏎';
    case '\t':
      return '↹';
    case ' ':
      return '␣';
    case 'Backspace':
      return '⌫';
    }
    return key;
  },

  renderCapslock: function(status) {
    if(status) {
      this.$('.js-capslock-warning').show();
    } else {
      this.$('.js-capslock-warning').hide();
    }
  }

})
