import Backbone from 'backbone'
import { upgrade_complete } from '@templates/student'
import moment from 'moment'

export default Backbone.View.extend({

  template: upgrade_complete,

  purchaseData: {price_paid: 0, expiration: 0,  nextCharge: 0},

  serialize: function() {
    return {
      ...this.purchaseData,
      nextCharge: moment(this.purchaseData.expiration*1000).format('LL'),
      accountPageLink: __url('/student/account')
    };
  }
})
