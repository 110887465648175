import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import Scoring from '@shared/scoring'
import AdView from '../../global/views/ad'
import LevelUpView from '../../global/views/level_up'
import GoalView from '../../global/views/goal'
import GoalAchievedView from '../../global/views/goal_achieved'
import SkinsCollection from '../../global/collections/skins'
import ProgressBarView from '../../global/views/progress_bar'
import CelebrationEffect from '@shared/celebration_effect'
import { playSound } from '@shared/helpers'
import { find, times, map } from 'lodash-es'
import { lesson_screen_complete } from '@templates/student'

export default Backbone.View.extend({

  saved: false,

  template: lesson_screen_complete,

  events: {
    'click .js-continue-button': 'continue',
    'click .js-restart': 'restart',
    'click .js-try-again': 'saveTryAgain',
    'click .js-start-over': 'startLessonOver'
  },

  congratsText: [
    'lesson.congrats_text.great'.t(),
    'lesson.congrats_text.excellent'.t(),
    'lesson.congrats_text.super'.t(),
    'lesson.congrats_text.keep_it_up'.t(),
    'lesson.congrats_text.doing_great'.t(),
    'lesson.congrats_text.doing_awesome'.t(),
    'lesson.congrats_text.awesome_job'.t(),
    'lesson.congrats_text.awesome_typing'.t(),
    'lesson.congrats_text.sweet'.t()
  ],

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);
    this.user = Registry.get('student');
    this.userActivity = Registry.get('userActivity');
    this.skins = new SkinsCollection(FTWGLOBALS('skins'));
    this.skin = this.skins.get(this.user.get('skin_id') || 1);

    if(this.progressView) {
      this.addChild('.js-progress', this.progressView, true);
    }

    if(!find(FTWGLOBALS('skins'), {id: this.user.get('skin_id') || 1}).noAvatar) {
      new LevelUpView()
    }

    this.initializeGoal()
  },

  serialize: function() {
    var lessonAccuracy = 0,
      lessonSpeed = 0,
      lessonTime = 0,
      lessonData = this.userLesson.toJSON(),
      userScreen = this.userLessonScreens.get(this.screen.id),
      skin = this.skins.get(this.user.get('skin_id') || 1),
      activity = this.userActivity.getOrAdd(0).toJSON();

    this.rank = skin.getRank(activity.typed - activity.errors);
    this.nextRank = skin.getNextRank(activity.typed - activity.errors);

    const prior = (userScreen?.get('typed') - userScreen?.get('errors')) || 0;
    this.rankXPOld = (activity.typed - activity.errors) - prior;
    this.rankXPTotal = activity.typed - activity.errors;
    this.rankProgressOld = (this.nextRank) ? (this.rankXPOld - this.rank.experience)/(this.nextRank.experience - this.rank.experience)*100 : 100;
    this.rankProgressTotal = (this.nextRank) ? (this.rankXPTotal - this.rank.experience)/(this.nextRank.experience - this.rank.experience)*100 : 100;

    if (lessonData.typed > 0) {
      lessonAccuracy = Scoring.accuracy(lessonData.typed, lessonData.errors)
      lessonSpeed = Scoring.speed(lessonData.typed, lessonData.seconds, lessonData.errors)
      lessonTime = Math.floor(lessonData.seconds || 0)
    }

    let accuracy = Scoring.accuracy(userScreen.get('typed'), userScreen.get('errors')),
      stars = userScreen.get('stars'),
      speed = Scoring.speed(userScreen.get('typed'), userScreen.get('seconds'), userScreen.get('errors')),
      lastTry = userScreen.get('lastTry'),
      lastTrySpeed = 0,
      lastTryAccuracy = 0;

    if(lastTry) {
      delete lastTry.lastTry; // it gets into an infinite chain of lastTry's if we don't remove it here
      // lastTry goodies are to show their improvement if they redo a screen
      lastTrySpeed = Scoring.speed(lastTry.typed,lastTry.seconds, lastTry.geterrors);
      lastTryAccuracy = Scoring.accuracy(lastTry.typed, lastTry.errors);
    }

    this.updateGoal(userScreen.get('seconds'))

    return {
      accuracy: accuracy,
      ads: AdView.canShowAds(),
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: activity.screens }),
      congratsText: this.congratsText[Math.floor(Math.random()*this.congratsText.length)],
      fasterThanLastTry: Math.floor(speed) > Math.floor(lastTrySpeed),
      lastTry: lastTry,
      lastTryAccuracy: lastTryAccuracy,
      lastTrySpeed: lastTrySpeed,
      lesson: this.lesson.toJSON(),
      lessonAccuracy: lessonAccuracy,
      lessonSpeed: lessonSpeed,
      lessonTime: lessonTime.countdownSeconds(),
      loggedIn: Registry.get('loggedIn'),
      moreAccurateThanLastTry: Math.floor(accuracy) > Math.floor(lastTryAccuracy),
      nextRank: this.nextRank,
      paths: this.screen.get('paths'),  // adventure stories
      problemKeys: map(this.lettersTyped.topProblemKeys(3), 'id'),
      problemKeysLesson: this.problemKeysLesson,
      rank: this.rank,
      renderInline: this.renderInline,
      saved: this.saved,
      screen: this.screen.toJSON(),
      userScreen: userScreen.toJSON(),
      screenType: this.screenType,
      showGuestBanner: !Registry.get('loggedIn') && this.screen.get('screen_type') !== 'keyboard-jump',
      showGoal: !!this.goalView,
      skin: this.skin.toJSON(),
      skins: this.skins.toJSON(),
      speed: speed,
      stars: stars,
      unlockedScreens: Registry.get('student').hasOption('unlockedscreens'),
      variantId: this.user.get('variant_id'),
      productGrade: this.user.getCurriculumGrade(),
      showButtons: !this.hasGoogleClassroomAddon,
    };
  },

  render: function() {
    Registry.set('preventKeyboardInput', false);
    this.input.start($('body')); // on screen completes we don't want the input element focusing, so they can click other stuff

    Backbone.View.prototype.render.apply(this, arguments);

    this.listenTo(this.input, 'character', this.handleKeyPress);

    const animationsFn = () => {
      var data = this.serialize();

      var user = Registry.get('student'),
        timeBetweenStars = 850,
        playSounds = user.hasOption('allowsounds') &&
          (user.getSetting('error_sounds') || user.getSetting('typing_sounds')),
        showConfetti = this.screenType !== 'coding' && this.lesson.get('content_type') !== 'adventure' && data.stars >= 3;

      // play the stars/screen-completion sound if they have any sounds enabled
      var star1,
        star2,
        star3;
      try {
        star1 = new Audio('/dist/student/extras/sounds/star1.mp3');
        star1.volume = 0.3;
        star2 = new Audio('/dist/student/extras/sounds/star2.mp3');
        star2.volume = 0.3;
        star3 = new Audio('/dist/student/extras/sounds/star3.mp3');
        star3.volume = 0.3;
      } catch (e) {}

      if (showConfetti) { // TODO: Move creation of canvas into celebration library
        $('body').
          append(
            '<canvas class="celebration js-celebration-effect" style="z-index: 11;"></canvas>');
      }

      if (data.stars > 0) {
        window.requestTimeout(() => { // Wait for screen to render before attempting to add confetti or display stars
          if (showConfetti) {
            var celebrationEle = $('.js-celebration-effect');
            celebrationEle.css({
              'top': -celebrationEle.offset().top,
              'left': -celebrationEle.offset().left
            });
            CelebrationEffect.init(celebrationEle[0], {
              screenHeight: $(document).height(),
              screenWidth: $(document).width(),
              colors: this.user.getSkinColors(),
              assets: [
                '/dist/shared/images/particles/star-1.png',
                '/dist/shared/images/particles/star-2.png',
                '/dist/shared/images/particles/star-3.png']
            });
          }

          // Play SFX and show Confetti as stars burst into existence
          if (playSounds || showConfetti) {
            times(data.stars, (index) => { // Loop through the number of stars earned by the user
              window.requestTimeout(() => {
                var starEle = this.$('.star--screen-' + (index + 1));
                starEle.addClass('star--floating');
                if (playSounds && eval('star' + (index + 1))) {
                  playSound(eval('star' + (index + 1)));
                }
                if (showConfetti) {
                  CelebrationEffect.explodeSingle({
                    type: 'custom',
                    x: starEle.offset().left + (starEle.width() / 2),
                    y: starEle.offset().top + (starEle.height() / 2),
                    count: 20,
                    angle: 230 + (index * 40),
                    spread: 85,
                    speed: 25
                  });
                }
              }, index * timeBetweenStars);
            })
          }
        }, 0);
      }

      data.accuracy = Scoring.accuracy(data.screen.typed, data.screen.errors);
      data.speed = Scoring.speed(data.screen.typed, data.screen.seconds,
        data.screen.errors);
      data.seconds = data.screen.seconds;
      this.dictation.setProperties(this.screen, null);
      // Speak whatever is needed for the current screen complete
      this.speakScreenComplete(data)

      // The progress bar needs to be rendered after the student finishes typing, which is on render of this screen
      if (this.$('.js-progress-bar').length) {
        var progressBarView = new ProgressBarView({
          user: this.user,
          rank: this.rank,
          totalRanks: this.skin.getTotalRanks(),
          nextRank: this.nextRank,
          rankXPOld: this.rankXPOld,
          rankXPTotal: this.rankXPTotal,
          rankProgressOld: this.rankProgressOld,
          rankProgressTotal: this.rankProgressTotal
        });
        this.$('.js-progress-bar').append(progressBarView.render().el);
      }
    }

    // Delay playing screen complete animations if the goal achieved is displayed
    if(this.goalAchieved) {
      this.goalAchieved.done(() => {
        animationsFn()
      })
    } else {
      animationsFn()
    }

    return this;
  },

  continue: function(e) {
    if(!this.saved) {
      return false;
    }

    this.trigger('continue', 'complete', $(e.currentTarget));
    return false;
  },

  restart: function() {
    if(this.saved) {
      this.userLesson.inc({
        progress: -1
      });
    }

    this.trigger('continue', 'complete_restart', this.$('.js-restart'));

    return false;
  },

  handleKeyPress: function(character) {
    if (character.keyCode === 13) {
      this.$('.js-continue-button:visible').click();
    }
  },

  /**
   * Speaks dictation text for screen complete
   * Override to speak something unique for each screen complete type
   * @param data
   */
  speakScreenComplete(data) {
    if (this.screenType === 'test') {
      this.dictation.speakTestComplete(data);
    } else {
      this.dictation.speakScreenComplete(data);
    }
  },

  /**
   * If the lesson successfully saved, this will show the Continue button
   */
  showSaved: function(){
    this.saved = true;
    this.userLesson.set({saved: true});
    this.$('.js-saving-spinner').fastHide();
    this.$('.js-saving-failed').fastHide();
    this.$('.js-continue').fastShow();
  },

  /**
   * If the lesson failed to save, show a message and a link to try again
   */
  showSaveError: function() {
    this.$('.js-saving-spinner').fastHide();
    this.$('.js-saving-failed').fastShow();
  },

  /**
   * This calls when they click the try again link
   */
  saveTryAgain: function() {
    this.$('.js-saving-failed').fastHide();
    this.$('.js-saving-spinner').fastShow();

    this.trigger('try-again');
    return false;
  },

  startLessonOver: function() {
    this.userLesson.set({progress: 0, _state: null, restart: true});
    this.trigger('continue', 'complete', this.$('.js-start-over'));
    return false;
  },

  initializeGoal: function() {
    // Do not show goal if this is a coding screen, is ZERO and they are in a class OR if this is an EduTec assessment
    if(this.screen.get('screen_type') !== 'coding' && (!this.user.get('in_class') || (this.user.get('in_class') && this.user.getSetting('daily_goal') > 0)) && !this.user.isAssessment()) {
      // Create a goal view that will be updated in serialize after we get all the most recent values
      this.goalView = new GoalView({
        noAnimation: true,
        type: 'screenComplete',
        totalSeconds: this.userActivity.getCompiled(1)?.seconds || 0,
        goalSeconds: this.user.getSetting('daily_goal') * 60,
        editable: !this.user.get('in_class')
      })
      // Do not render initially, the goal view will get rendered in serialize
      this.addChild('.js-goal', this.goalView, true, false)
    }
  },

  updateGoal: function(recentSeconds) {
    if(this.goalView) {
      const totalSeconds = (this.userActivity.getCompiled(1)?.seconds || 0),
        goalSeconds = this.user.getSetting('daily_goal') * 60

      this.goalView.updateValues(totalSeconds, recentSeconds)
      if(!this.goalAchieved && goalSeconds > 0) {
        // if user's recent progress made them achieve their goal time.
        if (totalSeconds - recentSeconds < goalSeconds && totalSeconds >= goalSeconds) {
          this.goalAchieved = new GoalAchievedView({
            recentSeconds: recentSeconds,
            totalSeconds: totalSeconds,
            goalSeconds: goalSeconds
          }).show()
        }
      }
    }
  }
})
