import Backbone from 'backbone'
import Registry from '@registry'
import LayoutView from '../global/views/layout_basic'
import MainView from './views/main'

export default Backbone.View.extend({
  layout: LayoutView,

  initialize: function() {
    window.document.title = 'Page Not Found';

    this.layout = new LayoutView({ hideLoginButtons: Registry.get('loggedIn') });

    Backbone.View.prototype.initialize.apply(this, arguments);

    var content = new MainView();

    this.addChild(this.el, content, true);
    this.render();
  }
})
