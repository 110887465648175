import Backbone from 'backbone'
import Registry from '@registry'
import MainView from './views/main'
import SkinsCollection from '../global/collections/skins'
import LayoutView from '../global/views/layout'

export default Backbone.View.extend({

  layout: LayoutView,

  initialize: function() {

    Backbone.View.prototype.initialize.apply(this, arguments);

    this.userLessons = Registry.get('userLessons');
    this.userAchievements = Registry.get('userAchievements');
    this.achievements = Registry.get('achievements');
    this.achievementGroups = Registry.get('achievementGroups');
    this.lessons = Registry.get('lessons');
    this.user = Registry.get('student');
    this.userActivity = Registry.get('userActivity');
    this.skins = new SkinsCollection(FTWGLOBALS('skins'));
    this.achievements.setCompleted(this.userAchievements);

    var openGroup = 'summary';
    if(this.openGroup && (this.openGroup === 'badges' || this.achievementGroups.get(parseInt(this.openGroup)))) {
      openGroup = this.openGroup;
    }

    this.views.mainView = new MainView({
      activeTab: openGroup,
      userLessons: this.userLessons,
      userAchievements: this.userAchievements,
      achievements: this.achievements,
      achievementGroups: this.achievementGroups,
      units: this.units,
      lessons: this.lessons,
      user: this.user,
      userActivity: this.userActivity,
      skins: this.skins
    });

    this.addChild(this.el, this.views.mainView, true);
    this.render();
  }
})
