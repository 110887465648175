import ScreenClickDrag from './screen_click_drag';
import $ from 'jquery'
import { snakeCase, trim } from 'lodash-es'
import { lesson_screen_drag_categories, } from '@templates/student'

import { includeSoundEffects, includeVisualEffects } from '../common';

const TAU = Math.PI * 2
const SELECTOR_ITEM = '.drag-categories--item'
const SELECTOR_CATEGORY = '.drag-categories--category'
const SELECTOR_WORKSPACE = '.drag-categories'
const CATEGORY_FONT_SIZE_SCALE = 0.125

export default ScreenClickDrag.extend({

  lessonTemplate: lesson_screen_drag_categories,

  sound: includeSoundEffects(),
  effects: includeVisualEffects(),

  setup() {
    // read the lesson data
    this.lessonContent = this.parseLesson()

    // tracking resolved shapes
    this.resolved = [ ]
  },

  build() {
    this.workspace = $(SELECTOR_WORKSPACE)

    // create a layout for the categories
    const { categories, items } = this.lessonContent
    const width = this.workspace.width()
    const height = this.workspace.height()
    applyLayout(width, height, categories, items)

    // get the container
    this.workspace
      .css({ ['--category-font-size']: `${categories[0].size * CATEGORY_FONT_SIZE_SCALE}px` })

    // handle dragging the view around
    this.$el
      .on('pointermove', this.onUpdateItemPosition.bind(this))
      .on('pointerup', this.onResolveItem.bind(this))

    // setup each category marker
    $(SELECTOR_CATEGORY)
      .each((i, t) => {
        const el = $(t)
        const { x: left, y: top, size: width } = categories[i]
        el.css({ left, top, width })
      })

    // setup each draggable item
    $(SELECTOR_ITEM)
      .on('dragstart', this.onBeginDragItem.bind(this))
      .each((i, t) => {
        const el = $(t)
        const { x: left, y: top, size: width } = items[i]

        el.css({ left, top, width })
          .attr('index', i)
      })
  },

  getItemFromEvent() {
    const { items } = this.lessonContent
    const index = 0 | this.target.attr('index')
    return items[index]
  },

  onBeginDragItem(event) {
    event.stopPropagation?.()
    event.preventDefault?.()

    // get the element
    const el = $(event.target).closest(SELECTOR_ITEM)

    // nothing to do
    if (el.is('.done')) {
      return
    }

    // mark this as active
    this.target = el.addClass('active')
  },

  onUpdateItemPosition(event) {
    if (this.target) {
      const position = this.getEventPosition(event)
      this.target.css({
        top: position.y,
        left: position.x
      })
    }
  },

  onResolveItem(event) {
    if (!this.target) {
      return
    }

    const { categories } = this.lessonContent
    const item = this.getItemFromEvent(event)
    const { x, y } = this.getEventPosition(event)

    // always deactivate the target
    this.target.removeClass('active')

    // check for this distance
    const category = categories.find(find => find.id === item.category)
    const range = category.size >> 1
    const distance = Math.hypot(category.x - x, category.y - y)

    // if within range, use it
    if (distance < range) {
      this.sound.play('pop')
      this.effects.celebrate(this.target)

      // since it's within range, place it along the edge
      // of the circle so it's not hiding the category name
      const angle = Math.atan2(y - category.y, x - category.x)
      const offset = range * 0.8
      const left = category.x + Math.cos(angle) * offset
      const top = category.y + Math.sin(angle) * offset

      // move and lock
      this.target.css({ top, left })
        .addClass('done')

      // make sure if the lesson is all done
      this.checkForLessonComplete()
    }
    else {
      this.sound.play('error')
      this.target?.css({ top: item.y, left: item.x })
    }

    this.target = null
  },

  checkForLessonComplete() {
    // when finished, check if the lesson is done
    const done = this.workspace.find('.done').length
    if (done === this.lessonContent.items.length) {
      this.completeScreen()
    }
  },

  // finds an event position relative to the workspace
  getEventPosition(event) {
    const { workspace } = this
    const { top, left } = workspace.offset()
    let { pageX: x, pageY: y } = event
    x -= left
    y -= top
    return { x, y }
  },

  serialize() {
    const data = ScreenClickDrag.prototype.serialize.call(this)
    const { categories, items } = this.lessonContent
    return { ...data, categories, items }
  },

  parseLesson() {
    const content = this.screen.get('content')
    const categories = [ ]
    const items = [ ]

    // read the incoming content
    content.split('\n')
      .forEach(line => {
        const [category, shapes] = line.split(':')
        const id = snakeCase(category)
        const name = trim(category)
        categories.push({ name, id })

        // create each item
        shapes.split(/,/g)
          .map(trim)
          .filter(item => !!item)
          .forEach((img, index) => {
            items.push({ img, id: `item_${index}`, category: id })
          })
      })

    return { categories, items }
  }

})

function applyLayout(width, height, categories, items) {
  const maxAllowedArea = 0.9

  // calculate workspace
  const surface = maxAllowedArea * width
  const padding = ((1 - maxAllowedArea) * 0.5) * width

  // calculate the area for a category bubble but
  // don't exceed a height that allows for the
  // bubbles to be stagger stacked
  const categorySize = Math.min(height * 0.6, ((surface - padding) / categories.length))
  const edge = (width - categorySize * categories.length) >> 1

  // shrink items sizes
  const itemSize = categorySize * 0.25

  // tracking items that have been placed so we can ensure
  // that later items aren't too close to their final positions
  const placed = [ ]

  // start by placing all category bubbles in view
  for (let i = 0; i < categories.length; i++) {
    const category = categories[i]
    category.size = categorySize
    category.x = edge + (category.size * 0.5) + (category.size * i)
    category.y = (i % 2 === 0 ? 0.333 : 0.666) * height
  }

  // checks if a coordinate is within the workspace
  function isInView(x, y) {
    const margin = (itemSize * 0.5) * 1.25
    const right = width - margin * 2
    const bottom = height - margin * 2
    return x > margin && x < right && y > margin && y < bottom
  }

  // check if a coordinate is within the bounds of a category bubble
  function isInCategory(x, y) {
    for (const category of categories) {
      if (Math.hypot(category.y - y, category.x - x) < (((category.size + itemSize) * 0.5) * 1.1)) {
        return true
      }
    }
  }

  // check the proximity of a coordinate to a placed item
  function isNearOtherItem(x, y) {
    for (const item of placed) {
      if (Math.hypot(item.y - y, item.x - x) < item.size) {
        return true
      }
    }
  }

  // choose positions for each item
  for (let i = 0; i < items.length; i++) {
    const item = items[i]

    // hunt for an open location
    // it's possible we could do this a little more
    // intelligently, but since it's quick and there's
    // only every a handful of items, we can just
    // brute force it a bit
    let x
    let y
    let attempt = 150
    while (--attempt > 0) {
      x = 0 | Math.random() * width
      y = 0 | Math.random() * height

      // make sure it's in the view, not in a bubble already, and far enough away
      // from another item that it's not too hard to interact with
      if (isInView(x, y) && !isInCategory(x, y) && !isNearOtherItem(x, y)) {
        break
      }
    }

    // this tried too many times
    if (attempt <= 0) {
      console.warn('Failed to find a suitable position for an item')
    }

    // save the changes
    item.x = 0 | x
    item.y = 0 | y
    item.size = itemSize

    // also add as an item that's on the board
    // so we can compare it for later placements
    placed.push(item)
  }

}


