import Backbone from 'backbone'
import GroupModel from '../models/achievement_group'
import { filter } from 'lodash-es'

export default Backbone.Collection.extend({

  model: GroupModel,

  comparator: 'order',

  /**
   * Returns an array of groups with extra fields total[total achievements in the group] and completed[total achievements completed]
   * @param {Achievements} achievements Collection of all achievements
   * @returns {Array}
   */
  getCounts: function(achievements) {
    return this.toJSON().map((row) => {
      var achs = achievements.where({achievement_group_id: row.achievement_group_id});
      row.total = achs.length;
      row.completed = achs.filter(function(row){
        return row.get('created_at') > 0;
      }).length;
      return row;
    });
  }

})
