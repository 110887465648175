import Backbone from 'backbone'
import $ from 'jquery'
import Tooltip from './tooltip'
import Velocity from 'velocity-animate'
import { global_progress_bar } from '@templates/student'

export default Backbone.View.extend({

  events: {
    'mouseover .js-progress-display': 'adjustRankingTooltips'
  },

  template: global_progress_bar,

  serialize: function () {
    const rank = this.user.getSkinRank(),
      nextRank = this.user.getSkinNextRank();

    this.visualRankProgressOld = this.rankProgressOld;
    this.visualRankProgressTotal = this.rankProgressTotal;

    if(this.rankProgressTotal !== this.rankProgressOld) {
    // if the new progress is less than 1% we want to make it look bigger
      if (this.rankProgressTotal - this.rankProgressOld < 1) {
        this.visualRankProgressOld = Math.max(this.rankProgressOld - 1, 0.1);
      }
      if (this.rankProgressOld < 5 || this.rankProgressOld > this.rankProgressTotal) {
      // the first few pixels of the progress bar are coverd by the avatar, so we need to visually compensate
      // When the user levels up, the progress bar displays properly
        this.visualRankProgressOld = this.visualRankProgressOld === 0 ? 0 : 1;
        this.visualRankProgressTotal = 5;
      }
    }

    return {
      user: this.user.toJSON(),
      rank: rank,
      nextRank: nextRank,
      totalRanks: this.totalRanks,
      rankProgressTotal: this.visualRankProgressTotal,
      rankProgressOld: this.visualRankProgressOld,
      rankXPTotal: this.rankXPTotal,
      variantId: this.user.get('variant_id'),
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    window.setTimeout(() => {
      let progressOldEle = this.$('.js-progress-old'),
        progressTotalEle = this.$('.js-progress-total');

      if (this.rankXPTotal !== this.rankXPOld && this.visualRankProgressOld !== 100) {
        let totalWidth = (this.$('.js-progress-display').width() *
          this.visualRankProgressTotal / 100 < 3) ? 3 : this.$('.js-progress-display').width() * this.visualRankProgressTotal / 100;

        progressOldEle.width('calc(' + this.visualRankProgressOld + '% - 2px)')
        Velocity(progressTotalEle,
          { width: totalWidth - 2 + 'px' },
          {
            duration: 1000,
            easing: 'easeInOutSine',
            complete: () => {
              window.setTimeout(() => this.adjustRankingTooltips(), 5000);
            }
          }
        );
        window.setTimeout(() => this.$('.js-progress-text').addClass('animate animate--fadeIn'), 750);
      } else {
        progressTotalEle.removeClass('progress--total').width('calc(' + this.visualRankProgressTotal + '% - 2px)');
        this.$('.js-progress-text').addClass('animate animate--fadeIn');//.text('global.xp_total'.t({ xp: this.rankXPTotal }));
      }
    }, 10);

    return this;
  },

  adjustRankingTooltips: function() {
    var id = this.$('.js-progress-tooltip').attr('aria-describedby');

    this.$('.js-progress-tooltip').remove();
    if (this.user.getSkinNextRank()) {
      $('#' + id).remove();
      this.$('.js-progress-display').addClass('has-tooltip');
    }
  }
})
