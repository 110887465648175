import $ from 'jquery'
import Modal from '../../global/views/modal'
import Registry from '@shared/registry'
import Model from '@shared/language_form'
import KeyboardHelperModal from '../../global/views/keyboard_helper_modal'
import { lesson_keyboard_modal } from '@templates/student'

export default Modal.extend({
  titleText: 'lesson.keyboard_settings'.t(),

  template: lesson_keyboard_modal,

  modelClass: Model,

  events: {
    'change input': 'changeInput',
    'click .js-keyboard-helper': 'keyboardHelper'
  },

  changeInput: function(e) {
    if($(e.target).attr('name') === 'show_keyboard') {
      if(!this.$('input[name=show_keyboard]').prop('checked')) {
        this.$('input[name=show_hands]').prop('checked', false).prop('disabled', true);
        this.$('input[name=animated_hands]').prop('checked', false).prop('disabled', true);
      } else {
        this.$('input[name=show_hands]').prop('checked', true).prop('disabled', false);
        this.$('input[name=animated_hands]').prop('checked', true).prop('disabled', false);
      }
    }

    if($(e.target).attr('name') === 'show_hands') {
      if(!this.$('input[name=show_hands]').prop('checked')) {
        this.$('input[name=animated_hands]').prop('checked', false).prop('disabled', true);
      } else {
        this.$('input[name=animated_hands]').prop('checked', true).prop('disabled', false);
      }
    }
  },

  submitCallback: function(data) {
    this.user.setSettings(data)

    Modal.prototype.submitCallback.apply(this, arguments)
  },

  // clickOk: function(e) {
  //   var data = {
  //     show_keyboard: this.$('input[name=show_keyboard]').prop('checked') ? 1 : 0,
  //     show_hands: this.$('input[name=show_hands]').prop('checked') ? 1 : 0,
  //     animated_hands: this.$('input[name=animated_hands]').prop('checked') ? 1 : 0,
  //     keyboard_id: this.$('select[name=keyboard_id]').val()
  //   };
  //
  //   if(Registry.get('loggedIn')) {
  //     var model = new Model(data);
  //     model.save();
  //   }
  //
  //   this.close();
  //   this.resolve(data);
  //   return false;
  // },

  serialize: function() {
    return {
      keyboards: FTWGLOBALS('keyboards'),
      keyboardMismatch: this.user.get('_invalidKeyboard'),
      user: this.user.toJSON()
    };
  },

  keyboardHelper: function() {
    new KeyboardHelperModal({ currentKeyboardId: this.$('select[name="keyboard_id"]').val() }).open()
      .done((data) => {
        this.$('select[name="keyboard_id"]').val(data.keyboard_id)
      })

    return false;
  },

  close: function() {
    this.user.set({ _invalidKeyboard: false });

    Modal.prototype.close.apply(this, arguments);
  }
})
