import Backbone from 'backbone';
import $ from 'jquery';
import Registry from '@registry';
import { extend, isObject } from 'lodash-es';

/**
 * Modal popup window.
 * Automatically attaches its self on open() and destroys its self on close().
 * Overwrite okCallback() and serialize()
 */
export default Backbone.View.extend({
  className: window.location.pathname.match(/^\/admin/) ? 'modal' : '',

  formSelector: 'form', // this can be changed, but we'll just say be default popups have a single form so this will work

  /**
   * These can be overwritten to set new values
   */
  titleText: 'shared.no_title_text'.t(),
  subtitleText: '',
  okText: 'shared.ok_text'.t(),
  destructive: false,
  cancelText: 'shared.cancel_text'.t(),
  closeButton: true,
  customFooter: false,
  headerType: 'standard',
  useModalsForm: false, // if true, a <form> tag will be set inside the modal's base template.. this avoids the form being disconnected if the inner content of the modal re-renders
  width: 's', // the default width for modals is 's' / small - corresponding with the cell modifier
  lastFocusedElement: null,
  focusableElements: null,
  /**
   * Be sure initialize is called by the parent
   */
  initialize: function() {
    if (this.useModalsForm) {  // <form> tag lives in base modal
      this.formSelector = 'form';
    }
    Backbone.View.prototype.initialize.apply(this, arguments);

    var events = {
        'click .cancel,.close,.js-close,.js-cancel': 'cancel'
    };

    if (!this.formSelector) {  // if there IS a formSelector, the view.js will handle the button
      events['click .js-submit,.submit'] = 'submitCallback';
    }

    // Create a new object and extend it with the existing 'events' object
    var mergedEvents = extend({}, events, this.events || {});

    // Pass the merged 'mergedEvents' object to 'delegateEvents'
    this.delegateEvents(mergedEvents);

    let responsiveWidths = [];

    if (this.baseModalOptions && isObject(this.baseModalOptions.width)) {
      Object.keys(this.baseModalOptions.width).forEach((key) => {
          responsiveWidths.push(`${key === 'default' ? '' : key + ':'}max-w-screen-${this.baseModalOptions.width[key]}`);
      });
    }

    this.baseModalOptions = extend({
      alert: false,
      container: true,
      knowledgeBaseLink: '',
      responsiveWidths: responsiveWidths,
      sidebarPosition: 'r',
      titleIcon: '',
      titleIconStroke: '2',
      upgrade: false,
      width: this.width,
    }, this.baseModalOptions || {});

    this.$el.html(
      this.modalBaseTemplate({
        useModalsForm: this.useModalsForm,
        headerType: this.headerType,
        titleText: this.titleText,
        subtitleText: this.subtitleText,
        titleIcon: this.titleIcon,
        okText: this.okText,
        destructive: this.destructive,
        cancelText: this.cancelText,
        customFooter: this.customFooter,
        closeButton: this.closeButton,
        ...this.baseModalOptions
      })
    );

    extend(this, $.Deferred());
  },

  captureKeys: function(e) {
    if(e.which === 27) {
      this.cancel(e);
      return false;
    }

    if(e.which === 13 && e.target === this.$('.js-submit:visible').get(0)) {
      this.$('.js-submit:visible').click();
      if (!this.$('.modal:visible')) {
        this.lastFocusedElement.focus();
      }
      return false;
    }
  },

  /**
   * Open and show the modal
   * @returns {*}
   */
  open: function() {
    this.lastFocusedElement = document.activeElement;
    
    Registry.set('preventKeyboardInput', true);

    $(document).on('keydown.close-modal', this.captureKeys.bind(this));

    if (!this.bootstrap) {
      $('body')
        .append(this.el)
        .addClass('has-modal');
    }

    this.render();

    // This must be called after elements exist
    this.initializeValidation();
    const $modal = this.$('.modal');

    if (this.bootstrap) {
      this.$el.modal({ backdrop: 'static', keyboard: false });
    } else {
      $modal
        .addClass('is-open')
        .find('input[type=text]:eq(0),input[type=email]:eq(0), a, button:not(:hidden), input, textarea, select, details,[tabindex]:not([tabindex="-1"])')
        .focus();

      this.focusableElements = $modal.find('input[type=text]:eq(0),input[type=email]:eq(0), a, button:not(:hidden), input:not(.hidden), textarea, select, details,[tabindex]:not([tabindex="-1"])');
      $modal.on('keydown', this.trapTabKey.bind(this));
    }

    return this;
  },

  /**
   * Close the modal and remove it
   */
  close: function() {
    Registry.set('preventKeyboardInput', false);
    $(document).off('keydown.close-modal');

    const $modal = this.$('.modal');

    if (this.bootstrap) {
      this.$el.modal('hide');
    } else {
      $modal.removeClass('is-open');
      this.$('.modal-overlay').remove();

      $('body').removeClass('has-modal');
      setTimeout(() => this.remove(), 1000);
      setTimeout(() => this.destroyIDE(), 0);

      $modal.off('keydown', this.trapTabKey.bind(this));
      this.lastFocusedElement.focus();
    }
  },

  /**
   * Close the modal and destroy it
   * Calls reject() on the promise
   * @returns {boolean}
   */
  cancel: function(e) {
    if (this.validator && this.validator.submitting) {
      return false;
    }

    this.close();
    this.reject();
    return false;
  },

  /**
   * Override this method for custom things to happen on OK
   * Be sure to call this parent one
   */
  submitCallback: function(data) {
    this.close();
    Backbone.View.prototype.submitCallback.apply(this, arguments);
    this.resolve(data);
    return false;
  },

  /**
   * Overriding the default one.  This can be overridden too
   * @return {object} data to serialize
   */
  serialize: function() {
    var data = this.model ? this.model.toJSON() : null;
    if (!data) {
      data = this.data || {};
    }

    return { data: data };
  },

  /**
   * Almost never call this directly
   */
  render: function() {
    this.$('.js-content').html(this.template(this.serialize()));
    return this;
  },

  trapTabKey: function(e) {
    const $activeModal = this.$('.modal.is-open');

    if (e.key === 'Tab' || e.target === this.$('.js-next').get(0)) {
      this.focusableElements = $activeModal.find('input[type=text]:eq(0),input[type=email]:eq(0), a, button:not(:hidden), input:not(.hidden), textarea, select, details,[tabindex]:not([tabindex="-1"])');
      const firstFocusableElement = this.focusableElements.first().get(0);
      const lastFocusableElement = this.focusableElements.last().get(0);
      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          e.preventDefault();
          lastFocusableElement.focus();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          e.preventDefault();
          firstFocusableElement.focus();
        }
      }
    }
  }
});
