import Backbone from 'backbone';
import $ from 'jquery';
import Registry from '@registry';
import LayoutView from '../global/views/layout';
import MainView from './views/main';
import CompleteView from './views/complete';
import LoadingView from '../global/views/loading_animated';
import UpgradeModel from './models/upgrade';
import Alert from '../global/views/alert';
import AuthModal from '../global/views/auth_modal';
import qs from 'qs';
import { isEmpty } from 'lodash-es';

export default Backbone.View.extend({

  events: {
    'click .js-submit': 'upgrade',
  },

  layout: LayoutView,

  initialize: function (options) {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.user = Registry.get('student');

    this.model = new UpgradeModel();

    this.views.main = new MainView({ miniNav: true });
    this.views.main.$el.fastHide();
    this.views.complete = new CompleteView();
    this.views.complete.$el.fastHide();
    this.views.loading = new LoadingView(
      { miniNav: true, loadingText: 'upgrade.looking_up_order'.t() });
    this.views.loading.$el.fastShow();

    this.addChild(this.el, this.views.loading, true);
    this.addChild(this.el, this.views.main, true);
    this.addChild(this.el, this.views.complete, true);

    this.render();

    const params = qs.parse(window.location.search.substr(1)) || {};

    if (params.success || this.user.get('licensed_at')) {
      this.confirmOrder();
    } else {
      this.views.loading.$el.fastHide();
      this.views.main.$el.fastShow();
    }

    if (window.localStorage.getItem('_forceUpgrade')) {
      window.localStorage.removeItem('_forceUpgrade');
      if (!Registry.get('student').get('licensed_at')) {
        this.$('.js-submit').click();
      }
    }
  },

  renderComplete: function (data) {
    this.views.loading.$el.fastHide();
    this.views.main.$el.fastHide();
    this.views.complete.purchaseData = data;
    this.views.complete.render();
    this.views.complete.$el.fastShow();
  },

  upgrade: function (e) {
    const button = $(e.currentTarget);

    if (button.hasClass('btn--loading')) { return false; }

    if (!Registry.get('loggedIn')) {
      new AuthModal({ authType: 'login', showGuestContent: false }).open();
    } else {
      button.addClass('btn--loading');

      $.ajax({
        url: '/apiv1/student/upgrade/stripesession',
        method: 'post',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify({
          origin: location.origin,
        }),
      }).done((data) => {
        if (!window.Stripe) {
          new Alert({
            title: 'upgrade.uh_oh_alert_title'.t(),
            text: 'upgrade.uh_oh_alert_text'.t(),
            cancel: '',
          }).on('ok', () => {
            window.location.reload();
          });
        } else {
          window.Stripe(FTWGLOBALS('stripeKey')).redirectToCheckout({
            sessionId: data.sessionId,
          });
        }
      }).fail((data) => {
        button.removeClass('btn--loading');
        new Alert({
          title: 'upgrade.uh_oh_alert_title'.t(),
          text: 'upgrade.uh_oh_alert_text'.t(),
        });
      });
    }
  },

  confirmOrder: function () {
    const button = this.$('.js-submit');

    button.addClass('btn--loading');

    $.ajax({
      url: '/apiv1/student/upgrade/validate',
      method: 'get',
    }).done((data) => {
      if (data?.status) {
        this.user.set({
          licensed_at: 1,
          subscription: {
            status: data.status,
            expiration: data.expiration,
            email: data.email,
          },
        });
        this.renderComplete(data);
      } else {
        this.views.loading.$el.fastHide();
        this.views.main.$el.fastShow();
      }
    }).fail(() => {
      alert(
        'Uh oh, there was an error confirming your order. Please try again later or contact support!');
      this.views.loading.$el.fastHide();
      this.views.main.$el.fastShow();
    }).always(() => {
      button.removeClass('btn--loading');
    });
  },
});
