import Backbone from 'backbone'
import MainView from './views/procedimiento'
import LayoutView from './views/layout'

export default Backbone.View.extend({

  layout: LayoutView,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.views.main = new MainView()
    this.addChild(this.el, this.views.main, true);

    this.render();
  }

})
