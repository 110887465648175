import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import Scoring from '@shared/scoring'
import Analytics from '@shared/analytics'
import radarTracker from '@shared/radar_tracker'
import LessonModel from '../global/models/lesson'
import LayoutView from './views/layout'
import ScreensCollection from '../global/collections/lesson_screens'
import IntroView from './views/intro'
import CongratsView from './views/congrats'
import BlueprintScreen from './views/screen_blueprints'
import UserLessonScreens from '../global/collections/user_lesson_screens'
import AchievementsCollection from '../global/collections/achievements'
import AdView from '../global/views/ad'
import KeyboardInput from '@shared/keyboard_input'
import Dictation from './classes/dictation'
import { lesson_screen_blueprints_lesson } from '@templates/student'
import { find, each } from 'lodash-es'
import { nextFrame } from './classes/blueprints/utils';
import { starAnimation } from './common';
import { bindSelectors } from '@shared/utils';

/**
 * This is the main overall controller for the typing lessons
 */
export default Backbone.View.extend({

  selectors: {
    $overlay: '.screenBlueprints-overlay'
  },

  events: {
    'click .js-next-lesson': 'handleContinueLesson',
  },

  // include the congrats star animation
  starAnimation,

  template(...args) {
    return this.views.congrats ? null : lesson_screen_blueprints_lesson(...args)
  },

  isLessonFinished() {
    return this.userLesson.get('progress') === this.lesson.get('screens')
  },

  initialize: async function(options) {
    Backbone.View.prototype.initialize.apply(this, arguments);
    bindSelectors(this)

    this.options = options;

    this.user = Registry.get('student');
    this.userAchievements = Registry.get('userAchievements');
    this.userActivity = Registry.get('userActivity');
    this.userTests = Registry.get('userTests');
    this.units = Registry.get('units');
    this.units.set(FTWGLOBALS('units'));

    this.input = new KeyboardInput();   // Handles and normalizes browser input
    this.dictation = new Dictation(null, null, this.user); // Handles dictation for all screens

    this.removeHash = false // Used by Proc2024 when going to a specific screen

    var lesson = find(FTWGLOBALS('lessons'), { lesson_id: this.lessonId });
    if(!lesson) {
      lesson = FTWGLOBALS('custom_lesson');
    }
    this.lesson = new LessonModel(lesson);

    this.unit = this.units.get(this.lesson.get('unit_id'));
    this.screens = new ScreensCollection(FTWGLOBALS('lesson_screens'));

    // Get localStorage stored status
    var userLessons = Registry.get('userLessons');
    this.userLesson = userLessons.get(this.lesson.id);
    if (!this.userLesson) {
      // If they've never done this lesson, create a new record for it
      this.userLesson = userLessons.add({lesson_id: this.lesson.id});
    }

    var progress = this.userLesson.get('progress')
    // // Force which screen (question) to display based on a URL hash from the router
    if(this.screenIndex) {
      // Use the URL hash to specify which screen to show (when applicable)
      progress = Math.min(Math.max(this.screenIndex - 1, 0), this.lesson.get('screens') - 1)
    }

    if(this.userLesson.get('restart')) {
      this.userLesson.unset('restart')
      this.userLesson.set('progress', 0)
      progress = 0;
    }

    this.userLessonScreens = new UserLessonScreens(Registry.get('userLessonScreens').where({lesson_id: this.lesson.id}));

    // Special tracking on this page with more info
    if(Registry.get('loggedIn') && window.location.pathname.match(/lesson\/[0-9]+/) && this.user.get('in_class') && this.user.isPremium() && this.user.hasOption('realtime')) {
      radarTracker.track(Registry.get('student'), {
        seconds: this.userLesson.get('seconds'),
        errors: this.userLesson.get('errors'),
        typed: this.userLesson.get('typed'),
        progress: progress
      });
    }

    // Get a reference to the current screen
    this.screen = this.screens.at(progress);

    // They may not have a screen if they've completed the lesson
    if (this.screen) {
      const started = this.userLesson.get('_state') === 'build'
      this.screen.formatContent(this.user);

      // Some screens have intros
      if (this.screen.get('intro') && !started) {
        this.views.intro = new IntroView({
          user: this.user,
          screen: this.screen,
          lesson: this.lesson,
          userLesson: this.userLesson,
          input: this.input,
          dictation: this.dictation,
          label: 'blueprints.start_blueprint'
        });
        this.views.intro.once('continue', function() {
          this.userLesson.set({_state: 'build'});
          window.setTimeout(() => window.location.safeReload(this.removeHash), 0);
        }.bind(this));
      }

      // The actual typing screen.
      this.views.blueprint = new BlueprintScreen({
        delay: true,
        user: this.user,
        input: this.input,
        dictation: this.dictation,
        lesson: this.lesson,
        screens: this.screens,
        screen: this.screen,
        userLesson: this.userLesson,
        userLessonScreens: this.userLessonScreens,
        problemKeysLesson: this.options.problemkeys,
      });
      this.listenTo(this.views.blueprint, 'complete', this.handleScreenComplete.bind(this));
    }

    // After lesson congrats screen
    if (this.isLessonFinished()) {
      this.views.congrats = new CongratsView({
        user: this.user,
        dictation: this.dictation,
        lesson: this.lesson,
        screens: this.screens,
        userLesson: this.userLesson,
        userLessonScreens: this.userLessonScreens,
        hasGoogleClassroomAddon: !!window.localStorage.getItem('googleClassroomAddonData'),
        hideTypingStats: true
      });
    }

    // layout is specific to typing page
    this.layout = (new LayoutView({
      user: this.user,
      timer: this.timer,
      child: this,
      input: this.input,
      dictation: this.dictation,
      unit: this.unit,
      lesson: this.lesson,
      screen: this.screen,
      userLesson: this.userLesson,
      test: this.options.test,
      problemkeys: this.options.problemkeys,
      hasGoogleClassroomAddon: !!window.localStorage.getItem('googleClassroomAddonData'),
      progressComplete: this.userLesson.get('_state') === 'complete'
    }));

    this.render();
  },

  serialize() {
    const totalScreens = this.userLesson.get('max_progress')
    const title = !this.views.intro && this.screen?.get('title')

    return {
      title,
      ads: AdView.canShowAds(),
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens })
    };
  },

  renderChildren: function() {
    Backbone.View.prototype.renderChildren.apply(this, arguments);

    var state = this.userLesson.get('_state'),
      ads = {};

    // display render and show a blueprint
    const appendChild = async (child, selector) => {
      await nextFrame()

      // find the place to append
      let $target = this.$el
      if (typeof selector === 'string') {
        $target = $target.find(selector)
      }

      // add to the view
      $target.append(child.$el)
      child.render()
      child.resetView?.()
    }

    var adOptions = {
      totalScreens: this.userLesson.get('max_progress')
    };

    if (this.isLessonFinished()) {
      AdView.initAds('lesson_screen');
      this.userLesson.set('_state', 'restart')

      // if they're done, show congrats instead of last screen
      appendChild(this.views.congrats)
    } else {
      if(this.views.intro) {
        AdView.initAds('lesson_intro');
        appendChild(this.views.intro,'.js-content')
      } else {
        AdView.initAds('lesson_screen');
        appendChild(this.views.blueprint,'.js-content')
      }
    }

    // init ads
    ads = AdView.getAds(adOptions);
    window.setTimeout(() => {
      each(ads, (ad, name) => {
        if(!ad) { return; }
        var ele = $('.js-' + name + '-ad');
        if(ele.length) {
          ele.append(ad.render().el);
        }
      });

      this.input.initialize({ shortcuts: ['dictation'] });
    }, 0);
  },

  startBuilding: function() {
    this.userLesson.set({_state: 'build'});
    window.setTimeout(window.location.safeReload, 0);
  },

  handleScreenComplete: function() {
    var progress = (this.userLesson.get('restart')) ? 0 : this.userLesson.get('progress');
    var pastScreen = this.userLessonScreens.get(parseInt(this.screen.id));
    var ulsTable = 'hot2'

    if(pastScreen) {  // every stat needs to know the right table
      ulsTable = pastScreen.get('uls_table')
    }

    this.screenStats = {
      lesson_id: this.lesson.id,
      lesson_screen_id: parseInt(this.screen.id),
      seconds: 0,
      errors: 0,
      typed: 0,
      std: 0,
      uls_table: ulsTable,
      stars: 3,
      newStars: 0,
      skin_id: this.user.get('skin_id') || 1,
      completed: true,
      restart: (this.userLesson.get('restart')) ? 1 : 0,
      progress: Math.min(progress + 1, this.lesson.get('screens')), // Progress can go no higher than the number of screens
      created_at: Date.getUnixTime(),
      test:  0,
      problem_keys: 0,
      tech_lit: 1,
      now: Math.floor(Date.now()/1000)
    };

    this.saveStats();
  },

  handleLessonComplete: function() {
    const { $overlay } = this.selectors
    this.userLesson.set({_state: 'complete'});
    this.starAnimation({ showOverlay: $overlay })
  },

  handleContinueLesson() {
    window.setTimeout(window.location.safeReload, 0);
  },

  saveStats: function() {
    AchievementsCollection.pause(true);
    this.userLesson.saveStats(this.screenStats)    // save to the server
      .done(() => {
        if (this.screenStats && this.screenStats.failed) {

        } else {
          Analytics.customMetric(2, Math.floor((this.userLesson.get('max_progress') / this.lesson.get('screens')) * 100)); // REMOVE ON 7/1/2023

          var lastTry = this.userLessonScreens.get(this.screenStats.lesson_screen_id);
          this.screenStats.lastTry = (lastTry) ? lastTry.toJSON() : null;
          this.userLessonScreens.add(this.screenStats, {merge: true});
          Registry.get('userLessonScreens').add(this.screenStats, {merge: true});  // this is the actual local storage one

          this.userLesson.set({
            max_progress: Math.max(this.screenStats.progress, this.userLesson.get('max_progress')),
            progress: this.screenStats.progress,
            updated_at: this.screenStats.created_at,
            typed: 0,
            errors: 0,
            seconds: 0,
            stars: this.screens.length * 3
          });

          this.userLesson.unset('restart');

          if(this.screenStats.completed) {
            this.handleLessonComplete();
          } else {
            // this.views.blueprint.screenSaved();
            window.setTimeout(window.location.safeReload, 0);
            this.screen = this.screens.at(this.screenStats.progress);
          }
        }
      })
      .fail(() => {
      // TODO: The user has finished the quiz, but there was an error saving the stats. How should we inform them?
    })
    .always(() => {
      AchievementsCollection.pause(false);
    })
  },
})
