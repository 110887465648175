import { random } from 'lodash-es';
import { Cookies } from '@shared/helpers';

export const GOOGLE_CLASSROOM_ADDON_POPUP_WINDOW_NAME = 'googleClassroomAddonLoginPopUpWindow'
export const GOOGLE_CLASSROOM_ADDON_LOGIN_SUCCESS_HANDLER = 'googleClassroomAddonSsoLoginSuccess'

export function getTargetOrigin(obj) {
  return obj.origin.includes('typing.com')
    ? obj.origin
    : 'https://www.typing.com'
}

export const googleClassroomAddonLogin = (portal, options = {}) => {
  const state = random(10000, 99999)
  const stateCookie = `google|${state}|popup|${portal}`
  Cookies.set('sso', portal, {path:'/'});
  Cookies.set('state', 'google|' + state, {path: '/'});
  if(options.teacher_id) {
    Cookies.set('sso_id', options.teacher_id, { path: '/' });
  }
  Cookies.set('join_code', options.joinCode || '', {path:'/'});
  Cookies.set('lang', FTWGLOBALS('language'), {path: '/', expires: 30});

  const { login_hint } = JSON.parse(window.localStorage.getItem('googleClassroomAddonData'))
  const loginHintSegment = login_hint ? `&login_hint=${login_hint}` : ''
  const googleClassroomAddonSsoUri = `${FTWGLOBALS('googleClassroomAddonSsoUri')}&state=${stateCookie}${loginHintSegment}`

  const createPopup = (url) => {
    window.open(
      url,
      GOOGLE_CLASSROOM_ADDON_POPUP_WINDOW_NAME,
      'height=500,width=500,left=100,top=100,resizable=no,'
      + 'scrollbars=yes,toolbar=yes,menubar=no,location=yes,'
      + 'directories=no,status=yes',
    )
  }

  createPopup(googleClassroomAddonSsoUri)
}
