import Backbone from 'backbone'
import Registry from '@registry'
import { achievements_panel } from '@templates/student'
import { generateCurriculumList } from '@shared/helpers'
import moment from 'moment'
import { find, times, filter } from 'lodash-es'

export default Backbone.View.extend({

  template: achievements_panel,

  activeTab: 'summary',

  className: 'dashboard-listInner',

  events: {
    'change .js-curriculum': 'changeCurriculumFilter',
  },

  initialize: function () {
    Backbone.View.prototype.initialize.apply(this, arguments)

    this.achievements.forEach((ach) => {
      const badge = ach.getBadgeView()
      this.addChild('.js-ach-image-' + ach.id, badge, false)
    })

    const recent = this.achievements.getRecentlyCompleted(2)
    recent.forEach((ach) => {
      const badge = ach.getBadgeView()
      this.addChild('.js-ach-recent-' + ach.id, badge, false)
    })

    this.appendBadgeImages()
  },

  serialize: function() {
    const groups = this.achievementGroups.getCounts(this.achievements),
      skins = this.parent.skins,
      units = this.parent.units,
      lessons = this.parent.lessons,
      badges = this.parent.badges

    return {
      moment: moment,
      lodash: { find, times, filter },
      totalEarned: this.parent.getTotalEarned(),
      totalAchievements: this.parent.getTotalAchievements(),
      recent: this.achievements.getRecentlyCompleted(2),
      activeTab: this.activeTab,
      achievements: this.achievements,
      groups: groups,
      lessons,
      units,
      badges,
      skins: skins.toJSON(),
      ranksEarned: this.parent.getRanksEarned(),
      totalRanks: skins.getTotalRanks(),
      totalRanksPerSkin: skins.at(0).getTotalRanks(),
      skinId: this.skinId,
      variantId: this.variantId,
      curriculumList: generateCurriculumList(),
      activeProduct: this.activeProduct,
      inClass: Registry.get('student').get('in_class')
    };
  },

  appendBadgeImages: function() {
    const units = this.parent.units,
      lessons = this.parent.lessons

    lessons.forEach((lesson) => {
      const badge = this.userLessons.getBadge(find(units, { unit_id: lesson.unit_id }), lesson)
      this.addChild('.js-badge-image-' + lesson.lesson_id, badge, false)
    })
  },

  changeCurriculumFilter: function(e) {
    this.activeProduct = this.$(e.currentTarget).val()

    this.parent.resetUnits(this.activeProduct)

    this.appendBadgeImages()

    this.render()
  }

})
