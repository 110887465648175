import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import MainView from './views/main'
import LoadingView from '../join/views/loading'
import FailedView from '../join/views/failed'
import LayoutView from '../global/views/layout_basic'
import { Cookies } from '@shared/helpers'

export default Backbone.View.extend({
  layout: LayoutView,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.views.main = new MainView();

    // If a Class Join Code is provided then display a loading view and validate the Join Code
    if (this.joinCode) {
      this.layout = new LayoutView({ joinCode: this.joinCode });

      this.views.loading = new LoadingView();

      this.views.failed = new FailedView();
      this.views.failed.$el.fastHide();

      this.views.main.$el.fastHide();
      this.views.main.joinCode = this.joinCode;

      // Validate the join code
      $.post('/apiv1/student/auth/join-validate', { join_code: this.joinCode })
        .done(this.showJoinCodeForm.bind(this))
        .fail(this.showJoinCodeFailed.bind(this));

      this.addChild(this.el, this.views.loading, true);
      this.addChild(this.el, this.views.failed, true);
    }

    this.addChild(this.el, this.views.main, true);
    this.render();

    window.setTimeout(() => {
      this.setFocus(!this.joinCode);
    }, 10);
  },

  showJoinCodeForm: function (data) {
    const settings = data.settings
    if(settings.language && settings.language !== FTWGLOBALS('defaultLanguage') && settings.language !== FTWGLOBALS('language')) {
      window.location.href = '/' + settings.language + window.location.pathname + window.location.hash;
      return;
    }
    this.views.main.section = data;
    this.views.main.joinCode = this.joinCode;
    this.views.loading.$el.fastHide();
    // Main form needs to render again in order to display the Join Code and Section data
    this.views.main.render().$el.fastShow();

    this.setFocus(true);
  },

  showJoinCodeFailed: function (request) {
    if(request.responseJSON && request.responseJSON.error) {
      this.views.failed.error = request.responseJSON.error;
      this.views.failed.render();
    }
    this.views.loading.$el.fastHide();
    this.views.failed.$el.fastShow();
  },

  setFocus: function (autoSubmit) {
    if(FTWGLOBALS('site') === 'typing') {
      this.$('input[name=username]').focus();
    } else {
      var code = this.$('input[name=code]').focus();
      if(code.val() !== '' && autoSubmit) {
        code.submit();
      }
    }
  }

});
