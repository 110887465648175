import Backbone from 'backbone'
import Registry from '@registry'
import Login from './login';
import Password from './password'
import GoogleClassroomAddonLogin from './googleClassroomAddon'
import Lessons from './lessons'
import TypingLesson from './lesson/typing_lesson'
import BlueprintsLesson from './lesson/blueprint_lesson'
import QALesson from './lesson/qa_lesson'
import ConceptMapLesson from './lesson/concept_map'
import ClickDragLesson from './lesson/click_drag_lesson'
import Signup from './signup'
import Join from './join'
import DebugKeyboard from './debug/keyboard'
import DebugKeyInfo from './debug/keyinfo'
import Account from './account'
import Upgrade from './upgrade'
import Oauth from './oauth'
import Tests from './tests'
import Assessments from './assessments'
import Procedimiento from './assessments/procedimiento'
import Recomendaciones from './assessments/recomendaciones'
import Verificaciones from './assessments/verificaciones'
import Usuario from './assessments/usuario'
import Games from './games'
import GamesPlay from './games/play'
import Achievements from './achievements'
import Skins from './skins'
import Scoreboard from './scoreboard'
import Proclamation from './proclamation';
import Error from './error'
import Prompt from './prompt'
import { find } from 'lodash-es'

const AppRouter = Backbone.Router.extend({
  routes: {
    '': function() {
      if(FTWGLOBALS('whitelabel')) {
        return new Login()
      } else {
        return new Lessons()
      }
    },
    'password': function() {
      return new Password();
    },
    'google-classroom-addon-login': function() {
      return new GoogleClassroomAddonLogin();
    },
    'login': function() {
      return new Login()
    },
    'digital-literacy': function() {return new Lessons();},  // seo stuff
    'coding-curriculum': function() {return new Lessons();},
    'conocimiento-digital': function() {return new Lessons();},
    'estudios-de-programacion': function() {return new Lessons();},
    'aprendizagem-digital': function() {return new Lessons();},
    'curriculo-de-programacao': function() {return new Lessons();},
    'lessons': function() {
      return new Lessons();
    },
    'lesson/prompt/:id': function(id) {
      var assignment = Registry.get('assignments').get(parseInt(id));
      if(!assignment) {
        return new Error({ code: '404' });
      }
      return new Prompt({ assignment_id: parseInt(id) });
    },
    'lesson/:id(/:slug)': function(id, slug) {
      if(id === 'problemkeys') {
        if(!window.location.hash){
          window.location.href = '/student/lessons';
          return;
        }
        return new TypingLesson({ problemkeys: true });
      }
      if(!FTWGLOBALS('lesson_screens')) {
        return new Error({code: 'generic'});
      }

      let lesson = find(FTWGLOBALS('lessons'), {lesson_id: parseInt(id)})

      if(!lesson) {
        lesson = find(Registry.get('customLessons').toJSON(), { lesson_id: parseInt(id) });
        if(!lesson) {
          // If this is a custom lesson, or if the productId is not found in the list of products, then this lesson is notFound
          const product = FTWGLOBALS('isCustomLesson') || !FTWGLOBALS('products').map((row) => row.product_id).includes(FTWGLOBALS('productId')) ? 'notFound' : FTWGLOBALS('products').find((row) => row.product_id === FTWGLOBALS('productId'))

          // This lesson might be in a different product. If so, tell the user
          return new Lessons({ newProduct: product })
        }
      }
      if(!slug) {
        window.location.href = '/student/lessons';
        return;
      }

      let screenIndex = parseInt(window.location.hash.replace('#', ''))
      const user = Registry.get('student').toJSON()

      // Only let users with a teaching.com email address access this feature
      if(isNaN(screenIndex) || (!user.email?.includes('@teaching.com'))) {
        screenIndex = null
      }

      if(lesson.content_type === 'concept-map') {
        return new ConceptMapLesson({ lessonId: parseInt(id), screenIndex });
      } else if(lesson.content_type === 'blueprints') {
        return new BlueprintsLesson({ lessonId: parseInt(id), screenIndex });
      } else if(lesson.content_type === 'qa') {
        return new QALesson({ lessonId: parseInt(id), screenIndex });
      } else if(lesson.content_type === 'click-and-drag') {
        return new ClickDragLesson({ lessonId: parseInt(id), screenIndex })
      }
      return new TypingLesson({ lessonId: parseInt(id), screenIndex });
    },
    'oauth': function() {
      return new Oauth();
    },
    'certificacion': function() {
      if(!FTWGLOBALS('whitelabel')) {
        window.location.href = __url('/student/tests');
      } else {
        return new Assessments()
      }
    },
    'certificacion/procedimiento': function() {
      if(!FTWGLOBALS('whitelabel')) {
        window.location.href = __url('/student/tests');
      } else {
        return new Procedimiento()
      }
    },
    'certificacion/recomendaciones': function() {
      if(!FTWGLOBALS('whitelabel')) {
        window.location.href = __url('/student/tests');
      } else {
        return new Recomendaciones()
      }
    },
    'certificacion/verificaciones': function() {
      if(!FTWGLOBALS('whitelabel')) {
        window.location.href = __url('/student/tests');
      } else {
        const userId = window.location.hash.replace('#', '');
        return new Verificaciones({ userId: userId })
      }
    },
    'certificacion/usuario': function() {
      if(!FTWGLOBALS('whitelabel')) {
        window.location.href = __url('/student/tests');
      } else {
        return new Usuario()
      }
    },
    'tests': function() {
      return new Tests({miniNav: !Registry.get('loggedIn') });
    },
    'typing-test/:minutes-minute(/:testLanguage)': function(minutes) {
      const screens = FTWGLOBALS('lesson_screens'),
        lesson = find(FTWGLOBALS('lessons'), {lesson_id: parseInt(screens[0].lesson_id)})

      if(!screens || !lesson) {
        return new Error({ code: 404 });
      }

      return new TypingLesson({ lessonId: lesson.lesson_id, test: true, minutes: minutes });
    },
    'typing-test/:pages-page(/:testLanguage)': function(pages) {
      const screens = FTWGLOBALS('lesson_screens'),
        lesson = find(FTWGLOBALS('lessons'), {lesson_id: parseInt(screens[0].lesson_id)})

      if(!screens || !lesson) {
        return new Error({ code: 404 });
      }
      return new TypingLesson({ lessonId: lesson.lesson_id, test: true, pages: pages });
    },
    'games': function() {
      // Student cannot access any games
      if(Registry.get('student').getGames(FTWGLOBALS('games')).length === 0) {
        window.location.href = '/student/lessons';
        return;
      }
      return new Games();
    },
    'games/play/:slug': function(slug) {
      window.location.href = __url('/student/game/' + slug);
    },
    'game/:slug': function(slug) {
      return new GamesPlay({ slug: slug });
    },
    'themes': function() {
      const user = Registry.get('student')
      if(!user.get('in_class') || user.hasOption('changetheme')) {
        return new Skins()
      }

      window.location.href = __url('/student/lessons')
    },
    'achievements': function() {
      var groupId = window.location.hash.replace('#', '');
      return new Achievements({ openGroup: groupId });
    },
    'scoreboard': function() {
      return new Scoreboard();
    },
    'account': function() {
      if(!Registry.get('loggedIn')){
        return window.location.href = __url('/student/lessons');
      }
      return new Account();
    },
    'verify': function() {
      var parts = window.location.hash.replace('#', '').split('-');
      window.location.href = '/apiv1/student/tests/' + parts[0] + '/' + parts[1] + '/certificate?language=' + FTWGLOBALS('language');
    },
    'upgrade': function() {
      if(FTWGLOBALS('whitelabel')) {
        return new Error({code: 404});
      }
      return new Upgrade();
    },
    'join': function() {
      return new Join({joinCode: window.location.hash.replace('#', '')});
    },
    'join/login': function() {
      return new Login({ joinCode: window.location.hash.replace('#', '') });
    },
    'join/signup': function() {
      return new Signup({ joinCode: window.location.hash.replace('#', '') });
    },
    'signup': function() {
      if(FTWGLOBALS('whitelabel')) {
        window.location.href = __url('/student/login');
        return;
      }
      return new Signup();
    },
    'proclamation-2024': function() {
      return new Proclamation()
    },
    'proclamation-2024-update': function() {
      return new Proclamation({ rereview: true })
    },
    'debug/keyinfo': function() {
      return new DebugKeyInfo();
    },
    'debug/keyboard': function() {
      return new DebugKeyboard();
    },
    '*notFound': function() {
      return new Error({code: 404});
    }
  }
})

export default {
  initialize: function() {
    new AppRouter();
    var parts = window.window.location.pathname.split('/'),
      // if index 1 (2nd val) is 'student' then they don't have a language on the URL like /es/student
      root = (parts[1] === 'student') ? 'student' : (parts[1]+'/student');

    Backbone.history.start({pushState: true, root: root, hashChange: false});
  }
}
