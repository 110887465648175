import Backbone from 'backbone'
import Registry from '@registry'
import { scoreboard_panel } from '@templates/student'

export default Backbone.View.extend({

  template: scoreboard_panel,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.listenTo(this.scoreboard, 'sort', this.render);
  },

  serialize: function () {
    return {
      type: this.scoreboard.comparatorKey,
      period: this.scoreboard.period,
      data: this.scoreboard.toJSON().filter(row => row.period === this.scoreboard.period)
    };
  }
})
