import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import Scoring from '@shared/scoring'
import Tooltip from '../../global/views/tooltip'
import ScreensCollection from '../../global/collections/lesson_screens'
import { lesson_progress, lessons_screen_tooltip } from '@templates/student'

export default Backbone.View.extend({

  events: {
    'click .chunk': 'goToScreen',
    'mouseover .js-screen': 'showTooltip'
  },

  template: lesson_progress,

  serialize: function() {
    var screens = new ScreensCollection(this.screens.toJSON());
    screens.set(this.userLessonScreens.toJSON(), {add: false, remove: false});

    var completedScreens = Math.min(screens.length, this.userLessonScreens.toJSON().filter(function(row){ return row.typed > 0; }).length);
    // if(this.userLesson.get('_screenStats')) {
    //   var screenStats = this.userLesson.get('_screenStats'),
    //     pastScreen = this.userLessonScreens.get(screenStats.lesson_screen_id);
    //
    //   if(screenStats && !pastScreen) {
    //     completedScreens++;
    //   }
    // }


    return {
      screens: screens.toJSON(),
      lesson: this.lesson.toJSON(),
      userLesson: this.userLesson.toJSON(),
      progress: this.progress,
      completedScreens: completedScreens,
      Scoring: Scoring,
      unlockedScreens: Registry.get('student').hasOption('unlockedscreens')
    };
  },


  goToScreen: function(e) {
    if(!Registry.get('student').hasOption('unlockedscreens')) {
      return false;
    }

    var target = $(e.currentTarget),
      id = target.data('id'),
      index = this.screens.indexOf(this.screens.get(id));

    this.userLesson.set({restart: false, _state: ''});

    // if they've either done it, or
    if(index <= this.userLessonScreens.length) {
      this.userLesson.set({progress: index});
      window.location.safeReload();
    }

    return false;
  },

  screenTooltip: null,
  showTooltip: function(e) {
    var id = parseInt($(e.currentTarget).data('id')),
      userScreen = this.userLessonScreens.get(id),
      data = { userScreen: false, hideStats: false, showStars: true, stars: 0 },
      index = this.screens.indexOf(this.screens.get(id)) + 1;
    if(userScreen){
      var screen = userScreen.toJSON();
      data = {
        ...data,
        userScreen: true,
        scoringSpeed: Scoring.speed(screen.typed, screen.seconds, screen.errors),
        scoringAccuracy: Scoring.accuracy(screen.typed, screen.errors),
        scoringSeconds: parseInt(screen.seconds || 0).countdownSeconds(),
        stars: screen.stars
      };
    }
    if(this.lesson.get('content_type') === 'adventure') {
      data.slug = this.lesson.get('settings')?.slug
      data.screenType = this.lesson.get('settings')?.display || 'standard'
      data.showStars = false
    } else {
      data.screenType = this.screens.get(id).get('screen_type')
      data.slug = this.screens.get(id).get('settings')?.slug;
    }

    data.title = this.lesson.get('name') + ' - ' + index;

    if (this.lesson.get('content_type') === 'written-prompt') {
      data.hideStats = true
    }

    if(!this.screenTooltip) {
      this.screenTooltip = lessons_screen_tooltip;
    }

    Tooltip.show(e.currentTarget, {
      force: true,
      text: this.screenTooltip(data)
    });
  }

})
