import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import { isIOS, isAndroid } from '@shared/helpers'


/**
 * The global popup when achievements are achieved
 */
const AdView = Backbone.View.extend({

  adTargeting: null,

  pageTargeting: null,

  attributes: {
    class: 'advert-ad',
    'data-refresh-time': '30'
  },

  render: function() {
    if(!AdView.canShowAds()) {
      return this;
    }

    this.pageTargeting = this.pageTargeting || {};
    var user = Registry.get('student');
    this.pageTargeting.logged_in = (Registry.get('loggedIn')) ? 'yes' : 'no';
    if(Registry.get('loggedIn')) {
      this.pageTargeting.in_class = (user.get('in_class')) ? 'yes' : 'no';

      var ageValue = 'new',
        days = Math.floor((Math.floor(Date.now()/1000)-user.get('created_at'))/60/60/24);

      if(days > 365) {
        ageValue = 'over 365 days';
      } else if(days > 100) {
        ageValue = '101-365 days';
      } else if(days > 50) {
        ageValue = '51-100 days';
      } else if(days > 10) {
        ageValue = '11-50 days';
      } else if(days > 0) {
        ageValue = '1-10 days';
      }
      this.pageTargeting.account_age = ageValue;
    }

    this.el.id = this.id;
    this.$el.css({width: this.size[0] + 'px', height: this.size[1] + 'px'});
    this.$el.attr('data-page', this.page)

    var that = this;
    window.setTimeout(() => {
      if(window.__adNetwork === 'freestar') {
        // window?.freestar?.queue.push(() => {
        //   window?.freestar?.newAdSlots({
        //     placementName: that.id,
        //     slotId: that.id,
        //   })
        // })
        freestar?.config?.enabled_slots?.push({ placementName: that.id, slotId: that.id });

      } else if(window.__adNetwork === 'google') {
        googletag.cmd.push(function() {
          var slot = googletag.defineSlot(that.slot, that.size, that.id).addService(googletag.pubads());
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
        });
        googletag.cmd.push(function() { googletag.display(that.id); });
      } else if(window.__adNetwork === 'proper') {
        // Proper only needs to be run once, but these units are each individual components so the first one will call it
        if(AdView.properComplete) return
        AdView.properComplete = true

        Object.values(AdView.adsStatic).filter(function(ad){return ad;}).map(function(unit){
          window.propertag.cmd.push(() => {
            window.proper_display(unit.unit)

            setTimeout(() => {
              window.ProperMedia?.ad_project?.refreshSlotByName(unit.unit)
            }, 30*1000)
          })
        });
      } else if(window.__adNetwork === 'playwire') {
        // Playwire only needs to be run once, but these units are each individual components so the first one will call it
        if(!AdView.playwireComplete) {
          AdView.playwireComplete = true;

          const readyFunction = function(){
            var ads = AdView.adsStatic;
            // configure units
            var adUnits = Object.values(ads).filter(function(ad){return ad;}).map(function(unit){
              return {
                selectorId: unit.id,
                type: unit.unit
              };
            });

            window.ramp
              // pass in the array where you defined the units
              .addUnits(adUnits)
              .then(() => {
              // then show the units
              window.ramp.displayUnits()
              console.log('Playwire displayUnits called')
            }).catch( (e) =>{
              // catch errors
              window.ramp.displayUnits()
              console.error('Playwire error', e)
            })

          };

          if (window.ramp) {
            console.log('Attached readyFunction to ramp.onReady', window.ramp.onReady, window.ramp.addUnits);
            window.ramp.onReady = readyFunction;
          } else if (window.ramp?.addUnits) {
            console.log('Playwire adUnits function ready. Manually calling onReady()');
            readyFunction();
          } else {
            const timeout = setInterval(() => {
              if(window.ramp?.addUnits) {
                clearInterval(timeout)
                console.log('Playwire adUnits function called after interval refresh', window.ramp.onReady, window.ramp.addUnits);
                readyFunction()
              }
            }, 1000)
          }
        }
      }

      // If this is a skyscraper ad, find the button and make it visible
      if(that.size[0] <= that.size[1]/2) {
        if(that.size[0] >= 300) {
          if(window.__adNetwork !== 'freestar') {
            $('.js-report-button-grid').removeClass('g-b--12of12').addClass('g-b--6of12');
          }
          $('.js-report-button-grid:first-of-type').parent().removeClass('db');
        } else {
          $('.js-report-button-grid').removeClass('g-b--6of12').addClass('g-b--12of12');
          $('.js-report-button-grid:first-of-type').parent().addClass('db');
        }
        $('.js-report-btn').fastShow();
      }
    }, 0);

    if(this.size[0] <= this.size[1]/2) {
      const thresholdHeight = $(window).width() > 1200 ? 768 : 825;

      if($(window).innerHeight() >= thresholdHeight) {
        window.setTimeout(() => {
          this.setLeftPosition($('.structure-advert').offset()?.left)
          this.setupFixedAd(this.size);
        }, 0)
      }
    }

    window.setTimeout(() => {
      if ($('.js-skyscraper-ad').children().length === 0) {
        $('.structure-advert').addClass('structure--collapse')
      }
    }, 0)

    return this;
  },

  setupFixedAd: function() {
    const structureAdvertEle = $('.structure-advert'),
      offset = ($('.js-fixed-nav').height() || 0) + 10; // nav height plus margin for fixed add

    let topPosition = structureAdvertEle.offset().top;

    structureAdvertEle.prepend(`<div class="js-fixed-ad-placeholder" />`)
    const fixedAdPlaceholder = $(".js-fixed-ad-placeholder");
    fixedAdPlaceholder.css("width", this.size[0]+20+"px")

    // if the ad needs to accommodate a fixed nav, descend it's top position slightly
    if (offset > 0 && ($(window).width() > 1200 || $('.structure-content').height() > this.size[1])) {
      structureAdvertEle.addClass('structure--descended')
    }

    this.setSkyscraperPosition(topPosition, offset, structureAdvertEle);

    if( isIOS() || isAndroid() ) {
      $("body").on("touchmove", () => {
        window.requestAnimationFrame(() => { this.setSkyscraperPosition(topPosition, offset, structureAdvertEle) });
      });
    }

    window.addEventListener('scroll', () => {
      window.requestAnimationFrame(() => { this.setSkyscraperPosition(topPosition, offset, structureAdvertEle) });
    });

    window.addEventListener('resize', ()=> {
      this.setLeftPosition(structureAdvertEle.offset().left)
      topPosition = structureAdvertEle.offset().top
      this.setSkyscraperPosition(topPosition, offset, structureAdvertEle)
      fixedAdPlaceholder.css("width", this.size[0]+20+"px")
    });
  },

  setLeftPosition: function(left) {
    $('.structure-fixed').css({ left: `${left}px` });
  },

  setSkyscraperPosition: function(topPosition, offset, structureAdvertEle) {
    // Sets the ad as either fixed or absolute positioned
    if((window.scrollY >= topPosition - offset) && window.scrollY !== 0) {
      structureAdvertEle.addClass('is-fixed')
    } else {
      structureAdvertEle.removeClass('is-fixed')
    }
  },

}, {
  noAdPages: FTWGLOBALS('noAdPages'),
  controlAds: {},
  floorAds: {},
  adPage: '',
  adType: 'controlAds',

  initAds: function(page) {
    if(!this.canShowAds()) {
      return false;
    }

    if(window.__adNetwork === 'google') {
      AdView.adType = (Math.floor(Math.random() * 20)) ? 'floorAds' : 'controlAds';  // if the number is 1-19 it's a floor ad, if it's zero it's a control
    }


    this.adPage = page;
    if(window.__adNetwork === 'freestar') {
      this.initFreestarAds(page);
    } else if(window.__adNetwork === 'proper') {
      this.initProperAds(page);
    } else if(window.__adNetwork === 'playwire') {
      this.initPlaywireAds(page);
    } else {
      this.initGoogleAds(page);
    }
  },

  initPlaywireAds: function(page) {
    var size;

    size = [160, 600];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({unit: 'sky_btf', id: 'sky-btf', size: size, page: page});

    size = [300, 600];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({unit: 'sky_atf', id: 'sky-atf', size: size, page: page});

    size = [300, 250];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({unit: 'corner_ad_video', id: 'med_rect-atf', size: size, page: page});
    // this.controlAds[size[0]+'x'+size[1]] = new AdView({unit: 'med_rect_atf', id: 'med_rect-atf', size: size, page: page}); // video ad test (search for this comment to reverse)

    size = [320, 50];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({unit: 'med_rect_btf', id: 'med_rect-btf', size: size, page: page});

    size = [468, 60];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({unit: 'med_rect_btf', id: 'med_rect-btf', size: size, page: page});

    size = [970, 90];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({unit: 'leaderboard_atf', id: 'leaderboard-atf', size: size, page: page});
  },

  initFreestarAds: function(page) {
    var size;

    size = [160, 600];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'typing_siderail_left', size: size, page: page});

    size = [300, 600];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'typing_siderail_300x600', size: size, page: page});

    size = [300, 250];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'typing_medrec_learning', size: size, page: page});

    size = [320, 50];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'Typing_320x50', size: size, page: page});

    size = [468, 60];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'Typing_468x60_320x50', size: size, page: page});

    size = [970, 90];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'typing_leaderboard_atf', size: size, page: page});
  },

  initProperAds: function(page) {
    var size;

    size = [160, 600];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({unit: 'typing_skyscraper', id: 'proper-ad-typing_skyscraper', size: size, page: page});

    size = [300, 600];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({unit: 'typing_side', id: 'proper-ad-typing_side', size: size, page: page});

    size = [300, 250];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({unit: 'typing_square', id: 'proper-ad-typing_square', size: size, page: page});

    size = [320, 50];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({unit: 'typing_top_mobile', id: 'proper-ad-typing_top_mobile', size: size, page: page});

    size = [468, 60];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({unit: 'typing_top_mobile', id: 'proper-ad-typing_top_mobile', size: size, page: page});

    size = [970, 90];
    this.controlAds[size[0]+'x'+size[1]] = new AdView({unit: 'typing_top', id: 'proper-ad-typing_top', size: size, page: page});
  },

  initGoogleAds: function(page) {
    var size;

    if(page === 'lesson_intro') {
      size = [300, 600];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-3916231013424-0', size: size, slot: '/35254017/TC_Lesson_Intro_300x600_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-9228907761987-0', size: size, slot: '/35254017/TC_Lesson_Intro_300x600_Floor1', page: page});

      size = [160, 600];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-8274143975562-0' , size: size, slot: '/35254017/TC_Lesson_Intro_160x600_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-1879452167871-0', size: size, slot: '/35254017/TC_Lesson_Intro_160x600_Floor1', page: page});

      size = [300, 250];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-5530863543772-0', size: size, slot: '/35254017/TC_Lesson_Intro_2_300x250_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-6157145785184-0', size: size, slot: '/35254017/TC_Lesson_Intro_2_300x250_Floor1', page: page});
    } else if(page === 'game_play') {
      size = [970, 90];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-9261624027743-0', size: size, slot: '/35254017/TC_Game_Play_970x90_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-4744718206554-0', size: size, slot: '/35254017/TC_Game_Play_970x90_Floor1', page: page});

      size = [468, 60];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-3660375587325-0', size: size, slot: '/35254017/TC_Game_Play_2_468x60_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-5140197210763-0', size: size, slot: '/35254017/TC_Game_Play_2_468x60_Floor1', page: page});

      size = [320, 50];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-9129062889633-0', size: size, slot: '/35254017/TC_Game_Play_320x50_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-9856155217960-0', size: size, slot: '/35254017/TC_Game_Play_320x50_Floor1', page: page});

      size = [300, 600];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-7444224442192-0', size: size, slot: '/35254017/TC_Game_Play_300x600_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-7270269551348-0', size: size, slot: '/35254017/TC_Game_Play_300x600_Floor1', page: page});

      size = [300, 250];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-5154910701351-0', size: size, slot: '/35254017/TC_Game_Play_300x250_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-1581225836208-0', size: size, slot: '/35254017/TC_Game_Play_300x250_Floor1', page: page});

      size = [160, 600];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-6638532037321-0', size: size, slot: '/35254017/TC_Game_Play_2_160x600_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-9233103277544-0', size: size, slot: '/35254017/TC_Game_Play_2_160x600_Floor1', page: page});

    } else if(page === 'lesson_screen') {
      size = [970, 90];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-3054282196274-0', size: size, slot: '/35254017/TC_Lesson_Screen_970x90_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-6292715905483-0', size: size, slot: '/35254017/TC_Lesson_Screen_970x90_Floor1', page: page});

      size = [728, 90];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-9245001256859-0', size: size, slot: '/35254017/TC_Lesson_Screen_728x90_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-9872704643189-0', size: size, slot: '/35254017/TC_Lesson_Screen_728x90_Floor1', page: page});

      size = [468, 60];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-9160337077190-0', size: size, slot: '/35254017/TC_Lesson_Screen_468x60_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-6007227681289-0', size: size, slot: '/35254017/TC_Lesson_Screen_468x60_Floor1', page: page});

      size = [320, 50];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-5102930108543-0', size: size, slot: '/35254017/TC_Lesson_Screen_320x50_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-8672204390722-0', size: size, slot: '/35254017/TC_Lesson_Screen_320x50_Floor1', page: page});

      size = [300, 600];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-3931305775679-0', size: size, slot: '/35254017/TC_Lesson_Screen_300x600_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-7047720019504-0', size: size, slot: '/35254017/TC_Lesson_Screen_300x600_Floor1', page: page});

      size = [160, 600];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-7503911098377-0', size: size, slot: '/35254017/TC_Lesson_Screen_2_160x600_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-7276788543986-0', size: size, slot: '/35254017/TC_Lesson_Screen_2_160x600_Floor1', page: page});

      size = [300, 250];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-5451948300927-0', size: size, slot: '/35254017/TC_Lesson_Screen_300x250_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-2480804532919-0', size: size, slot: '/35254017/TC_Lesson_Screen_300x250_Floor1', page: page});

    } else {
      size = [970, 90];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-5168043871729-0', size: size, slot: '/35254017/TC_Dashboard_4_970x90_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-3907926832722-0', size: size, slot: '/35254017/TC_Dashboard_4_970x90_Floor1', page: page});

      size = [728, 90];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-3095120872738-0', size: size, slot: '/35254017/TC_Dashboard_728x90_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-1502408530712-0', size: size, slot: '/35254017/TC_Dashboard_728x90_Floor1', page: page});

      size = [468, 60];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-7097153922844-0', size: size, slot: '/35254017/TC_Dashboard_468x60_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-9670998057892-0', size: size, slot: '/35254017/TC_Dashboard_468x60_Floor1', page: page});

      size = [320, 50];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-3068998803985-0', size: size, slot: '/35254017/TC_Dashboard_320x50_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-7791228662593-0', size: size, slot: '/35254017/TC_Dashboard_320x50_Floor1', page: page});

      size = [300, 600];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-1971137319174-0', size: size, slot: '/35254017/TC_Dashboard_300x600_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-6548718471744-0', size: size, slot: '/35254017/TC_Dashboard_300x600_Floor1', page: page});

      size = [160, 600];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-9775943648506-0', size: size, slot: '/35254017/TC_Dashboard_160x600_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-6467772497742-0', size: size, slot: '/35254017/TC_Dashboard_160x600_Floor1', page: page});

      size = [300, 250];
      this.controlAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-2883018025860-0', size: size, slot: '/35254017/TC_Dashboard_300x250_Control', page: page});
      this.floorAds[size[0]+'x'+size[1]] = new AdView({id: 'div-gpt-ad-7958555434737-0', size: size, slot: '/35254017/TC_Dashboard_300x250_Floor1', page: page});
    }
  },


  adsStatic: null,
  getAds: function(options) {
    var ads = this._getAdPlacements(options);

    this.adsStatic = ads;
    return ads;
  },

  getLeftMarginClass: function(options = {}) {
    if(!this.canShowAds()) return '';

    // sub screens don't show ads because the wrapping screen
    // will handle it for them
    if (options.isSubScreen) {
      return ''
    }

    var adPlacements = this._getAdPlacements(options),
      skyscraperAdSize = adPlacements.skyscraper ? adPlacements.skyscraper.size[0] : undefined;

    return (options.forceFixedMargin || skyscraperAdSize >= 300 ? 'has-structure--fixedLeftMargin' : (skyscraperAdSize < 300 ? 'has-structure--fixedLeftMargin_s' : ''));
  },

  isSquareAd: function(options) {
    if(!this.canShowAds()) return false;

    var adPlacements = this._getAdPlacements(options);
    return adPlacements.squareBottomRight;
  },

  _getAdPlacements: function(options) {
    options = options || {};

    if(!this.canShowAds()) {
      return {};
    }

    var w = window.innerWidth,
      h = window.innerHeight,
      page = this.adPage,
      targetingPage = 'tc_other';

    switch(page) {
    case 'dashboard':
      targetingPage = 'tc_lessons_list';
      break;
    case 'games_list':
      targetingPage = 'tc_games_list';
      break;
    case 'game_play':
      targetingPage = 'tc_game_play';
      break;
    case 'lesson_intro':
      targetingPage = 'tc_lesson_intro';
      break;
    case 'lesson_screen':
      targetingPage = 'tc_lesson_screen';
      break;
    case 'tests_list':
      targetingPage = 'tc_tests_list';
      break;
    }

    if(page === 'dashboard') {
      if(w >= 1625) {
        return {
          skyscraper: this.getAd('300x600', {page: targetingPage}, {position: 'left'})
          // add corner unit here if we disable video
        }
      } else if(w >= 1010) {
        return {
          skyscraper: this.getAd('160x600', {page: targetingPage}, {position: 'left'}),
        };
      } else if(w >= 800) {
        return {};
      } else {
        return {
          square: this.getAd('300x250', {page: targetingPage}, {position: 'right'})
        };
      }
    }

    if(page === 'game_play') {
      if(w >= 1625) {
        return {
          top: this.getAd('970x90', {page: targetingPage}, {position: 'atf'}),
          skyscraper: this.getAd('300x600', {page: targetingPage}, {position: 'left'}),
          // add corner unit here if we disable video
        };
      }
      if(w >= 1350) {
        return {
          top: this.getAd('970x90', {page: targetingPage}, {position: 'atf'}),
          skyscraper: this.getAd('300x600', {page: targetingPage}, {position: 'left'}),
        };
      } else if(w >= 1200) {
        return {
          top: this.getAd('970x90', {page: targetingPage}, {position: 'atf'}),
          skyscraper: this.getAd('160x600', {page: targetingPage}, {position: 'left'})
        };
      } else if(w >= 1010) {
        return {
          top: this.getAd('970x90', {page: targetingPage}, {position: 'atf'})
        };
      } else if(w >= 800) {
        return {
          top: this.getAd('468x60', {page: targetingPage}, {position: 'atf'})
        };
      } else {
        return {
          top: this.getAd('320x50', {page: targetingPage}, {position: 'atf'})
        };
      }
    }

    if(page === 'lesson_intro') {
      if(options.totalScreens === 0 && !options.forceAd) {
        return {};
      }
      if(w >= 1350) {
        return {
          skyscraper: this.getAd('300x600', {page: targetingPage}, {position: 'left'}),
          // squareBottomRight: (options.totalScreens <= 1) ? null : this.getAd('300x250', {page: targetingPage}, {position: 'right'})
        };
      } else if(w >= 800) {
        return {
          skyscraper: this.getAd('160x600', {page: targetingPage}, {position: 'left'})
        };
      } else {
        return {};  // no ads :'(
      }
    }

    if(page === 'lesson_screen') {
      if(options.totalScreens === 0 && !options.forceAd) {
        return {};
      }
      if(w >= 1350) {
        return {
          top: (options.totalScreens <= 2 && !options.forceAd) ? null : this.getAd('970x90', {page: targetingPage}, {position: 'atf'}),
          skyscraper: this.getAd('300x600', {page: targetingPage}, {position: 'left'}),
          // squareBottomRight: this.getAd('300x250', {page: targetingPage}, {position: 'right'})
        };
      } else if(w >= 1010) {
        return {
          top: (options.totalScreens <= 2 && !options.forceAd) ? null : this.getAd('970x90', {page: targetingPage}, {position: 'atf'}),
          skyscraper: this.getAd('160x600', {page: targetingPage}, {position: 'left'})
        };
      } else if(w >= 800) {
        return {
          top: this.getAd('468x60', {page: targetingPage}, {position: 'atf'})
        };
      } else if(w >= 508) {
        return {
          top: this.getAd('320x50', {page: targetingPage}, {position: 'atf'})
        };
      }
    }

    // everything else
    // games_list, tests_list, other (account, achievements, scoreboard, skins) 

    if(w >= 1625) {
      return {
        top: this.getAd('970x90', {page: targetingPage}, {position: 'atf'}),
        skyscraper: this.getAd('300x600', {page: targetingPage}, {position: 'left'}),
        // add corner unit here if we disable video
      };
    }
    if(w >= 1350) {
      return {
        top: this.getAd('970x90', {page: targetingPage}, {position: 'atf'}),
        skyscraper: this.getAd('300x600', {page: targetingPage}, {position: 'left'}),
      };
    } else if(w >= 1010) {
      return {
        top: this.getAd('970x90', {page: targetingPage}, {position: 'atf'}),
        skyscraper: this.getAd('160x600', {page: targetingPage}, {position: 'left'})
      };
    } else if(w >= 800) {
      return {
        top: this.getAd('468x60', {page: targetingPage}, {position: 'atf'})
      };
    } else {
      return {
        top: this.getAd('320x50', {page: targetingPage}, {position: 'atf'})
      };
    }
  },

  getAd: function(size, pageTargeting, adTargeting) {
    if(!this.canShowAds()) {
      return false;
    }

    const adType = AdView.adType
    if(this[adType][size]) {
      var ad = this[adType][size];
      ad.pageTargeting = pageTargeting;
      ad.adTargeting = adTargeting;
      return ad;
    }
  },

  canShowAds: function() {
    if(window.location.pathname.match('student/lessons') && !Registry.get('loggedIn') && Registry.get('userLessons').length === 0 && Registry.get('userGames').length === 0 && Registry.get('userTests').length === 0) {
      return false;
    }
    return !FTWGLOBALS('whitelabel') &&
      !window.localStorage.getItem('student_license') && // No license
      this.noAdPages && this.noAdPages.filter(function(page){ return window.location.pathname.match(page); }).length === 0;
  }

});

export default AdView
