import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import ScoreModel from '../../global/models/user_game'
import ChartView from '../../global/views/chart'
import GuestBannerView from '../../global/views/guest_banner'
import { filter, map } from 'lodash-es'
import moment from 'moment'
import { games_scoreboard } from '@templates/student'

export default Backbone.View.extend({

  template: games_scoreboard,

  events: {
    'click .js-filter-difficulty,.js-filter-level': 'filter'
  },

  hasLoaded: true,

  selectedDifficulties: [],
  selectedLevels: [],

  initialize: function() {
    this.scores = Registry.get('userGames');
    this.listenTo(this.scores, 'update', this.render);

    if(Registry.get('loggedIn') && FTWGLOBALS('game').scoreboard && this.scores.where({game_id: FTWGLOBALS('game').game_id}).length === 0) {
      this.hasLoaded = false;
      this.scores.getScores(FTWGLOBALS('game').game_id).done(this.update.bind(this));
    } else {
      this.hasLoaded = true;
    }

    this.overlayView = null;

    if(!Registry.get('loggedIn')) {
      this.overlayView = new GuestBannerView({
        isChart: true,
        customTitle: this.getHighscoreText()
      })
    }
    this.chartView = new ChartView({
      chartId: 'game',
      title: null,
      height: 97,
      width: 300,
      toggles: ['score'],
      dataLength: this.getScores().length,
      overlayView: this.overlayView
    });
    this.addChild('.js-chart-game', this.chartView);
  },

  serialize: function() {
    return {
      moment: moment,
      selectedDifficulties: this.selectedDifficulties,
      selectedLevels: this.selectedLevels,
      levels: this.scores.getLevels(FTWGLOBALS('game').game_id),
      difficulties: this.scores.getDifficulties(FTWGLOBALS('game').game_id),
      loggedIn: Registry.get('loggedIn'),
      scores: filter(this.scores.toJSON(), {game_id: FTWGLOBALS('game').game_id}),
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    if(this.hasLoaded) {
      this.liveTextElement = $('.js-chart-game-aria-live-text');

      window.setTimeout(() => {
        var chartData = this.getChartData();
        this.chartView.resetChart(chartData);

        if(this.overlayView) {
          this.overlayView.customTitle = this.getHighscoreText()
          this.overlayView.render()
        }

        // Format the chartData for screen readers
        let formattedChartData = chartData.data.map((dataPoint, index) => {
          return 'scoredboard.chartdata.point_text'.t({index: index + 1, score: dataPoint.score, time: new Date(chartData.labels[index]).toLocaleString()})
        }).join('. ');

        this.liveTextElement.text(`${'scoredboard.chartdata.intro_text'.t()} ${formattedChartData}`);
        
      }, 0);
    }

    return this;
  },

  filter: function(e){
    e.preventDefault();
    this.selectedDifficulties = this.$('.js-filter-difficulty:checked').map(function(){ return $(this).val(); }).get();
    this.selectedLevels = this.$('.js-filter-level:checked').map(function(){ return $(this).val(); }).get();
    this.render();
  },

  update: function(data) {
    this.hasLoaded = true;
    this.scores.add(data, {merge: true});
  },

  getChartData: function() {
    var chartData = {},
      data = this.getScores();

    if (!Registry.get('loggedIn')) {
      data = [
        {
          score: (data[0] ? data[0].score : 1500),
          created_at: (data[0] ? data[0].created_at : Date.now() / 1000)
        },
        {
          score: (data[0] ? Math.ceil(data[0].score * 1.01) : 1515),
          created_at: Date.now() / 1000 + 86400
        },
        {
          score: (data[0] ? Math.ceil(data[0].score * 1.015) : 1523),
          created_at: Date.now() / 1000 + 86400 * 2
        },
        {
          score: (data[0] ? Math.ceil(data[0].score * 1.025) : 1538),
          created_at: Date.now() / 1000 + 86400 * 3
        },
        {
          score: (data[0] ? Math.ceil(data[0].score * 1.05) : 1575),
          created_at: Date.now() / 1000 + 86400 * 4
        }
      ];

      chartData.options = { // Only force a y-axis padding for zero-state charts
        score: {
          scales: {
            yAxes: [
              {
                ticks: {
                  min: data[0].score - 10
                }
              }]
          }
        }
      };
    } else {
      data.reverse();
    }

    chartData.labels = data.map(function (row) { return row.created_at * 1000; });
    chartData.data = data.map(function (row) { return { score: row.score }; });

    return chartData;
  },

  getScores: function() {
    return this.scores.toJSON()
      .filter(function(row) {
        return row.game_id === FTWGLOBALS('game').game_id &&
          (this.selectedLevels.length === 0 || this.selectedLevels.indexOf(row.level) !== -1) &&
          (this.selectedDifficulties.length === 0 || this.selectedDifficulties.indexOf(row.difficulty) !== -1);
      }.bind(this));
  },

  gameOver: function(data) {
    if (data.seconds <= 0) return; // Don't save their score

    data.game_id = FTWGLOBALS('game').game_id;
    data.created_at = Date.getUnixTime();

    var model = new ScoreModel(data);
    model.saveScore().done(() => this.scores.add(model));
  },

  getHighscoreText: function() {
    let highscore = 0

    if(this.getScores().length > 0) {
      highscore = Math.max(...map(this.getScores(), 'score'))
    }

    return (highscore > 100) ? 'game.guest_banner_wow_score_title'.t({smart_count: highscore }) : ''
  }
})
