import Backbone from 'backbone'
import Registry from '@shared/registry';
import SignupForm from '../../global/models/signup'
import Alert from '@shared/alert'
import { find } from 'lodash-es';
import { Cookies, googleLogin, microsoftLogin, cleverLogin, classlinkLogin } from '@shared/helpers';
import { signup_main } from '@templates/student'

export default Backbone.View.extend({

  section: {},

  formSelector: '#reg-form',

  modelClass: SignupForm,

  events: {
    'click .js-clever-login': 'cleverAuth',
    'click .js-google-login': 'googleAuth',
    'click .js-microsoft-login': 'microsoftAuth',
    'click .js-classlink-login': 'classlinkAuth',
    'change input#form-ele-coppa': 'showParentalConsent',
    'click .js-switch': function() { this.trigger('switch'); return false }
  },

  template: signup_main,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments)

    if(!Registry.get('loggedIn') && Registry.get('userActivity').length) {
      this.saveAnonProgress();
    }
  },

  serialize: function() {
    return {
      section: this.section,
      joinCode: this.joinCode
    };
  },

  /**
   * Stick a bunch of data in a cookie for any anon typing activity and save it on registration
   */
  saveAnonProgress: function() {
    try{
      var user = [Registry.get('student').get('skin_id'), Registry.get('student').get('variant_id')];

      var tests = Registry.get('userTests').toJSON().map(function(row){
        return [row.lesson_id, row.seconds, row.errors, row.typed, row.now || row.created_at];
      });

      var screens = Registry.get('userLessonScreens').toJSON().filter(function(row){ return Registry.get('userLessons').get(row.lesson_id).get('max_progress') && find(FTWGLOBALS('lessons'), {lesson_id: row.lesson_id});  }).map(function(row){
        return [Registry.get('student').getSetting('product_id'), row.lesson_id, row.lesson_screen_id, row.typed, row.errors, row.seconds, row.stars, Registry.get('userLessons').get(row.lesson_id).get('max_progress'), (Registry.get('userLessons').get(row.lesson_id).get('max_progress') === find(FTWGLOBALS('lessons'), {lesson_id: row.lesson_id}).screens) ? 1 : 0, row.now || row.created_at];
      });

      var games = Registry.get('userGames').toJSON().map((row) => ([row.game_id, row.difficulty, row.level, row.score, row.seconds, row.now || row.created_at]))

      var data = JSON.stringify({
        u: user,
        t: tests,
        s: screens,
        g: games
      });

      if(data.length < 4000) {
        Cookies.set('student_anon', data, {path: '/'});
      }
    }catch(e){
      console.error(e);
    }
  },

  submitCallback: function(data) {
    // On modals, this button becomes clickable after form submission and we don't want that
    this.$('.js-submit').addClass('btn--loading').prop('disabled', true)

    this.setupRedirect()

    data.joinCode = this.joinCode;
    Registry.get('student').loginWithData(data);

    window.location.href = __url('/student/lessons');
  },

  googleAuth: function() {
    this.setupRedirect()

    googleLogin('student', { joinCode: this.joinCode })

    return false
  },

  microsoftAuth: function() {
    this.setupRedirect()

    microsoftLogin('student', { joinCode: this.joinCode })

    return false
  },

  cleverAuth: function() {
    this.setupRedirect()

    cleverLogin('student')

    return false
  },

  classlinkAuth: function() {
    this.setupRedirect()

    classlinkLogin('student')

    return false
  },

  setupRedirect: function() {
    if(!window.location.pathname.match(/\/signup$/) && !window.location.pathname.match(/\/login$/)) {
      window.localStorage.setItem('_oneTimeRedirect', window.location.href.replace(/\/typing-test\/.*/, '/tests'))
      if(window.location.pathname.match(/student\/upgrade/)) {
        window.localStorage.setItem('_forceUpgrade', 1)
      }
    }
  },

  showParentalConsent: function(e) {
    if(!this.$('input#form-ele-coppa').prop('checked')){
      new Alert({
        title: 'shared.signup.coppa_age_title'.t(),
        text: 'shared.signup.coppa_age_paragraph'.t()
      });
    }
  }
})
