import Backbone from 'backbone'
import Registry from '@registry'
import PasswordView from '../../account/views/password'
import ProfileView from '../../account/views/profile'
import ProfileForm from '../../account/models/profile_form'
import PasswordForm from '../../account/models/password_form'
import moment from 'moment'
import { assessments_usuario } from '@templates/student'

export default Backbone.View.extend({

  template: assessments_usuario,

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.user = Registry.get('student');

    this.views.profile = new ProfileView({
      model: new ProfileForm()
    });
    this.addChild('.js-profile', this.views.profile, false);

    this.views.password = new PasswordView({
      model: new PasswordForm()
    });
    this.addChild('.js-password', this.views.password, false);
  },

  serialize: function() {
    return {
      moment: moment,
      user: this.user.toJSON(),
    };
  }
})
