import Backbone from 'backbone'
import Registry from '@registry'
import Scoring from '@shared/scoring'
import SVG from '../views/svg'
import moment from 'moment'
import { each, uniq, find, filter, map } from 'lodash-es'

export default Backbone.Model.extend({

  idAttribute: 'achievement_id',

  defaults: {
    achievement_group_id: 0,
    name: '',
    rules: '',
    created_at: 0,
    hint: 0 // this is a special field used to let the DB know exactly which record to look at
  },

  /**
   * Method for the view to format completed date
   * @returns {String}
   */
  prettyCompletedStamp: function() {
    return moment(this.get('created_at')*1000).fromNow();
  },

  /**
   * Format the progress text, like Total Races: 10/200
   * @returns {String}
   */
  progress: function() {
    var progressRules = [],
      rules = this.get('rules'),
      group = Registry.get('achievementGroups').get(this.get('achievement_group_id')),
      prefix, suffix, format, progValue, ruleValue;

    each(rules, (rule, index) => {
      if(group.get('progress_type') === 'individual') {
        return;
      }

      progValue = this.getUserValue();
      ruleValue = rule.value;
      if ((rule.operand === '>' || rule.operand === '>=') && progValue >= ruleValue) {
        progValue = Math.min(progValue, ruleValue);
      }
      // format = text[rule.field].format || null;

      if(group.get('type') === 'games' || group.get('type') === 'time') {
        progValue = Math.floor(progValue / 60);
        ruleValue = Math.floor(ruleValue / 60);
      }

      progressRules.push(('achievements.' + group.get('type') + '.progress').t({
        earned: (Number(progValue) === 1) ? Number(progValue) : Number(progValue).addCommas(0),
        smart_count: (Number(ruleValue) === 1) ? Number(ruleValue) : Number(ruleValue).addCommas(0)
      }));
    });

    if (progressRules.length > 0) {
      return progressRules[0];
    }
    return '';
  },

  name: function() {
    var groupType = Registry.get('achievementGroups').get(this.get('achievement_group_id')).get('type'),
      rule = this.get('rules')[0],
      value = rule.value;

    if(groupType === 'games' || groupType === 'time') {
      value = value/60;
    }
    return ('achievements.'+groupType+'.ach_name').t({smart_count: (Number(value) === 1) ? Number(value) : Number(value).addCommas(0)})
  },

  /**
   * Get the text representing the subgroup
   */
  subGroupText: function() {
    var group = Registry.get('achievementGroups').get(this.get('achievement_group_id')),
      type = group.get('type'),
      subGroup = this.get('sub_group');

    // if there's only one subgroup, don't show anything
    if(uniq(map(filter(Registry.get('achievements').toJSON(), {achievement_group_id: group.id}), 'sub_group')).length === 1) {
      return '';
    }

    switch(type) {
    case 'speed':
      return 'achievements.speed.header'.t({smart_count: subGroup});
    case 'tests':
      return 'achievements.tests.header'.t({time: subGroup.countdownSeconds()});
    case 'games':
      return (find(FTWGLOBALS('games'), {game_id: subGroup}) || {name:'Unknown'}).name;
    }
    return '';
  },

  getBadgeView: function() {
    var type = Registry.get('achievementGroups').get(this.get('achievement_group_id')).get('type'),
      path = '/dist/student/images/badges/badge-' + type,
      rules = this.get('rules');

    // different images have slightly different paths
    if(type === 'games') {
      var games = FTWGLOBALS('games'),
        game = find(games, {game_id: this.get('sub_group')});

      if(!game) {
        path = path + '-unknown-game';
      } else {
        path = path + '-' + game.folder;
      }
    }
    if(type === 'tests') {
      path = path + '-' + this.get('sub_group');
    }

    // grab the SVG by ajax so we can modify it and slap it on the page
    return new SVG({
      path: path + '.svg',
      preprocess: function(svgEle){ // downloads the elements and switches out necessary text
        var titleTextEle = svgEle.querySelector('.achievement-title');

        var value = rules[0].value;
        if(type === 'games' || type === 'time') {
          value = parseInt(value / 60);
        }
        svgEle.querySelector('.achievement-subtitle').textContent = ('achievements.'+type+'.counter').t({smart_count: value});
        titleTextEle.textContent = Number(value).addCommas(0);

        if(titleTextEle.textContent.length > 3) {
          titleTextEle.setAttribute('class', titleTextEle.className.baseVal + ' achievement-title--squeezed'); // if the text is big, it needs a different ID
        }else if(titleTextEle.textContent.length > 2) {
          titleTextEle.setAttribute('class', titleTextEle.className.baseVal + ' achievement-title--small'); // if the text is big, it needs a different ID
        }

      }.bind(this)
    });

  },

  /**
   * Get the percent complete for the achievement
   * @return {Number}
   */
  progressPercent: function() {
    var rules = this.get('rules'),
      type = Registry.get('achievementGroups').get(this.get('achievement_group_id')).get('type'),
      progValue, ruleValue;

    if (rules[0].operand !== '>' && rules[0].operand !== '>=') {
      return 0;
    }

    if(type === 'games' || type === 'time') {
      progValue = Math.floor(this.getUserValue() / 60);
      ruleValue = Math.floor(rules[0].value / 60);
    } else {
      progValue = this.getUserValue();
      ruleValue = rules[0].value;
    }

    return Math.min(100, Math.floor((progValue/ruleValue)*100));
  },

  comparisons: {
    '>=': function(a, b) { return a >= b; },
    '>': function(a, b) { return a > b; },
    '==': function(a, b) { return a === b; },
    '===': function(a, b) { return a === b; },
    '<=': function(a, b) { return a <= b; },
    '<': function(a, b) { return a < b; }
  },

  /**
   * Check if they have completed this achievement by comparing to the 'comp' object
   * @returns {boolean}
   */
  checkIfComplete: function() {
    var rules = this.get('rules'),
      rulesPassed = 0,
      len,i,userVal, passed;

    for(i=0, len=rules.length, passed=0; i<len; i++) {
      userVal = this.getUserValue();
      if(!this.comparisons[rules[i].operand]) {
        if (typeof console !== 'undefined') {
          console.log('Unknown operand in achievement: '+this.id+', comp: '+rules[i].operand);
        }
        continue;
      }

      rulesPassed += (this.comparisons[rules[i].operand](userVal, rules[i].value)) ? 1 : 0;
    }

    return rulesPassed === len;
  },

  getUserValue: function() {
    var type = Registry.get('achievementGroups').get(this.get('achievement_group_id')).get('type'),
      userActivity = Registry.get('userActivity'),
      userLessons = Registry.get('userLessons'),
      userLessonScreens = Registry.get('userLessonScreens'),
      userTests = Registry.get('userTests'),
      userGames = Registry.get('userGames'),
      rules = this.get('rules'),
      subGroup = parseInt(this.get('sub_group')),
      value;

    switch(type) {
    case 'stars':   // total stars earned
    case 'typed':   // total characters typed
    case 'time':    // total time spent typing lessons
      value = userActivity.getCompiled(0)[rules[0].field];
      break;
    case 'tests':
      // this calculates the number of chars required to type in a number of seconds to achieve a given wpm
      // WPM = (TYPED / 5) / (SECONDS / 60);
      // TYPED = (SECONDS * SPEED) / 12
      // sub_group = seconds, rule[value] = wpm
      var toType = (subGroup * rules[0].value)/12;
      value = userTests.toJSON()
        .filter(function(row){
          return row.seconds === subGroup;
        })
        .reduce(function(memo, row){
          return Math.max(memo, Scoring.speed(row.typed, row.seconds, row.errors));
        }, 0);
      break;
    case 'games':
      value = userGames.toJSON()
        .filter(function(row){
          return row.game_id === subGroup;
        })
        .reduce(function(memo, row){
          return memo + row.seconds;
        }, 0);
      break;
    case 'speed':
      value = userLessonScreens.toJSON()
        .filter(function(row){
          return row.seconds >= subGroup;
        })
        .reduce(function(memo, row){
          var speed = Scoring.speed(row.typed, row.seconds, row.errors);
          if(speed >= rules[0].value) {
            this.set({hint: row.lesson_screen_id});
          }
          return Math.max(memo, speed);
        }.bind(this), 0);
      break;
    }

    return value;
  }

})
