import $ from 'jquery'
import Registry from '@registry'
import Model from '@shared/language_form'
import Modal from './modal'
import KeyboardHelperModal from './keyboard_helper_modal';
import { isWindows } from '@shared/helpers'
import { global_language_modal } from '@templates/student'

export default Modal.extend({
  titleText: 'account.language_settings_title'.t(),

  template: global_language_modal,

  width: 'm',

  modelClass: Model,

  formSelector: 'form',

  events: {
    'change #form-ele-language': 'changeLanguageInline',
    'click .js-keyboard-helper': 'keyboardHelper'
  },

  serialize: function() {
    var languages = Registry.get('languages'),
      emojiFlags = (isWindows()) ? {} : FTWGLOBALS('emojiFlags'),  // stupid Edge doesn't support emojis in a modal
      getEmoji = function(country){ return (!emojiFlags[(country || '').toUpperCase()]) ? '' : emojiFlags[(country || '').toUpperCase()]; };

    return {
      languages: languages.toJSON().map(function(row){ return [row.id,  getEmoji(row.flag || row.language || row.country) + ' ' + row.name]; }),
      keyboards: FTWGLOBALS('keyboards').filter(function(row){ return row.type !== 'keypad';}).map(function(row){ return [row.keyboard_id, getEmoji(row.country || row.language) + ' ' + row.name]; }),
      language: FTWGLOBALS('language'),
      displayLanguage: (FTWGLOBALS('language') === 'en-gb') ? 'en' : FTWGLOBALS('language'),
      keyboard_id: this.user.getSetting('keyboard_id')
    }
  },

  changeLanguageInline: function(e) {
    var language = $(e.currentTarget).val(),
      siteLabel = this.$('label[for=form-ele-language]'),
      curriculumLabel = this.$('label[for=form-ele-product_id]'),
      keyboardLabel = this.$('label[for=form-ele-keyboard_id]');

    if(language === 'en-gb') {
      language = 'en';
    }

    siteLabel.text(('language_modal.' + language + '_site_language').t());
    curriculumLabel.text(('language_modal.' + language + '_curriculum').t());
    keyboardLabel.text(('language_modal.' + language + '_keyboard_layout').t());
  },

  keyboardHelper: function() {
    new KeyboardHelperModal({ currentKeyboardId: this.$('select[name="keyboard_id"]').val() }).open()
    .done((data) => {
      this.$('select[name="keyboard_id"]').val(data.keyboard_id)
    })

    return false;
  },
})
