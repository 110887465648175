import FormModel from '@shared/form_model'

export default FormModel.extend({

  offlineSupport: true,

  defaults: {

  },

  validationRules: {

  },
  validationMessages: {

  },

  url: '/apiv1/student/user/profile'
})
