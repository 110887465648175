import Modal from '../../global/views/modal'
import Model from '../models/sound_form'
import { lesson_sound_modal } from '@templates/student'

export default Modal.extend({
  titleText: 'lesson.sound_settings'.t(),

  template: lesson_sound_modal,

  modelClass: Model,

  events: {
    'click .js-submit': 'clickOk',
    'keydown .has-switch': 'toggleCheckbox'
  },

  serialize: function() {
    return {
      screen: (this.screen) ? this.screen.toJSON() : {},
      typing_sounds: this.user.getSetting('typing_sounds'),
      error_sounds: this.user.getSetting('error_sounds')
    };
  },

  clickOk: function(e) {
    this.close();
    this.resolve({
      typing_sounds: !!this.$('.js-key-sounds').prop('checked'),
      error_sounds: !!this.$('.js-error-sounds').prop('checked'),
    });
    return false;
  },

  toggleCheckbox: function(e) {
    // Check if the key pressed is the Spacebar (key code 32) or Enter (key code 13)
    if (e.keyCode === 32 || e.keyCode === 13) {
      // Find the checkbox associated with the label that triggered the event and toggle its state
      var checkbox = this.$(e.target).closest('.has-switch').find('input[type="checkbox"]');
      checkbox.prop('checked', !checkbox.prop('checked'));
      e.preventDefault(); 
    }
  }
});
