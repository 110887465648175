import Backbone from 'backbone'
import { join_failed } from '@templates/student'

export default Backbone.View.extend({

  template: join_failed,

  serialize: function() {
    return {
      error: this.error
    };
  }

})
