import $ from 'jquery'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import TypingView from './typing'
import Velocity from 'velocity-animate'
import { extend } from 'lodash-es'
import { lesson_typing_fill_in_blank, lesson_screen_standard_typing } from '@templates/student'

export default TypingView.extend({

  template: lesson_typing_fill_in_blank,

  contentClassName: 'Basic',

  // the state of the page: words | lesson
  mode: 'words',
  words: [],
  currentIndex: 0,

  preAnimationLines: 0, // this is really only a coding curriculum thing
  postAnimationLines: 1,

  initialize: function() {
    TypingView.prototype.initialize.apply(this, arguments);

    const events = this.events || {},
      words = this.screen.get('settings')?.fill_in_blank_words?.split(',')

    this.delegateEvents(
      extend(events, {
        'click .js-continue-button': 'continue',
        'click .js-nav-word': 'wordClick',
        'keydown input': 'handleEnter'
      })
    );

    const userWords = Registry.get('student').get(`_fillinblank_${this.screen.id}`) || []
    this.words = words.map((word, index) => ({ word: word.trim(), userWord: userWords[index] }))
    this.currentIndex = this.words.findIndex((row) => !row.userWord) === -1 ? this.words.length - 1 : this.words.findIndex((row) => !row.userWord)
  },

  /**
   * Override this
   * @returns {*}
   */
  render: function() {
    Registry.set('preventKeyboardInput', true);

    this.listenTo(this.screen, 'change:letterCacheIndex', this.animate);

    const userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens'),
      unitType = this.unit ? this.unit.get('type') : '';

    this.$el.append(this.template({
      ads: AdView.canShowAds() && (unitType === 'test' || (unitType !== 'test' && totalScreens > 0)), // Always show ads on the test page
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens, forceAd: unitType === 'test' }),
      mode: this.mode,
      words: this.words,
      currentIndex: this.currentIndex
    }));

    TypingView.prototype.render.apply(this, arguments);

    this.typingContent = this.$('.screen' + this.contentClassName + '-lines')

    window.setTimeout(() => {
      this.$(`input[name=word_${this.currentIndex}]`).focus()
    }, 100)

    return this;
  },

  triggerCacheAnimations: function() {
    this.typingContent = this.$('.js-screen-lines');
    this.cacheAnimations();
  },

  /**
   * Animate the vertically
   */
  topOffset: 0,
  lastOffset: 0,
  rowHeight: 0,
  lastAnimationLine: 0,
  animate: function(model, index) {
    var nextEle = this.lettersCache[index+1],
      currentEle = this.lettersCache[index];

    if(!nextEle) return;
    if(currentEle.animationOffset !== this.lastOffset) {
      var offset = currentEle.animationOffset;
      this.lastOffset = offset;
      // Only animate if there are visible lines below the viewing area.
      if((this.screen.get('lines') - this.lastAnimationLine > this.screen.get('visibleLines') - this.postAnimationLines) &&
        currentEle.animationLine !== this.lastAnimationLine
      ) {
        this.lastAnimationLine = currentEle.animationLine;
        var duration = (this.lowPerformance) ? 100 : 250;
        var translateY = parseInt('-'+ (offset - (this.topOffset))) + (this.preAnimationLines * this.rowHeight);
        if(this.preAnimationLines && this.screen.get('typed') === 0) {
          Velocity(this.typingContent, 'stop')
          Velocity(this.typingContent, {translateY: translateY + 'px'}, {easing: 'ease-in-out', duration: 1000});
        } else {
          Velocity(this.typingContent, 'stop')
          Velocity(this.typingContent, {translateY: translateY + 'px'}, {easing: 'ease-in-out', duration: duration});
        }
      }
    }
  },

  handleEnter: function(e) {
    if(e.which === 9 || e.which === 13) {
      this.continue()
      return false
    }
  },

  wordClick: function(e) {
    const word = this.$(`input[name=word_${this.currentIndex}]`).val().trim(),
      nextIndex = parseInt($(e.currentTarget).data('id'))

    if(nextIndex === this.currenIndex) {
      return false
    }

    this.setWord(word)

    this.animateWordEle(nextIndex)

    return false
  },

  continue: function() {
    if(this.mode === 'words') {
      const word = this.$(`input[name=word_${this.currentIndex}]`).val().trim()
      if(word.length === 0) {
        return false
      }

      this.setWord(word)

      const nextIndex = this.words.findIndex((row) => !row.userWord)

      if(nextIndex === -1) {
        this.mode = 'lesson'
        this.setLessonMode()
      } else {
        this.animateWordEle(nextIndex)
      }
    }

    return false;
  },

  handleComplete: function() {
    this.screen.set({ response: this.userResponse })

    TypingView.prototype.handleComplete.apply(this, arguments)
  },

  animateWordEle: function(nextIndex) {
    const curWordEle = this.$(`.js-word-input[data-id=${this.currentIndex}]`),
      nextWordEle = this.$(`.js-word-input[data-id=${nextIndex}]`),
      curWordNavEle = this.$(`.js-nav-word[data-id=${this.currentIndex}]`),
      nextWordNavEle = this.$(`.js-nav-word[data-id=${nextIndex}]`),
      wordSet = !!this.words[this.currentIndex].userWord

      // curWordEle.addClass('is-hidden')
      // curWordEle.on('transitionend', () => {
        curWordEle.hide()
        nextWordEle.show()
        nextWordEle.removeClass('is-hidden')
      // })

    if(wordSet) {
      curWordNavEle.html(this.words[this.currentIndex].userWord).addClass('fib--dark')
    } else {
      curWordNavEle.html(`shared.lesson.fill_in_blank.${this.words[this.currentIndex].word}`.t()).removeClass('fib--dark')
    }

    curWordNavEle.removeClass('active')
    nextWordNavEle.addClass('active')

    this.currentIndex = nextIndex

    this.$(`input[name=word_${this.currentIndex}]`).focus()
  },

  setWord: function(word) {
    word = word.stripHTML()
    this.words[this.currentIndex].userWord = (word.length > 0 ? word : null)

    Registry.get('student').set(`_fillinblank_${this.screen.id}`, this.words.map(row => row.userWord))
  },

  setLessonMode: function() {
    this.setUserResponse()

    const key = this.screen.getContentByLine()[0][0].toLowerCase(),
      wordsContentEle = this.$('.js-words'),
      lessonContentEle = this.$('.js-lesson'),
      userWords = this.words.map((row) => row.userWord),
      formattedTypingContent = this.screen.get('formatted_content').replaceAll(/___/g, () => userWords.length ? ('‡' + userWords.shift() + '‡') : 'X'),
      typingContent = formattedTypingContent.replaceAll(/‡/g, '')

    this.screen.set({ content: typingContent, formatted_content: formattedTypingContent })

    this.$('.js-screen-content').append(lesson_screen_standard_typing({
      lesson: this.lesson.toJSON(),
      typingContent: this.screen.getContentByWord(),
      image: this.screen.get('settings')?.image,
      displayType: 'advanced',
      hasKeyboard: this.keyboardView && this.user.getSetting('show_keyboard'),
      wordsPerPage: this.wordsPerPage,
      testType: this.lesson.getTestType()
    }));

    wordsContentEle.addClass('hide')
    lessonContentEle.removeClass('hide')

    if(this.keyboardView) {
      this.keyboardView.highlightKey(key);
    }

    window.setTimeout(() => {
      this.triggerCacheAnimations()
      this.setPosition(0)
      this.startCursorAnimation()
      $('.js-input-box').focus()
    }, 0);
  },

  setUserResponse: function() {
    const userWords = this.words.map((row) => row.userWord)

    this.userResponse = this.screen.get('formatted_content').replaceAll(/___/g, () => userWords.length ? ('<strong>' + userWords.shift() + '</strong>') : 'X')
  }
})
