import FormModel from '../models/forgot_password'
import Modal from '../../global/views/modal'
import { login_forgot_password } from '@templates/student'

export default Modal.extend({

  titleText: 'index.forgot_login_info_title'.t(),

  okText: 'index.forgot_password_modal.modal_ok_text'.t(),

  template: login_forgot_password,

  modelClass: FormModel

});
