import Backbone from 'backbone'
import $ from 'jquery'
import ChartConfig from '@shared/chart_config'
import Chart from 'chart.js'
import { shared_chart } from '@templates/shared'

export default Backbone.View.extend({

  template: shared_chart,

  chartDefaults: {},

  events: {
    'click .js-toggle': 'toggleClicked'
  },

  initialize: function(options) {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.toggles = this.toggles || [];
  },

  serialize: function() {
    if(!this.selectedToggle && this.toggles.length > 0) {
      this.selectedToggle = this.toggles[0];
    }

    return {
      chartId: this.chartId,
      title: this.title,
      toggles: this.toggles,
      selectedToggle: this.selectedToggle,
      width: this.width,
      height: this.height,
      dataLength: this.dataLength
    };
  },

  resetChart(chartData) {
    this.chartData = chartData || this.chartData;

    if(this.chartData.data) {
      this.dataLength = this.chartData.data.length;
    }

    this.render();
  },

  resetView(data) {
    this.title = data.title || this.title;
    this.toggles = data.toggles || this.toggles;
    this.selectedToggle = data.selectedToggle || this.toggles[0];
    this.width = data.width || this.width;
    this.chartData = data.chartData || this.chartData;

    this.render();
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    if(this.chartData) {
      if(this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart('js-chart-' + this.chartId, this.generateChartConfig());
    }

    return this;
  },

  toggleClicked: function(e) {
    var target = $(e.currentTarget),
      toggle = $(e.currentTarget).data('id');

    if(target.hasClass('is-active')) return;

    this.$('.js-toggle').removeClass('is-active');
    this.$('.js-toggle[data-id='+toggle+']').addClass('is-active');

    this.selectedToggle = toggle;

    this.chart.destroy();
    this.chart = new Chart('js-chart-' + this.chartId, this.generateChartConfig());
  },

  generateChartConfig() {
    return ChartConfig.createGraphObj(
      this.chartDefaults,
      this.chartData.types ? this.chartData.types[this.selectedToggle] : undefined, // Default type is "line", but sometimes we want a different type
      this.chartData.labels.filter((row, index) => ((this.selectedToggle !== 'progress' && this.selectedToggle !== 'avg_progress') || ((this.selectedToggle === 'progress' || this.selectedToggle === 'avg_progress') && !this.chartData.data[index].hideProgress))),
      {
        data: this.chartData.data
          .filter((row) => ((this.selectedToggle !== 'progress' && this.selectedToggle !== 'avg_progress') || ((this.selectedToggle === 'progress' || this.selectedToggle === 'avg_progress') && !row.hideProgress)))
          .map((row) => row[this.selectedToggle]),
        label: this.selectedToggle
      },
      this.chartData.options ? this.chartData.options[this.selectedToggle] : {});
  }
})
