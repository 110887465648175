import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import CelebrationEffect from '@shared/celebration_effect'
import { lesson_congrats_qa } from '@templates/student'

export default Backbone.View.extend({

  template: lesson_congrats_qa,

  events: {
    'click .js-restart': 'restart'
  },

  initialize: function(options) {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.userLessons = Registry.get('userLessons');
    this.lessons = Registry.get('lessons');
    this.units = Registry.get('units');

    if(this.progressView) {
      this.addChild('.js-progress', this.progressView, true);
    }
  },

  serialize: function() {
    this.lessons.setProgress(this.userLessons);
    this.units.setProgress(Registry.get('lessons'));

    var activeLessonId = this.units.get(this.lesson.get('unit_id')).get('active_lesson'),
      activeLesson = this.lessons.get(activeLessonId),
      userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens');

    var userScreenResults = this.screens.map(function(screen) {
      var userScreen = this.userLessonScreens.get(screen.id);
      const sourceScreen = this.screens.get(screen.id)
      const settings = sourceScreen.get('settings')
      const displayAsImage = settings?.display_as === 'images'

      return {
        // userScreen.get('std') represents the answer the user chose
        answer: screen.get('content').split('\n')[0],
        userAnswer: screen.get('content').split('\n')[userScreen.get('std')],
        displayAsImage,
        title: screen.get('title')
      };
    }.bind(this));

    return {
      ads: AdView.canShowAds(),
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens }),
      lesson: this.lesson.toJSON(),
      userScreenResults: userScreenResults,
      numCorrectAnswers: this.lesson.get('screens') - this.userLesson.get('errors'),
      unit: this.units.get(this.lesson.get('unit_id')).toJSON(),
      showMascot: this.user.getCurriculumGrade() < 7,
      activeLesson: (activeLesson) ? activeLesson.toJSON() : null,
      allowQuizRetake: this.user.hasOption('allowquizretakes'),
      congratsText: this.lesson.get('settings')?.congrats,
      showButtons: !this.hasGoogleClassroomAddon,
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    if(this.units.get(this.lesson.get('unit_id')).get('badge')) {
      this.$('.js-badge-img').each((index, badge) => {
        this.$(badge).append(Registry.get('userLessons').getBadgeForId(this.lesson.id).el);
      });
    }

    if(this.userLesson.get('errors') === 0) { // Only celebrate if they get 100% on the quiz
      window.setTimeout(() => {
        // Reposition effect so that it is over the entire screen
        var celebrationEle = this.$('.js-celebration-effect');
        celebrationEle.css({ 'top': -celebrationEle.offset().top, 'left': -celebrationEle.offset().left });
        // Instantiate confetti
        CelebrationEffect.init(celebrationEle[0], { screenHeight: $(document).height(), screenWidth: $(document).width(), launchCount: 20, colors: this.user.getSkinColors() });
      }, 0);
    }

    var data = this.serialize();
    this.dictation.speakQALessonComplete(data);

    return this;
  },

  restart: function() {
    this.userLesson.set({
      progress: 0
    });
    window.setTimeout(window.location.safeReload, 0);

    return false;
  }
})
