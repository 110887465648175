import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import { extend, bind } from 'lodash-es'
import { isEdge, isChromeOS, isWindows, isLinux, isMac, isIOS } from '@shared/helpers'

const supportsIsTrusted = function() {
  if(supportsIsTrusted.supports !== undefined) {
    return supportsIsTrusted.supports;
  }
  var e = document.createEvent('KeyboardEvent')
  supportsIsTrusted.supports = (e.isTrusted !== undefined);
  return supportsIsTrusted.supports;
};

let KeyboardInput = function(){};

/**
 * Any codes in this list will get a e.preventDefault() so it does not bubble
 * @type {number[]}
 */
const IGNORE_KEY_DOWN_CODES = [
  0,    // unknown
  12,  // numlock
  16, // shift
  17, // ctrl left
  18, // alt right
  20,  // caps lock
  27,  // esc
  46,  // delete
  91,  // command
  93 // context menu
];

/**
 * These keys will be treated as characters and prevent further events
 * @type {number[]}
 */
const KEY_DOWN_AS_INPUT = [
  8, // backspace,
  9, // tab
  13, // enter
  32 // space
];

/**
 * List of all keyboard shortcuts to detect by default (this can be overridden on initialize)
 */
const ALL_SHORTCUTS = ['sound','restart', 'keyboard', 'dictation'];

extend(KeyboardInput.prototype, Backbone.Events, {

  boundElement: null,

  browser: 'other',

  os: 'other',

  shortcuts: ALL_SHORTCUTS,

  initialize: function(options) {
    options = options || {};

    if(isEdge()) {
      this.browser = 'edge';
    }

    if(isChromeOS()) {
      this.os = 'chrome os';
    } else if(isWindows()) {
      this.os = 'windows';
    } else if(isMac()) {
      this.os = 'mac';
    } else if(isLinux()) {
      this.os = 'linux';
    } else if(isIOS()) {
      this.os = 'ios';
    }

    // for iOS we can't auto-focus an input element, so just use the body.
    // Linux does not acknowledge dead keys from within an input.
    // Trying to auto-focus causes dup events.
    // And if it's a software keyboard they'll have to click the input to bring up the keyboard it so we're good.
    if(this.os === 'ios' || this.os === 'linux') {
      options.boundElement = null;
    }

    if(options.shortcuts) {
      this.shortcuts = options.shortcuts;
    }

    this.start((options.boundElement && options.boundElement.length) ? options.boundElement : $('body'));
  },

  refocusInput: function() {
    if (!this.boundElement || !this.boundElement[0]) {
      // If not set, default to the body
      this.boundElement = [$('body')];
    }
    // stick this in a setTimeout because focus timing is weird on some instances
    window.setTimeout(() => this.boundElement[0].focus({preventScroll: true}), 0);
  },

  start: function(element) {
    if(element) {
      this.stop();
      this.boundElement = element;
    }

    this.boundElement[0].focus();
    this.boundElement[0].addEventListener('blur', function(e){
      const googleClassroomAddonModalAction = e.sourceCapabilities === null && !!window.localStorage.getItem('googleClassroomAddonData')
      if(!Registry.get('preventKeyboardInput') && !googleClassroomAddonModalAction){
        this.refocusInput();
      }
    }.bind(this));
    this.boundElement.on('keypress', bind(this.handleKeyDown, this));
    this.boundElement.on('keydown', bind(this.handleKeyDown, this));
    // this.boundElement.on('keyup', bind(this.handleKeyDown, this));
    if(this.os !== 'mac' && this.os !== 'ios') {
      this.boundElement.on('input', bind(this.handleKeyDown, this));
    }
  },

  stop: function() {
    if(this.boundElement) {
      if(this.boundElement.is('body')) {
        this.boundElement.off();
      } else {
        this.boundElement.replaceWith(this.boundElement.clone());
      }
    }
  },

  prevKeyEvents: {},
  prevTimeStamp: 0,
  hasPressed: false,
  handleKeyDown: function(e) {
    // On first keypress, notify AdX that the page is active
    if(!this.hasPressed) {
      this.hasPressed = true
      try{
        // noinspection JSUnresolvedReference,SpellCheckingInspection
        window?.PageOS?.setClientTag(6, 'tevax');
        // eslint-disable-next-line no-empty
      }catch (e){}
    }

    const timestamp = e.timeStamp
    e = e.originalEvent;

    // iOS 15 removed timeStamp from the orignalEvent. Also, we can't hijack timeStamp - so we inject timestamp into the event object and use timestamp
    e.timestamp = e.timeStamp === 0 ? timestamp : e.timeStamp

    this.trigger(e.type, e);
    if(e.type === 'keyup') {return;}

    // disable typing
    if(Registry.get('preventKeyboardInput')) {
      return true;  // but let it pass to other elements
    }

    // holding a key down so ignore
    if(e.repeat) { return false; }

    // faked event
    if(supportsIsTrusted() && !e.isTrusted) { return false; }

    // this duplicate check becomes sometimes we get dups from some browser/OS combos
    if(this.prevKeyEvents[e.type] && this.prevKeyEvents[e.type].timestamp === e.timestamp) { return false; }
    this.prevKeyEvents[e.type] = e;

    // attempt to get the key. some older browsers don't support event.key
    var key = e.key,
      keyCode = e.keyCode || e.which,
      hardware = e.code,
      special = false;

    // Check for keyboard shortcut and suppress any further keypresses if found
    if(this.checkForKeyboardShortcut(e, key)) { return false; }

    // it's one of the keys that we want to capture as keydown and forward as a character
    if(e.type === 'keydown' && KEY_DOWN_AS_INPUT.indexOf(keyCode) !== -1) {
      switch(keyCode) {
      case 8:   // backspace
        special = true;
        key = 'Backspace';
        break;
      case 9: // tab
        key = '\t';
        break;
      case 13:
        key = '\n';
        break;
      case 32:
        key = ' ';
        break;
      }
      this.trigger('character', {
        type: 'character',
        fromType: e.type,
        timeStamp: e.timestamp,
        keyCode: keyCode,
        special: special,
        key: key,
        hardware: hardware,
        target: e.target
      });

      return false; // prevent further bubbling
    }

    // let the event system forward these on
    if(e.type === 'keydown' && (!key || key.length !== 1) && IGNORE_KEY_DOWN_CODES.indexOf(keyCode) !== -1) {
      if (e.which === 20) {
        this.trigger('capslock', true);
      }
      return true;
    }

    // shift + ctrl + tilde (~) hack to skip a lesson
    if (e.which === 192 && e.shiftKey && e.ctrlKey) {
      var user = Registry.get('student');
      if(FTWGLOBALS('env') === 'local' || window.location.hostname.match(/feature-(.*)\.typing\.com/) || window.location.hostname.match(/release-(.*)\.typing\.com/) || window.location.hostname.match(/staging-(.*)\.typing\.com/) || (user.get('email') && user.get('email').indexOf('@teaching.com') !== -1)) {
        key = 'NEXT';

        this.trigger('character', {
          type: 'character',
          fromType: e.type,
          timeStamp: e.timestamp,
          keyCode: keyCode,
          special: true,
          key: key,
          hardware: hardware,
          shiftKey: e.shiftKey,
          altKey: e.altKey,
          metaKey: e.metaKey,
          ctrlKey: e.ctrlKey,
          target: e.target
        });
      }
      return false; // prevent further bubbling
    }

    if(e.type === 'keydown' && key === 'Dead') {
      this.trigger('character', {
        type: 'character',
        fromType: e.type,
        timeStamp: e.timestamp,
        key: key,
        keyCode: keyCode,
        hardware: hardware,
        dead: true,
        shiftKey: e.shiftKey,
        altKey: e.altKey,
        metaKey: e.metaKey,
        ctrlKey: e.ctrlKey,
        target: e.target
      });
      return true;
    } else if(e.type === 'keydown' && key && this.os !== 'ios' && this.os !== 'chrome os' && this.os !== 'linux') { // if we have a key in keydown, then let's just trust that
      this.trigger('character', {
        type: 'character',
        fromType: e.type,
        timeStamp: e.timestamp,
        key: key,
        hardware: hardware,
        special: key.length !== 1, //|| e.altKey || e.metaKey,
        keyCode: keyCode,
        shiftKey: e.shiftKey,
        altKey: e.altKey,
        metaKey: e.metaKey,
        ctrlKey: e.ctrlKey,
        target: e.target,
        hasCapsLock: this.checkForCapsLock(e, key)
      });
      return e.metaKey || e.ctrlKey;

    } else if(e.type === 'keypress') {
      if(!key) {
        key = String.fromCharCode(e.keyCode || e.which).trim();
      }
      this.trigger('character', {
        type: 'character',
        fromType: e.type,
        timeStamp: e.timestamp,
        key: key,
        hardware: hardware,
        keyCode: keyCode,
        special: key.length !== 1,
        shiftKey: e.shiftKey,
        altKey: e.altKey,
        metaKey: e.metaKey,
        ctrlKey: e.ctrlKey,
        target: e.target,
        hasCapsLock: this.checkForCapsLock(e, key)
      });
      // true for ios because it types into a password box which needs to return so it does't hold Caps on forever
      // also true for meta keys incase they're refreshing
      return (this.os === 'ios' && e.target.tagName === 'INPUT') || e.metaKey || e.ctrlKey;

    } else if(e.type === 'input') {
      if(this.os === 'mac') return;

      if(e.target.value){
        key = e.target.value.substr(-1);
      } else if (e.value) {
        key = e.value.substr(-1);
      }

      if(key) {
        this.trigger('character', {
          type: 'character',
          fromType: e.type,
          timeStamp: e.timestamp,
          key: key,
          hardware: hardware,
          keyCode: key.charCodeAt(0),
          shiftKey: e.shiftKey,
          altKey: e.altKey,
          metaKey: e.metaKey,
          ctrlKey: e.ctrlKey,
          target: e.target
        });
        return false;
      }
    }
  },

  checkForCapsLock: function(e, key){
    if(this.os === 'ios' && e.target.tagName === 'INPUT') { return false; }

    if (!e.shiftKey && key.match(/[a-zA-Z]/) && key.length === 1) {
      // unfortunately we can only detect if they press the caps lock on, not when they press it off so we do a check
      if (key === key.toUpperCase()) {
        this.trigger('capslock', true);
        return true;
      } else if (key !== key.toUpperCase()){
        this.trigger('capslock', false);
      }
    }
    return false;
  },

  checkForKeyboardShortcut: function(e, key) {
    if(this.shortcuts.indexOf('sound') !== -1 && (e.metaKey || e.ctrlKey) && key === 's') {
      this.trigger('shortcut', 'sound');
      return true;
    } else if(this.shortcuts.indexOf('restart') !== -1 && (e.metaKey || e.ctrlKey) && key === 'r') {
      this.trigger('shortcut', 'restart');
      return true;
    } else if(this.shortcuts.indexOf('keyboard') !== -1 && (e.metaKey || e.ctrlKey) && key === 'k') {
      this.trigger('shortcut', 'keyboard');
      return true;
    } else if(this.shortcuts.indexOf('dictation') !== -1 && (e.metaKey || e.ctrlKey) && e.shiftKey && key === 'd') {
      this.trigger('shortcut', 'dictation');
      return true;
    }

    return false;
  }
})

export default KeyboardInput
