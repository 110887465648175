import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import AssignmentAlertView from '../../global/views/assignment_alert'
import Model from '../models/prompt'
import AdButtonsView from '../../global/views/ad_buttons'
import AdView from '../../global/views/ad'
import { each } from 'lodash-es'
import Quill from 'quill'
import { prompt_main } from '@templates/student'

export default Backbone.View.extend({

  template: prompt_main,

  modelClass: Model,

  formSelector: 'form',

  events: {
    'keyup .js-text-area': 'processInput',
    'paste .js-text-area': 'handlePaste'
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.response = Registry.get('student').get('_writtenResponse' + this.assignmentId);
    this.assignment = Registry.get('assignments').get(this.assignmentId);
    Registry.get('student').unset('_writtenResponse' + this.assignmentId);

    $(window).on('beforeunload', this.saveProgress.bind(this));

    if(AdView.canShowAds()) {
      AdView.initAds('lesson_screen');
      // grab all the ads and assign them to the right divs
      each(AdView.getAds(), (ad, size) => {
        if(!ad) { return; }
        this.addChild('.js-'+size+'-ad', ad);
      });

      var adButtonsView = new AdButtonsView();
      this.addChild('.js-ad-buttons', adButtonsView, false);
    }
  },

  serialize: function() {
    return {
      ads: AdView.canShowAds(),
      adsClass: AdView.getLeftMarginClass(),
      assignment: this.assignment ? this.assignment.toJSON() : undefined
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    var container = this.$('.js-text-area').get(0),
      options = {
        modules: {
          toolbar: {
            container: [
              [{header: [1, 2, 3, 4, 5, 6, false]}],
              ['bold', 'italic', 'underline'],
              [{list: 'ordered'}, {list: 'bullet'}],
              ['clean']
            ]
          }
        },
        theme: 'snow',
        placeholder: 'lesson.written_prompt_placeholder'.t({word_count: this.assignment.get("word_count")}),
      };
    this.editor = new Quill(container, options);

    window.setTimeout(() => {
      var ele = this.$('.ql-editor');

      ele.html(this.response).focus();
      this.$('.js-current-words').text(Math.floor(ele.text().length / 5));

      this.processInput();

      this.dictation.speakWrittenPrompt(this.assignment.toJSON());
    }, 0);

    return this;
  },

  processInput: function() {
    var ele = $('.ql-editor'),
      plainText = ele.text(),
      response = ele.html(),
      currentWordCount = Math.floor(plainText.length / 5);

    // Update word count
    this.$('.js-current-words').text(currentWordCount);

    this.$('input[name=response]').val(response);

    if(currentWordCount >= this.assignment.get('word_count')) {
      this.$('.js-submit').removeClass('is-disabled').prop('disabled', false);
    } else {
      this.$('.js-submit').addClass('is-disabled').prop('disabled', true);
    }
  },

  handlePaste: function(e) {
    e.preventDefault();
    return false;
  },

  submitCallback: function() {
    var alert = new AssignmentAlertView();
    alert.show([this.assignment.id]);

    // Disable the submit button so the user cannot submit again
    this.$('.js-submit').addClass('is-disabled').prop('disabled', true);

    Registry.get('assignments').remove([this.assignment.id]);
    window.location.href = __url('/student/lessons');
  },

  saveProgress: function() {
    var response = $('.ql-editor').html();

    if (this.editor && this.editor.getLength() > 1 && Registry.get('assignments').get(this.assignmentId)) {
      Registry.get('student').set('_writtenResponse' + this.assignmentId, response);
    }
  }
})
