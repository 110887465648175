import Backbone from 'backbone'
import AuthModal from './auth_modal'
import { global_guest_banner } from '@templates/student'

export default Backbone.View.extend({

  customMessage: null,
  
  customTitle: null,

  events: {
    'click .js-close-guest-banner': 'close',
    'click .js-login': 'login',
    'click .js-signup': 'signup'
  },

  template: global_guest_banner,

  serialize: function() {
    return {
      customMessage: this.customMessage,
      customTitle: this.customTitle,
      isTest: this.isTest,
      isChart: this.isChart,
      page: this.page
    };
  },

  login: function() {
    new AuthModal({ authType: 'login' }).open()

    return false;
  },

  signup: function() {
    new AuthModal({ authType: 'signup' }).open()

    return false;
  }

})
