import Backbone from 'backbone'
import Registry from '@registry'
import { pick, find, isEmpty, map, filter } from 'lodash-es'
import LessonsCollection from '../collections/lessons';

export default Backbone.Model.extend({

  idAttribute: 'assignment_id',

  convertLessons: function() {
    let model = this.toJSON(),
      lessons = model.lessons.split(','),
      // Registry.get('lessons') has all available lessons for the student. BUT... if a teacher hides some lessons, the tests are also hidden.
      // Therefore, we must grab tests from FTWGLOBALS('lessons') and concat it with all other lessons
      allTests = filter(FTWGLOBALS('lessons'), (row) => row.time_limit > 0),
      allLessons = new LessonsCollection(Registry.get('lessons').toJSON().concat(allTests))

    // Only convert the assignment's list of lessons IF we've downloaded all the lessons a student can view
    if(allLessons.length > 0 && typeof model.lessons === 'string') {
      lessons = filter(map(lessons,
        (row) => pick(find(allLessons.toJSON(), { lesson_id: parseInt(row) }),
          ['lesson_id', 'name', 'screens', 'time_limit'])),
        (row) => !isEmpty(row));

      // Sometimes a teacher removes a custom lesson, or switches the curriculum. When this happens some
      // assignments are not completable and are simply not shown to the user
      if (isEmpty(lessons)) {
        this.set({ invalid: true, lessons: [] })
      } else {
        this.set({
          lessons: lessons.length > 0 ? lessons : model.lessons
        });
      }
    }
  },

  convertGames: function() {
    let model = this.toJSON(),
      user = Registry.get('student'),
      allGames = user.getGames(FTWGLOBALS('games')),
      games = {}

    try {
      games = JSON.parse(this.get('games'))
    } catch(e) {
      this.set({ invalid: true, games: [] })
      return false
    }

    // Only convert the assignment's list of games if the student has access to the games
    if(allGames.length > 0) {
      games = filter(map(allGames,
          (row) => find(games, { game_id: parseInt(row.game_id) })),
        (row) => !isEmpty(row))
      // Sometimes a teacher remove games. When this happens some
      // assignments are not completable and are simply not shown to the user
      if (isEmpty(games)) {
        this.set({ invalid: true, games: [] })
      } else {
        this.set({ games: games })
      }
    } else {
      this.set({ invalid: true, games: [] })
    }
  },

  checkIfComplete: function() {
    var userLessons = Registry.get('userLessons') ? Registry.get('userLessons').toJSON() : [],
      userTests = Registry.get('userTests') ? Registry.get('userTests').getCompiled() : [],
      userGames = Registry.get('userGames') ? Registry.get('userGames').getCompiled(this.get('created_at')) : []

    if(typeof this.get('lessons') === 'string') {
      return false
    }

    if(this.get('type') === 'written_prompt') {
      return !!this.get('complete');
    } else if(this.get('type') === 'typing_games') {
      const completeCount = this.get('games').filter((game) => { // Return all completed games
          const userGame = userGames[game.game_id + '_' + game.difficulty + '_' + game.level]
          return (userGame >= game.seconds) // If user has finished enough time in the game on the appropriate difficulty/level
        }).length;

      return this.get('games').length === completeCount;
    } else {
      const completeCount = this.get('lessons').filter((lesson) => { // Return all completed lessons
        const userLesson = find(userTests, { 'lesson_id': lesson.lesson_id }) || find(userLessons, { 'lesson_id': lesson.lesson_id });

        return (userLesson &&
          ((lesson.time_limit && userLesson.created_at >= this.get('created_at')) || // If lesson is a test and the test was finished after the assignment was assigned
            (!lesson.time_limit && userLesson.max_progress >= lesson.screens))); // If user has finished all screens in the lesson
      }).length;
      return this.get('lessons').length === completeCount;
    }
  }

})
