import $ from 'jquery'
import ScreenCompleteView from './screen_complete'
import { extend, isEmpty, map } from 'lodash-es';
import { lesson_screen_complete_resume } from '@templates/student'

export default ScreenCompleteView.extend({

  template: lesson_screen_complete_resume,

  state: 'showingStats',
  renderContent: '',

  serialize: function() {
    const paths = this.screen.get('paths') || [],
      parentSerialized = ScreenCompleteView.prototype.serialize.apply(this, arguments),
      displayOrder = this.screen.get('display_order'),
      screenPath = this.getScreenSlug(this.screen.get('title'))

    paths.forEach((row) => {
      const screen = this.screens.get(row.to_id)
      row.slug = screen ? screen.get('title') : ''
    })

    // Run through each screen to create the rendered content
    this.screens.toJSON().forEach((screen) => {
      if(screen.display_order === 0) return // Never show the first screen (it's just an intro typing screen)
      if(this.getScreenSlug(screen.title) !== screenPath) return // Only display screens from the same path

      let sectionType = (screen.display_order === displayOrder ? 'is-active' : (screen.display_order < displayOrder ? 'is-complete' : '')),
        splitContent = screen.original_content.split('\n'),
        processingList = false

      this.renderContent += `<div class="screenResume-section ${sectionType}">`

      // If this is the first job specific screen
      if(screen.title.match('_1')) {
        const avatarClasses = this.user.get('skin_id') === 10 ? 'avatar--basic-resume' : `avatar--${String(this.rank.id).pad(2, '0', 'STR_PAD_LEFT')} avatar--v${this.user.get('variant_id')}`
        this.renderContent += `
          <div class="g g--flag g--s">
            <div class="g-b g-b--3of12">
              <div class="avatar ${avatarClasses} avatar--fw"></div>
            </div>
            <div class="screenResume-profile g-b g-b--9of12">`
      }


      // Split content by line and reformat it to be properly rendered
      this.renderContent += splitContent.map((row, index) => {
        row = row.replace('⏎', '')

        if(row[0] === '#') { // Show Title
          row = '<div class="screenResume-sectionTitle"><div class = "screenResume-block">' + row.substring(2) + '</div></div>'
        } else if(row.startsWith('- ')) { // Show List
          if(!processingList) { // First item in list
            row = '<ul class="screenResume-list"><li class="screenResume-listItem screenResume-block">' + row.substring(2) + '</li>'
          } else { // Item in list
            row = '<li class="screenResume-listItem screenResume-block">' + row.substring(2) + '</li>'
          }
          // Close out the list if this is the last line of the screen content
          if(index === splitContent.length - 1) {
            row += '</ul>'
          }
          processingList = true
        } else { // Standard text
          row = (processingList ? '</ul>' : '') + '<div class = "screenResume-block">' + row + '</div><br />'
          processingList = false
        }

        return row
      }).join('')

      if(screen.title.match('_1')) {
        this.renderContent += '</div></div>'
      }
      this.renderContent += '</div>'
    })

    return extend(parentSerialized, {
      paths: paths,
      finalScreen: paths.length === 0,
      renderContent: this.renderContent
    })
  },

  continue: function(e) {
    const id = $(e.currentTarget).data('id'),
      screen = this.screens.get(id),
      progress = this.screens.indexOf(screen)

    if(this.state === 'showingStats') {
      if(this.goalView) {
        this.goalView.closeTooltip()
      }
      this.showNewSection()
    } else {
      if(progress !== -1) { // -1 means there is no more progress
        this.userLesson.set({ progress: progress })
      }

      this.saved = true;

      this.trigger('continue', 'tyoa_pick', $(e.currentTarget));
      return false
    }
  },

  showNewSection: function() {
    this.$('.js-screen-complete-content').fastHide();

    if(this.screen.get('display_order') === 0) {
      // the screen complete should present job options
      this.$('.js-resume-jobs').fastShow();
      // this.speakResumeComplete()
    } else {
      // the screen complete should just be summary and details for next section
      this.$('.js-resume-content').fastShow();
    }

    this.state = 'showingResume'
  },

  handleKeyPress: function(character) {
    const options = map(this.screen.get('paths'), 'title')

    if(character.keyCode === 13) {
      // Choosing an option
      if(this.state === 'showingStats' || (this.state === 'showingResume' && options.length === 1)) {
        this.$('.js-continue-button:visible').click();
      } else if(this.selectedOptionIndex !== undefined) {
        this.$(`.js-continue-button[data-index=${this.selectedOptionIndex}]`).click();
      }
    } else if(character.key <= options.length && this.state === 'showingResume') {
      if(parseInt(character.key) === 0) {
        // Repeat the intro dictation for this screen
        this.speakResumeComplete();
      } else {
        // Read individual option to user
        const option = this.screen.get('paths')[character.key - 1]
        if(option) {
          this.dictation.speakOption({ text: option.title + '. ' });
          this.selectedOptionIndex = character.key - 1
        }
      }
    }

    return false;
  },

  speakResumeComplete: function() {

  },

  getScreenSlug: function(title) {
    // Remove numbers and spaces to create the slug value
    return title.replace(/[0-9\s]/g, '')
  }
})

