import Registry from '@registry'
import Model from '../models/support_form'
import Modal from './modal'
import { global_support_modal } from '@templates/student';

export default Modal.extend({
  titleText: 'shared.support_text'.t(),

  okText: '',

  cancelText: '',

  successNotice: 'contact.success'.t(),

  template: global_support_modal,

  width: 'm',

  modelClass: Model,

  formSelector: 'form',

  events: {
    'click .js-contact-trigger': 'showContactForm',
    'click .js-cancel-primary': 'cancel',
    'click .js-search-articles': 'searchKnowledgeBase',
    'keydown .js-search-input': 'handleEnter'
  },

  serialize: function() {
    return {
      user: Registry.get('student').toJSON(),
      reasons: [
        [],
        ['contact.bug'.t(), 'contact.bug'.t()],
        ['contact.access'.t(), 'contact.access'.t()],
        ['contact.upgrade'.t(), 'contact.upgrade'.t()],
        ['contact.info'.t(), 'contact.info'.t()],
        ['contact.other'.t(), 'contact.other'.t()]
      ]
    };
  },

  showContactForm: function() {
    this.$('.js-categories, .js-contact-trigger, .js-cancel-primary').hide();
    this.$('.js-contact, .js-cancel, .js-submit').show();
    this.$('.js-contact [name]:not([disabled], .hidden)')[0].focus();
  },

  searchKnowledgeBase: function() {
    var query = this.$('.js-search-input').val();
    if (query.length > 0) {
      window.open('https://typingcom.helpscoutdocs.com/search?query=' + query);
      this.close();
    }
  },

  handleEnter: function(e) {
    if (e.which === 13) {
      this.searchKnowledgeBase();

      return false;
    }
  }


})
