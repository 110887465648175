import Backbone from 'backbone'
import $ from 'jquery'
import AdView from '../../global/views/ad'
import { template } from 'lodash-es'
import { lesson_instruction } from '@templates/student'
import Registry from '@registry';

export default Backbone.View.extend({

  template: lesson_instruction,

  events: {
    'click .js-interactive input': 'handleInteractive',
    'click .js-next-lesson': 'nextLesson'
  },

  initialize: function() {
    Backbone.View.prototype.initialize.apply(this, arguments)

    this.dictation.setProperties(this.screen);
  },

  serialize: function() {
    const userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens'),
      screen = this.screen.toJSON(),
      lesson = this.lesson.toJSON()

    screen.content = screen.content.replaceAll(/resources.edutyping.com/g, 'resources.typing.com/edutyping')
      .replace('Return to Lessons', '')

    return {
      ads: AdView.canShowAds() && totalScreens > 0,
      adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens }),
      content: (template(screen.content))({
        lesson: lesson,
        screen: screen
      }),
      lesson: lesson,
      screen: screen,
    };
  },

  render: function() {
    Backbone.View.prototype.render.apply(this, arguments);

    this.$('.js-inline').toArray().forEach(function(img){
      $.get(img.src).done(function(payload, status){
        if(status != 'success') return;
        $(img).replaceWith(new XMLSerializer().serializeToString(payload.documentElement));
      });
    });

    this.dictation.speakInstruction(this.screen.toJSON());
    return this;
  },

  handleInteractive: function(e) {
    var type = $(e.currentTarget).closest('.js-interactive').data('type');

    if(!type) {
      return;
    }

    if(this['handle' + type.ucFirst()]) {
      this['handle' + type.ucFirst()](e);
    }
  },

  handleCheckboxes: function(e) {
    var target = $(e.currentTarget),
      form = target.closest('.js-interactive'),
      image = form.find('svg,img'),
      checked = form.find('input:checked').toArray(),
      unchecked = form.find('input:not(:checked)').toArray();

    // Check if all the checked ones contain any errors
    var hasWrong = false;
    checked.forEach(function(ele) {
      if(ele.value == 'incorrect') {
        hasWrong = true;
      }
    });

    // if there are errors, then show an error and they're done
    if(hasWrong) {
      form.find('.flash--error').removeClass('hide').addClass('animate animate--fadeIn');
      form.find('.flash--success').addClass('hide');
      return;
    } else {
      form.find('.flash--error').addClass('hide');
    }

    // now see if any of the unchecked ones are correct.  if none are correct, then all the correct ones must be checked
    var hasUncheckedCorrect = false;
    unchecked.forEach(function(ele){
      if(ele.value == 'correct') {
        hasUncheckedCorrect = true;
      }
    });

    if(hasUncheckedCorrect) {
      form.find('.flash--success').addClass('hide');
    } else {
      form.find('.flash--success').removeClass('hide').addClass('animate animate--fadeIn');
      image.addClass('is-animate');
    }
  },

  nextLesson: function() {
    this.screen.set({
      typed: 1,
      errors: 0
    }, { silent: true });

    this.$('.js-next-lesson').addClass('btn--loading')
    this.trigger('complete')
    return false
  },

  hide: function() {
    this.stopListening(this.input);
  },
})
