const GoogleClassroomAddonModel = Backbone.Model.extend({

  url: '/apiv1/student/user/google-addon-submission',

  fetch: function() {
    const { attachmentId, courseId, itemId } = JSON.parse(window.localStorage.getItem('googleClassroomAddonData'));

    this.save({ attachmentId, courseId, itemId }, {
      contentType: 'application/json',
    });
  },
});

export default GoogleClassroomAddonModel;
