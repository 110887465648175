import FormModel from '@shared/form_model'

export default FormModel.extend({

  defaults: {

  },

  validationRules: {
    join_code: {
      required: true
    }
  },
  validationMessages: {
    join_code: {
      INVALID: 'shared.invalid_code'.t()
    }

  },

  url: '/apiv1/student/user/join'

})
