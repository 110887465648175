import Backbone from 'backbone'

export default Backbone.Model.extend({
  idAttribute: 'product_id',

  defaults: {
    name: '',
    display_order: 0
  }
})
