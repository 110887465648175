import Backbone from 'backbone'
import $ from 'jquery'
import { shared_keyboard_helper_languages } from '@templates/shared'

export default Backbone.View.extend({

  template: shared_keyboard_helper_languages,

  selectedLanguageId: FTWGLOBALS('defaultLanguage'),

  events: {
    'click .js-keyboard-language': 'selectLanguage',
    'keydown .js-keyboard-language': 'handleKeydown'
  },

  serialize: function() {
    return {
      keyboardLanguages: FTWGLOBALS('keyboardLanguages'),
      selectedLanguageId: this.selectedLanguageId
    };
  },

  handleKeydown: function(e) {
    if (e.which === 13) {
      this.selectLanguage(e);
    }
  },

  selectLanguage: function(e) {
    this.$('.js-keyboard-language').removeClass('is-active');
    $(e.currentTarget).addClass('is-active');
    this.selectedLanguageId = $(e.currentTarget).data('id');

    return false;
  }
})
