import FormModel from '@shared/form_model'

export default FormModel.extend({
  url: '/apiv1/student/auth/login',

  defaults: {
    code: null,
    username: null,
    password: null,
    screenWidth: screen.width
  },

  validationRules: {
    username: {
      required: true
    },
    password: {
      required: true
    }
  }
})
