import {
  global_form_helper_group,
  global_form_helper_text,
  global_form_helper_checkbox,
  global_form_helper_textarea,
  global_form_helper_hidden,
  global_form_helper_password,
  global_form_helper_select,
  global_form_helper_date
} from '@templates/student'
import InitHelpers from '@shared/form_helpers'

// Initialize the form helpers based on teacher templates
InitHelpers({
  global_form_helper_group,
  global_form_helper_text,
  global_form_helper_checkbox,
  global_form_helper_textarea,
  global_form_helper_hidden,
  global_form_helper_password,
  global_form_helper_select,
  global_form_helper_date
})

